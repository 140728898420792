import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Menu,
  MenuItem,
  FormControl,
  FormControlLabel, Checkbox, makeStyles, Radio
} from "@material-ui/core";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { addTaskAssignmentData } from "../../services/taskAssignmentData";
import { addActivity } from "../../services/activityTransaction";
import { toast } from "react-toastify";
import { addassetsSharings } from '../../services/assetsSharingService';
import DatePicker from 'react-datepicker';
import { showToast } from "../../messages";
import Loader from "../../utils/loader";
 
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
 
const useStyles = makeStyles((theme) => ({
  highPriority: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    color: "#DA4141"
  },
  moderatePriority: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    color: "#DBBC00"
  },
  lowPriority: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    color: "#18A350"
  },
  bullet: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    display: 'inline-block',
  },
  highBullet: {
    backgroundColor: '#DA4141',
  },
  moderateBullet: {
    backgroundColor: '#DBBC00',
  },
  lowBullet: {
    backgroundColor: '#18A350',
  },
  selectedValue: {
    display: 'flex',
    alignItems: 'center',
  },
}));
 
const TaskAssignmentModal = (props) => {
 
  const { isOpen, onClose, memberList, sectionHead, assetId, sectionId, assetType, currnetUser, currentUserName, assetSectionS,sectionType,callTaskAssignment } = props
 
  const [sectionTitle, setSectionTitle] = useState(sectionHead || "");
  const [priority, setPriority] = useState('')
  const [selectedMember, setSelectedMember] = useState([])
  const [dueDate, setDueDate] = useState("")
  const [taskDescription, setTaskDescription] = useState()
  const [selectedSectionId, setSelectedSectionId] = useState(sectionId || '');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const renderValue = (value) => {
    let bulletClass = '';
    if (value === 'High') bulletClass = classes.highBullet;
    else if (value === 'Moderate') bulletClass = classes.moderateBullet;
    else if (value === 'Low') bulletClass = classes.lowBullet;
 
    return (
      <div className={classes.selectedValue}>
        <div className={`${classes.bullet} ${bulletClass}`}></div>
        {value}
      </div>
    );
  };
  // const handleSelectedMember = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectedMember(typeof value === 'string' ? value.split(',') : value,)
  // };
  const handleSelectedMember = (event) => {
    const {
        target: { value },
    } = event;
    setSelectedMember(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
    );
};
 
 
  const handlePriority = (event) => {
    setPriority(event.target.value)
  }
  const handleTaskDescription = (event) => {
    setTaskDescription(event.target.value)
  }
 
  const handleDueDate = (date)=>{
            setDueDate(date)
        }
  useEffect(() => {
    setSectionTitle(sectionHead);
    setSelectedSectionId(sectionId);
  }, [sectionHead, sectionId]);
 
  useEffect(() => {
    if (isOpen) {
      setPriority('');
      setSelectedMember([]);
      setDueDate('');
      setTaskDescription('');
    }
  }, [isOpen]);

  // const submitTaskAssignment = () =>{
  //   selectedMember.map(e => handlePostTaskAssignmentData(e))
  // }
 


  const submitTaskAssignment = async () => {
    setLoading(true)
    for (const e of selectedMember) {
      await handlePostTaskAssignmentData(e);
    }
    setLoading(false)
  };


  const handlePostTaskAssignmentData = async (selectedUser) => {
    try {
      const data = {
        "assetId": assetId,
        "sectionTitle": sectionTitle ? sectionTitle : sectionHead,
        dueDate: dueDate,
        description: taskDescription,
        priority: priority,
        userToAssign: selectedUser,
        "sectionId": selectedSectionId ? selectedSectionId : sectionId,
        currentUser: currnetUser,
        assetType,
        currentUserName
      }
      console.log(data, "!!data")
      if (!data.dueDate || !data.description || !data.priority || !data.userToAssign) {
        showToast('Warning.allFields')
        
        return;
      }
      console.log("data of task assignment", data)
      const response = await addTaskAssignmentData(data)
      if (response.exists === true) {
        console.log("response of task assignment", response);
        showToast('Proposal.taskAlreadyAssigned')
        setPriority('')
        setTaskDescription("")
        setDueDate("")
        setSelectedMember([])
        onClose()
      } else if (response.status === 200) {
        console.log("response of task assignment", response);
        handleShareSection(selectedUser, response?.reAssignedStatus)
      } else {
        showToast('Proposal.errTaskAssign')
        console.log("error");
      }
    } catch (e) {
      showToast('Proposal.errTaskAssign')
      console.log(e)
    }
  }
 
  const handleShareSection = async (selectedUser, reAssignVariable) => {
    const data = {
      sharedwith: [{
        email: selectedUser,
        comment: taskDescription,
      }],
      assetid: assetId,
      assetType: assetType,
      loggedInEmail: currnetUser,
      assetSection: [{ id: selectedSectionId ? selectedSectionId : sectionId, sectionType: sectionType, email: [selectedUser] }],
      workflowName: "Task Assignment",
      fromStageName: "Open",
      toStageName: "Open",
      FromStatusName: "Open",
      ToStatusName: "Accept",
      orgId: sessionStorage.getItem("organizationId"),
      assetId: assetId,
      assetSectionId: selectedSectionId ? selectedSectionId : sectionId,
      assignedBy: currnetUser,
      assignedTo: [selectedUser],
      dueDate: dueDate,
      priority: priority,
      taskDescription,
      reAssignVariable
    };
    console.log(data, "__))data")
    const response = await addassetsSharings(data);
    if (response) {
      toast.success(response.message);
      // callTaskAssignment()
      const msg = "shared a section"
      onClose()
      const activity = await addActivity(assetId, assetType, msg, currentUserName)
      setPriority('')
      setTaskDescription("")
      setDueDate("")
      setSelectedMember([])
     
    } else {
      showToast('Proposal.rfpNotSent')
    }
  }
 
 
  const handleSectionTitleChange = (event) => {
    const selectedId = event.target.value;
    const selectedDocument = assetSectionS.find(doc => doc._id === selectedId);
 
    console.log("Selected Section ID", selectedId);
    console.log("Selected Section Document", selectedDocument);
 
    setSelectedSectionId(selectedId);
    setSectionTitle(selectedDocument.title);
  };
 
  const handleCancelClick = () => {
    
    setPriority('')
    setTaskDescription("")
    setDueDate("")
    setSelectedMember([])
    onClose()
  }
 


 
  return (

    <>
    <Loader loading={loading} />
      <Modal
        open={isOpen}
        onClose={onClose}
      >
        <div className="clauseProjectModal">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" id="Clause-modal-title">
              Task Assignment
            </Typography>
            <IconButton onClick={() => onClose()}>
              <HighlightOffIcon id="closeIcon" />
            </IconButton>
          </div>
 
          <div style={{ marginBottom: "12px" }}>
            {/* <TextField
 
                label="Section title"
                variant="outlined"
                size="small"
                value={sectionHead}
                style={{ width: "100%" }}
              /> */}
 
            <FormControl fullWidth>
              <InputLabel style={{ color: "black" }}>Section Title</InputLabel>
              <Select
                value={selectedSectionId}
                onChange={handleSectionTitleChange}
              >
                {assetSectionS && assetSectionS.map((document) => (
                  <MenuItem key={document._id} value={document._id}>
                    {document.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
 
          <div style={
            {
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "12px"
            }}>
            <div
              style={{ width: "49%" }}>
              <FormControl fullWidth>
              <InputLabel style={{ color: "black" }}>Add Member</InputLabel>
                <Select
                    multiple
                    value={selectedMember} // Note the name change to plural for clarity
                    onChange={handleSelectedMember}
                    renderValue={(selected) => selected.join(', ')} 
                >
                    {memberList?.map((data) => (
                        <MenuItem key={data.email} value={data.email}>
                            <Checkbox checked={selectedMember.indexOf(data.email) > -1} />
                            <ListItemText primary={data.email} />
                        </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
 
            {/* <div><p>End Date:</p></div> */}
            <div style={{ width: "49%" }}>
              <p>Due Date</p>
              {/* <TextField
                id="date-picker"
                type="date"
                // label="Due Date"
                value={dueDate}
                onChange={(e) => handleDueDate(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    height: "40px",
                    boxShadow: "1px 1px 4px -2px #000000",
                    padding: "8px",
                    width: "100%"
                  },
                  //   readOnly: clauseDataEditable
                }}
              /> */}
               <DatePicker
      selected={dueDate}
      onChange={(e) => handleDueDate(e)}
      dateFormat="yyyy-MM-dd"
      placeholderText="yyyy-mm-dd"
      customInput={
        <TextField
          label="Select Date"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          // style={{ width: 350}}
        /> }
        />
 
            </div>
 
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "49%" }} >
              <InputLabel style={{ color: "black" }}>Description</InputLabel>
              <TextField
                value={taskDescription}
                // label="Task Description"
                variant="outlined"
                rows={5}
                multiline
                style={{ width: "100%", borderRadius: "20px" }}
                onChange={(e) => handleTaskDescription(e)} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>
 
            <div style={{ width: "49%" }}>
              <FormControl fullWidth>
                <InputLabel style={{ color: "black" }}>Priority</InputLabel>
                <Select
                  labelId="priority-select-label"
                  id="priority-select"
                  value={priority}
                  onChange={handlePriority}
                  renderValue={renderValue}
                  MenuProps={{
                    MenuListProps: {
                      className: classes.menuList,
                    },
                  }}
                >
                  <MenuItem value={"High"} className={classes.highPriority}>
                    <div className={`${classes.bullet} ${classes.highBullet}`}></div>
                    High Priority
                  </MenuItem>
                  <MenuItem value={"Moderate"} className={classes.moderatePriority}>
                    <div className={`${classes.bullet} ${classes.moderateBullet}`}></div>
                    Moderate Priority
                  </MenuItem>
                  <MenuItem value={"Low"} className={classes.lowPriority}>
                    <div className={`${classes.bullet} ${classes.lowBullet}`}></div>
                    Low Priority
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
 
 
 
 
          </div>
 
 
 
 
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
            <div
              style={{ margin: "5px" }}
            >
              <Button
                variant="outlined"
                style={{ color: "red", borderColor: "red" }}
                onClick={() => handleCancelClick()}
                id="addclauseButton"
              >
                Cancel
              </Button>
            </div>
            <div style={{ margin: "5px" }}>
              <Button
                variant="outlined"
                onClick={submitTaskAssignment}
                id="addclauseButton"
                style={{ color: "white", borderColor: "#007bff", backgroundColor: "#007bff", }}
              >
                Add
              </Button>
            </div>
          </div>
 
 
        </div>
      </Modal>
    </>
  )
}
export default TaskAssignmentModal;

