import React, { useContext, useState, useEffect, useRef , useCallback} from "react";
import "./ChatInput.css";
import AttachmentIcon from '@mui/icons-material/Attachment';
import MoodIcon from '@mui/icons-material/Mood';
import { ChatContext } from "../../../contexts/ChatContext";
import Picker from 'emoji-picker-react';
import SendIcon from '@mui/icons-material/Send';
import ChatServices from "../../../services/ChatServices";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../redux/user/user.actions";
import StopIcon from '@mui/icons-material/Stop';
import { Card, CardContent, CardActions, Button, Typography, Box } from "@mui/material";
import AudioRecord from "./AudioRecord";
import { socket } from '../Socket/Socket';
import { addActivity } from "../../../services/activityTransaction";

const ChatInput = ({ disable, setDisable, handleReceiveMessage,  currentUser, messageType, setMessageType, selectedFile, setSelectedFile, selectedFileName, setSelectedFileName, aid, usr }) => {

  const [message, setMessage] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const { selectedContactId, selectedGroupId, setAudioURL, audios, setAudios, userId } = useContext(ChatContext);
  const pickerRef = useRef(null);

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


    const handleTextChange = (e) => {
    setMessage(e.target.value);
    setMessageType('text');
    setSelectedFile(null)
    setSelectedFileName(null)
    setAudios([]);
    };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file.name);
    setMessageType('file');
    setAudios([]);
    setMessage('');
    setDisable(true);
  };

  const sendFileMessage = () => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result;
      sendMessage(base64Data, 'file' , selectedFileName);
    };
    reader.readAsDataURL(selectedFile);
  }

  const sendTextMessage = () => {
      sendMessage(message, 'text' , '');
    }


  const sendAudioMessage = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      sendMessage(base64String, 'audio', '');
    };
    reader.readAsDataURL(audios[0].data);
  };


  const sendChat = (Type) => {
    switch (Type) {
      case 'text':
        sendTextMessage();
        setShowPicker(false);
        break;
      case 'file':
        sendFileMessage();
        setShowPicker(false);
        break;
      case 'audio':
        sendAudioMessage();
        setShowPicker(false);
        break;
      default:
        break;
    }
  }


  // const sendMessage = async (messages, messageTypes , name) => {
  //   try {
  //     if (selectedContactId && selectedContactId !== null) {
  //        const response = await ChatServices.sendMessage(currentUser._id, selectedContactId, messages, messageTypes, name);
  //     if (response.status === 200) {
  //       fetchMessages();
  //       setMessageType('');
  //       setMessage('');
  //       setSelectedFile(null);
  //       setSelectedFileName(null);
  //       setAudios([]);
  //       setAudioURL('');
  //       setDisable(false)
  //       document.getElementById('fileInput').value=null;
  //     } else {
  //       console.error("Error Opening Chat:", response.message);
  //     }
  //   }
  //     else if (selectedGroupId && selectedGroupId !== null) {
  //       const response = await ChatServices.sendMessageInGroup(currentUser._id, selectedGroupId, messages, messageTypes, name);
  //       if (response.status === 200) {
  //         fetchMessages();
  //         setMessageType('');
  //         setMessage('');
  //         setSelectedFile(null);
  //         setSelectedFileName(null);
  //         setAudios([]);
  //         setAudioURL('');
  //         setDisable(false)
  //         document.getElementById('fileInput').value = null;
  //       } else {
  //         console.error("Error Opening Chat:", response.message);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error calling backend API:", error.message);
  //   }
  // }





      const sendMessage = async (messages, messageTypes, name) => { 
      try {
      if (selectedContactId && selectedContactId !== null) {
      socket.emit('send_personal_message', {
      senderUserId: currentUser._id,
      receiverUserId: selectedContactId ,
      content: messages,
      contentType: messageTypes,
      fileName: name,
      userName:currentUser.username,
      });
      socket.on('receive_personal_message', handleReceiveMessage);
      }
      else if (selectedGroupId && selectedGroupId !== null) {
        socket.emit('send_message_in_group', {
      senderId: currentUser._id,
      groupId: selectedGroupId,
      content: messages,
      contentType: messageTypes,
      fileName: name,
      userName: currentUser.username,
      });
        socket.on('receive_group_message', handleReceiveMessage);
      }
      setMessage('');
      setSelectedFile(null);
      setSelectedFileName(null);
      setAudios([]);
      setMessageType('');
      const activityResponse = await addActivity(aid, "proposal", "sent a message", usr)
      } catch (error) {
      console.error("Error calling backend API:", error.message);
      }
      }

  const handleKeyDown = (event) => {
    // Check if the Enter key was pressed (key code 13)
    if (event.keyCode === 13) {
      // Prevent the default behavior
      event.preventDefault();
      // Call the sendChat function
      sendChat(messageType);
    }
  };

  return (
    <div className="chatinput" id='chat-input'>
      <div className="chatinput__form">
        {showPicker && 
          <div ref={pickerRef} className="emoji-picker-container">
          <Picker 
          searchDisabled
          style={{width:'350px', height:'300px'}}
          onEmojiClick={(emojiObject) => {
            setMessage((prevMsg) => prevMsg + emojiObject.emoji)
            setMessageType('text');
            setSelectedFile(null)
            setSelectedFileName(null)
            setAudios([]);
          }}
          />
          </div>
         }

        <MoodIcon onClick={togglePicker} sx={{ verticalAlign: 'middle', fontSize: '35px', cursor:'pointer' }} />
        <form>
          <input
            value={message}
            placeholder="Type a message"
            onChange={handleTextChange}
            disabled={disable}
            onKeyDown={handleKeyDown}
            style={{fontSize:'17px'}}
          />
        </form>

        <AudioRecord setDisable={setDisable} setMessageType={setMessageType} />

        <div className="chatinput__formIcons">
          <label htmlFor="fileInput">
            <AttachmentIcon sx={{ verticalAlign: 'middle', fontSize: '35px', cursor: 'pointer' }} />
          </label>
          <input
            id="fileInput"
            type="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,.rar"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

        </div>
      </div>
      <div className="chatinput__icons">
        <SendIcon onClick={() => sendChat(messageType)} sx={{ verticalAlign: 'middle', fontSize: '35px', cursor: 'pointer' }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatInput);
