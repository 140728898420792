
import OppourtunityManagementTemplate from "./OppourtunityManagementTemplate";
import { Dialog, DialogContent, DialogTitle, FormHelperText, InputLabel, LinearProgress, Link, styled } from "@material-ui/core";
import { ReusableCard } from "./MyDocumentsCards";

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import {
  Container,
  TextField,
  Button,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,

  Menu,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox, Box,
  Radio
} from "@material-ui/core";
import DatePicker from 'react-datepicker';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckIcon from '@mui/icons-material/Check';
import { PDFDocument, PDFPage } from 'pdf-lib';
import JoditEditor,{Jodit} from 'jodit-react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import Select from "react-select";
import UploadIcon from '@mui/icons-material/Upload';
import SearchIcon from "@mui/icons-material/Search";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import WindowIcon from "@mui/icons-material/Window";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DownloadIcon from "@mui/icons-material/Download";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccordionSummary from "@mui/material/AccordionSummary";
import InfoIcon from '@mui/icons-material/Info';
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendIcon from '@mui/icons-material/Send';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PrintIcon from '@mui/icons-material/Print';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { node } from "prop-types";
import { getUserInfo, getopportunities, isSummarizedDescription, updateSummarizedDescription } from "../services/opportunityService";
import { addAssetsection, addAssetsubsection, decisionByReceiver, decisionByReceiverforSubsection, getAllSectionVersions, getAssetsection, getSubsections, updateAssetsection } from "../services/assetSectionServices";
// import { addAssetCommentsProposal } from "../services/assetSectionNotesProposal"
import { addCoverImage, convertPDFToHTML, deleteExisting, deleteFile, deleteSubsectionFile, fetchImagePreview, getCoverPages, getEstimateTemplatePreview, getList, getS3Data, improveInputSection, keywordForSamrtSuggestion, smartSuggestion, uploadFile } from "../services/s3bucketService";

import { toast } from "react-toastify";
import { showToast } from "../messages";
import axios from 'axios';
import { getFileContent, getRFPDocument, getSectionDocument, getSpecificVersionData, getTemplates } from "../services/templatesService";
import { PreviewEditor } from "./PreviewEditor";
import html2pdf from 'html2pdf.js';
import { AltRoute, CollectionsOutlined } from "@mui/icons-material";
import Loader from "../utils/loader";
import { removeTemplate } from "../services/assetService";

import { getCheckSummariser } from "../services/summariserService";
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded';
import { addActivity, getActivity } from "../services/activityTransaction";
import Swapping from './Images/Swapping.png'
import ReorderModal from "./pages/ReorderModal";
import ReorderSequence from "./pages/ReorderSequence";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { UpdateassetComments, addAssetComments, deleteAssetComment, getAssetComments, replyToComment, deleteCommentReply, UpdateReplyOfComment } from "../services/assetSectionNotes";
import { templateData } from "../redux/template/template.action";
import '../css/proposal_Screen_Css/ProposalWriting.css'
import '../components/pages/opportunityManagmentNewPage.css'
import CodeIcon from '@mui/icons-material/Code';
// import Select from 'react-select';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { saveAs } from 'file-saver';
import { asBlob } from 'html-docx-js-typescript';
import PreviewIcon from '@mui/icons-material/Preview';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ImageIcon from '@mui/icons-material/Image';
import { GetGalleryList, GetPreviewContent } from "../services/TemplateGalleryServices";
import zIndex from "@mui/material/styles/zIndex";
import { addClauseProject, addClauses, approveClause, deleteClause, getClauseProject, getClauses, getResourceLinks, rejectClause, updateClauseProject, getDeletedClause } from "../services/clauseExtractionService";
import { getAllTaskAssignments, getAllassetsharing, getClausesByAssetId, getSpecificAsset, getTeamTask, isNotificationSent, sendBackTeams, sentNotitfication, validateFunctionalityAccess, terminateProject, updateTeamTask, addAuditHistory, getAuditHistory, getasset } from "../services/assetService";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { submitToNextTeam, sendBack, submitProposal } from "../services/assetService";
import SendBackModal from "./SendBackModal";
import { Chip } from '@material-ui/core';
import { getRequirementMapping, getRequirementMappingById, revertRequirementMapping, updateresourceallocationbyid } from "../services/resourceAllocationService";
import TaskCompletionModal from "./TaskCompletionModal";
import TeamDeadline from "./pages/Modals/teamDeadLineModal";
import jsPDF from 'jspdf';

import ChatModal from "./ChatModal";
import { getUsersByOrgId } from "../services/userService";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Document, Packer, Paragraph, TextRun, Table as DocxTable, LeftTabStop, } from 'docx';
import Divider from '@mui/material/Divider';
import { extractPdfData, improveInput, aiGeneratedRequirnments, requirementMapping } from "../services/s3bucketService";
import htmlDocx from 'html-docx-js/dist/html-docx';
import { TemplateMerge } from "../services/templatesService";
import { saveTrackedChanges, getTrackedChanges, getContentLibrary, getClausesList } from "../services/trackChanges";
import ContentLibrary from "./ContentLibrary";
import { OpportunityInputBoxMui, OpportunityMultilineInputBoxMui } from "./ReusableComponents/inputBoxOpportunity";
import { getAllUserForSameOrg, getorgdata } from "../services/organizationService";
// import ShareAssetsSectionPage from "./pages/AssetSectionSharePage";
import { getSectionSharedData } from "../services/assetsSharingService";
import EditIcon from "@mui/icons-material/Edit";
import { diffWordsWithSpace } from "diff";
import { DialogActions, Tabs, Tab } from '@mui/material';
import { sendFinalEmail } from "../services/assetSharingServices";
import DialogContentText from '@mui/material/DialogContentText';
import ResourceAllocation from "./pages/ResourceAllocation";
import { getresourceallocation, mappedReuirementClause } from "../services/resourceAllocationService";
import { updateresourceallocation } from "../services/resourceAllocationService";
import _ from 'lodash'; // lodash for debouncing
import { validateTerminateBtn } from "../services/assetService";
import SignatureModal from "./SignatureModal";
import { addElectronicSignatureSection } from "../services/assetSectionServices";
import { isFirstTeamUser } from "../services/assetService";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ReactTooltip from "react-tooltip";
import 'jspdf-autotable';
import SaveIcon from '@material-ui/icons/Save';
import SingleTemplate from "./Cards/SingleTemplate";
import html2canvas from 'html2canvas';

// import { backgroundImage } from "html2canvas/dist/types/css/property-descriptors/background-image";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { List, ListItem, ListItemText } from '@mui/material';
import { referenceAttachmentText } from "../services/s3bucketService";
import TaskAssignmentModal from "./pages/TaskAssignment";
import { getTaskAssignedDetails } from "../services/taskAssignmentData";
import { VersionComapreModal, VersionHistoryCard } from "./pages/Modals/versionCompare";
import { TaskAssignmentAddComentModal, TaskAssignmentComentModal } from "./pages/Modals/rfpModals";
import TerminateProjectModal from "./TerminateProjectModal";
import { alignProperty } from "@mui/material/styles/cssUtils";



const Diff = require('diff')

const removeHtmlTags = (htmlContent) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;
  const text = tempElement.textContent || tempElement.innerText || "";
  return text;
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '200px',
    marginRight: '8px'
  })
};


const configForPlaceholder = {
  toolbar: false,
  branding: false,
  statusbar: false,
  readonly: true,
  // Set to false to hide the status bar
  height: 550,
  uploader: {
    insertImageAsBase64URI: true, // Enable Base64 encoding
  }
}

const greenRowStyle = { backgroundColor: '#90EE90' };
const redRowStyle = { backgroundColor: '#FF7F7F' };

const customStyles1 = {
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#1976d2',
    color: 'white',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': {
      backgroundColor: '#145a86',
      color: 'white',
    },
  }),
  control: (provided) => ({
    ...provided,
    height: '50px', // Fixed height for the Select control
    overflowY: 'auto', // Make it scrollable
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1300,
  height: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "auto"
};




const ProposalWriting = () => {
  const [selectedDocument, setSelectedDocument] = useState({});
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [inputKey, setInputKey] = useState()
  const [userInfo, setUserInfo] = useState('')
  const [opportunitiesId, setopportunitiesId] = useState("65cf49fdfbd8206f10612ee3")
  const [asssetsectionData, setAssetSectionData] = useState([])
  const [openAddModal, setOpenAddModal] = useState(false)
  const [normalDate, setNormalDate] = useState('')
  const [cuurentUser, setCuurentUser] = useState('')
  const [title, setTitle] = useState('');
  const [cardId, setCardId] = useState();
  const [viewType, setViewType] = useState();
  const [commentData, setCommentData] = useState([])
  const [adddescription, setaddDescription] = useState('');
  const [agancyName, setAgancyName] = useState('')
  const [cleanedContent, setCleanedContent] = useState('');
  const [fileName, setFileName] = useState('')
  const [fileURL, setFileURL] = useState()
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState()
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [isLeftPaneVisible, setLeftPaneVisible] = useState(true);
  const [content, setContent] = useState('');
  const [isgetDataEditor, setIsgetDataEditor] = useState(false)
  const [assetsectionId, setAssetSetionId] = useState('')
  const [assetSubsectionId, setAssetSubsectionId] = useState('')
  const [selectedNoteId, setSelectedNoteId] = useState()
  const [loading, setLoading] = useState(false)
  const [htmlContent, setHtmlContent] = useState('')
  const [sectionTitle, setSectionTitle] = useState('')
  const [editSectionTitle, seteditSectionTitle] = useState(false)
  const [summarizedDescription, setsummarizedDescription] = useState('')
  const [showtemplate1, setShowTemplate1] = useState(false)
  const [propWritingData, setPropWritingData] = useState('')
  const [isTemplatListModal, setIsTemplatListModal] = useState(false)
  const [templateList, setTemplateList] = useState(null);
  const [noteCount, setNoteCount] = useState();
  const [commentCount, setCommentCount] = useState()
  const [propsPassed, setPropPassed] = useState('')
  const [cardData, setcardData] = useState()
  const [selectedTemplateListPath, setSelectedTemplateListPath] = useState()
  const [selectedTemplateListId, setSelectedTemplateListId] = useState()
  const [isTemplatListWithPlaceholderModal, setisTemplatListWithPlaceholderModal] = useState('')
  const [commentsText, setComments] = useState()
  const [isCommentModalOpen, setisCommentModalOpen] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [notesData, setNotesData] = useState([])
  const [updateIcon, setUpdatIcon] = useState()
  const [updatCommentIcon, setUpdatCommentIcon] = useState()
  const [isCardDatModalOpen, setisCardDatModalOpen] = useState()
  const [note, setNote] = useState('');
  const [activityCount, setActivityCount] = useState()
  const [createdByCard, setCreatedByCard] = useState()
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadTitle, setDownloadTitle] = useState();
  const [downloadContent, setDownloadContent] = useState();
  const [wordCount, setWordCount] = useState(0);
  const [galleryTemplateList, setGalleryTemplateList] = useState([])
  const [isOpenClauseModal, setIsOpenClauseModal] = useState(false)
  const [extractedClause, setExtractedClause] = useState([])
  const [addManualClause, setAddManualClause] = useState('')
  const [assetIdMerge, setAssetIdMerge] = useState(null)
  const [clauseDataEditable, setClauseDataEditable] = useState(false)
  const [clauseProjectData, setClasueProjectData] = useState({});
  const [isClauseProjectModalOpen, setIsClauseProjectModalOpen] = useState("");
  const [selectedClause, setSelectedClause] = useState("");
  const [selectedClauseObjectId, setSelectedClauseObjectId] = useState('')
  const [selectedClauseDocumentId, setSelectedClauseDataId] = useState('')
  const [selectedClauseAssetId, setSelectedClauseAssetId] = useState('')
  const [downloadFileName, setDownloadFileName] = useState("");
  const [previousTeams, setPreviousTeams] = useState([])
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [pageStyles, setPageStyles] = useState('');
  const [chatModal, setChatModal] = useState(false)
  const [isreadonly,setIsReadOnly]=useState(false);
  const [reviewData,setReviewData]=useState({})
  const [versionData, setVersionData] = useState([]);
  const [isReplyModelOpen, setIsReplyModelOpen] = useState(false)
  const [selectedCommentForReply, setSelectedCommentForReply] = useState()
  const [replyText, setReplyText] = useState()
  const [showReply, setShowReply] = useState(true)
  const [isNotesReplyModelOpen, setIsNotesReplyModelOpen] = useState(false)
  const [selectedNoteForReply, setSelectedNoteForReply] = useState()
  const [notesReplyText, setNotesReplyText] = useState()
  const [notesReplyUpdateIcon, setNotesReplyUpdateIcon] = useState(false)
  const [commentReplyUpdateIcon, setCommentReplyUpdateIcon] = useState(false)
  const [currentAccessTeam, setCurrentAccessTeam] = useState('')
  const [sendBackModalOpen, setSendBackModalOpen] = useState(false)
  const [allTaskCompleted, setAllTaskCompleted] = useState(true)
  const orgId = sessionStorage.getItem("organizationId")
  const [firstTeamUser, setFirstTeamUser] = useState(false)
  const [keywords, setKeywords] = useState([]);
  const [htmlOwnerContent, setHtmlownerContent] = useState("");
  const [taskAssignWithData, setTaskAssignWithData] = useState([]);
  const [allSubsectionData, setAllSubsectionData] = useState([]);
  const [templateManagement, setTemplateManagement] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [preview, setPreview] = useState(false);
  const [placeholder, setPlaceholder] = useState(false);
  const [previousPage, setPreviousPage] = useState(null);
  const [editorStateWithPlaceholder, setEditorStateWithPlaceholder] = useState(null)
  const [taskApproveStatus, setTaskApproveStatus] = useState('')
  //Without placeholder Modal
  const [templateManagement2, setTemplateManagement2] = useState(false);
  const [gallery2, setGallery2] = useState(false);
  const [preview2, setPreview2] = useState(false);
  const [previousPage2, setPreviousPage2] = useState(null);
  const [currentInfo, setCurrentInfo] = useState({
    type: "", htmlContent: "", title: "", name: "", sectionId: ""
  })
  const [priorHtmlContent, setPriorHtmlContent] = useState('')
  const [showDownloadModalForMerge, setShowDownloadModalForMerge] = useState(false)
  const [contentLibraryOpen, setContentLibraryOpen] = useState(false)
  const [modalOpenSectionshr, setModalOpenSectionshr] = useState(false);
  const [sharedAssetSectionScreenType, setSharedAssetSectionScreenType] = useState();
  const [modalOpenshr, setModalOpenshr] = useState(false);
  const [users, setUsers] = useState([]);
  const [existingusers, setExistingUsers] = useState([]);
  const [sharedAssetSectionType, setSharedAssetSectionType] = useState();
  const [isSectionSharing, setIsSectionSharing] = useState("");
  const [particularSection, setParticularSection] = useState({});
  const [assignbyUser, setAssignbyUser] = useState();
  const [sharedOwnerId, setsharedOwnerId] = useState("");
  const [newSectionTitle, setnewSectionTitle] = useState()
  const [newSubSectionTitle, setnewSubSectionTitle] = useState("")
  const [sharedSectionDetails, setSharedSectionDetails] = useState([]);
  const [opennewPopup, setOpennewPopup] = useState(false);
  const [taskApprovalOwnerData, setTaskApprovalOwnerData] = useState(null);
  const [taskApprovalReceiverData, setTaskApprovalReceiverData] = useState(null);
  const [rawUpdatedContent, setRawUpdatedContent] = useState("");
  const [acceptedBothData, setAcceptedBothData] = useState("");
  const [allTaskAssignments, setallTaskAssignments] = useState();
  const [taskDetail, setTaskDetail] = useState();
  const [finalProposal, setFinalProposal] = useState(false);
  const [submittedStatus, setSubmittedStatus] = useState(false);
  const [fullProposalvrushabh, setFullProposalvrushabh] = useState()
  const [activityMsg, setActivityMsg] = useState({
    uploadFile: "uploaded the document",
    uploadSection: "uploaded the section",
    deleteFile: "deleted the document",
    addSection: "added a section",
    updateSection: "updated a section",
    deleteSection: "deleted a section",
    addComment: "added a comment",
    updateComment: "updated a comment",
    deleteComment: "deleted a comment",
    addNote: "added a note",
    updateNote: "updated a note",
    deleteNote: "deleted a note",
    addClause: "added a clause",
    addReply: "sent a reply",
    deleteReply: "deleted a reply",
    updateReply: "updated a reply",
    taskAccepted: "accepted a task",
    taskRejected: "rejected a task",
    submitForReview: "submitted a task for review",
    accepteChanges: "approved a changes",
    rejectChanges: "rejected a changes"
  });

 
  const [themeExist, setThemeExist] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [allClausesSelected, setAllClausesSelected] = useState(false);
  const [editState, setEditState] = useState({});
  const [newRate, setNewRate] = useState({});
  const tableRef = useRef();
  const [showEstimateTemplate, setShowEstimateTemplate] = useState()
  const [showFlowcharts, setShowFlowcharts] = useState()
  const [isOpenNewModal, setIsOpenNewModal] = useState(false);
  const [filePreviewEditor, setFilePreviewEditor] = useState(null)
  const [isFilePreviewOpen, setIsFilePreviewOpen] = useState(false)
  const [htmlPreview, setHTMLPreview] = useState("")
  const [assetId, setAssetId] = useState(null);
  const [popupForScan, setPopupForScan] = useState(false);
  const [fieldsToRender, setFieldsToRender] = useState([]);
  const [contentForScan, setContentForScan] = useState(null)
  const [dataTypeMap, setDataTypeMap] = useState({});
  const [formData, setFormData] = useState({});
  const [scanFileName, setScanFileName] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [statusColor, setStatusColor] = useState('')
  const [path, setPath] = useState(null);
  const [showEditButton, setShowEditButton] = useState('')
  const [open, setOpen] = React.useState(false);
  const [opens, setsOpen] = React.useState(false);
  const [assetData, setAssetData] = useState()
  const [displayCount, setDisplayCount] = useState(5); // Initial count of messages to display
  const [activityData, setActivityData] = useState([])
  const assetType = "proposal"
  const commentType = "comments"
  const noteType = "note"
  const editor = useRef(null)
  const editor1 = useRef(null)
  const history = useHistory();
  const maxWordLimit = 10000;
  const [deadLineProposal, setDeadLineProposal] = useState('')
  const [isTeamModalState, setIsTeamModalState] = useState(false)
  const [deadLine, setDeadLne] = useState("")
  // const [ispdfFile,setIspdfFile] = useState()
  // const [isCanContinue,setIsCanContinue]=useState(false)
  // const [isExpanded, setIsExpanded] = useState(false);
  const [showClauseDownloadModal, setClauseshowDownloadModal] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0)
  // const [showReorderModal, setshowReorderModal] = useState(false);
  // const [selectedFields,setSelectedFields]=useState([])
  const [sendBackButtonClicked, setSendBackButtonClicked] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [resourceLinks, setResourceLink] = useState([]);
  const [documentData, setDocumentData] = useState(null);
  const [showSection, setShowSection] = useState(false);
  const [showRequirnments, setShowRequirnments] = useState(false);
  const [showMapping, setShowMapping] = useState(false);
  const [aiRequirnmentsData, setAiRequirnmentsData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileContent, setSelectedFileContent] = useState(null);
  const [requirementMappingData, setRequirementMappingData] = useState(null)
  const [dummy, setDummy] = useState([])
  const [showDownloadMappingModal, setShowDownloadMappingModal] = useState(false)
  const editorForRequirnment = useRef(null)
  const [opportunity, setOpportunity] = useState(null);
  const [showDownloadModalForRequirement, setShowDownloadModalForRequirement] = useState(false)
  const [trackedChanges, setTrackedChanges] = useState([]);
  const [temporaryContent, setTemporaryContent] = useState("");
  const [mergeDocColor, setMergeDocColor] = useState(false)
  const [openPopup, setOpenPopup] = useState(false);
  const [specifiSectiontaskData, setSpecifiSectiontaskData] = useState(null);
  const [seubsectionDataforApproval, setSeubsectionDataforApproval] = useState(null);
  const [subsectionIdforAcceptance, setSubsectionIdforAcceptance] = useState(null);
  const [mainAssetid, setmainAssetid] = useState();
  const [allSharedAsset, setallSharedAsset] = useState();
  const [success, setSuccess] = useState(false);
  const [statusAccepted, setStatusAccepted] = useState(false);
  const [editshow, setEditshow] = useState(false);
  const [acceptedSections, setAcceptedSections] = useState([]);
  const [subsectionButtons, setSubsectionButtons] = useState(false);
  const [isEditorReadOnly, setIsEditorReadOnly] = useState(false);
  const [openResourceAllocation, setOpenResourceAllocation] = useState(false);
  const [mergeArray1, setMergeArray1] = useState([]);
  const [mergeArray2, setMergeArray2] = useState([]);
  const [unMatchedContentIndex, setUnMatchedContentIndex] = useState([]);
  const [section_id, setSection_id] = useState(null);
  const [showBtnInMerge, setShowBtnInMerge] = useState(false);
  const [taskCompletionModal, setTaskCompletionModal] = useState(false)
  const [teamTasks22, setTeamTasks22] = useState([])
  const [auditHistory, setAuditHistory] = useState(null)
  const [stylledContent, setstylledContent] = useState(null);
  const [manualSections,setManualSections ]=useState([])
  const [aiGeneratedSections ,setAiGeneratedSections ]=useState([])
  const [selectedRow, setSelectedRow] = useState(null);
  const [editedRequirementText, setEditedRequirementText] = useState("");
  const [selectedClauseResources, setselectedClauseResources] = useState();
  const [terminateReason,setTerminateReason]=useState("")
  const [isModalOpenCustom, setIsModalOpenCustom] = useState(false);
  const [remarks, setRemarks] = useState({});
  const [selectedResources, setSelectedResources] = useState({});
  const [resourceOptions, setResourceOptions] = useState([]);
  const [totalBillingAmounts, setTotalBillingAmounts] = useState({});
  const [effectiveHours, setEffectiveHours] = useState({});
  const [dummy1, setDummy1] = useState([])
  const [resourcePayRates, setResourcePayRates] = useState({});
  const [editableRow, setEditableRow] = useState(null);
  const [hasYellowOrRedRows, setHasYellowOrRedRows] = useState(false);
  const [keywordData, setKeywordData] = useState('');
  const [selectedKeyword, setSelectedKeyword] = useState('');
  const [farClauses, setFarClauses] = useState([])
  const [dfarClauses, setDfarClauses] = useState([])
  const [startDate, setStartDate] = useState([])
  const [endDate, setEndDate] = useState([])
  const [terminateProjectModal,setTerminateProjectModal]=useState(false);
  const [editorImage, setEditorImage] = useState("")
  const [editorIm, setEditorIm] = useState(String);
  const [openReferencePopup, setOpenReferencePopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false)

  const [isTaskAssignmentModalOpen, setTaskAssignmentModalOpen] =
    useState(false);
  const [taskAssignmentsectionId, setTaskAssignmentsectionId] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  //   const config = useMemo(() => ({
  //     enter: 'BR',
  //     branding: false,
  //     statusbar: false,
  //     readonly: false,
  //     height: 490,
  //     width: 1200,
  //     uploader: {
  //         insertImageAsBase64URI: true,
  //     },
  //     plugins: ['autoformat'],
  //     autoformat: {
  //         removeEmpty: true,
  //         cleanHTML: true,
  //         formatBlock: true,
  //         wrapParagraphs: true
  //     },
  //     style: {
  //         backgroundColor: '#e6e6e6',
  //         backgroundImage: `url("path_to_image")`,
  //         backgroundSize: 'cover',
  //         backgroundRepeat: 'no-repeat',
  //         overflow: 'auto'
  //     },
  //     events: {
  //         afterInit: (editor) => {
  //             editor.events.on('keydown', (event) => {
  //                 if (event.key === 'Enter') {
  //                   event.preventDefault();
  //                     handleEnterKey(editor);
  //                 }
  //             });
  //         }
  //     }
  // }), []);


  // console.log(isButtonEnabled,"isButtonEnabledisButtonEnabled")


  const config = useMemo(
    () => ({
      enter: 'BR',
      branding: false,
      statusbar: false,
      readonly: isEditorReadOnly ? isEditorReadOnly : isreadonly,
      height: 490,
      uploader: {
        insertImageAsBase64URI: true,
      },
      plugins: ['autoformat'],
      autoformat: {
        removeEmpty: true,  // Removes empty tags
        cleanHTML: true,    // Cleans up HTML
        formatBlock: true,  // Formats blocks of text
        wrapParagraphs: true // Wrap text nodes with paragraphs
      },
      controls: {
        paragraph: {
            list: Jodit.atom({
                p: 'Paragraph',
                h1: 'Heading 1',
                h2: 'Heading 2',
                h3: 'Heading 3',
                h4: 'Heading 4',
                blockquote: 'Quote',
            })
        }
      },
      style: {
        backgroundColor: mergeDocColor ? '#e6e6e6' : '',// Conditionally change background color
        backgroundImage: `url("${editorImage}")`,
        backgroundSize: 'contain',
        backgroundSize: 'cover', // Adjust as per your requirements
        backgroundRepeat: 'no-repeat', // Adjust as per your requirements
      },
      overflow: "auto",
    }),
    [isEditorReadOnly, isreadonly, mergeDocColor, editorImage]
  );




  useEffect(() => {
    console.log("isreadonly isEditorReadOnly", isreadonly, isEditorReadOnly)
  }, [isreadonly, isEditorReadOnly])




  const [isOpenDeleteReasonModel, setIsOpenDeleteReasonModel] = useState(false);
  const [dataForDeleteClause, setDataForDeleteClause] = useState({
    id: "",
    clause: "",
    dataid: ""
  });
  const [deleteReason, setDeleteReason] = useState("");

  const [isOpenAddReasonModel, setIsOpenAddReasonModel] = useState(false);
  const [addReason, setAddReason] = useState("");
  const [deletedClauseArray, setDeletedClauseArray] = useState([])

  const config2 = {
    toolbar: false,
    readonly: true,
    branding: false,

    statusbar: false,

    // Set to false to hide the status bar

    height: 520,

    uploader: {
      insertImageAsBase64URI: true, // Enable Base64 encoding
    },
  };


  const config3 = {
    toolbar: false,

    branding: false,

    statusbar: false,

    // Set to false to hide the status bar

    height: 520,

    uploader: {
      insertImageAsBase64URI: true, // Enable Base64 encoding
    },
  };
  const [requirementDataForSuggestion, setRequirementDataForSuggestion] = useState(null);
  const [isSearchTerm, setIsSearchTerm] = useState('');
  const [cleanedInitialContent, setInitialCleanedContent] = useState('');
  const [openAddSubSectionModal, setOpenAddSubSectionModal] = useState(false);
  const [subTitle, setSubTitle] = useState('');
  const [addSubDescription, setAddSubDescription] = useState('');
  const dispatch = useDispatch()
  const [subsections, setSubsections] = useState([]);
  const [subsectionsMap, setSubsectionsMap] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSections, setSelectedSections] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sectionCreateddBy, setSectionCreatedBy] = useState("");
  const projectType = useSelector((state) => state.opportunity.projectType);
  const [sUserRole, setSUserRole] = useState(null);
  const [sUserId, setSUserId] = useState(null);
  const [clausesData, setClausesData] = useState([]);
  const [selectedClauses, setSelectedClauses] = useState([]);
  const [checkValidation, setCheckValidation] = useState(false)
  const [aiRequirnmentsArray, setaiRequirnmentsArray] = useState([]);
  const [openJoditPopup, setOpenJoditPopup] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexforsubsection, setCurrentIndexforsubsection] = useState(0);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(currentIndex);
  const [selectedTaskIndexforsubsection, setSelectedTaskIndexforsubsection] = useState(currentIndex);
  const [subsectionEmail, setsubsectionEmail] = useState(null);
  const [teamTasks, setTeamTasks] = useState([])
  const [docClauseArray, setDocClauseArray] = useState(null)
  const [openCompliance, setOpenCompliance] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [showPopupFarCompliance, setShowPopupFarCompliance] = useState(false);
  const [popupMessageFarCompliance, setPopupMessageFarCompliance] = useState('');
  const [popupTitleFarCompliance, setPopupTitleFarCompliance] = useState('');
  const [requirementMappingHistory, setRequirementMappingHistory] = useState([])
  const [requirementMappingHistoryExists, setRequirementMappingHistoryExists] = useState(false)
  const [retrieveRequirementModal, setRetrieveRequirementModal] = useState(false)
  const [retrieveRequirementClicked, setRetrieveRequirementClicked] = useState(false)
  const [historyClicked, setHistoryClicked] = useState(false)
  const [requirementMappingId, setRequirementMappingId] = useState('')
  const [originalData, setOriginalData] = useState([]);
  const [allSectioDetails, setAllSectionDetails] = useState(null);
  const [isBlueTeam, setIsBlueTeam] = useState(false)
  const [isLastStage, setIsLastStage] = useState(false)
  const [showTerminateButton, setShowTerminateButton] = useState(false);
  const [signaturePayload, setSignaturePayload] = useState({})
  const [mergeSectionData, setMergeSectionData] = useState()
  const [orgUsers, setOrgUsers] = useState([])
  const [inAttachmentSection, setInAttachmentSection] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const fileInputRef = useRef(null);
  const [createdTime, setCreatedTime] = useState('')
  // const [editState, setEditState] = useState({});
  // const [newRate, setNewRate] = useState({});
  // const [allSelected, setAllSelected] = useState(false);
  const [showReorderModal, setshowReorderModal] = useState(false);
  const [isCanContinue, setIsCanContinue] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false);
  const [assignedTaskdetails, setAssignedTaskDetails] = useState()
  const [versionModalOpen, setVersionModalOpen] = useState(false);
  const [versionSectionId, setVersionSectionId] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [specificVersionData, setSpecificVersionData] = useState([]);
  const [commentMessage, setCommentMessage] = useState('')
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false)
  const [templateStyle ,setTemplateStyle]=useState("")
  // const TemplateStyle = localStorage.getItem("TemplateStyle")






  useEffect(() => {

    const isSubAdmin = userInfo?.userRole?.includes("Application Admin")
    setIsAdmin(isSubAdmin)
  }, [userInfo])


    const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
    };




  const fetchAssignedTaskdetails = async () => {
    try {
      const data = {
        assetId: proposalWritingData.assetid,
        assetType: "proposal"
      }
      const response = await getTaskAssignedDetails(data);
      console.log(response, "_____ress")
      setAssignedTaskDetails(response.taskAssignmentData);
    } catch (err) {
      showToast('Proposal.fetchAsset')
    }
  };


  const fetchAssetData = async () => {
    try {
      const assetId = proposalWritingData.assetid
      const response = await getasset(assetId);
      console.log("fetchAssetData",response)
      setTemplateStyle(response?.data?.templateStyle)
      setCreatedTime(response.data.createdTimeAgo);
    } catch (err) {
      showToast('Proposal.fetchAsset');
    }
  };

  useEffect(() => {
    fetchAssetData()
  }, [])

  const handleCloseReferensePopup = () => {
    setOpenReferencePopup(false);

  }
  const handleListItemClick = () => {
    fileInputRef.current.setAttribute("data-upload-type", "reference");
    fileInputRef.current.click();
    handleClosePopup();
  };

  const handleUploadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCustomAttachments = () => {
    fileInputRef.current.setAttribute("data-upload-type", "custom");
    fileInputRef.current.click();
    handleMenuClose();
  };

  const handleReferenceAttachments = () => {
    setOpenReferencePopup(true);
    handleMenuClose();


  };

  console.log(assignedTaskdetails, "assignedTaskdetails")


  const onFileChange = (event) => {
    handleCloseReferensePopup()
    const file = event.target.files[0];
    const uploadType = fileInputRef.current.getAttribute("data-upload-type");

    if (uploadType === 'custom') {
      handleCustomFileUpload(file);
    } else if (uploadType === 'reference') {
      handleReferenceFileUpload(file);
    }
  };


  const decodeByteData = (data) => {
    console.log("dtaaat12  ", data)
    try {
      const decoder = new TextDecoder('utf-8');
      console.log("decoder.decode(new Uint8Array(data))", decoder.decode(new Uint8Array(data)))
      return decoder.decode(new Uint8Array(data));
    } catch (error) {
      console.error('Error decoding byte data:', error);
      return 'Error decoding data';
    }
  };



  const handleCustomFileUpload = async (file) => {
    console.log("fff  ", file)
    let fileContent;
    let sectionId1;
    let subSectionId;
    let extractedText;
    let assetData;
    let previousData;
    let subSectionData

    if (!file) {
     showToast('Warning.noFile')
      return;
    }
    // console.log("fileAA")
    const formData1 = new FormData();
    formData1.append('file', file);
    

    // Axios configuration
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    // Send FormData to backend API
    setLoading(true)

    try {
      const response = await axios.post('https://api.contractpro360.com/api/newbucket/uploadFileForPDFcustom', formData1, axiosConfig);
      console.log('Response from backend:', response);

      if (!response.data.html || response.data.html.length === 0) {
        showToast('Proposal.pdfExtraction')
        setLoading(false)

        return;
      }

      extractedText = response.data.html;
      console.log("extractedText 12 ", extractedText)
      assetData = await getAssetSectionData()

    } catch (err) {
      console.log("Error from API: ", err);
      setLoading(false)
      
      showToast('Error.errorOccured')
      return

    }

    const attachmentSection = asssetsectionData.find((section) => section.title === "Attachment");
    const customGeneratedAttachmentExists = attachmentSection.subSectionsData.some(
      (subSection) => subSection.subsectionName === "Custom generated attachments"
    );
    sectionId1 = attachmentSection._id;

    if (attachmentSection && !customGeneratedAttachmentExists) {



      console.log("asettt  ", assetData)


      try {
        const response = await addAssetsubsection(proposalWritingData.assetid, "", "Custom generated attachments", assetType, userInfo.organizationId, sectionId1);
        if (response.status === 200) {
          showToast('Success.add')
          assetData = await getAssetSectionData();
          fetchSubsections();
        } else if (response.status == 400) {
          toast.error(response.message)
        }
      } catch (error) {
        console.log(error);
        showToast('Error.errorOccured')
        setLoading(false)
        return
      }
    }
    else {
      for (let item of assetData) {
        if (item.title === "Attachment") {
          for (let subSection of item.subSectionsData) {
            if (subSection.subsectionName === "Custom generated attachments") {
              previousData = subSection.htmlData;
            }
          }
        }
      }
      console.log("previousData12 ", previousData)
    }
    console.log("iddd22 ", assetData)

    for (let item of assetData) {
      if (item.title === "Attachment") {
        for (let subSection of item.subSectionsData) {
          if (subSection.subsectionName === "Custom generated attachments") {
            subSectionId = subSection.subsectionIds;
            console.log("iddd ", subSectionId)
          }
        }
      }
    }
    let combinedHtml;

    if (previousData) {
      combinedHtml = `${previousData} ${extractedText}`;

    } else {
      combinedHtml = `${extractedText}`;
    }
    console.log("conmbine12 ", combinedHtml)

    const blob = new Blob([combinedHtml], { type: "text/html" });

    // Create FormData object
    const formData = new FormData();
    formData.append("filename", blob, "Custom generated section"); // Use a more standard filename
    formData.append("orgName", userInfo.organizationName);
    formData.append("assetid", proposalWritingData.assetid);
    formData.append("assetsectionId", sectionId1);
    formData.append("htmlContent", combinedHtml)
    formData.append("userId", userInfo._id);
    formData.append("orgId", userInfo.organizationId);
    formData.append("subsectionId", subSectionId);



    console.log("isfilename");

    try {
      const response = await axios.post(
        `https://api.contractpro360.com/api/newbucket/assetsectionuploads/${proposalWritingData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );

      if (response.status === 200) {
        console.log("isuploaded", response);
        assetData = await getAssetSectionData();
        console.log("response from upload subsection ", response);
        showToast('Success.dataSaved')

      } else {
        console.log("error in upload");
        showToast('Error.errorOccured')
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      showToast('Error.errorOccured')
      setLoading(false);
      return;
    }

    try {
      subSectionData = await getSubsections(sectionId1);
      fetchSubsections(sectionId1) // Using getSubsections function from utilities

    }
    catch (err) {
      console.log("error in subsection fetch ", err)
      showToast('Error.errorOccured')
      return
    }


    const customData = subSectionData.data.find(item => item.title === "Custom generated attachments");




    console.log("subsectionsMap12 ", JSON.stringify(subSectionData, null, 2));
    console.log("subsectionsMap12 ".subSectionData)

    console.log("subsectionsMap32 ", JSON.stringify(assetData, null, 2));

    console.log("subsectionsMap32 ", assetData)
    setIsgetDataEditor(false)
    setInAttachmentSection(false)

    await handlesubsectionFileName(customData.documentpath, customData.type, customData.htmlContent, customData._id, customData.title,
      attachmentSection.subSectionsData)


    setLoading(false);

  }


  const handleOpenRejectDialog = () => {
    setOpenRejectDialog(true);
  };

 
  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
    // handleAcceptClose(); 
  };


  const getVersionData = async () => {
    try {
      const orgid = sessionStorage.getItem("organizationId")
      let data = {
        orgId: orgid,
        assetid: rfmManagementData?.value?.assetid
      }
      console.log(data, assetData, "___data")
      if (data.orgId && data.assetid) {
        const response = await getAllSectionVersions(data);
        if (response.status === 200) {
          console.log("getVersionData", response);
          setVersionData(response?.sectionVersions);
        } else {
          console.log("Error in response", response);
        }
      } else {
        console.log("Missing orgId or assetid", data);
      }

    } catch (error) {
      console.log("error while getting version");
    }
  };

  useEffect(() => {

    getVersionData();
    const intervalId = setInterval(getVersionData, 60000);
    return () => clearInterval(intervalId);

  }, []);

  const handleVersionModal = async (sectionId, version) => {
    const response = await specificVersion(sectionId);
    if (response == true) {
      setVersionModalOpen(true);
      setVersionSectionId(sectionId);
      setSelectedVersion(version);
    }
  };
  const specificVersion = async (assetSectionId) => {
    try {
      if (assetSectionId) {
        console.log("assetSectionId:", assetSectionId);
        let data = {
          assetSectionId: assetSectionId,
        };
        const response = await getSpecificVersionData(data);
        if (response.status === 200) {
          console.log("getSpecificVersionData", response);
          const sortedVersions = response.documents.sort((a, b) => new Date(b.date) - new Date(a.date));
          setSpecificVersionData(sortedVersions);
          return true;
        }
      }

    } catch (error) {
      console.log("error while getting specific  version")
    }
  }

  const handleReferenceFileUpload = async (link, filename) => {
    console.log("link 12", link, filename)
    let sectionId1;
    let extractedText;
    let subSectionId;
    let assetData;
    let previousData;
    let subSectionData



    const requestData = {
      link: link,
      filename: filename
    };

    setLoading(true)

    try {
      const response = await referenceAttachmentText(requestData);
      console.log('HTML Content:', response.html);
      extractedText = response.html
      assetData = await getAssetSectionData()

    }
    catch (err) {
      console.log("error in referense text extraction")
      showToast('Proposal.errExtractText')
      setLoading(false)

      return;
    }



    const attachmentSection = asssetsectionData.find((section) => section.title === "Attachment");

    const referenceGeneratedAttachmentExists = attachmentSection.subSectionsData.some(
      (subSection) => subSection.subsectionName === "Reference attachments"
    );
    console.log("One ", sectionId1)

    sectionId1 = attachmentSection._id;

    if (attachmentSection && !referenceGeneratedAttachmentExists) {
      sectionId1 = attachmentSection._id;




      try {
        const response = await addAssetsubsection(proposalWritingData.assetid, "", "Reference attachments", assetType, userInfo.organizationId, sectionId1);
        if (response.status === 200) {
          showToast('Proposal.subsectionSuccess')

          assetData = await getAssetSectionData();
          fetchSubsections();
          console.log("iddd2233 ", assetData)

        }
      } catch (error) {
        console.log(error);
        showToast("Error.errorOccured")
        setLoading(false)

      }
    } else {
      for (let item of assetData) {
        if (item.title === "Attachment") {
          for (let subSection of item.subSectionsData) {
            if (subSection.subsectionName === "Reference attachments") {
              previousData = subSection.htmlData;
            }
          }
        }
      }
      console.log("previousData12 ", previousData)
    }
    console.log("iddd22 ", assetData)




    for (let item of assetData) {
      if (item.title === "Attachment") {
        for (let subSection of item.subSectionsData) {
          if (subSection.subsectionName === "Reference attachments") {
            subSectionId = subSection.subsectionIds;
            console.log("iddd ", subSectionId)
          }
        }
      }
    }
    let combinedHtml;
    if (previousData) {
      combinedHtml = `${previousData} ${extractedText}`;

    } else {
      combinedHtml = `${extractedText}`;
    }


    const blob = new Blob([combinedHtml], { type: "text/html" });

    // Create FormData object
    const formData = new FormData();
    formData.append("filename", blob, "Reference attachments"); // Use a more standard filename
    formData.append("orgName", userInfo.organizationName);
    formData.append("assetid", proposalWritingData.assetid);
    formData.append("assetsectionId", sectionId1);
    formData.append("htmlContent", combinedHtml)
    formData.append("userId", userInfo._id);
    formData.append("orgId", userInfo.organizationId);
    formData.append("subsectionId", subSectionId);



    console.log("isfilename");

    try {
      const response = await axios.post(
        `https://api.contractpro360.com/api/newbucket/assetsectionuploads/${proposalWritingData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );

      if (response.status === 200) {
        console.log("isuploaded", response);
        assetData = await getAssetSectionData();
        console.log("response from upload subsection ", response);
        showToast("Success.dataSaved")

      } else {
        console.log("error in upload");
        showToast("Error.errorOccured")

        setLoading(false)
        return;

      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      showToast('Proposal.errSavingFile')

      setLoading(false)
      return

    }
    try {
      subSectionData = await getSubsections(sectionId1);
      fetchSubsections(sectionId1)
      // Using getSubsections function from utilities

    }
    catch (err) {
      console.log("error in subsection fetch ", err)
      showToast('Proposal.errSubsectionData')
      return
    }


    const customData = subSectionData.data.find(item => item.title === "Reference attachments");




    console.log("subsectionsMap12 ", JSON.stringify(subSectionData, null, 2));
    console.log("subsectionsMap12 ".subSectionData)

    console.log("subsectionsMap32 ", JSON.stringify(assetData, null, 2));

    console.log("subsectionsMap32 ", assetData)
    handleCloseReferensePopup()
    setInAttachmentSection(false)
    setIsgetDataEditor(false)
    setInAttachmentSection(false)

    await handlesubsectionFileName(customData.documentpath, customData.type, customData.htmlContent, customData._id, customData.title,
      attachmentSection.subSectionsData)




    setLoading(false)


  };





  const fetchflowcharts = async () => {
    let data = {
      path: "Flowcharts and diagrams/"
    }
    try {
      const response = await getS3Data(data)
      if (response.status == 200) {
        console.log("response of full proposal", response)
        setShowFlowcharts(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchflowcharts()
  }, [])

  const handleFilePreviewClose = () => {
    setIsFilePreviewOpen(false);
  };


  const handleImagePreview = async (template) => {
    try {
      let fileExtension = template?.split('.').pop().toLowerCase();
      const data = {
        filename: template,
        folderPath: "Flowcharts and diagrams",
      };
      const fileContent = await fetchImagePreview(data);
      console.log("fetchDataFile", fileContent);

      if (fileExtension === "gif" || fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
        const blob = new Blob([fileContent], { type: `image/${fileExtension}` });
        const url = URL.createObjectURL(blob);
        console.log(url, "url")
        setFileURL("");
        setHTMLPreview("")
        setFilePreviewEditor(`<img src="${url}" />`);
        setIsFilePreviewOpen(true);
      } else {
        showToast("Warning.noPreview")
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDiagramsClick = async (template) => {
    try {
      let fileExtension = template?.split('.').pop().toLowerCase();
      const data = {
        filename: template,
        folderPath: "Flowcharts and diagrams",
      };

      const fileContent = await fetchImagePreview(data);
      console.log("fetchDataFile", fileContent);

      if (fileExtension === "gif" || fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
        // Convert the fileContent (ArrayBuffer) to a base64 string
        const base64String = await arrayBufferToBase64(fileContent);
        const imageUrl = `data:image/${fileExtension};base64,${base64String}`;

        // if (editor1.current) {
        //   setContent(content => `${content}<img src="${imageUrl}" alt="Image Preview" />`);
        //   setHtmlContent(content => `${content}<img src="${imageUrl}" alt="Image Preview" />`);
        // }
        setTimeout(() => {
          if (editor1.current) {
            const editor = Jodit.make(editor1.current);
            if (editor) {
              editor.selection.insertHTML(
                `<img src="${imageUrl}" alt="Inserted Image" />`
              );
            }
          }
        }, 100);
      }

      setIsOpenNewModal(false)
      setIsgetDataEditor(true);
      setLoading(false);

    } catch (error) {
      console.log(error);
    }
  }
  const fetchEstimateTemplate = async () => {
    let data = {
      path: "Spreadsheets/"
    }
    try {
      const response = await getS3Data(data)
      if (response.status == 200) {
        console.log("response of full proposal", response)
        setShowEstimateTemplate(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }



  const handleEstimatePreview = async (template) => {
    try {
      let fileExtension = template?.split('.').pop().toLowerCase();
      const data = {
        filename: template,
        folderPath: "Spreadsheets"
      };
      let fileContent
      if (fileExtension == "html") {
        fileContent = await convertPDFToHTML(data);
        console.log("fetchHTMLDataFile", fileContent);
        setHTMLPreview(fileContent.htmlContent);
        // setIsOpenNewModal(false)
        setFileURL("");
        setFilePreviewEditor("")
        // setPopupOpen(true);
        setIsFilePreviewOpen(true);
      } else if (fileExtension == "pdf") {

        fileContent = await getEstimateTemplatePreview(data);
        console.log("fetchDataFile", fileContent);
        if (fileExtension === "pdf") {
          const blob = new Blob([fileContent], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          console.log(url, "__url")

          setIsOpenNewModal(false)
          setIsFilePreviewOpen(true);
          // setPopupOpen(true);
        }

      } else {
        showToast("Warning.noPreview")
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchEstimateTemplate()
  }, [])


  const cursorPosition2 = useRef(null);
  console.log(cursorPosition2, "cursorPosition2")
  const handlePDFDataInEditor = async (template) => {
    try {
      let fileExtension = template?.split('.').pop().toLowerCase();
      const data = {
        filename: template,
        folderPath: "Spreadsheets"
      };
      const fileContent = await convertPDFToHTML(data);
      console.log("fetchDataFile", fileContent.htmlContent);
      console.log(fileContent, "______ress")
      console.log(editor1.current, "______editorcurrent")
      if (fileContent && typeof fileContent.htmlContent === 'string') {
        if (editor1.current) {
          // joditRef.current.jodit.selection.insertHTML('Your text to insert');


          console.log("Editor value",content)
          if (content) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = content; // Load existing content into a temporary div
        
            const tempFileDiv = document.createElement('div');
            tempFileDiv.innerHTML = fileContent.htmlContent; // Load new file content into a temporary div
        
            const pageContainer = tempDiv.querySelector('#page-container');
            const firstPfElement = tempDiv.querySelector('.pf'); // Select the first pf element to use as a template
        
            if (pageContainer && firstPfElement) {
                const selection = window.getSelection();
                if (!selection || selection.rangeCount === 0) {
                    console.warn("No selection found or range is empty.");
                    return;
                }
        
                const range = selection.getRangeAt(0);
                const anchorNode = range.startContainer;
                const selectedParentElement = anchorNode.nodeType === Node.ELEMENT_NODE ? anchorNode : anchorNode.parentElement;
        
                let currentPfElement = selectedParentElement?.closest('.pf');
                if (currentPfElement) {
                    const pfId = currentPfElement.id || 'Unknown ID';
                    console.log(`Found the current pf element: ${pfId}`);
        
                    let editableArea = currentPfElement.querySelector('.editable-area');
                    if (editableArea) {
                      // Clear the existing content in editable area
        
                        const linesPerPage = 25;
                        let currentLinesUsed = countRemainingLines(editableArea);
                        let remainingLines = linesPerPage - currentLinesUsed;
        
                        console.log(`Lines used in current pf: ${currentLinesUsed}, Remaining lines: ${remainingLines}`);
        
                        const tableContent = tempFileDiv.querySelector('table');
                        if (tableContent) {
                            const rows = Array.from(tableContent.querySelectorAll('tr'));
                            let rowIndex = 0;
        
                            while (rowIndex < rows.length) {
                                const currentTableWrapper = document.createElement('table');
                                const currentTableBody = document.createElement('tbody');
                                currentTableWrapper.appendChild(currentTableBody);
        
                                for (let i = 0; i < remainingLines && rowIndex < rows.length; i++, rowIndex++) {
                                    currentTableBody.appendChild(rows[rowIndex]); // Append row to the table body
                                }
        
                                editableArea.appendChild(currentTableWrapper);
                                tempDiv.querySelector(`#${currentPfElement.id} .editable-area`).innerHTML = editableArea.innerHTML;
        
                                if (rowIndex < rows.length) {
                                    console.log(`Remaining rows exceed current pf. Moving to the next pf or creating a new one.`);
        
                                    let nextPfElement = currentPfElement.nextElementSibling;
                                    if (!nextPfElement || !nextPfElement.classList.contains('pf')) {
                                        // Clone the first pf element to maintain the same structure
                                        nextPfElement = firstPfElement.cloneNode(true);
        
                                        // Remove only the content of the editable area in the cloned element
                                        const newEditableArea = nextPfElement.querySelector('.editable-area');
                                        newEditableArea.innerHTML = ""; // Clear content for new editable area
        
                                        // Increment the ID and page number for the new pf
                                        const newPfNumber = parseInt(currentPfElement.getAttribute('data-page-no')) + 1;
                                        nextPfElement.id = `pf${newPfNumber}`;
                                        nextPfElement.setAttribute('data-page-no', newPfNumber.toString());
        
                                        // Append the new pf element to the page container
                                        pageContainer.appendChild(nextPfElement);
                                        console.log(`Dynamically created a new pf element with ID: ${nextPfElement.id} and data-page-no: ${newPfNumber}.`);
                                    }
        
                                    currentPfElement = nextPfElement;
                                    editableArea = nextPfElement.querySelector('.editable-area');
                                    remainingLines = linesPerPage; // Reset remaining lines for the new pf
                                }
                            }
        
                            tempDiv.querySelector(`#${currentPfElement.id} .editable-area`).innerHTML = editableArea.innerHTML;
                            setContent(tempDiv.innerHTML); // Update the content state
                        }
                    }
                } else {
                    console.warn("No 'pf' element found at the current selection in the editor.");
                }
            } else {
                console.warn("Page Container or first pf element not found in the provided content.");
            }
        }
        
        

          setHtmlContent(content => `${content} \n ${fileContent.htmlContent}`);

          setIsPreviewOpen(false);
          setPopupOpen(false);
          setIsOpenNewModal(false);

        } else {
          alert("Editor instance is not available");
        }
      } else {
        alert("Failed to convert PDF to HTML");
      }
    } catch (error) {
      console.error("Error handling PDF data in editor", error);
    }
  };




  const countRemainingLines = (editableArea)=>{
    let currentPageContent = ''; 
    let editableHTML = editableArea.innerHTML.trim();
    editableHTML = editableHTML.replace(/<br\s*\/?>/gi, '\n');
    editableHTML = editableHTML.replace(/<\/?[^>]+(>|$)/g, "");
    const maxCharsPerLine = 65; // Maximum number of characters (letters and spaces) allowed per line
    const linesPerPage = 36; 
    console.log("editableHTML",editableHTML)
    const words = editableHTML?.replace(/\n/g, '<br>').split(/(<br>|\s+)/).filter(Boolean); 
    let isFirstPage = true; 
    let pages = [];
    let lineCount = 0; // To track the number of lines in the current page
    let currentLineChars = 0; // To track the number of characters in the current line


    words.forEach((word, index) => {
      // If the word is a <br> tag, handle it as a line break
      if (!word.trim()) {
          // console.log(`Skipping empty or whitespace word at index ${index}`);
          return;
      }

      if (word == '<br>') {
        

          // console.log(`Encountered <br> tag at index ${index}, moving to a new line.`);
          lineCount++; // Increment line count for the line break
          currentLineChars = 0; // Reset character count for the new line

       
      } else {
          // Calculate the total characters if the word is added to the current line
          const wordLengthWithSpace = currentLineChars === 0 ? word.length : word.length + 1; // Add space only if not the first word

          // console.log(`Adding word: "${word}" with length: ${wordLengthWithSpace} to current line.`);
          
          if (currentLineChars + wordLengthWithSpace <= maxCharsPerLine) {
              // If the word fits within the current line, add it
              currentLineChars += wordLengthWithSpace;
              // console.log(`Current line character count after adding: ${currentLineChars}`);
          } else {
              // If the word doesn't fit, move to a new line
              // console.log(`Word "${word}" caused overflow. Moving to a new line.`);
              lineCount++;
              currentLineChars = word.length; // Start the new line with the current word's length
              // console.log(`Line count: ${lineCount}, Characters in new line: ${currentLineChars}`);
          }
      }

      // Check if the current content exceeds the allowed number of lines per page
      if (lineCount >= linesPerPage) {
          // console.log("Reached maximum lines per page. Creating a new page.");

          // Push the content of the current page and start a new one
          pages.push(currentPageContent.trim());
          // console.log(`Page ${pages.length} created with content:`);
          console.log(currentPageContent);

          currentPageContent = ''; // Reset content for the new page
          lineCount = 0; // Reset line count for the new page
          currentLineChars = 0; // Reset character count for the new page

          // Adjust padding for subsequent pages
          isFirstPage = false;
      } 

      // Add the word or <br> to the current page content
      if (word === '<br>') {
          console.log("currentPageContent@@",currentPageContent)
          if (currentPageContent == "" || !currentPageContent  || !currentPageContent.trim()) {
            
              // console.log(`Skipping <br> tag at index ${index} because it's the start of a new page.`);
              return;
          }
          
      } else {
          // Add space before word if it's not the first word in the line
      }
      
  });

  return lineCount
    
  }

  const arrayBufferToBase64 = (buffer) => {
    return new Promise((resolve, reject) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }


  // const handleDownloadWithoutTable = () => {
  //   const doc = new jsPDF('landscape');
  //   const table = tableRef.current;
  //   console.log("tableContent",table )
  //   // Extract table headers
  //   const headers = Array.from(table.querySelectorAll('thead tr th')).map(th => th.textContent.trim());
  
  //   // Extract table data, ensuring alignment with headers
  //   const data = Array.from(table.querySelectorAll('tbody tr')).map(tr => 
  //     Array.from(tr.querySelectorAll('td')).map(td => td.textContent.trim())
  //   );
  
  //   // Check if headers and data columns are aligned
  //   if (headers.length > 0 && data.length > 0) {
  //     const columnsMatch = data.every(row => row.length === headers.length);
  //     if (!columnsMatch) {
  //       console.warn('Mismatch between headers and row data columns!');
  //     }
  //   }
  
  //   // Add the table to the PDF with borders
  //   doc.autoTable({
  //     head: [headers],
  //     body: data,
  //     theme: 'grid', // Use the 'grid' theme to include borders
  //     styles: {
  //       halign: 'center', // Horizontal alignment for cells
  //       lineWidth: 0.1,   // Border line width
  //       lineColor: [0, 0, 0], // Border color (black)
  //     },
  //     headStyles: {
  //       fillColor: [230, 230, 230], // Light gray background for headers (optional)
  //     },
  //     margin: { top: 20 }, // Top margin for the table
  //   });
  
  //   doc.save('table-content.pdf');
  // };
  
  const handleDownloadWithoutTable = () => {
    const doc = new jsPDF('landscape');
    const table = tableRef.current;
    console.log('Table Reference:', table);
    const headers = Array.from(table.querySelectorAll('thead tr th'))
    .slice(1) // Exclude the first <th>
    .map(th => th.textContent.trim());
    // Extract table data, ensuring we skip the first column with radio buttons and map the rest
    const data = Array.from(table.querySelectorAll('tbody tr')).map((tr) =>
      Array.from(tr.querySelectorAll('td')).map((td, index) => {
        // Skip the first cell which is for the radio button
        if (index === 0) return '';
        // Return the cell text content or an empty string if undefined or null
        return td.textContent || '';
      }).slice(1) // Skip the first column for all rows
    );
    console.log('Table Body Data:', data);
    // Ensure that the number of columns in each row matches the number of headers
    const formattedData = data.map(row => {
      return headers.map((header, index) => row[index] || ''); // Map the columns to headers, use empty string for missing data
    });
  
    // Add the table to the PDF with borders
    doc.autoTable({
      head: [headers],
      body: formattedData,
      theme: 'grid', // Include borders
      styles: {
        halign: 'center', // Center-align cell content
        lineWidth: 0.1, // Set border width
        lineColor: [0, 0, 0], // Border color (black)
      },
      headStyles: {
        fillColor: [230, 230, 230],
        textColor: [0, 0, 0], // Light gray background for headers (optional)
      },
      margin: { top: 20 }, // Top margin for the table
    });
  
    // Save the PDF document
    doc.save('table-content.pdf');
  };
   
  





  const handleEditClick = (requirementId, resourceId, currentRate) => {
    setEditState({ [requirementId]: { [resourceId]: true } });
    setNewRate({ [requirementId]: { [resourceId]: currentRate } });
  };

  // const handleSaveClick = (requirementId, resourceId) => {
  //   const parsedRate = parseFloat(newRate[requirementId][resourceId]);
  //   updateResourcePayRate(requirementId, resourceId, isNaN(parsedRate) ? 0 : parsedRate);
  //   setEditState({ [requirementId]: { [resourceId]: false } });
  // };
  const handleSaveClick = (requirementId, resourceId) => {
    const parsedRate = parseFloat(newRate[requirementId][resourceId]);
    updateResourcePayRate(requirementId, resourceId, isNaN(parsedRate) ? 0 : parsedRate);
    setEditState({ [requirementId]: { [resourceId]: false } });
    const selected = selectedResources[requirementId] || [];
    const hours = effectiveHours[requirementId] || 0;
    setResourcePayRates(prevState => {
      const updatedPayRates = {
        ...prevState,
        [requirementId]: {
          ...prevState[requirementId],
          [resourceId]: parsedRate,
        },
      };
      const totalAmount = calculateTotalBillingAmount(requirementId, selected, hours, updatedPayRates);
      setTotalBillingAmounts(prevState => ({
        ...prevState,
        [requirementId]: totalAmount
      }));
      const updatedDummy = dummy.map((row) => {
        if (row.requirement_id === requirementId) {
          return {
            ...row,
            hours: hours,
            totalAmount: totalAmount,
          };
        }
        return row;
      });
      setDummy(updatedDummy);
      return updatedPayRates;
    });
  };


  const handleRateChange = (requirementId, resourceId, value) => {
    setNewRate({
      ...newRate,
      [requirementId]: {
        ...newRate[requirementId],
        [resourceId]: value,
      },
    });
  };




  const updateResourcePayRate = (requirementId, resourceId, newRate) => {
    setResourcePayRates(prevState => ({
      ...prevState,
      [requirementId]: {
        ...prevState[requirementId],
        [resourceId]: newRate,
      },
    }));
  };




  const userEmail = sessionStorage.getItem("currentUserEmail")
  useEffect(() => {

    fetchData1();
  }, [userEmail, assetId]);



  const fetchData1 = async () => {
    try {
      const data = {
        assetId: proposalWritingData.assetid,
        userEmail: userEmail,
      };
      const response = await validateTerminateBtn(data);
      if (response.isLastTeamUser) {
        setIsLastStage(true)
      }



      if (response.isUpperManagementTeamUser) {
        setShowTerminateButton(response.isUpperManagementTeamUser);
        console.log(showTerminateButton, "showTerminateButton")
      } else {
        console.error('Failed to validate terminate button', response.data);
      }
    } catch (error) {
      console.error('Error validating terminate button', error);
    }
  };








  // const [isGetDataEditor2, setIsgetDataEditor2] = useState(false);
  const handleCloseFinalProposal = () => {
    showToast('Proposal.proposalCompleted')
    history.push("/OppourtunityManagementSubadmin")
    setFinalProposal(false);
  };

  const handleDownloadFinalProposal = () => {
    const element = document.createElement('a');
    const file = new Blob([originalData], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'originalData.txt';
    document.body.appendChild(element);
    element.click();
  };

  const closeModalFarCompliance = () => {
    setShowPopupFarCompliance(false);
  };





  const handleSignatureSubmit = (signatureUrl) => {
    const data = {
      assetId: proposalWritingData.assetid,
      orgId: orgId,
      assetType: "proposal",
      userId: userInfo._id,
      teamName: teamTasks.teamName,
      signature: signatureUrl
    }
    setSignaturePayload(data)
    getTaskCompletion()
    setIsSignatureModalOpen(false);
  };

  const closeSignatureModal = () => setIsSignatureModalOpen(false);



  const closeTaskCompletionModal = () => {
    setTaskCompletionModal(false)
  }

  const openChatModal = () => {
    fetchAllTeamMembers()
  }

  const closeChatModal = () => {
    setChatModal(false)
  }

  const fetchAllTeamMembers = async () => {
    try {
      setLoading(true);
      const data = {
        orgId,
        email: sessionStorage.getItem("currentUserEmail")

      }
      const response = await getUsersByOrgId(data);
      if (response.status === 200) {
        console.log(response, "all team members");
        setOrgUsers(response.users)
        setChatModal(true);
      } else {
        console.log("Error in fetching team members");
      }
    } catch (error) {
      console.error("An error occurred while fetching team members:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleComplianceCheck = async () => {

    setLoading(true);

    // Fetch clauses from documents
    let { responseDataArray: one, manuallyAddedClausesArray } = await ExtractClauseFromOpportunityDoc();

    console.log("one12  ", one);
    console.log("manually added  ", manuallyAddedClausesArray);

    // Fetch clauses from sections
    let two = await extractClausesFromAllSections();

    console.log("two12  ", two);

    if (one === undefined || two === undefined) {
      showToast('Proposal.clausesNotFound')
      setLoading(false);
      return;
    }

    // Modify each element in the arrays to remove text after decimal
    one = one.map(clause => clause.replace(/(\d+\.\d+).*/, "$1"));
    two = two.map(clause => clause.replace(/(\d+\.\d+).*/, "$1"));
    manuallyAddedClausesArray = manuallyAddedClausesArray.map(clause => clause.replace(/(\d+\.\d+).*/, "$1"));

    // Find missing elements
    const missingElements = one.filter(clause => !two.includes(clause));
    const missingElementsFromManuallyAdded = manuallyAddedClausesArray.filter(clause => !two.includes(clause));

    // Remove duplicates from missingElements
    const uniqueMissingElements = Array.from(new Set(missingElements));
    const uniqueMissingManualAddedElements = Array.from(new Set(missingElementsFromManuallyAdded));

    console.log("we are  ", uniqueMissingElements);
    console.log("Missing from manually added: ", uniqueMissingManualAddedElements);

    // Set popup message based on missing elements
    if (uniqueMissingElements.length === 0 && uniqueMissingManualAddedElements.length === 0) {
      setPopupTitleFarCompliance("Compliance Check");
      setPopupMessageFarCompliance("The mentioned FAR clauses are compliant");
    } else {
      const formattedClauses = uniqueMissingElements
        .map(clause => `<li>${clause}</li>`)
        .join('');

      const formattedManualAddedClauses = uniqueMissingManualAddedElements
        .map(clause => `<li>${clause}</li>`)
        .join('');

      const formattedMessage = `
  The following FAR Clauses are missing in the sections of the proposal. Please revisit the details <ul>${formattedClauses}</ul>
  The following FAR Clause(s) is/are non-compliant. Please check if it was added manually: <ul>${formattedManualAddedClauses}</ul>
      `;

      setPopupTitleFarCompliance("Non-Compliance Detected");
      setPopupMessageFarCompliance(formattedMessage);
    }

    // Show the popup and indicate loading is complete
    setShowPopupFarCompliance(true);
    setLoading(false);
  };


  const closeRetrieveRequirementModal = () => {
    setRetrieveRequirementModal(false)
  }

  const handleHistoryClicked = () => {
    setHistoryClicked(true)
  }

  const handleRevertClicked = async () => {

    try {
      const response = await revertRequirementMapping(requirementMappingId, selectedRow.requirement_id);
      if (response.status === 200) {

        const res = await addRetrievedRequirement(requirementMappingId)
        setHistoryClicked(false)
      }


    } catch (error) {
      console.error("Error reverting requirement mapping:", error);
    }

  }


  const addRetrievedRequirement = async (id) => {
    setRequirementMappingId(id)
    try {
      const response = await getRequirementMappingById(id);

      const transformedMapping = response?.requirements?.mapping?.map(item => {
        return {
          clauses: item.Clauses,
          filename: item.filename,
          section_title: item.Section,
          requirement_id: item.requirementID,
          requirement_text: item.description,
          manualClauses: item.manualClauses?.map(clause => ({ label: clause, value: clause })) || [],
          manualSection: item.manualSection?.map(section => ({ label: section, value: section })) || [],
          resources: item.Resources?.map(resource => ({ label: resource.label, value: resource.value })) || [],
          hours: item.hours,
          remarks: item.remarks,
          amount: item.amount,
          payRates: item.payRates?.map(pay => ({ label: pay.rate, value: pay.id }))

        };
      });

      const initialSelectedSectionMap = transformedMapping.reduce((acc, item) => {
        acc[item.requirement_id] = item.manualSection;
        return acc;
      }, {});

      const initialSelectedClauseMap = transformedMapping.reduce((acc, item) => {
        acc[item.requirement_id] = item.manualClauses;
        return acc;
      }, {});

      const initialSelectedResourceMap = transformedMapping.reduce((acc, item) => {
        acc[item.requirement_id] = item.resources;
        return acc;
      }, {});

      const initialSelectedHours = transformedMapping.reduce((acc, item) => {
        acc[item.requirement_id] = item.hours;
        return acc;
      }, {});

      const initialSelectedRemarks = transformedMapping.reduce((acc, item) => {
        acc[item.requirement_id] = item.remarks;
        return acc;
      }, {});

      const initialSelectedAmount = transformedMapping.reduce((acc, item) => {
        acc[item.requirement_id] = item.amount;
        return acc;
      }, {});

      const initialSelectedPayrate = transformedMapping.reduce((acc, item) => {
        acc[item.requirement_id] = item.payRates;
        return acc;
      }, {});


      setSelectedSectionMap(initialSelectedSectionMap);
      setselectedClauseResources(initialSelectedClauseMap)
      setSelectedResources(initialSelectedResourceMap)
      setResourcePayRates(initialSelectedPayrate)
      setEffectiveHours(initialSelectedHours)
      setRemarks(initialSelectedRemarks)
      setTotalBillingAmounts(initialSelectedAmount)

      setDummy(transformedMapping)


      setRequirementMappingData(null);
      setShowSection(false);
      setShowRequirnments(false);
      setShowMapping(true);

      setRetrieveRequirementClicked(true)
      closeRetrieveRequirementModal()

    } catch (error) {
      console.error("Error retrieving requirement mapping:", error);
    }
  }


  const formatDate2 = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };





  const extractClausesFromAllSections = async () => {

    try {
      const assetId = proposalWritingData.assetid
      const orgId = sessionStorage.getItem("organizationId")
      const data = { orgId: orgId, assetId: assetId }
      const response = await getClausesByAssetId(data); // Fetch clauses data
      if (response && response.data) {

        console.log("432  3", response)
        const allFarClauses = response.data.flatMap(section => section.farClauses);


        console.log("allFarClauses1232 ", allFarClauses)
        return allFarClauses

      }
    } catch (error) {
      console.error('Error fetching clauses data:', error);
    }
  }



  //new 
  const ExtractClauseFromOpportunityDoc = async () => {
    let data = {
      resourceLinks: assetData?.opportunityid.resourceLinks,
      assetId: proposalWritingData?.assetid,
      email: sessionStorage.getItem("currentUserEmail")

    };
    try {
      const response = await getResourceLinks(data);

      if (response.status === 201) {
        console.log("extracted clauses:", response);
        console.log("pkj2  ", response);

        const responseDataArray = [];
        const manuallyAddedClausesArray = [];

        if (response && Array.isArray(response.data.clauses)) {
          response.data.clauses.forEach(clause => {
            if (clause.responseData !== "no clauses found") {
              const parts = clause.responseData.split('\n');
              if (clause.filename === "Manually Added Clauses") {
                manuallyAddedClausesArray.push(...parts);
              } else {
                responseDataArray.push(...parts);
              }
            }
          });

          console.log("ExtractClauseFromOpportunityDoc  ", responseDataArray);
          console.log("Manually Added Clauses  ", manuallyAddedClausesArray);

          return {
            responseDataArray,
            manuallyAddedClausesArray
          };
        }
      } else if (response.status === 400) {
        // alert("status is 400 on line 466");
      } else {
        console.log("error in getting extracted clauses ");
      }
    } catch (error) {
      console.log(error);
    }
  };




  const handleClickOpenCompliance = () => {
    setOpenCompliance(true);
  };

  const handleCloseCompliance = () => {
    setOpenCompliance(false);
  };


  const handleFilesModal = async (title, sectionId, createdby) => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    const response = await validateFunctionalityAccess(data);
    if (response.status === 200) {
      if (title === "Cost and Estimation" && !response.isFinanceTeamUser) {
        showToast('Proposal.accessDenied')
      } else {
        // setAssetSetionId(sectionId);
        setIsOpenNewModal(true)

      }
    } else if (response.status === 403 && response.isFinanceTeamUser && title === "Cost and Estimation") {
      // setAssetSetionId(sectionId);
      setIsOpenNewModal(true)

    } else {
     showToast('Error.errorOccured')
    }
  };


  const handleClauseExtractionForCompliense = async () => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    if (showBtnInMerge) {
      // If showBtnInMerge is true, give access without validation
      processClauseExtraction();
    } else {
      // Validate functionality access
      const response = await validateFunctionalityAccess(data);

      if (response.status === 200) {
        if (sectionTitle === "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessDenied')
        } else {
          processClauseExtraction();
        }
      } else if (response.status === 403 && response.isFinanceTeamUser && sectionTitle === "Cost and Estimation") {
        processClauseExtraction();
      } else {
        showToast('Error.errorOccured')
      }
    }
  };




  const processClauseExtraction = async () => {
    setDocClauseArray("");
    setExtractedClause("");


    const data = {
      resourceLinks: assetData?.opportunityid.resourceLinks,
      assetId: proposalWritingData?.assetid,
      email: sessionStorage.getItem("currentUserEmail")
    };

    try {
      setLoading(true);
      const response = await getResourceLinks(data);

      if (response.status === 201) {

        console.log("extracted clauses:", response);
        setDocClauseArray(response);
        extractResponseData(response);
      } else if (response.status === 400) {
        getExtractedClause(proposalWritingData?.assetid);
      } else {

        console.log("error in getting extracted clauses");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };





  async function extractResponseData(response) {
    // Initialize an empty array to hold the extracted response data
    //This function is giving the array having only FAR clauses of opportunity Document

    console.log("pkj2  ", response)

    const responseDataArray = [];

    // Check if the response has data and clauses
    if (response && Array.isArray(response?.data?.clauses)) {
      // Iterate through each clause
      response.data.clauses.forEach(clause => {
        // Check if the responseData is not "no clauses found"
        if (clause.responseData !== "no clauses found") {
          // Split the responseData string by newline character and add each part separately
          const parts = clause.responseData.split('\n');
          responseDataArray.push(...parts);
        }
      });
    }
    setDocClauseArray(responseDataArray)

    checkCompliance(responseDataArray)
    console.log("respo12 ", responseDataArray)


  }

  async function checkCompliance(opportunityDocumentClauses) {


    console.log("Opportunity Document Clauses:", opportunityDocumentClauses);
    console.log("Clauses Data:", clausesData);

    // Initialize an empty array to hold the matched section farClauses
    let matchedFarClauses = [];

    // Iterate through each section in the data array
    clausesData.forEach(section => {
      // Check if sectionTitle matches the provided searchString
      if (section.sectionTitle === sectionTitle) {
        // If matched, assign the farClauses of the matched section
        console.log("Matched Section Title:", section.sectionTitle);
        matchedFarClauses = section.farClauses;
      }
    });

    console.log("Matched FAR Clauses:", matchedFarClauses);

    matchedFarClauses = matchedFarClauses.map(clause => clause.replace(/(\d+\.\d+).*/, "$1"));
    opportunityDocumentClauses = opportunityDocumentClauses.map(clause => clause.replace(/(\d+\.\d+).*/, "$1"));

    console.log("Matched FAR Clauses12:", matchedFarClauses);
    console.log("opportunityDocumentClauses1243  :", opportunityDocumentClauses);

    // if (matchedFarClauses.length === 0) {
    //    toast.warn("No clauses found");
    //   return;
    // }

    const unmatchedClauses = matchedFarClauses.filter(clause => !opportunityDocumentClauses.includes(clause));
    console.log("unmatchedClause  ", unmatchedClauses)

    if (unmatchedClauses.length === 0) {
      setDialogTitle('Compliance Check');
      setDialogContent('The mentioned FAR clauses are compliant');
    } else {
      setDialogTitle('Compliance Check');
      setDialogContent(`The FAR Clause ${unmatchedClauses.join(', ')} is non-complaint. Please check if it was added manually`);
    }

    handleClickOpenCompliance();

  }

  useEffect(() => {
    getTaskCompletionOnpageLoad()
    fetchAuditHistory()
  }, []);





  const handleChange = (event) => {

    console.log(event.target.value,"vrushabhvbdata")
    const selectedOption = JSON.parse(event.target.value);
    const { index, document } = selectedOption;
    console.log(document,index, "documentdata")
    setSelectedTaskIndex(index);
    if (index !== '') {
      const selectedTask = tasks[index];
      handledropdownclick(
        document?.documentpath,
        document?.type,
        document?.htmlContent,
        document?._id,
        document?.title,
        document?.assetType,
        selectedTask,
        selectedTask?.assignToEmail,
        document?.taskAssignWith[0].htmlcontent
      );
    }
    setCurrentIndex(index); // Update the parent state

  };
  // const handleChangeforsubsection = (event) => {

  //   // alert(event.target.value)
  //   const newIndexsubsection = event.target.value;
  //   setSelectedTaskIndexforsubsection(newIndexsubsection);
  //   setCurrentIndexforsubsection(newIndexsubsection); // Update the parent state

  // };

  const handleChangeforsubsection = (event) => {
    // Parsing the selected option value
    const selectedOption = JSON.parse(event.target.value);
    const { index, subtask } = selectedOption;

    console.log(subtask,index, "subtaskdocument");

    setSelectedTaskIndexforsubsection(index);
    setCurrentIndexforsubsection(index); // Update the parent state

    if (index !== '') {
      handledropdownclickforsubsection(subtask, index);
    }
  };

  const handledropdownclick = (
    documentpath,
    type,
    htmlContent,
    _id,
    title,
    assetType,
    task,
    email,
  updatedContentreceiver) => {
    // alert("editors")
    setSubsectionButtons(false)
    setsubsectionEmail(email)
    console.log(documentpath,
      type,
      htmlContent,
      _id,
      title,
      assetType,
      task,
      email, updatedContentreceiver,"subsectionsDatasubsectionsData")
    // alert(email)

    //   const matchedSubsections = document?.subsectionsData
    //   .map(subsection => ({
    //     ...subsection,
    //     taskAcceptedEmail: subsection.taskAcceptedEmail.filter(task => task.email === email)
    //   }))
    //   .filter(subsection => subsection.taskAcceptedEmail.length > 0);


    // console.log(matchedSubsections, "matchedSubsections");

    // setSeubsectionDataforApproval(matchedSubsections)


    handlegetFileNames(
      documentpath,
      type,
      htmlContent,
      _id,
      title,
      assetType,
      tasks[selectedTaskIndex]?.taskassignid,
      tasks[selectedTaskIndex]?.linkSubmitted,
      tasks[selectedTaskIndex]?.htmlcontent,
      tasks[selectedTaskIndex]?.assignToEmail,
      tasks[selectedTaskIndex]?.link,
      // updatedContentreceiver
    );
  }






  const handleSelectChange = async (selectedOption) => {
    const selectedValue = selectedOption.value;
    console.log("Selected value:", selectedValue);

    setSelectedKeyword(selectedValue);
    setLoading(true);

    const dataToSend = {
      selectedKeyword: selectedValue
    };

    try {
      const response = await smartSuggestion(dataToSend);
      if (response.status === 200) {
        console.log("keywordData___", response.data);
        setKeywordData(response.data.output);
      } else {
        showToast('Proposal.errKeywordData')
      }
    } catch (error) {
      showToast('Proposal.errKeywordData')
    } finally {
      setLoading(false);
    }
  };


  // const handledropdownclickforsubsection = (subtask) => {
  //   setSubsectionButtons(true)
  //   console.log(subtask, "subtaskdocument")



  //   // alert(subsectionEmail)



  //   setSeubsectionDataforApproval(subtask)
  //   setSubsectionIdforAcceptance(subtask?.subsectionIds)


  //   const specificEmailData = subtask?.taskAcceptedEmail?.filter(k => k.email == subsectionEmail)
  //   console.log(specificEmailData)
  //   // alert(specificEmailData[0].htmlUpdatedData)
  //   handlegetFileNames(
  //     subtask.originalDocumentLink,
  //     "sec",
  //     subtask.htmlData,
  //     subtask._id,
  //     subtask.subsectionName,
  //     "proposal",
  //     "6667e96c541ed808a8369610",
  //     specificEmailData[currentIndexforsubsection]?.submittedlink,
  //     specificEmailData[currentIndexforsubsection]?.htmlUpdatedData,
  //     specificEmailData[currentIndexforsubsection]?.email,
  //     specificEmailData[currentIndexforsubsection]?.originalDocumentLink
  //   );

  // }


  const handledropdownclickforsubsection = (subtask, index) => {
    setSubsectionButtons(true);

    console.log(subtask, "subtaskdocument");

    setSeubsectionDataforApproval(subtask);
    setSubsectionIdforAcceptance(subtask?.subsectionIds);

    const specificEmailData = subtask?.taskAcceptedEmail?.filter((k) => k.email === subsectionEmail);
    console.log(specificEmailData);

    handlegetFileNames(
      subtask.originalDocumentLink,
      "sec",
      subtask.htmlData,
      subtask._id,
      subtask.subsectionName,
      "proposal",
      "6667e96c541ed808a8369610",
      specificEmailData[currentIndexforsubsection]?.submittedlink,
      specificEmailData[currentIndexforsubsection]?.htmlUpdatedData,
      specificEmailData[currentIndexforsubsection]?.email,
      specificEmailData[currentIndexforsubsection]?.originalDocumentLink
    );
  };


  const fetchClausesData = async () => {
    try {
      const assetId = proposalWritingData.assetid
      const orgId = sessionStorage.getItem("organizationId")
      const data = { orgId: orgId, assetId: assetId }
      const response = await getClausesByAssetId(data); // Fetch clauses data
      if (response && response.data) {
        console.log("Chetn rspo  ", response.data)
        setClausesData(response.data); // Set clauses data in state
      }
    } catch (error) {
      console.error('Error fetching clauses data:', error);
    }
  };

  const handleClauseToggle = (clause) => {
    const existingClauseIndex = selectedClauses.findIndex(
      (c) => c.sectionId === clause.sectionId && c.clause === clause.clause
    );
    if (existingClauseIndex !== -1) {
      setSelectedClauses(selectedClauses.filter((_, index) => index !== existingClauseIndex));
    } else {
      setSelectedClauses([...selectedClauses, clause]);
    }
  };



  const handleSelectAllClausesToggle = () => {
    if (allClausesSelected) {
      setSelectedClauses([]);
    } else {
      const allClauses = [];
      clausesData.forEach(section => {
        const uniqueSectionClauses = [...new Set([...section.farClauses, ...section.dfarClauses])];
        uniqueSectionClauses.forEach(clause => {
          allClauses.push({ sectionId: section.sectionId, clause });
        });
        section.subsections.forEach(subsection => {
          const uniqueSubsectionClauses = [...new Set([...subsection.farClauses, ...subsection.dfarClauses])];
          uniqueSubsectionClauses.forEach(clause => {
            allClauses.push({ sectionId: subsection.subsectionId, clause });
          });
        });
      });
      setSelectedClauses(allClauses);
    }
    setAllClausesSelected(!allClausesSelected);
  };


  const handleSectionsListToggle = async () => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    if (showBtnInMerge) {
      // If showBtnInMerge is true, give access without validation
      setIsDialogOpen(true);
    } else {
      // Validate functionality access
      const response = await validateFunctionalityAccess(data);

      if (response.status === 200) {
        if (sectionTitle === "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessDenied')
        } else {
          setIsDialogOpen(true);
        }
      } else if (response.status === 403 && response.isFinanceTeamUser && sectionTitle === "Cost and Estimation") {
        setIsDialogOpen(true);
      } else {
        showToast('Error.errorOccured')
      }
    }
  };
  console.log(projectType, "__projectType")
  const handleSectionToggle = (section) => {
    const sectionId = section._id || section.id; // Ensure the identifier used is consistent
    if (selectedSections.some(s => (s._id || s.id) === sectionId)) {
      setSelectedSections(selectedSections.filter(s => (s._id || s.id) !== sectionId));
    } else {
      setSelectedSections([...selectedSections, section]);
    }
  };



  const handleSelectAllToggle = () => {
    if (allSelected) {
      setSelectedSections([]);
    } else {
      const allSections = [...asssetsectionData];
      asssetsectionData.forEach(section => {
        if (subsectionsMap[section._id]) {
          allSections.push(...subsectionsMap[section._id]);
        }
      });
      setSelectedSections(allSections);
    }
    setAllSelected(!allSelected);
  };
  console.log(allSelected, "allSelected")

  const handleSubmitToReview = async () => {
    setLoading(true)
    try {
      let data = {
        assetId: proposalWritingData?.assetid
      };
      const response = await submitToNextTeam(data);
      if (response.status == 200) {
        history.push("/OppourtunityManagementSubadmin");
        setLoading(false)
        showToast('Proposal.proposalSubmit')
      } else {
        setLoading(false)
        showToast('Error.somethingFailed')
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
      showToast('Error.errorOccured')
    }
  };


  const handleOpenSendBackModal = () => {
    setSendBackButtonClicked(true)
    getTaskCompletion()
    // getPreviousTeams()
  };

  const getPreviousTeams = async () => {
    try {
      let data = {
        assetId: proposalWritingData?.assetid,
        orgId
      };
      const response = await sendBackTeams(data);
      if (response.status == 200) {
        setSendBackModalOpen(true);
        setPreviousTeams(response.data)
      } else {
        showToast('Error.errorOccured')
      }

    } catch (error) {
      console.log(error);
      showToast('Error.errorOccured')
    }
  }

  const handleCloseSendBackModal = () => {
    setSendBackModalOpen(false);
  };

  const handleSendBack = async (selectedTeam) => {
    if (selectedTeam === "") {
      showToast('Proposal.selectTeam')
      return
    }

    setLoading(true)
    try {
      let data = {
        assetId: proposalWritingData?.assetid,
        orgId: orgId,
        selectedTeam: selectedTeam,
        userEmail: sessionStorage.getItem("currentUserEmail")
      };
      const response = await sendBack(data);
      if (response.status == 200) {
        auditHistoryAddition("Send back", selectedTeam, teamTasks.teamName)
        history.push("/OppourtunityManagementSubadmin");
        setLoading(false)
        showToast('Proposal.sentBack')
      } else {
        setLoading(false)
        showToast('Proposal.errSentBack')
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
      showToast('Error.errorOccured')
    }
    handleCloseModal();
  };



  const fetchAuditHistory = async () => {
    try {
      let data = {
        assetId: proposalWritingData?.assetid,
        orgId: orgId
      };

      const response = await getAuditHistory(data);
      if (response.status == 200) {
        const lastAuditEntry = response.auditHistory[response.auditHistory.length - 1];
        setAuditHistory(lastAuditEntry)
      } else {
        console.log("Audit history not fetched")
      }

    } catch (error) {
      console.log("Error in fetching audit history:", error);
    }
  }

  const auditHistoryAddition = async (actionName, selectedTeam, fromTeam) => {
    try {
      let data = {
        assetId: proposalWritingData?.assetid,
        orgId: orgId,
        userId: userInfo._id,
        userName: userInfo.username,
        action: actionName,
        toTeam: selectedTeam,
        fromTeam: fromTeam
      };

      console.log(data, "lololol")

      const response = await addAuditHistory(data);
      if (response.status == 200) {
        console.log("Audit history added successfully")
      } else {
        console.log("Audit history not added")
      }

    } catch (error) {
      console.log("Error in adding audit history:", error);
    }
  }


  useEffect(() => {
    const allTasksAreCompleted = asssetsectionData.every(section =>
      section.taskAssignWith.every(task =>
        ["Task Approved", "Task Denied","Task Rejected"].includes(task.statusofSection)
      )
    );

    setAllTaskCompleted(allTasksAreCompleted);
  }, [asssetsectionData]);

  useEffect(() => {
    console.log(allTaskCompleted, "akakakaka")
  }, [allTaskCompleted])

  const insertLink = (text, sectionId) => {
    const link = document.createElement('a');
    link.textContent = text;
    link.style.cursor = 'pointer';
    link.style.textDecoration = 'underline';
    link.style.color = 'blue';
    link.href = `#${sectionId}`;

    link.addEventListener('click', (event) => {
      event.preventDefault();
      openEditor(sectionId);
    });

    const linkContainer = document.createElement('div');
    linkContainer.style.display = 'inline';

    linkContainer.appendChild(link);

    const space = document.createTextNode(' ');
    linkContainer.appendChild(space);

    setContent((prevContent) => {
      const container = document.createElement('div');
      container.innerHTML = prevContent;
      container.appendChild(linkContainer);
      return container.innerHTML;
    });
  };
  // const insertClauseLink = (text, clauseId) => {
  //   const link = document.createElement('a');
  //   link.textContent = text;
  //   link.style.cursor = 'pointer';
  //   link.style.textDecoration = 'underline';
  //   link.style.color = 'blue';
  //   link.href = `/FARclause#${text}`; // Redirect to the specified clause

  //   link.addEventListener('click', (event) => {
  //     event.preventDefault();
  //     console.log("Clause Link clicked, clauseId:", text);
  //     // You can define the behavior when the clause link is clicked here
  //     // For example, redirecting to the specific clause route
  //     window.location.href = `/FARclause#${text}`;
  //   });

  //   const linkContainer = document.createElement('div');
  //   linkContainer.style.display = 'inline';

  //   linkContainer.appendChild(link);

  //   const space = document.createTextNode(' ');
  //   linkContainer.appendChild(space);

  //   setContent((prevContent) => {
  //     const container = document.createElement('div');
  //     container.innerHTML = prevContent;
  //     container.appendChild(linkContainer);
  //     return container.innerHTML;
  //   });
  // };


  const insertClauseLink = (text, clauseId) => {
    const isAlreadyFormatted = (text) => {
        return /^FAR_\d+(_\d+){1,2}$/.test(text) || /^DFARS?(_\d+){1,2}$/.test(text);
    };

    const convertClauseText = (text) => {
        // Handle FAR formatting
        if (isAlreadyFormatted(text)) {
            const parts = text.split('_');
            return `FAR_${parts[1]}_${parts[2]}`; // Keep FAR_x_x format
        }

        // Handle DFARS formatting
        const result = text.match(/(\d+(\.\d+)?)/g);
        if (result && result.length > 0) {
            const clauseNumbers = result.join('_').replace(/[_\.]/g, '.'); // Replace underscores and dots
            const parts = clauseNumbers.split('.');

            if (text.startsWith('DFARS') || text.startsWith('DFAR')) {
                // Ensure we only format if we have at least two parts
                if (parts.length >= 2) {
                    return `DFARS_${parts[0]}.${parts[1]}`; // Change to DFARS_x.x format
                } else if (parts.length === 1) {
                    return `DFARS_${parts[0]}`; // Just return the first part if there's only one
                }
            } else {
                // Only keep up to two sections for FAR
                return `FAR_${parts.slice(0, 2).join('_')}`;
            }
        }

        return text; 
    };

    const formattedText = convertClauseText(text);

    const link = document.createElement('a');
    link.textContent = text;
    link.style.cursor = 'pointer';
    link.style.textDecoration = 'underline';
    link.style.color = 'blue';
    link.href = `/FARclause#${formattedText}`; 

    link.addEventListener('click', (event) => {
        event.preventDefault();
        console.log("Clause Link clicked, clauseId:", formattedText);
        window.location.href = `/FARclause#${formattedText}`;
    });

    const linkContainer = document.createElement('div');
    linkContainer.style.display = 'inline';

    linkContainer.appendChild(link);

    const space = document.createTextNode(' ');
    linkContainer.appendChild(space);

    setContent((prevContent) => {
        const container = document.createElement('div');
        container.innerHTML = prevContent;
        container.appendChild(linkContainer);
        return container.innerHTML;
    });
};

  const handleHashChange = async () => {
    const sectionId = window.location.hash.substring(1);
    // console.log(sectionId, "sectionIddddd");
    if (sectionId) {
      openEditor(sectionId);
    } else {
      console.error('Data not yet loaded.');
    }
};

  window.addEventListener('hashchange', handleHashChange);
  window.addEventListener('load', handleHashChange);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedSections([]);
    setSelectedClauses([]);
    setAllClausesSelected(false)
    setAllSelected(false)
    setSelectedTab(0);
  };

  const handleAddSelection = () => {
    selectedSections.forEach(section => {
      insertLink(section.title, section._id);
    });
    selectedClauses.forEach(clause => {
      insertClauseLink(clause.clause, clause.sectionId);
    });
    setSelectedSections([]);
    setSelectedClauses([]);
    setIsDialogOpen(false);
    setAllClausesSelected(false)
    setAllSelected(false)
  };


  const openEditor = async (sectionId) => {
    setIsEditorReadOnly(true);
    try {
      const response = await getAssetsection(proposalWritingData.id, assetType, proposalWritingData.assetid);
      const sectiondata = response.data;
      console.log("Asset section data:", sectiondata);
      let parentSectionId = null;
      let parentTaskAssignWith = null;
      let parentCreatedBy = null;
      let parentsubsectiondata = null;
      for (const section of sectiondata) {
        const subsection = section.subSectionsData.find(sub => sub.subsectionIds === sectionId);
        if (subsection) {
          parentSectionId = section._id;
          parentTaskAssignWith = section.taskAssignWith; 
          parentCreatedBy = section.createdby;
          parentsubsectiondata = section.subSectionsData;  
          console.log("Found subsection in section:", section);
          break;
        }
      }
      if (parentSectionId) {
        console.log("Parent sectionId found:", parentSectionId);
        const subsectionsResponse = await getSubsections(parentSectionId);
        console.log("Fetched subsections for parent sectionId:", subsectionsResponse.data);
        
        const foundSubsection = subsectionsResponse.data.find(sub => sub._id === sectionId);
  
        if (foundSubsection) {
          console.log("Found subsection data:", foundSubsection);
          handlesubsectionFileName(foundSubsection.documentpath, foundSubsection.type, foundSubsection.htmlContent, foundSubsection._id, foundSubsection.title, parentsubsectiondata, parentTaskAssignWith, parentCreatedBy);
        } else {
          console.error('Subsection not found with sectionId:', sectionId);
        }
      } else {
        const section = sectiondata.find(section => section._id === sectionId);
        if (section) {
          console.log("Found section data:", section);
          handlegetFileName(section.documentpath, section.type, section.htmlContent, section._id, section.title, section.assetType, section.taskAssignWith, "owner", section.assetId, section.createdby);
        } else {
          console.error('Section or subsection not found with sectionId:', sectionId);
        }
      }
    } catch (error) {
      console.error('Error opening editor:', error);
    }
  };
  const dummyData = [
    { id: '1', title: 'Dummy Item 1' },
    { id: '2', title: 'Dummy Item 2' },
    { id: '3', title: 'Dummy Item 3' },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // useEffect(() => {
  //   // Fetch subsections for each section
  //   asssetsectionData.forEach(section => {
  //     if (!subsectionsMap[section._id]) {
  //       fetchSubsections(section._id);
  //     }
  //   });
  // }, [asssetsectionData]);
  const fetchSubsections = async (sectionId) => {
    console.log("fetchSubsections", sectionId, "sectionId")
    try {
      const result = await getSubsections(sectionId); // Using getSubsections function from utilities
      if (result.status === 200) {
        // Update subsectionsMap with fetched subsections for the section




        setSubsectionsMap(prevState => ({
          ...prevState,
          [sectionId]: result.data
        }));
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error fetching subsections:", error);
    }
  };






  const handleReply = async (id, replyMsg, isUpdateTrue) => {
    // console.log("insideReplyComment", id,replyMsg,isUpdateTrue)
    setIsReplyModelOpen(true)
    setSelectedCommentForReply(id)
    setReplyText(replyMsg)
    setCommentReplyUpdateIcon(isUpdateTrue)
  }

  const handleDeleteCommentReply = async (id) => {
    try {
      // console.log(id, "idFromDelete")
      const response = await deleteCommentReply(id)
      if (response.status == 200) {
        toast.success(response.message)
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.deleteReply, userInfo.username)
        handleGetActivity()
        handleGetTypeComment()

      }
    } catch (e) {
      console.log(e, "errro while deleting")
    }
  }

  const handleUpdateCommentReply = async () => {
    try {
      // console.log(selectedCommentForReply, userInfo.username, replyText,"____updateData")
      const response = await UpdateReplyOfComment(selectedCommentForReply, userInfo.username, replyText)
      if (response.status == 200) {
        toast.success(response.message)
        handleGetTypeComment()
        setIsReplyModelOpen(false)
        setReplyText('')
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.updateReply, userInfo.username)
        // console.log("update comment",activityResponse)
        handleGetActivity()
        setComments('')
      }
    } catch (e) {
      console.log(e, "error while deleting")
    }
  }

  const handleReplyTocomment = async (e) => {
    try {
      // console.log(replyText, userInfo.username, selectedCommentForReply)
      const response = await replyToComment(replyText, userInfo.username, selectedCommentForReply)
      // console.log("Reply called", response)
      if (response.status == 201) {
        toast.success(response.message)
        setIsReplyModelOpen(false)
        setReplyText('')
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.addReply, userInfo.username)
        handleGetActivity()
        handleGetTypeComment()
      } else {
        showToast('Proposal.errSendReply')
      }
    } catch (error) {
      console.log("Error while replying to comment", error)
    } finally {
      setIsReplyModelOpen(false)
    }
  }
  const handleReplyModelClose = async () => {
    setIsReplyModelOpen(false)
    setReplyText('')
  }

  const handleCancelReply = () => {
    setReplyText('')
    setIsReplyModelOpen(false)
  }

  const handleReplyChange = (event) => {
    setReplyText(event.target.value)
  }


  const handleNotesReply = async (id, replyMsg, isUpdateTrue) => {
    // console.log("insideReply", id,replyMsg,isUpdateTrue)
    setNotesReplyUpdateIcon(isUpdateTrue)
    setIsNotesReplyModelOpen(true)
    setSelectedNoteForReply(id)
    setNotesReplyText(replyMsg)
  }

  const handleDeleteNotesReply = async (id) => {
    try {
      // console.log(id, "idFromDelete")
      const response = await deleteCommentReply(id)
      if (response.status == 200) {
        toast.success(response.message)
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.deleteReply, userInfo.username)
        handleGetActivity()
        // handleGetTypeComment()
        handleGetComment()

      }
    } catch (e) {
      console.log(e, "errro while deleting")
    }
  }

  const handleUpdateNotesReply = async () => {
    try {
      console.log(selectedNoteForReply, userInfo.username, notesReplyText, "___updatenotebody")
      const response = await UpdateReplyOfComment(selectedNoteForReply, userInfo.username, notesReplyText)
      if (response.status == 200) {
        toast.success(response.message)
        // handleGetTypeComment()
        handleGetComment()
        setIsNotesReplyModelOpen(false)
        setNotesReplyText('')
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.updateReply, userInfo.username)
        // console.log("update comment",activityResponse)
        handleGetActivity()
      }
    } catch (e) {
      console.log(e, "error while deleting")
    }
  }

  const handleNotesReplyModelClose = async () => {
    setIsNotesReplyModelOpen(false)
    setNotesReplyText('')
  }

  const handleCancelNotesReply = () => {
    setNotesReplyText('')
    setIsNotesReplyModelOpen(false)
  }

  const handleReplyToNotes = async (e) => {
    try {
      // console.log(notesReplyText, userInfo.username, selectedNoteForReply)
      const response = await replyToComment(notesReplyText, userInfo.username, selectedNoteForReply)
      // console.log("Reply called", response)
      if (response.status == 201) {
        toast.success(response.message)
        setIsNotesReplyModelOpen(false)
        setNotesReplyText('')
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.addReply, userInfo.username)
        handleGetActivity()
        // handleGetTypeComment()
        handleGetComment()
      } else {
        showToast('Proposal.errSendReply')
      }
    } catch (error) {
      console.log("Error while replying to comment", error)
    } finally {
      setIsNotesReplyModelOpen(false)
    }
  }

  const handleNotesReplyChange = (event) => {
    setNotesReplyText(event.target.value)
  }



  const handleAddSubSectionModal = async (assetsectionId, createdby, title) => {
    let featurename = "Add SubSection";
    const checkfeatureName = accessFeatures?.filter(k => k.name === featurename);
    console.log(checkfeatureName[0].access, "checkfeatureName");

    if (!checkfeatureName[0].access) {
      showToast('Error.upload')
    } else {
      const UserEmail = sessionStorage.getItem("currentUserEmail");
      const data = {
        assetId: proposalWritingData.assetid,
        userEmail: UserEmail,
        assetType: "proposal"
      };

      const response = await validateFunctionalityAccess(data);
      if (response.status === 200) {
        if (title === "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessDenied')
        } else {
          setSubTitle('');
          setAddSubDescription('');
          setAssetSetionId(assetsectionId);
          setOpenAddSubSectionModal(true);
        }
      } else if (response.status === 403 && response.isFinanceTeamUser && title === "Cost and Estimation") {
        setSubTitle('');
        setAddSubDescription('');
        setAssetSetionId(assetsectionId);
        setOpenAddSubSectionModal(true);
      } else {
        showToast('Error.errorOccured')
      }
    }
  };


  const closeModalSectionshr = () => {
    setModalOpenSectionshr(false);
  };
  useEffect(() => {

    getAllUserForSameOrgData();

  }, [])

  const getAllUserForSameOrgData = async () => {
    // console.log("Called check", cuurentUser);
    // console.log("userinfodata===========", userInfo.email);
    const data = {
      email: sessionStorage.getItem("currentUserEmail"),
      assetid: proposalWritingData.assetid,
      assetType: assetType,
    };
    // console.log("existinguSersdata===========", data);
    try {
      const response = await getAllUserForSameOrg(data);
      // console.log("infodata==========>>>", response);
      if (response) {
        console.log("response of addassetsSharingData ", response);
        setUsers(response.data);
        setExistingUsers(response.assetSent);
      } else {
        console.log("error in response");
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  const openModalshr = () => {
    let featurename = "Share RFP";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast('Error.upload')
    } else {
      getAllUserForSameOrgData();
      setModalOpenshr(true);
    }
  };

  const closeModalshr = () => {
    setModalOpenshr(false);
  };

  const openModalSectionshr = async (sectionId, documenttype, Assettype, createdby, title) => {
    console.log(sectionId, documenttype, Assettype, "openmodaldata");
    let featurename = "Share Section";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );

    if (!checkfeatureName[0].access) {
      showToast('Error.share')
    } else {
      const UserEmail = sessionStorage.getItem("currentUserEmail");
      const data = {
        assetId: proposalWritingData.assetid,
        userEmail: UserEmail,
        assetType: "proposal"
      };

      const response = await validateFunctionalityAccess(data);
      if (response.status === 200) {
        if (title === "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessDenied')
        } else {
          setAssetSetionId(sectionId);
          setSharedAssetSectionScreenType(Assettype);
          setSharedAssetSectionType(documenttype);
          setModalOpenSectionshr(true);
          getAllUserForSameOrgData();
          setIsSectionSharing(true);
        }
      } else if (response.status === 403 && response.isFinanceTeamUser && title === "Cost and Estimation") {
        setAssetSetionId(sectionId);
        setSharedAssetSectionScreenType(Assettype);
        setSharedAssetSectionType(documenttype);
        setModalOpenSectionshr(true);
        getAllUserForSameOrgData();
        setIsSectionSharing(true);
      } else {
        showToast('Error.errorOccured')
      }
    }
  };


  //search notice summary
  const handleSearchTerm = (searchString) => {
    setIsSearchTerm(searchString)
    let color = "red"
    const contentForDescription = cleanedInitialContent;
    const escapedWordForDescription = searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const replacementForDescription = `<span style="background-color: yellow;">${searchString}</span>`;
    const coloredContentForDescription = contentForDescription.replace(
      new RegExp(`(${escapedWordForDescription})(?![^<]*>)`, 'gi'),
      replacementForDescription
    );
    setCleanedContent(searchString ? coloredContentForDescription : cleanedInitialContent);
  };

  const highlightDescription = (content, term) => {
    if (!term) return content;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const regex = new RegExp(`\\b(${term})\\b`, 'gi');
    const walk = (node) => {
      if (node.nodeType === 3) {
        const text = node.nodeValue;
        const spanWrapper = document.createElement('span');
        spanWrapper.innerHTML = text.replace(regex, '<span style="background-color: yellow;">$1</span>');
        node.parentNode.replaceChild(spanWrapper, node);
      } else if (node.nodeType === 1 && node.childNodes && node.childNodes.length) {
        for (let i = 0; i < node.childNodes.length; i++) {
          walk(node.childNodes[i]);
        }
      }
    };
    // Start walking the DOM tree
    walk(tempDiv);
    // Return the modified HTML content
    return tempDiv.innerHTML;
  };


  const highlightSearchTerm = (content, term) => {
    if (!term) return content;

    // Split content into parts based on search term
    const parts = content.split(new RegExp(`(${term})`, 'gi'));

    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
          ) : (
            part
          )
        )}
      </span>
    );
  };
  let screenName = "Proposal Management";

  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const featureArray = features?.featuresAccess;

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(feature => feature.ScreenName == screenName);

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features
      // setAccessTofeature(accessFeatures)
      // console.log("accessFeatures", accessFeatures);
    } else {
      console.log("No feature found with screen name:", screenName);
    }
  } else {
    console.log("No features found in the storage.");
  }

  const closeContentLibrary = () => {
    setContentLibraryOpen(false)
  }

  const handleContentLibrary = async (title, id) => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    if (showBtnInMerge) {
      // If showBtnInMerge is true, give access without validation
      setContentLibraryOpen(true)
    } else {
      // Validate functionality access
      const response = await validateFunctionalityAccess(data);

      if (response.status === 200) {
        if (sectionTitle === "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessDenied')
        } else {
          setContentLibraryOpen(true)
        }
      } else if (response.status === 403 && response.isFinanceTeamUser && sectionTitle === "Cost and Estimation") {
        setContentLibraryOpen(true)
      } else {
        showToast('Error.errorOccured')
      }
    }
    // setAssetSetionId(id)
  }



  const handleAttachmentModal = () => {
    setShowSection(true);
    setAttachmentModal(true)
    setShowRequirnments(false);
    setShowMapping(false)
    setSelectedSectionMap('')
    setselectedClauseResources('')
    setSelectedResources('')
    setResourcePayRates('')
    setTotalBillingAmounts('')
    setEffectiveHours('')
    setRemarks('')

  }

  const closeAttachmentModal = () => {
    setShowSection(false);
    setShowRequirnments(false);
    setShowMapping(false)
    setAttachmentModal(false)
    setResourceLink([])
    setRetrieveRequirementClicked(false)
    setHistoryClicked(false)


    setDummy([])

  }

  const isPDFAttachment = (attachmentName) => {
    return attachmentName.toLowerCase().endsWith('.pdf') || attachmentName.toLowerCase().endsWith('.docx')
  };



  useEffect(() => {
    // console.log("opportunity22",opportunity)
    if (opportunity !== null) {


      // Extract resourceLinksPath and resourceLinks arrays from the API response
      const resourceLinksPath = opportunity?.data?.resourceLinksPath;
      const resourceLinks = opportunity?.data?.resourceLinks;

      // Create a new array to store the combined data
      const combinedResources = [];

      // Loop through resourceLinksPath to create objects with path, linkname, and corresponding URL
      if (resourceLinksPath) {
        resourceLinksPath.forEach((pathObj, index) => {
          const { path, linkname } = pathObj;
          const url = resourceLinks[index]; // Get corresponding URL from resourceLinks array

          // Create an object with path, linkname, and URL
          const combinedObject = {
            path,
            linkname,
            url
          };

          // Push the combined object into the array
          combinedResources.push(combinedObject);
        });
      }


      // Log the resulting array of combined objects
      console.log("76543   ", combinedResources);
      setDocumentData(combinedResources)

    }
  }, [opportunity])


  // Function to handle attachment selection
  const handleSelectAttachment = (attachmentName) => {
    const attachmentUrl = attachmentName; // Assuming attachmentName is the URL

    // Check if the attachmentUrl is already in resourceLinks
    const isSelected = resourceLinks.includes(attachmentUrl);

    if (isSelected) {
      // If already selected, remove it from resourceLinks
      const updatedLinks = resourceLinks.filter((link) => link !== attachmentUrl);
      setResourceLink(updatedLinks);
    } else {
      // If not selected, add it to resourceLinks (only if less than 5 links)
      if (resourceLinks.length < 5) {
        setResourceLink([...resourceLinks, attachmentUrl]);
      } else {
        // Handle case where maximum links are reached (optional)
        showToast('Proposal.errDocLimit')
      }
    }
  };






  const handleShowRequirement = async (documentData) => {



    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };


    // Validate functionality access
    if(!isAdmin){
      const response = await validateFunctionalityAccess(data);

      if (response.status === 200) {
        if (!response.isFinanceTeamUser) {
          toast.error("Access denied for this section.");
          return;
        }
      } else if (response.status === 403 && response.isFinanceTeamUser) {
  
      } else {
        toast.error(response.message);
        return;
      }
  }


    console.log(documentData?.length, "resourceLinks")
    if (documentData?.length == 0) {
      showToast('Proposal.noAttachments')
    } else if (resourceLinks.length === 0) {
    showToast('Proposal.oneDoc')
      return;
    }
    fetchRequirementMappingHistory()
    setSelectedFileName(null)
    setAiRequirnmentsData(null)
    setShowSection(false);
    setShowRequirnments(true);
    setShowMapping(false)
    extractTextFromPdf2()
  }


  const downloadSinglePDFForRequirement = () => {
    // let fileName = fileForSave.slice(0, -5);

    let pdfContent = `<div><h5></h5><p>${editorForRequirnment.current.value}</p></div>`;

    pdfContent = pdfContent.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);
  
  
  pdfContent = pdfContent.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');

    const element = document.createElement('div');
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: `Requirement.pdf`, // Set the filename to the document name
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }).then((pdf) => {
      saveAs(pdf, `Requirement.pdf`);
    });
    //onHide();
    setShowDownloadModal(false)
  };




  const handleDocxDownloadForRequirement = async () => {


    let htmlString = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>Requirement</title>
    </head>
    <body>
        <h3>Requirement</h3>
        <hr/>
        ${editorForRequirnment.current.value}
    </body>
    </html>`;

    htmlString = htmlString.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);
  
  
  htmlString = htmlString.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');

    asBlob(htmlString).then(docx => {
      saveAs(docx, `Requirement.docx`);
      setShowDownloadModal(false) // Close the modal after the download
    });
  };



  // async function extractTextFromPdf() {

  //   const UserEmail = sessionStorage.getItem("currentUserEmail");
  //   const data = {
  //     assetId: proposalWritingData.assetid,
  //     userEmail: UserEmail,
  //     assetType: "proposal"
  //   };


  //   // Validate functionality access
  //   const response = await validateFunctionalityAccess(data);

  //   if (response.status === 200) {
  //     if (!response.isFinanceTeamUser) {
  //       // toast.error("Access denied for this section.");
  //       // return;
  //     }
  //   } else if (response.status === 403 && response.isFinanceTeamUser) {
  //     toast.error("Access denied for this section.");
  //     return;
  //   } else {
  //     toast.error(response.message);
  //     return
  //   }











  //   if (resourceLinks.length === 0) {
  //     toast.warn("Please select atleast one document.");
  //     return;
  //   }


  //   console.log("array  ", resourceLinks)

  //   setLoading(true)
  //   try {
  //     const responce = await extractPdfData(resourceLinks)
  //     if (!responce || responce.length === 0) {
  //       toast.warn("No response received from the PDF extraction.");
  //       setLoading(false);
  //       return;
  //     }

  //     console.log("12345ty   ", responce)
  //     const extractedText = responce.join('').replace(/[\n\r]+/g, ' ');;


  //     console.log("bjkkm   ", extractedText)

  //     setLoading(false)

  //     aiResponce(extractedText)

  //   }
  //   catch (error) {
  //     console.log(error)
  //     toast.warn("error2")
  //     setLoading(false)


  //   }

  // }

  const handleCloseVersionModal = () => {
    setVersionModalOpen(false)
  }


  




  async function extractTextFromPdf2() {


    console.log("arrayresourceLinks  ", resourceLinks)
    setLoading(true)
    try {
      // aiResponce(extractedText)
      aiGenerateRequirnments()

    }
    catch (error) {
      console.log(error)
      setLoading(false)


    }

  }






  const aiGenerateRequirnments = async () => {
    // console.log("Calling AI Generated Requirements");

    setLoading(true);

    try {
      const aiRequirnments = await aiGeneratedRequirnments(
        resourceLinks,
        // extractedClause
      );

      console.log("aiRequirnments13  ", aiRequirnments.results);
      setaiRequirnmentsArray(aiRequirnments.results)
      let formattedText = aiRequirnments.results.map((data) => {
        const processedTextWithLineBreaks = data.processedText.replace(/\n/g, '<br>');
        const htmlContent = processedTextWithLineBreaks.replace(/\*\*REQ \d+:\*\*/g, (match) => `<b>${match}</b>`);
        return `<p style="color: #1A57F2;" >${data.filename}</p><p>${htmlContent}</p>`;
      }).join('');
      formattedText = `<pre>${formattedText}</pre>`;
      setAiRequirnmentsData(formattedText);
      const cleanedData = removeHtmlTag(aiRequirnments.results);
      setRequirementDataForSuggestion(cleanedData);
    } catch (error) {
      console.log("Error in AI Requirements: ", error);
    } finally {
      setLoading(false);
    }
  };

  function removeHtmlTag(html) {
    if (typeof html === 'object' && html !== null) {
      // Convert object to a string if necessary
      html = JSON.stringify(html);
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }






  const addContentInAutoSection = async (formData1) => {
    console.log("FormData11  ", formData1)
    console.log("vbd")

    try {
      const response = await axios.post(
        `https://api.contractpro360.com/api/newbucket/assetsectionuploads/${proposalWritingData.id}`,
        formData1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("upload response", response)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileUploadInMapping = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    console.log("ytr  ", file)
    setSelectedFile(file)
    setSelectedFileName(file.name)



  };

  const downloadTableAsPDF = () => {
    const element = document.getElementById('table-to-pdf');
    html2pdf().from(element).save('RequirementMapping.pdf');
    setShowDownloadMappingModal(false)
  };












  const AddSectionData2 = async (heading, description) => {
    heading = heading.charAt(0).toUpperCase() + heading.slice(1);
    console.log("headinggg1v ".heading)
    try {
      const response = await addAssetsection(proposalWritingData.assetid, description, heading, assetType,"Manual", orgId, userInfo._id)
      if (response.status === 200) {
        console.log(`Section "${heading}" added successfully.`);
        // Handle success if needed
      }
      else {
        console.log(`Failed to add section "${heading}".`);
        // Handle failure if needed
      }
    } catch (error) {
      console.log("Error adding section:", error);
      // Handle error if needed
    }
    getAssetSectionData();

  }


  const handleRequirnmentBack = () => {
    setShowSection(true);
    setShowRequirnments(false);
    setShowMapping(false)

  }


  const handleRequirementsMapping = () => {
    setSelectedSectionMap();
    setselectedClauseResources()
    setSelectedResources({})

    if (!selectedFile) {
      console.log("No file selected.");
      showToast('Warning.upload')
      return;
    }
    setRequirementMappingData(null)
    setShowSection(false);
    setShowRequirnments(false);
    setShowMapping(true)
    getUploadFileContent()


  }




  const getUploadFileContent = async () => {
    if (!selectedFile) {
      console.log("No file selected.");
      return;
    }
    setLoading(true)
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {

      const response = await axios.post('https://api.contractpro360.com/api/newbucket/getPdfCont', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (!response) {
        showToast('Proposal.errDoc')
        setLoading(false)
        return
      }
      console.log('API Response:123', response.data.data.data);
      setSelectedFileContent(response.data.data.data)
      setLoading(false)
      requirementMap(response.data.data.data)

    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false)
    }
  };




  async function requirementMap(selectedFileContent) {


    console.log("SelectFile-text11  ", selectedFileContent)

    setLoading(true)
    let text = editorForRequirnment.current.value;
    console.log("text-text11  ", text)

    text = text.replace(/<pre>/g, "").replace(/<\/pre>/g, "");

    try {

      const mappingResponse = await requirementMapping(aiRequirnmentsArray, selectedFileContent);


      if (!mappingResponse) {
        showToast('Proposal.errDoc')
        setLoading(false)
        return;

      }



      console.log("requirement mappinggggg  ", mappingResponse)

      console.log("requirement mapping222222  ", mappingResponse?.mapping)
      setCheckValidation(false)
      // toast.success("section added sucessfully")
      //  setRequirementMappingData(mappingResponse.mapping)
      setDummy(mappingResponse?.mapping)
      setDummy1(mappingResponse?.mapping)

      // try {

      //   console.log("requirement mapping321  ", mappingResponse)

      //   console.log("requirement mapping  ", mappingResponse?.mapping)
      //   const requirements = mappingResponse?.mapping;
      //   console.log("file122  ", resourceLinks);
      //   // console.log("requirements122  ", requirements.mapping);
      //   const requirementsJson = JSON.stringify(requirements);

      //   const formData = new FormData();
      //   formData.append('resourceLinks', resourceLinks);
      //   formData.append('requirements', requirementsJson);



      //   const response = await axios.post('https://api.contractpro360.com/api/newbucket/mapRequirement', formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   });

      //   console.log("Final response", response)



      // } catch (error) {
      //   console.log(error)
      // }


    } catch (error) {
      console.log("err  ", error)
      showToast('Error.errorOccured')
    }
    setLoading(false)

  }


  const handleOpen = () => {
    setOpen(true);
    fetchData()

  }

  const handleOpenCommentModal = () => {
    setisCommentModalOpen(true)

  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }
  // const handleAddNote = () => {
  //   handleCloseModal();
  // }

  const handleGetComment = async () => {
    try {
      const response = await getAssetComments(proposalWritingData.assetid, assetType, noteType)
      if (response.status == 201) {
        setNotesData(response.data)

      }
    } catch (error) {
      console.log(error)
    }

  };
  const handleColorforDate = () => {
    const today = new Date();
    let tooltipContent;
    console.log("today", today)
    console.log("responseDeadLine", proposalWritingData.date1)
    if (proposalWritingData.date1) {
      const providedDate = new Date(proposalWritingData.date1);
      const timeRemaining = providedDate - today;
      const totalDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const percentageRemaining = (totalDays / 30) * 100;
      console.log("percentageRemaining", percentageRemaining)
      if (totalDays < 0) {
        // Provided date has passed
        setStatusColor('black'); // Updated color to 'black'
        tooltipContent = `Delayed (${Math.abs(totalDays)} days late)`;
      } else if (percentageRemaining <= 33.33) {
        setStatusColor('red');
        tooltipContent = `Delayed (${totalDays} days remaining)`;
      } else if (percentageRemaining <= 66.33) {
        setStatusColor('orange');
        tooltipContent = `Moderately delayed (${totalDays} days remaining)`;
      } else {
        setStatusColor('green');
        tooltipContent = `On time (${totalDays} days remaining)`;
      }
    } else {
      setStatusColor('grey');
      tooltipContent = `Response date not available`;
    }

  };
  const handleGetTypeComment = async () => {
    try {
      const response = await getAssetComments(proposalWritingData.assetid, assetType, commentType)
      if (response.status == 201) {
        console.log("Comment_data", response)
        setCommentData(response.data)

      }
    } catch (error) {
      console.log(error)
    }

  }

  const handleAddNote = async (e) => {
    // e.stopPropagation()
    try {

      const response = await addAssetComments(proposalWritingData.assetid, assetType, noteType, note, userInfo.username)
      console.log("called", response)
      if (response.status == 201) {
        setIsModalOpen(false)
        toast.success(response.message)
        handleGetComment()
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.addNote, userInfo.username)
        handleGetActivity()
        console.log("response of activity transaction", activityResponse)

      }
    } catch (error) {
      console.log(error)
    }
  };

  const countNotes = () => {
    if (notesData) {
      const count = notesData.length
      const commentCount = commentData.length
      const activityiesCount = activityData.length
      setNoteCount(count)
      setCommentCount(commentCount)
      setActivityCount(activityiesCount)
      // console.log("count of note",count,commentCount)
    }
  }

  useEffect(() => {
    countNotes()
  }, [notesData, commentData, activityData])


  const handleClose = () => {
    setOpen(false);
    setPopupForScan(false)

  }

  const handleCommentModalClose = () => {
    setisCommentModalOpen(false)
    setComments('')
  }
  const handleCommentChange = (event) => {
    setComments(event.target.value)
  }

  const handleUpdateCommentApi = async (e) => {
    e.stopPropagation()
    try {
      const response = await UpdateassetComments(selectedCommentId, userInfo.username, commentsText)
      if (response.status == 200) {
        showToast('Success.update')
        handleGetTypeComment()
        setisCommentModalOpen(false)
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.updateComment, userInfo.username)
        // console.log("update comment",activityResponse)
        handleGetActivity()
        setComments('')
      }
    } catch (e) {
      console.log(e, "errro while deleting")
    }
  }

  const handleAddComment = async (e) => {
    // e.stopPropagation()
    try {
      const response = await addAssetComments(proposalWritingData.assetid, assetType, commentType, commentsText, userInfo.username)
      console.log("called", response)
      if (response.status == 201) {
        toast.success(response.message)
        setisCommentModalOpen(false)
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.addComment, userInfo.username)
        handleGetActivity()
        handleGetTypeComment()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleCancelComment = () => {
    setComments('')
  }


  const handleNote = async () => {
    setLoading(true)
    try {


    } catch (error) {
    }
  }

  async function fetchDataForFormScan(fileName1, title, _id, path) {

    console.log("ScanFile    ", fileName1, title, _id, path)




    setLoading(true)
    try {
      // const filename = fileName1;

      console.log(userid, "userid")
      console.log(orgName, fileName1, "orgname")
      let docFormat = fileName1.slice(-4).replace(/\./g, '');
      console.log("ppp template", fileName1, orgName, userid)

      const docxContent = await getFileContent(fileName1, orgName, userid);


      console.log("fetchDataFile", docxContent, fileName1, docFormat)
      if (docFormat == "doc") {
        showToast('Warning.noPreview')
      }
      else if (docFormat == "docx") {

        console.log("DOCX CONTENT ", docxContent.textResponse)
        setContentForScan(docxContent.textResponse)

      }
      else if (docFormat == "html") {

        console.log("DOCX CONTENT ", docxContent.textResponse)
        setContentForScan(docxContent.textResponse)
      }


    } catch (error) {
      console.error('Error fetching and processing file content:', error);
      // showNoPreviewInIframe();
    }

    setLoading(false)
    console.log("SCANNN  ", contentForScan)

    //setPopupForScan(true)
  }
  const handleReduxValue = (rfpData) => {
    setPropPassed(rfpData)
    console.log("propPassed", rfpData.title)
    getAssetSectionData()
    getDescriptionData()
    const convertToDate = (dateTimeString) => {
      if (dateTimeString) {
        const date = new Date(dateTimeString);
        return date.toISOString().split('T')[0];
      }

    };

    handleHtmlContent(rfpData.descriptionData)

    const dateTimeString = rfpData.responseDeadLine;
    // commented as of now as date is comming in date formate
    const convertedDate = convertToDate(rfpData.date1);
    setNormalDate(convertedDate);

    if (rfpData.agancy) {

      const splitDepartment = rfpData.agancy.split('.');

      const last_value = splitDepartment[splitDepartment.length - 1];
      // console.log(last_value, "last_value")
      const first_value = splitDepartment[0]
      setAgancyName(first_value)
      // console.log("First value", first_value)


    }


    handleGetList()
    handleColorforDate()
    handleGetComment()
    handleGetTypeComment()
    handleGetActivity();
    getSectionSharedfunction();
  }


  // const rfmManagementData = useSelector((state) =>state.rfp);
  // console.log(rfmManagementData,"rfmManagementData")
  // useEffect(() => {
  //   // console.log("props passed to this component", rfmManagementData);
  //   handleReduxValue(rfmManagementData.value);
  // }, [rfmManagementData]);

  const rfmManagementData = useSelector((state) => state.rfp);
  useEffect(() => {
    // console.log("props passed to this component", rfmManagementData);
    handleReduxValue(rfmManagementData.value);
  }, [rfmManagementData]);

  const getSectionSharedfunction = async (id) => {
    let data = {
      email: sessionStorage.getItem("currentUserEmail"),
      assetid: id,
    };
    try {
      const response = await getSectionSharedData(data);
      if (response) {
        console.log(response, "response of section shared ", response.data);
        setSharedSectionDetails(response.data);
        setsharedOwnerId(response.ownerUserid);
      } else {
        console.log("not found shared section ");
      }
    } catch (e) {
      console.log(e, "error in getting shared section data ");
    }
  };
  console.log("assetId============>>", rfmManagementData);

  const handleDeleteNotes = async (id) => {
    console.log("delete NOte value", id)
    try {
      const response = await deleteAssetComment(id)
      if (response.status == 200) {
        showToast('Success.delete')
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.deleteNote, userInfo.username)
        handleGetActivity()
        handleGetComment()
      }
    } catch (e) {
      console.log(e, "errro while deleting")
    }
  }

  const handleUpdateNotes = async (id, text) => {

    setUpdatIcon(true)
    setIsModalOpen(true)
    setSelectedNoteId(id)
    setNote(text)
  }

  const handleDeleteComments = async (id) => {
    try {
      const response = await deleteAssetComment(id)
      if (response.status == 200) {
        showToast('Success.delete')
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.deleteComment, userInfo.username)
        handleGetActivity()
        handleGetTypeComment()

      }
    } catch (e) {
      console.log(e, "errro while deleting")
    }
  }

  const handleUpdateComments = async (id, text) => {

    setUpdatCommentIcon(true)
    setisCommentModalOpen(true)
    setSelectedCommentId(id)
    setComments(text)
  }

  const handleCardDataModalOpen = () => {
    setisCardDatModalOpen(true)
  }

  const openCard = (text, viewType, Id, createdby) => {
    console.log("Text from card", text, viewType, Id, createdby)
    setCreatedByCard(createdby)
    handleCardDataModalOpen()
    setViewType(viewType)
    setcardData(text)
    setCardId(Id)
  }

  const handleRevertChanges = async (verId, doc) => {
    // try {

    //     if (Array.isArray(currentInfo.name) && typeof currentInfo.name[0] === 'string') {
    //         const newName = decodeURIComponent(currentInfo.name[0]);
    //         const parts = newName.split('/');
    //         const filteredName = parts.pop();

    //         const oldData = await getSectionDocument(
    //             filteredName,
    //             userInfo.organizationId,
    //             proposalWritingData.id,
    //             proposalWritingData.assetid,
    //             userInfo._id,
    //             currentInfo.sectionId,
    //             verId
    //         );


    //         if (oldData.status !== 200) {
    //           setContent(doc);
    //           setTemporaryContent(doc);
    //             return
    //         }

    //         if (oldData.textResponse) {
    //             const docBody = JSON.parse(oldData.textResponse);
    //             setContent(docBody.data?.documentBody);
    //             setTemporaryContent(docBody.data?.documentBody);
    //         }
    //     }
    // } catch (error) {
    //     console.error("Error reverting changes:", error);
    //     toast.error(`Error in reverting document`);
    // }
    setContent(doc);
    setTemporaryContent(doc);
  };

  const handleMouseEnter = (addedContent, removedContent) => {
    let modifiedContent = htmlContent;
    setTemporaryContent(htmlContent)

    // Helper function to escape special characters in sentences for regex
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    // Function to wrap found sentences with a span tag
    const wrapSentences = (content, sentences, className) => {
      sentences.forEach((sentence) => {
        const escapedSentence = escapeRegExp(sentence);
        const regex = new RegExp(`(${escapedSentence})`, 'g');
        content = content.replace(regex, `<span class="${className}">$1</span>`);
      });
      return content;
    };

    // Wrap sentences in addedContent and removedContent
    modifiedContent = wrapSentences(modifiedContent, addedContent, 'added-content');
    modifiedContent = wrapSentences(modifiedContent, removedContent, 'removed-content');

    console.log('Modified HTML Content:', modifiedContent);
    setContent(modifiedContent)
  };

  const handleMouseLeave = () => {
    // Function to remove span tags with specific class names
    const removeSpans = (content, classNames) => {
      classNames.forEach((className) => {
        const regex = new RegExp(`<span class="${className}">(.*?)<\/span>`, 'g');
        content = content.replace(regex, '$1');
      });
      return content;
    };

    let revertedContent = temporaryContent; // Revert to the original content
    revertedContent = removeSpans(revertedContent, ['added-content', 'removed-content']);

    setContent(revertedContent);
  };



  useEffect(() => {
    setFieldsToRender([]);
    const uniqueFields = [];
    const scannedFields1 = contentForScan?.match(/&lt;&lt;([^&]+?)&gt;&gt;/g) || [];
    const scannedFields2 = contentForScan?.match(/\[([^\]]+)]/g) || [];

    // Loop through scanned fields of type 1
    scannedFields1.forEach(match => {
      const labelText = match.slice(8, match.indexOf("&gt;&gt;"));
      if (!uniqueFields.includes(labelText)) {
        uniqueFields.push(labelText);
      }
    });

    // Loop through scanned fields of type 2
    scannedFields2.forEach(match => {
      const labelText = match.slice(1, -1); // Remove square brackets
      if (!uniqueFields.includes(labelText)) {
        uniqueFields.push(labelText);
      }
    });

    setFieldsToRender(uniqueFields);
  }, [contentForScan]);




  const handleDataTypeChange = (event, label) => {
    const { value } = event.target;
    setDataTypeMap(prevState => ({
      ...prevState,
      [label]: value // Associate the selected value with the label
    }));
  };


  const handleCardDataModalClose = () => {
    setisCardDatModalOpen(false)
  }




  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleShowLess = () => {
    setDisplayCount(5);
  };






  const logoUpload = (e, label) => {
    console.log("label to render ______", label);
    const file = e.target.files[0];
    const feildName = label.toLowerCase().replace(/\s/g, "-"); // Ensure feildName is a string
    console.log("feildName", feildName);
    if (!file) {
     showToast('Warning.noFile')
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const imageUrl = event.target.result;
      const imageHtml = `<img src="${imageUrl}" alt="${label}"  >`;
      setFormData(prevState => ({
        ...prevState,
        [feildName]: imageHtml
      }));
    };
    reader.readAsDataURL(file);
  };


  const handleGetActivity = async () => {
    try {
      const response = await getActivity(proposalWritingData.assetid)
      if (response) {
        setActivityData(response.data)
        console.log("All Activity", response.data)
      }


    } catch {

    }
  }


  const handleDatePicker = (e, label) => {
    // console.log("Date1",date.toLocaleDateString())
    const Newlabel = [label.toLowerCase().replace(/\s/g, "-")]
    const formattedDate = e.target.value
    console.log("Date2", formattedDate, label)
    setFormData(prevFormData => ({
      ...prevFormData,
      [Newlabel]: formattedDate
    }));
    // setFormData({ ...formData, [label]: formattedDate })

  };

  const handleShowMore = () => {
    setDisplayCount(displayCount + 5);
  };


  const handlePlaceholderFields = (fileName, title, _id, path, GalleryFile, category, nameOfType) => {
    handlePlaceholderClick()
    console.log("recived ID from checkbox", fileName, title, _id, path)
    setScanFileName(fileName)
    setPath(path)
    if (nameOfType === "template") {
      fetchDataForFormScan(fileName, title, _id, path)
    }
    if (nameOfType === "gallery") {
      GalleryDataForFormScan(GalleryFile, category)
    }
  };


  const GalleryDataForFormScan = async (filename, category) => {
    console.log(filename, " this ", category)
    // setEditorStateWithPlaceholder(null)
    setLoading(true)
    try {
      const response = await GetPreviewContent(category, filename);

      console.log("$$$$$$   ", response)
      setContentForScan(response.textResponse)



    } catch (error) {
      console.error('Error fetching preview content:', error.message);
    }

    setLoading(false)

  }





  const handleSubmitClick = () => {
    handleCloseListTemplatListWithPlaceholderModal()

    const updatedContent = contentForScan?.replace(
      /&lt;&lt;([^&]+?)&gt;&gt;|\[([^\]]+)]/g,
      (match, angleBracketLabel, squareBracketLabel) => {
        let labelText = angleBracketLabel || squareBracketLabel;

        return formData[labelText.toLowerCase().replace(/\s/g, "-")] || '';

      }
    );

    console.log("JODIT sCAN  ", updatedContent);
    setContent(updatedContent);
    setHtmlContent(updatedContent)


    setIsgetDataEditor(true)
    handleClose()
    // setShowForm(false);
    // setProceedClicked(true);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation()
    //  setUpdatIcon(false)
    //  setNote('')
    setIsModalOpen(true)
  }







  //console.log(props, "props in proposal   ")
  // const rfmManagementData = useSelector(state => state.rfp)
  console.log(rfmManagementData, "res1111")
  const proposalWritingData = useSelector(state => state.rfp.ProposalData)
  console.log(proposalWritingData, "proposalWritingData")
  useEffect(() => {
    console.log("proposalWritingData", proposalWritingData)
    handleProposalData(proposalWritingData)
  }, [proposalWritingData])


  useEffect(() => {
    console.log("propWritingData___state value ", propWritingData)
  }, [propWritingData])

  const handleProposalData = (value) => {

    // console.log("props passed to this component", props,value)
    setPropWritingData(value)
    getAssetSectionData()
    getDescriptionData()
    const convertToDate = (dateTimeString) => {
      const date = new Date(dateTimeString);
      return date.toISOString().split('T')[0];
    };

    handleHtmlContent(value.descriptionData)

    const dateTimeString = value.date1;
    if (dateTimeString) {
      const convertedDate = convertToDate(dateTimeString);
      setNormalDate(convertedDate);
    }


    if (value.agancy) {

      const splitDepartment = value.agancy.split('.');

      const last_value = splitDepartment[splitDepartment.length - 1];
      console.log(last_value, "last_value")
      const first_value = splitDepartment[0]
      setAgancyName(first_value)
      console.log("First value", first_value)


    }
    handleGetList()
  }


  const getDescriptionData = async () => {
    console.log("oppopopopopo", proposalWritingData.id)
    try {
      const response = await getopportunities(proposalWritingData.id)
      if (response) {
        console.log("Descrption response is ", response)
        setOpportunity(response)

      }

    } catch (e) {
      console.log("error in getting description ", e)
    }
  }
  const handleHtmlContent = (htmlContent) => {
    const cleanedText = removeHtmlTags(htmlContent);
    setCleanedContent(cleanedText);
    setInitialCleanedContent(cleanedText)
  };
  const handleClosePopup = () => {
    setPopupOpen(false);
    setFileURL()
  };


  const handleGetList = async () => {
    try {
      const response = await getList();
      if (response) {
        const filteredData = response.filter(item => {
          const parts = item.split('/');
          return parts.length > 1 && parts[1] === proposalWritingData.id;
        });

        const names = filteredData.map(item => {
          const parts = item.split('/');
          return parts.length > 2 ? parts[2] : null;
        });

        console.log("Filtered data:", filteredData);
        console.log("Names:", names);
      }
    } catch (e) {
      console.log("Error in getting list data", e);
    }
  }
  //   const fetchuserdata = async () => {
  //     try {
  //       let data = email;
  //       const response = await organizationService.getuserdata(data);
  //       setuserdata(response?.data?.organizationId);
  //     } catch (error) {
  //       console.error("Error fetching organization data:", error.message);
  //       setLoading(false);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchuserdata()
  //   },[]
  // )

  const getAssetSectionData = async () => {




    try {
      const response = await getAssetsection(proposalWritingData.id, assetType, proposalWritingData.assetid)
      if (response.data) {
        console.log("response of asssetsection ", response)

        // console.log("assetId11 ", response.data[0].assetId)
        if (response?.editorImage) {
          setEditorImage(response?.editorImage)
          setThemeExist(true)
        }
        setAssetSectionData(response?.data)
        setAssetIdMerge(response?.data[0]?.assetId)

        const manualSections = [];
        const aiGeneratedSections = [];
        response.data.forEach(section => {
          if (section.sectionType === "Manual") {
            manualSections.push(section);
          } else if (section.sectionType === "Ai-generated") {
            aiGeneratedSections.push(section);
          }
          setManualSections(manualSections);
          setAiGeneratedSections(aiGeneratedSections)

          if (!subsectionsMap[section._id]) {
            fetchSubsections(section._id);
          }
        });

        return response.data
      }
      else {
        console.log("error in response")
      }
    } catch (e) {
      console.log(e, "errrororrooror")
    }
  }

  useEffect(() => {
    const realTimeCollab = () => {
      getAssetSectionData();
      // console.log(currentInfo,"xxx inside timeout")
      // fetchDataInEditor(currentInfo.type, currentInfo.htmlContent, currentInfo.title, currentInfo.name, currentInfo.sectionId)
    }
    realTimeCollab()
    const intervalId = setInterval(realTimeCollab, 30000);
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    const getTaskassignmentdetails = () => {
      fetchAssignedTaskdetails();
      // console.log(currentInfo,"xxx inside timeout")
      // fetchDataInEditor(currentInfo.type, currentInfo.htmlContent, currentInfo.title, currentInfo.name, currentInfo.sectionId)
    }
    getTaskassignmentdetails()
    const intervalId = setInterval(getTaskassignmentdetails, 30000);
    return () => clearInterval(intervalId);
  }, []);


  const handleButtonClick = (e, createdby) => {

    // Your custom function here
    // For example:
    console.log('Button clicked!');
    // Prevent the AccordionDetails from expanding
    e.stopPropagation();
    if (createdby !== userInfo?._id && userInfo?.userRole[0] === "Application User") {
      showToast('Proposal.taskAssign')
    }


  };
  const predefinedSections = [
    "executive summary",
    "needs assessment",
    "goals and objectives",
    "expected result",
    "desired features",
    "maintenance and support",
    "project deliverables",
    "training plan",
    "technical approach",
    "project assumptions"
  ];


  useEffect(() => {
    const Username = sessionStorage.getItem("currentUserEmail")
    getCuurentUser(Username)
    setCuurentUser(Username)
    fetchData()
    getGalleryTemplateList()
    getAssetData()
  }, [])

  const getCuurentUser = async (Username) => {
    try {
      const response = await getUserInfo(Username)
      console.log("userinfo", response)
      setUserInfo(response.data)
      sUserRole = response?.data?.userRole[0]
      sUserId = response?.data._id

    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (userInfo) {
      setSUserRole(userInfo.userRole[0]);
      setSUserId(userInfo._id);
    }
  }, [userInfo]);

  let userid = userInfo?._id;
  let orgName = userInfo?.organizationName;

  // console.log(sUserRole, "__sUserRole")
  // console.log(sUserId, "_sUserId")

  const handleAddopenModal = async () => {
    let featurename = "Add Section";
    const checkfeatureName = accessFeatures?.filter(k => k.name === featurename);
    console.log(checkfeatureName[0].access, "checkfeatureName");

    if (!checkfeatureName[0].access) {
      showToast('Error.upload')
    } else {
      const yourUserEmail = sessionStorage.getItem("currentUserEmail")
      const data = {
        assetId: proposalWritingData.assetid,
        userEmail: yourUserEmail,
        assetType: "proposal"
      };

      const response = await validateFunctionalityAccess(data);

      if (response.status === 200) {
        setTitle('');
        setaddDescription('');
        setOpenAddModal(true);
      } else {
        showToast('Error.errorOccured')
      }
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleUpdateNoteApi = async (e) => {
    // e.stopPropagation()
    try {
      const response = await UpdateassetComments(selectedNoteId, userInfo.username, note)
      if (response.status == 200) {
        showToast('Success.update')
        handleGetComment()
        setIsModalOpen(false)
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.updateNote, userInfo.username)
        handleGetActivity()
        setNote('')
      }
    } catch (e) {
      console.log(e, "errro while deleting")
    }
  }

  const AddSectionData = async () => {
    try {
      if (!adddescription || !title) {
        return showToast('Proposal.titleReq')
      }
      const response = await addAssetsection(proposalWritingData.assetid, adddescription, title, assetType,"Manual", userInfo.organizationId, userInfo._id, userInfo.username)
      if (response.status == 200) {
        console.log("response",response)
        showToast('Success.add')
        handleApplyTemplateTheme(response.data)
  
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.addSection, userInfo.username)
        handleGetActivity()
        getAssetSectionData()
        setOpenAddModal(false)
      } else if (response.status == 400) {
        showToast('Error.errorOccured')
      }
    } catch (error) {
      console.log(error)
    }
  }
  const AddSubSectionData = async () => {
    // alert("check")
    try {
      if (!addSubDescription || !subTitle) {
        return showToast('Proposal.titleReq')
      }
      const sectionId = assetsectionId;
      if (subTitle && addSubDescription) {
        const response = await addAssetsubsection(proposalWritingData.assetid, addSubDescription, subTitle, assetType, userInfo.organizationId, sectionId);
        if (response?.status == 200) {
          toast.success(response?.message);
          // getAssetSectionData();
          setOpenAddSubSectionModal(false)
          fetchSubsections(sectionId);

        }
        else if (response?.status == 400) {
          showToast('Error.errorOccured')
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  const handleAppNameChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setaddDescription(event.target.value);
  };
  const handleSubAppNameChange = (event) => {
    setSubTitle(event.target.value);
  };

  const handleSubDescriptionChange = (event) => {
    setAddSubDescription(event.target.value);
  };



const handlegetFileName = async (name, type, htmlContent, sectionId, title, assetType, taskassignwith, keydata, assetid, createdby,document) => {
  setAssetSetionId(sectionId);
  setnewSectionTitle(title)
  setSectionCreatedBy(createdby)

  const UserEmail = sessionStorage.getItem("currentUserEmail");
  const data = {
    assetId: proposalWritingData.assetid,
    userEmail: UserEmail,
    assetType: "proposal"
  };
  console.log(name, type, htmlContent, sectionId, title, assetType, taskassignwith, keydata, assetid, createdby,"___argss")
  setContent("")
  console.log("content of editor ",content)
  if (showBtnInMerge) {
    // If showBtnInMerge is true, give access without validation
    setIsEditorReadOnly(false)

    } else {
      // Validate functionality access
      const response = await validateFunctionalityAccess(data);


      if (response.status === 200) {
        if (title == "Cost and Estimation" && !response.isFinanceTeamUser) {
          setIsEditorReadOnly(true)
        } else if (title !== "Cost and Estimation" && !response.isFinanceTeamUser) {
          setIsEditorReadOnly(false)


        }

      }
      else if (response.status === 403 && response.isFinanceTeamUser) {
        if (title !== "Cost and Estimation" && response.isFinanceTeamUser) {
          setIsEditorReadOnly(true)

        }
        else if (title == "Cost and Estimation" && response.isFinanceTeamUser) {
          setIsEditorReadOnly(false)


        }
      }

      else {
        setIsEditorReadOnly(true)



      }
    }

    if (title == "Attachment") {
      setInAttachmentSection(true)
    }




    console.log(taskassignwith, "taskassignwith")
    console.log("type of selected accordian ", name, type, htmlContent, sectionId, title, assetType, taskassignwith, keydata, assetid, createdby);
    getSectionSharedfunction(assetid);
    let featurename = "Preview";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
  
    if (!checkfeatureName[0].access) {
      showToast('Warning.noPreview')
    } else {
      // console.log("before change name ", name);
      // console.log("type of selected accordian ", type);
      // console.log("sectionId", sectionId);

      const currentUser = cuurentUser;
      const foundMatchingObject = taskassignwith?.find(
        (obj) => obj.assignToEmail === currentUser && obj.isSubmitted && obj.statusofSection === "Task performed"
      );

    setIsReadOnly(!!foundMatchingObject);
    
    const firstReadonlyCondition = !!foundMatchingObject
    console.log("matchingObject",foundMatchingObject,currentUser,taskassignwith)
    console.log("isreadonly State",isreadonly,!!foundMatchingObject)

    submitButtonValidation(taskassignwith)

      setAssetSubsectionId('');
      
      const assignByUser = assignbyUser;
      let matchingObject;
      if (taskassignwith?.length > 0) {
        matchingObject = taskassignwith?.find(
          (obj) => obj.assignToEmail === currentUser
        );
      }
      const assignedEmail = matchingObject?.assignToEmail;
      const isTaskAccepted = matchingObject?.isAccepted;
      const isTaskSubmitted = matchingObject?.isSubmitted;
      // console.log(matchingObject, "matchingobj");

      // If matchingObject is found, get the taskassignid, otherwise assign null

      const taskassignids = matchingObject
        ? matchingObject?.taskassignid
        : null;

      // Print the taskassignid

      // console.log(taskassignids, "taskassignidtaskassignid");



      const data = {
        name,
        type,
        htmlContent,
        sectionId,
        title,
        assetType,
        taskassignids,
        keydata,
      };
      setParticularSection(data);
      if (type == "Doc" || type == "doc") {
        if (Array.isArray(name) && typeof name[0] === "string") {
          // Check if name is an array and the first element is a string
          const newName = decodeURIComponent(name[0]); // Decode the filename
          const parts = newName.split("/");
          const FilteredName = parts.pop(); // Extract the last element after splitting by '/'
          setFileName(FilteredName);
          fetchDataFile(FilteredName, sectionId);
          // console.log("FilteredName__:", FilteredName);
          return FilteredName;
        } else {
          console.log("Input is not an array containing strings.");
        }
      } else {
        setCurrentInfo({ type, htmlContent, title, name, sectionId });
        fetchDataInEditor(type, htmlContent, title, name, sectionId, keydata, createdby, assignedEmail, firstReadonlyCondition, isTaskAccepted, isTaskSubmitted);
        fetchTrackedChanges(
          proposalWritingData.assetid,
          sectionId,
          assetType
        );
      }
    }

  }


const handleApplyTemplateTheme =async (data)=>{
  console.log("handleApplyTemplateTheme",data)
  if(templateStyle !=="" && templateStyle){
    const parser = new DOMParser();
    const doc = parser.parseFromString(templateStyle, 'text/html');
    const pf1Div = doc.querySelector('div.pf');
            
    if (pf1Div) {
        // Locate the 'pc' div and the 'img' tag inside it (logo or image)
        const pcDiv = pf1Div.querySelector('div.pc');
        const styles = Array.from(doc.querySelectorAll('style')).map(style => style.outerHTML).join('');
        
        const FIRST_PAGE_PADDING = { horizontal: 50, vertical: 80 };
        if (pcDiv) {
            const imgTag = pcDiv.querySelector('img');
            if (imgTag) {
                // Replace heading after the image
                let sibling = imgTag.nextElementSibling;
                let firstTextFound = false; // Track if the first non-empty text sibling is found

                // Step 7: Traverse the siblings of the image tag and replace their content with the section heading
                while (sibling && !firstTextFound) {
                    if (sibling.textContent.trim()) {
                        // Clear the content of all child nodes in this sibling
                        sibling.querySelectorAll('*').forEach(node => {
                            if (node.textContent.trim()) {
                                node.textContent = ''; // Clear existing text
                            }
                        });

                        // Insert the section heading into the first cleared child node
                        sibling.querySelectorAll('*').forEach(node => {
                            if (node.textContent === '') {
                                node.textContent = `${title}`; // Insert custom heading
                                firstTextFound = true;
                            }
                        });
                    }
                    // Move to the next sibling if no text content is found
                    sibling = sibling.nextElementSibling;
                }

                // If the heading was successfully replaced, proceed to create the HTML structure for the pages
                if (firstTextFound) {
                     // Append current page content to the updated content

                    // Step 8: Wrap the entire updated content in HTML structure with head, styles, and body
                    let finalDocumentContent = `
                        <!DOCTYPE html>
                        <html xmlns="http://www.w3.org/1999/xhtml">
                        <head>
                            <meta charset="utf-8"/>
                            <meta name="generator" content="pdf2htmlEX"/>
                            <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
                            <style type="text/css">
                                ${styles}
                            </style>
                            <style>
                                .editable-area {
                                    position: absolute;
                                    background: rgba(255, 255, 255, 0);
                                    z-index: 1;
                                    white-space: pre-wrap;
                                    overflow: hidden;
                                    box-sizing: border-box;
                                }
                            </style>
                        </head>
                        <body>
                            <div id="page-container">
                            <div id="pf1" class="pf w0 h0" data-page-no="1">
                                ${pf1Div.innerHTML}
                                <div class="editable-area" style="top: ${FIRST_PAGE_PADDING.vertical}px; left: ${FIRST_PAGE_PADDING.horizontal}px; right: ${FIRST_PAGE_PADDING.horizontal}px; bottom: 80px">
                                    <p></p>
                                </div>
                            </div>
                            </div>
                        </body>
                        </html>
                    `;

                    // Update the section's content with the newly generated HTML
                    

                    console.log("Updated section content with pagination:", finalDocumentContent);

                    const blob = new Blob([finalDocumentContent], { type: 'text/html' });
                    console.log("assetsectionId", assetsectionId)
                    // alert(assetSubsectionId)
                    // Create FormData object
                    const newTitle = data?.title.replace("/","")
                    const formData = new FormData();
                    formData.append('filename', blob, newTitle); // Append content as file with generated filename
                    formData.append('orgName', userInfo.organizationName);
                    formData.append('assetid', proposalWritingData.assetid);
                    formData.append('subsectionId', "");
                    formData.append('assetsectionId', data._id);
                    formData.append('htmlContent', "");
                    formData.append('userId', userInfo._id);
                    formData.append('orgId', userInfo.organizationId);
                    formData.append('assetType', "proposal");
                
                
                    
                    const response = await axios.post(`https://api.contractpro360.com/api/newbucket/assetsectionuploads/${proposalWritingData.id}`, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    });
                
                    if (response.status === 200) {
                
                
                
                    }
                }
            } else {
                console.error("No 'img' tag found inside the 'pc' div.");
            }
        } else {
            console.error("No 'pc' class found inside the 'pf1' div.");
        }
    } else {
        console.error("No 'pf1' class found in the document.");
    }

  }



}


  const handlesubsectionFileName = (name, type, htmlContent, sectionId, title, subSectionsData, taskAssignWith, createdby) => {
    // alert(keydata)
    console.log(name, type, htmlContent, sectionId, title, subSectionsData, taskAssignWith, createdby, "subSectionsDatasubSectionsData")

    setnewSubSectionTitle(title)

    const matchedSubsection = subSectionsData.find(subsection => subsection.subsectionIds === sectionId);

    let matchingObject;
    const currentUser = cuurentUser;
    if (taskAssignWith?.length > 0) {
      matchingObject = taskAssignWith?.find(
        (obj) => obj.assignToEmail === currentUser
      );
    }
    const assignedEmail = matchingObject?.assignToEmail;
    const isTaskAccepted = matchingObject?.isAccepted;
    const isTaskSubmitted = matchingObject?.isSubmitted;    // Check if the matchedSubsection exists and if it has a taskAcceptedEmail array
    let keydata;
    let userSubmitted = "chetana3@gmail.com";
    if (matchedSubsection && matchedSubsection.taskAcceptedEmail.length > 0) {
      // Check if the taskAcceptedEmail array has an object with the email matching currentUser
      userSubmitted = matchedSubsection.taskAcceptedEmail.find(emailObj => emailObj.email === cuurentUser);

      // If userSubmitted is found, do something
      console.log("User submitted:", userSubmitted);
      if (userSubmitted) {
        // userSubmitted object exists, you can perform your further actions here
        keydata = "receiver"
      } else {
        console.log("User has not submitted.");
        keydata = "owner"
      }
    } else {
      console.log("No matching subsection or   array is empty.");
    }
    console.log("before change name ", name, type, htmlContent, sectionId, title);
    console.log("type of selected accordian ", type);
    setAssetSubsectionId(sectionId)

    if (type == "Doc" || type == "doc") {
      if (Array.isArray(name) && typeof name[0] === 'string') { // Check if name is an array and the first element is a string
        const newName = decodeURIComponent(name[0]); // Decode the filename
        const parts = newName.split('/');
        const FilteredName = parts.pop(); // Extract the last element after splitting by '/'
        setFileName(FilteredName);
        fetchDataFile(FilteredName);
        console.log("FilteredName__:", FilteredName);
        return FilteredName
      } else {
        console.log("Input is not an array containing strings.");
      }
    } else {
      setCurrentInfo({ type, htmlContent, title, name, sectionId })
      fetchDataInEditor(type, htmlContent, title, name, sectionId, keydata, createdby, assignedEmail, isTaskAccepted, isTaskSubmitted)
      fetchTrackedChanges(proposalWritingData.assetid, sectionId, assetType)

    }

  }



  console.log(newSubSectionTitle,"newSubSectionTitlenewSubSectionTitle")
  useEffect(() => {
    const fetchProposalWritingUpdates = () => {
      fetchTrackedChanges(proposalWritingData.assetid, currentInfo.sectionId, assetType)
    };
    fetchProposalWritingUpdates();

    const intervalId = setInterval(fetchProposalWritingUpdates, 30000);
    return () => clearInterval(intervalId);
  }, [currentInfo]);




  const fetchTrackedChanges = async (assetId, sectionId, assetType) => {
    try {

      const trackedData = await getTrackedChanges(assetId, sectionId, assetType);
      setTrackedChanges(trackedData.data);
    } catch (error) {
      console.error("Error fetching tracked changes:", error);
    }
  };


  async function fetchDataInEditor(type, htmlContent, title, name, sectionId, keydata, createdby, assignedEmail, firstReadonlyCondition, isTaskAccepted, isTaskSubmitted) {
    console.log(type, htmlContent, title, name, sectionId, keydata, createdby, assignedEmail, "Title of the asset section ")
    try {
      
      setSectionTitle('')
      setContent('')
      setSaveClicked(false)
    
      console.log("name of the file on which i am working ", )
      // if (Array.isArray(name) && typeof name[0] === 'string') { // Check if name is an array and the first element is a string
      //   const newName = decodeURIComponent(name[0]); // Decode the filename
      const namesArray = Array.isArray(name) ? name : [name];
      if (namesArray.length > 0 && typeof namesArray[0] === "string") {
        console.log("__00");
        // Decode the filename
        const newName = decodeURIComponent(namesArray[0]);
        const parts = newName.split('/');
        const FilteredName = parts.pop();
        console.log("File name for editor__:", FilteredName);
        let userid = userInfo._id;
        if (rfmManagementData?.ProjectType?.data === "Shared Project") {
          userid = sharedOwnerId;
        }
        const newTitle = title.replace("/",'')
        console.log(`${newTitle}.docx`, userInfo.organizationId, proposalWritingData.id, proposalWritingData.assetid, userInfo._id, sectionId, keydata, "proppassed")
        const docxContent = await getSectionDocument(`${newTitle}.docx`, orgId, proposalWritingData.id, proposalWritingData.assetid, userInfo._id, sectionId, keydata);
        console.log("yes", docxContent, `${title}.docx`)
        // alert(cuurentUser)
        // alert(assignedEmail)
        if (firstReadonlyCondition === false) {
          console.log("RESET IS DONE ")
          if (createdby == sUserId) {
            setIsReadOnly(false)
          } else if (assignedEmail == cuurentUser && isTaskAccepted && !isTaskSubmitted) {
            setIsReadOnly(false)
            console.log("RESET IS DONE@@",isreadonly,"isEditorReadOnly:",isEditorReadOnly)
          }
          else if (isAdmin && title !== "Cost and Estimation") {
            setIsReadOnly(false)

          }
          else {
            setIsReadOnly(true)
          }
        }

        if (docxContent.textResponse) {
          seteditSectionTitle(false)
          setIsgetDataEditor(true)
          console.log("yes1", docxContent.textResponse)
          const parsedResponse = JSON.parse(docxContent.textResponse);
          // let newContent = `<pre style="white-space: pre-wrap; word-wrap: break-word; overflow: auto;">${parsedResponse.data.documentBody}</pre>`;
          // let newContent = parsedResponse.data.documentBody.replace(/\n/g, '<br>');
          setContent(parsedResponse.data.documentBody);
          console.log("yes2", parsedResponse.data.documentBody)
          setPriorHtmlContent(parsedResponse.data.documentBody);
          setSectionTitle(title)
        } else {
          setIsgetDataEditor(true)
          seteditSectionTitle(false)
          setSectionTitle(title)
          setContent("")
        }
      }
    }
    catch (error) {
      console.error('Error fetching and Section file content:', error);
    }finally{
      getHtmlcontent()
    }
  }

  const [isSubmitButtonShow,setSubmitButtonShow]=useState(false)
  const submitButtonValidation = (taskAssignWith) => {
    // console.log("GOTIT",taskAssignWith)
    const isAssignedToCurrentUser = taskAssignWith?.some(
      (task) =>
        task?.assignToEmail === cuurentUser &&
        task?.isAccepted === true &&
        task.isSubmitted === false &&
        task.statusofSection !== "Task Rejected" && showBtnInMerge == false
    );
  
    setSubmitButtonShow(isAssignedToCurrentUser);
  
    // console.log(isAssignedToCurrentUser, "isAssignedToCurrentUser",showBtnInMerge);
  };

  async function fetchDataFile(name) {
    setLoading(true)
    try {
      const filename = name
      let docFormat = name.slice(-4).replace(/\./g, '');
      console.log("final file name", filename)
      const docxContent = await getRFPDocument(filename, userInfo.organizationName, proposalWritingData.id, proposalWritingData.assetid);
      // const docxContent = await fetch(`http://localhost:5050/api/buckets3/getByName2/${filename}`);
      console.log("fetchDataFile", docxContent, filename, docFormat)
      if (docFormat == "doc") {
        showToast('Warning.noPreview')
      }
      else if (docFormat == "docx") {

        let arraydata = [docxContent.textResponse]
        setPreviewContent(arraydata)
        setIsPreviewOpen(true)
        console.log("ressssss", arraydata)

      }
      else if (docFormat == "pdf") {
        setFileURL(docxContent.blobURL)
        setPopupOpen(true)
      }
    } catch (error) {
      console.error('Error fetching and processing file content:', error);
      // showNoPreviewInIframe();
    }
    finally {
      setLoading(false)
    }
    // setLoading(false)
  }



  const handleSectionClick = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };



  const toggleLeftPane = () => {
    setLeftPaneVisible(!isLeftPaneVisible);
  };

  const closePreview = () => {
    setIsPreviewOpen(false)
  }

  // download fucntion
  // const handPassNameforDownload = (e, name) => {

  //   if (Array.isArray(name) && typeof name[0] === 'string') { // Check if name is an array and the first element is a string
  //     const newName = decodeURIComponent(name[0]); // Decode the filename
  //     const parts = newName.split('/');
  //     const FilteredName = parts.pop();
  //     handleDownload(FilteredName);
  //     console.log("FilteredNameDownload__:", FilteredName);
  //   } else {
  //     console.log("Input is not an array containing strings.");
  //   }

  //   e.stopPropagation();
  // }

  // const handleDownload = async (filename) => {
  //   try {
  //     const docxContent = await getRFPDocument(filename, userInfo.organizationName, proposalWritingData.id, proposalWritingData.assetid);
  //     // const blob = await docxContent.blob();
  //     const blob = new Blob([docxContent], { type: 'text/plain' });
  //     console.log(blob, "blob");
  //     const anchor = document.createElement('a');
  //     anchor.href = URL.createObjectURL(blob);
  //     anchor.download = 'text_content.doc';
  //     anchor.click();
  //     URL.revokeObjectURL(anchor.href);
  //   } catch (error) {
  //     console.error('Error downloading file:', error);
  //   }
  // };


  const handleDeleteFile = async (e, filename, assetsectionId, type, createdby, title) => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const validationData = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    try {
      const validationResponse = await validateFunctionalityAccess(validationData);

      if (validationResponse.status !== 200) {
        toast.error(validationResponse.message);
        return;
      }

      if (title === "Cost and Estimation" && !validationResponse.isFinanceTeamUser) {
        showToast('Proposal.accessDenied')
        return;
      }

      e.stopPropagation();
      setIsPreviewOpen(false);
      setLoading(true);

      let validationName;
      let featurename = "Delete Section";
        const checkfeatureName = accessFeatures?.filter(
          (k) => k.name == featurename
        );
      if (!checkfeatureName[0].access) {
        showToast(`Proposal.deleteSectionAuth`);
      } else {
        if (!isAdmin && createdby !== sUserId) {
          
          showToast(`Proposal.deleteSectionAuth`)
        }
        else{
      if (Array.isArray(filename) && filename.length === 1 && filename[0] !== "") {
        validationName = filename;
      } else {
        validationName = "delete";
      }

      console.log("validationName", validationName);
      if (createdby !== sUserId) {
        showToast('Error.delete')
      } else {

        if (validationName !== "delete") {
          const name = await handleDeleteFileName(validationName, "doc");
          console.log("name of the delete function", name, assetsectionId, filename);
          const response = await deleteFile(
            userInfo.organizationName,
            proposalWritingData.id,
            proposalWritingData.assetid,
            assetsectionId,
            name,
            userInfo._id
          );
          getAssetSectionData();
          console.log(response, "response of delete");
          setIsPreviewOpen(false);

          if (response.status == 200) {
            showToast('Success.dataDeleted')
          } else {
            console.log("error in delete");
          }
        } else {
          const response = await deleteFile(
            userInfo.organizationName,
            proposalWritingData.id,
            proposalWritingData.assetid,
            assetsectionId,
            validationName,
            userInfo._id
          );
          getAssetSectionData();
          console.log(response, "response of delete");
          setIsPreviewOpen(false);

          if (response.status == 200) {
            if (type == "doc") {
              const activityResponse = await addActivity(
                proposalWritingData.assetid,
                assetType,
                activityMsg.deleteFile,
                userInfo.username
              );
              handleGetActivity();
            } else {
              const activityResponse = await addActivity(
                proposalWritingData.assetid,
                assetType,
                activityMsg.deleteSection,
                userInfo.username
              );
              handleGetActivity();
            }
            showToast('Success.dataDeleted')
          } else {
            console.log("error in delete");
          }
        }
      }
    }}
    } catch (e) {
      console.log("error in Delete", e);
    } finally {
      setLoading(false);
    }
  };




  const handleSubsectionDeleteFile = async (e, filename, assetSubsectionId, assetsectionId, type,createdby) => {
    e.stopPropagation();
    setIsPreviewOpen(false);
    try {
      setLoading(true);
      let featurename = "Delete SubSection";
        const checkfeatureName = accessFeatures?.filter(
          (k) => k.name == featurename
        );
      let validationName;
      if (!checkfeatureName[0].access) {
       return showToast(`Proposal.deleteSubSectionAuth`);
      } else {
        if (createdby !== sUserId) {
         return showToast(`Proposal.deleteSubSectionAuth`)
        } 
      }
      if (Array.isArray(filename) && filename.length === 1 && filename[0] !== "") {
        validationName = filename;
      } else {
        validationName = "delete";
      }

      if (validationName !== "delete") {
        const name = await handleDeleteFileName(validationName, "doc");
        const response = await deleteSubsectionFile(userInfo.organizationName, proposalWritingData.id, proposalWritingData.assetid, assetSubsectionId, name, userInfo._id);
        // if (response.status === 200) {
        // Remove the deleted subsection from the UI

        showToast('Proposal.subsectionDelete')
        // }
        // else {
        //     toast.error("Error deleting subsection");
        // }
      } else {
        const response = await deleteSubsectionFile(userInfo.organizationName, proposalWritingData.id, proposalWritingData.assetid, assetSubsectionId, validationName, userInfo._id);
        // if (response.status === 200) {
        if (type === "doc") {
          const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.deleteFile, userInfo.username);
          handleGetActivity();
        } else {
          const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.deleteSection, userInfo.username);
          handleGetActivity();
        }
        showToast('Proposal.subsectionDelete')
        setSubsectionsMap((prevMap) => {
          const updatedSections = prevMap[assetsectionId].filter(subsection => subsection._id !== assetSubsectionId);
          return {
            ...prevMap,
            [assetsectionId]: updatedSections
          };
        });
        fetchSubsections(assetsectionId)
        // }
        // else {
        //     toast.error("Error deleting subsection");
        // }
      }
    } catch (e) {
      console.log("Error in Delete", e);
      showToast('Proposal.errSubsectionDelete')
    } finally {
      // fetchSubsections(assetsectionId)
      setLoading(false);
    }
  }

  const handleDeleteFileName = (name, type) => {

    if (Array.isArray(name) && typeof name[0] === 'string') {
      const newName = decodeURIComponent(name[0]);
      const parts = newName.split('/');
      const FilteredName = parts.pop();
      console.log("FilteredName__:", FilteredName);
      return FilteredName
    } else {
      console.log("Input is not an array containing strings.");
    }

  }



  const stripHtmlTags = (htmlString) => {
    return htmlString?.replace(/<\/?[^>]+(>|$)/g, "");
  };


  const splitIntoSentences = (text) => {
    return text.match(/[^.!?]+[.!?]*/g) || [];
  };



  const handleAssetsectionupload = async (value) => {

    setSaveClicked(true)
    //  alert(value)
    if (wordCount > maxWordLimit) {
      showToast('Proposal.wordLimit')
      return
    }

    if (rfmManagementData?.ProjectType?.data === "Shared Project") {

      const data = sharedSectionDetails?.find(sharedData => sharedData.id === assetsectionId && sharedData.selectedOption === "Edit");



      // if (!data) {

      //   toast.error("You don't have edit access");

      //   return;

      // }

    }
    console.log(content, "checkcontent")
    const cleanedContent = content.replace(/<[^>]*>/g, ''); // This regex removes all HTML tags
    const words = cleanedContent.split(/\s+/).slice(0, 4).join('_'); // Adjust the number of words as needed
    const decodedWords = decodeURIComponent(words); // Convert HTML entities to characters
    const filename = decodedWords.length > 0 ? `${decodedWords}` : 'default_filename';

    // Convert HTML content to Blob
    setLoading(true);
    const blob = new Blob([content], { type: 'text/html' });
    console.log("assetsectionId", assetsectionId)
    // alert(assetSubsectionId)
    // Create FormData object
    let newTitle;
if(newSubSectionTitle !== ""){

  newTitle = newSubSectionTitle.replace("/",'')
}else if(newSubSectionTitle == ""){

  newTitle = newSectionTitle.replace("/",'')
}



  // alert(newTitle)
    const formData = new FormData();
    formData.append('filename', blob, newTitle); // Append content as file with generated filename
    formData.append('orgName', userInfo.organizationName);
    formData.append('assetid', proposalWritingData.assetid);
    formData.append('subsectionId', assetSubsectionId);
    formData.append('assetsectionId', assetsectionId);
    formData.append('htmlContent', htmlContent);
    formData.append('userId', userInfo._id);
    formData.append('orgId', userInfo.organizationId);
    formData.append('assetType', "proposal");
    if (value == true) {

      formData.append('abhay', "reciver");

    }
    console.log(formData, "yolllo")

    const priorHtmlContentWithoutTags = stripHtmlTags(priorHtmlContent);
    const htmlContentWithoutTags = stripHtmlTags(htmlContent);
    const priorSentences = splitIntoSentences(priorHtmlContentWithoutTags);
    const currentSentences = splitIntoSentences(htmlContentWithoutTags);

    const diff = Diff.diffArrays(priorSentences, currentSentences);

    const result = {
      added: [],
      removed: []
    };

    diff.forEach(part => {
      if (part.added) {
        result.added.push(part.value.join(' '));
      } else if (part.removed) {
        result.removed.push(part.value.join(' '));
      }
    });

    console.log(result, "yollo");


    try {
      const trackChanges = await saveTrackedChanges(proposalWritingData.assetid, assetsectionId, assetType, userInfo.username, result.added, result.removed, htmlContent)
      formData.append('trackchangesId', trackChanges.data._id);

      const response = await axios.post(`https://api.contractpro360.com/api/newbucket/assetsectionuploads/${proposalWritingData.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log("upload response", response)
        showToast('Success.fileUpdate')
        const activityResponse = await addActivity(proposalWritingData.assetid, assetType, activityMsg.uploadSection, userInfo.username)
        handleGetActivity()
        getAssetSectionData()
        assetSubsectionId('')
        if(!isSubmitButtonShow){setIsgetDataEditor(false);}
        setnewSubSectionTitle("")
        
      } else {
        console.log("error in upload")
        setnewSubSectionTitle("")
      }
      // setnewSubSectionTitle("")
      // Handle success
      console.log('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error.message);
    }
    finally {
      if(!isSubmitButtonShow){setIsgetDataEditor(false);}

      setLoading(false);
    }
  };


  const handleAssetsectionuploadReq = async (sectionId, text,title) => {
    if (wordCount > maxWordLimit) {
      showToast('Proposal.wordLimit')
      return;
    }
    console.log("textxx  ", text, content)

    const updatedContent = text || content;
    const updatedSectionId = sectionId || assetsectionId;

    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const validationData = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    try {
      const validationResponse = await validateFunctionalityAccess(validationData);

      // if (validationResponse.status !== 200) {
      //   toast.error(validationResponse.message);
      //   return;
      // }

      if (rfmManagementData?.ProjectType?.data === "Shared Project") {
        const data = sharedSectionDetails?.find(
          (sharedData) =>
            sharedData.id === updatedSectionId &&
            sharedData.selectedOption === "Edit"
        );

        // if (!data) {
        //   toast.error("You don't have edit access");
        //   return;
        // }
      }

      console.log(htmlContent, "rfpcontent");
      console.log("text", text)

      console.log(updatedContent, "rfpcontent");
      const cleanedContent = updatedContent.replace(/<[^>]*>/g, ""); // This regex removes all HTML tags
      const words = cleanedContent.split(/\s+/).slice(0, 4).join("_"); // Adjust the number of words as needed
      const decodedWords = decodeURIComponent(words); // Convert HTML entities to characters
      const filename = decodedWords.length > 0 ? `${decodedWords}` : "default_filename";
      // Convert HTML content to Blob
      setLoading(true);
      const blob = new Blob([updatedContent], { type: "text/html" });

      // Create FormData object
      const formData = new FormData();
      formData.append("filename", blob, title); // Append content as file with generated filename
      formData.append("orgName", userInfo.organizationName);
      formData.append("assetid", proposalWritingData.assetid);
      formData.append("assetsectionId", updatedSectionId);
      formData.append("htmlContent", text ? text : htmlContent);
      formData.append("userId", userInfo._id);
      formData.append("orgId", userInfo.organizationId);
      console.log("isfilename", filename)

      const priorHtmlContentWithoutTags = stripHtmlTags(priorHtmlContent);
      const htmlContentWithoutTags = stripHtmlTags(htmlContent);
      const priorSentences = splitIntoSentences(priorHtmlContentWithoutTags);
      const currentSentences = splitIntoSentences(htmlContentWithoutTags);

      const diff = Diff.diffArrays(priorSentences, currentSentences);

      const result = {
        added: [],
        removed: []
      };

      diff.forEach(part => {
        if (part.added) {
          result.added.push(part.value.join(' '));
        } else if (part.removed) {
          result.removed.push(part.value.join(' '));
        }
      });

      console.log(result, "yollo");

      try {
        const trackChanges = await saveTrackedChanges(proposalWritingData.assetid, assetsectionId, assetType, userInfo.username, result.added, result.removed, htmlContent);
        formData.append('trackchangesId', trackChanges?.data?._id);

        const response = await axios.post(
          `https://api.contractpro360.com/api/newbucket/assetsectionuploads/${proposalWritingData.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          }
        );

        if (response.status === 200) {
          // toast.success("upload successfully");
          console.log("isuploaded", response);
          const activityResponse = await addActivity(
            proposalWritingData.assetid,
            assetType,
            activityMsg.uploadSection,
            userInfo.username
          );

          handleGetActivity();
          getAssetSectionData();
          assetSubsectionId('');
          return response;
        } else {
          console.log("error in upload");
        }
      } catch (error) {
        console.error("Error uploading file:", error.message);
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in validation:", error.message);
    }
  };





  const getHtmlcontent = () => {
    console.log("editor val ", editor1.current, editor1.current.value)
    // console.log("in fun");

    if (editor1.current && editor1.current.value) {
      console.log("in if condition ");
      const editorInstance = editor1.current.value;
      const value = editorInstance;
      console.log("value321  ", value);
      setContent(value);
      setHtmlContent(value);

      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/html');
      const divs = doc.querySelectorAll('div[data-index]');
      let updatedDataArray = [...originalData];

      divs.forEach(div => {
        const index = div.getAttribute('data-index');
        const newItem = div.innerHTML;
        updatedDataArray[index] = newItem;
      });

      console.log("result Array554  ", updatedDataArray);
      setMergeArray2(updatedDataArray)
      if (updatedDataArray) {
        const doc1 = parser.parseFromString(updatedDataArray[0], 'text/html');
        const plainText = doc1.body.textContent || "";
        console.log("result Array555 ", plainText);
      }
    } else {
      console.log("Editor instance is not available.");
    }

    console.log("oneeeeeeeeee12  ", content)
    console.log("threeeeeeeeee12  ", htmlContent)


    if (editor1.current) {
      const value = editor1.current.value;


      console.log("Editor current value", value);



      const htmlContent = editor1.current.value; // Access the HTML content
      console.log("htmlContent:", htmlContent);

      let wordCount = 0;
      let textContent = '';
      if (htmlContent === "") {

        setWordCount(0)
        return
      }

      if (htmlContent.trim() !== '') {
        // Replace line breaks with newline characters
        const processedHtmlContent = htmlContent.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n');
        // Count words
        const tempElement = document.createElement('div');
        tempElement.innerHTML = processedHtmlContent;
        textContent = tempElement.innerText.trim(); // Use innerText instead of textContent
        wordCount = textContent.split(/\s+/).length;
      }
      setWordCount(wordCount)
    }

  };


  const getCleanedTextContent = (htmlString, divClassOrId) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const divToRemove = doc.querySelector(divClassOrId);
    if (divToRemove) {
      divToRemove.remove();
    }
    return doc.body.textContent.trim();
  };



  const mergeChangeDetect = async () => {
    // alert("subsection")
    setLoading(true)
    setUnMatchedContentIndex([])
    const parser = new DOMParser();
    console.log("MergeArray222222 ", mergeArray2)
    // Ensure both arrays have the same length
    if (originalData.length !== mergeArray2.length) {
      showToast('Proposal.noChangeDetected')
      setLoading(false)
      console.error("Arrays have different lengths!");
      return;
    }

    // Loop through each index in the arrays
    originalData.forEach((e, index) => {
      const a = parser.parseFromString(e, 'text/html').body.textContent.trim();
      const b = getCleanedTextContent(mergeArray2[index], '.title-class'); // Adjust the selector as needed



      if (a === b) {
        console.log(`Match at index ${index}`);
        // Perform any action you want on match, e.g., alert or logging
      } else {
        console.log(`No match at index ${index}`);
        unMatchedContentIndex.push(index)
      }
    });
    console.log("ssss22222  ", unMatchedContentIndex)

    if (unMatchedContentIndex.length === 0) {
      setLoading(false)
      showToast('Proposal.noChangeDetected')
      return
    }


    for (let i = 0; i < unMatchedContentIndex.length; i++) {
      console.log("content at position  ", unMatchedContentIndex[i], "   ", originalData[unMatchedContentIndex[i]])
      console.log("checkkk21  ", mergeArray2[unMatchedContentIndex[i]])

      let data = getDataForSection(originalData[unMatchedContentIndex[i]])

      console.log("GotData11  ", data)
      console.log("userInfo334  ", userInfo)


      await uploadTheSection(data, mergeArray2[unMatchedContentIndex[i]])


    }
    setIsgetDataEditor(false);

    setLoading(false)

  };


  const uploadTheSection = async (data, contentToUpload1) => {
    const contentToUpload = getCleanedTextContent(contentToUpload1, '.title-class');


    console.log("XSXs2222  ", data)


    // Convert HTML content to Blob
    const blob = new Blob([contentToUpload], { type: 'text/html' });
    // Create FormData object
    const formData = new FormData();
    if (data.subsectionName) {
      formData.append('filename', blob, data.subsectionName); // Append content as file with generated filename

    } else {
      formData.append('filename', blob, data.title); // Append content as file with generated filename


    }
    formData.append('orgName', userInfo.organizationName);
    formData.append('assetid', proposalWritingData.assetid);
    formData.append('subsectionId', data.subsectionIds ? data.subsectionIds : '');
    if (section_id) {
      formData.append('assetsectionId', section_id);

    } else {
      formData.append('assetsectionId', data._id);

    }
    if (data.htmlContent) {
      formData.append('htmlContent', contentToUpload);

    } else {
      formData.append('htmlContent', contentToUpload);


    }
    formData.append('userId', userInfo._id);
    formData.append('orgId', userInfo.organizationId);
    formData.append('assetType', "proposal");


    console.log("onee  ", blob, data.title)
    console.log("twoo  ", userInfo.organizationName)
    console.log("threee  ", proposalWritingData.assetid)
    console.log("fourr  ", data.assetSubsectionId)
    console.log("fivee  ", data._id)
    console.log("sixx  ", contentToUpload)
    console.log("sevenn  ", userInfo._id)
    console.log("eightt   ", userInfo.organizationId)
    console.log("ninee   ", proposalWritingData)



    console.log("formDataa  ", formData)


    try {


      const response = await axios.post(`https://api.contractpro360.com/api/newbucket/assetsectionuploads/${proposalWritingData.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log("upload response123", response)
        showToast('Success.fileUpdate')

      } else {
        console.log("error in upload")
      }
      // Handle success
      console.log('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error.message);
    }

    // fetchClausesData();

  }
  const handleBlurEditor = (newContent) => {
    setContent(newContent)
    setHtmlContent(newContent)
    const editorContent = editor1.current.value;
    const parser = new DOMParser();
    const doc = parser.parseFromString(editorContent, 'text/html');
    const divs = doc.querySelectorAll('div[data-index]');
    let updatedDataArray = [...originalData];

    divs.forEach(div => {
      const index = div.getAttribute('data-index');
      const newItem = div.innerHTML;
      updatedDataArray[index] = newItem;
    });





  }






  const getDataForSection = (htmlContentToMatch) => {
    setSection_id(null)
    // Assuming apiResponse is accessible here
    for (let i = 0; i < allSectioDetails.data.length; i++) {
      const dataItem = allSectioDetails.data[i];

      // Check main htmlContent field
      if (dataItem.htmlContent === htmlContentToMatch) {
        console.log("ppppp ", dataItem);
        console.log("ppppppppp222222 ", dataItem.htmlContent);
        console.log("ppppppppp444444 ", htmlContentToMatch);


        // setState(dataItem);
        return dataItem;
      }

      // Check subSectionsData htmlData field

      for (let j = 0; j < dataItem.subsections.length; j++) {
        const subsection = dataItem.subsections[j];
        console.log("new  ", subsection);
        console.log("new22222222  ", subsection);
        console.log("new33333333 ", htmlContentToMatch)

        if (subsection === htmlContentToMatch) {
          console.log("_ID 234 ", dataItem[i]);

          console.log("aaaaaaaa11  ", dataItem.subSectionsData[j])

          setSection_id(dataItem._id)
          return dataItem.subSectionsData[j];
        }
      }
    }
    return null; // Return null if no match is found
  };


  const getHtmlOwnercontent = () => {
    if (editor1.current) {
      const value = editor1.current.value;
      setHtmlownerContent(value);
      console.log("", value);
    } else {
      console.log("Editor ref is not set up properly.");
    }
  };

  // const handleBlur = (newContent) => {
  //   console.log(newContent, "newContent"); // Log newContent to the console
  //   setContent(newContent); // Update content state
  // };
  // edit section title
  const handlSectiontitle = async (e) => {
    console.log("Edited section title__", e)
    setSectionTitle(e)
    try {
      const response = await updateAssetsection(assetsectionId, e)
      if (response.status == 200) {
        // toast.success("Updated succesfully")
        getAssetSectionData()
      } else {
        console.log("error in response ")
      }
    }
    catch (e) {
      console.log("error while updating the title", e)
    }

  }

  //Summarize description

  const handleSummaryModalOpen = async () => {

    setLoading(true)
    const isSummarized = await callSummarizer()

    setOpenSummaryModal(true)


    setLoading(false)
  }

  const handleNextCard = () => {
    if (viewType === "noteCard") {
      const currentIndex = notesData.findIndex(item => item._id === cardId);
      console.log("currentIndex", currentIndex, cardId)
      if (currentIndex !== -1 && currentIndex < notesData.length - 1) {
        const nextCard = notesData[currentIndex + 1];
        setcardData(nextCard.text)
        setCardId(nextCard._id)
        setCreatedByCard(nextCard.createdby)
        console.log("Next Card:", nextCard);
      } else {
        console.log("No next card available.");
      }
    } else if (viewType === "commentCard") {
      const currentIndex = commentData.findIndex(item => item._id === cardId);
      console.log("currentIndex", currentIndex, cardId)
      if (currentIndex !== -1 && currentIndex < commentData.length - 1) {
        const nextCard = commentData[currentIndex + 1];
        setcardData(nextCard.text)
        setCardId(nextCard._id)
        setCreatedByCard(nextCard.createdby)
        console.log("Next Card:", nextCard);
      } else {
        console.log("No next card available.");
      }
    }


  }

  const handlePreviousCard = () => {
    if (viewType === "noteCard") {
      const currentIndex = notesData.findIndex(item => item._id === cardId);
      console.log("currentIndex", currentIndex, cardId)
      if (currentIndex !== -1 && currentIndex > 0) {
        const nextCard = notesData[currentIndex - 1];
        setcardData(nextCard.text)
        setCardId(nextCard._id)
        setCreatedByCard(nextCard.createdby)
        console.log("Previous Card:", nextCard);
      } else {
        console.log("No Previous card available.");
      }
    } else if (viewType === "commentCard") {
      const currentIndex = commentData.findIndex(item => item._id === cardId);
      console.log("currentIndex", currentIndex, cardId)
      if (currentIndex !== -1 && currentIndex > 0) {
        const nextCard = commentData[currentIndex - 1];
        setcardData(nextCard.text)
        setCardId(nextCard._id)
        setCreatedByCard(nextCard.createdby)
        console.log("Previous Card:", nextCard);
      } else {
        console.log("No Previous card available.");
      }
    }
  }


  const callSummarizer = async () => {

    try {
      const isSummarized = await isSummarizedDescription(proposalWritingData.id)
      console.log("isSummarized", isSummarized)
      if (isSummarized.status == 200) {
        const data = {
          text: cleanedContent,
          prompts: "one",
          content: ""
        };
        const summarizerResponse = await getCheckSummariser(data);
        console.log("summarizerResponse", summarizerResponse);
        if (summarizerResponse.data !== "") {
          let responseOutput = summarizerResponse?.data?.output
          console.log("summarized description ", responseOutput)
          setsummarizedDescription(responseOutput)
          try {
            const response = await updateSummarizedDescription(proposalWritingData.id, responseOutput[0])
            console.log(response)
          } catch (error) {
            console.log(error)
          }

        }
      }
      else if (isSummarized.status == 400) {
        setsummarizedDescription(isSummarized.data.summarizedDescription)
      }
    } catch (error) {
      console.log(error)
    }

  }

  const handleAddTemplate = () => {
    // setShowTemplate1(true)
    const assetId = proposalWritingData.assetid;
    localStorage.setItem('assetId', assetId)
    localStorage.setItem('opportunityId', proposalWritingData.id)
    sessionStorage.setItem('assetId', assetId)
    sessionStorage.setItem('opportunityId', proposalWritingData.id)
    dispatch(templateData("PROPOSAL"))
    history.push("/TemplateManagement")

  }
  const hideAddTemplateForProposal = () => {

    setShowTemplate1(false)
  }
  // swapping state


  const toggleModal = async () => {
    let featurename = "Swap"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {

      showToast('Error.upload')
    } else {
      const UserEmail = sessionStorage.getItem("currentUserEmail")
      const data = {
        assetId: proposalWritingData.assetid,
        userEmail: UserEmail,
        assetType: "proposal"
      };

      const response = await validateFunctionalityAccess(data);
      if (response.status === 200) {
        setModalOpen(!modalOpen);
      } else {
        showToast('Error.errorOccured')
      }
    }

  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Reorder state


  const toggleReorderModal = async () => {
    let featurename = "Reorder"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {

      showToast('Error.upload')
    } else {
      const UserEmail = sessionStorage.getItem("currentUserEmail")
      const data = {
        assetId: proposalWritingData.assetid,
        userEmail: UserEmail,
        assetType: "proposal"
      };

      const response = await validateFunctionalityAccess(data);
      if (response.status === 200) {
        setshowReorderModal(!showReorderModal);
      } else {
        showToast('Error.errorOccured')
      }
    }

    // window.alert('This is an alert message!');
  };

  const handleBacktoOpportunity = () => {
    history.push('/OppourtunityManagementSubadmin')
  }


  const handleOpenListTemplatModal = (sectionId, title) => {
    setIsTemplatListModal(true)
    setAssetSetionId(sectionId)
    setSectionTitle(title)
  }
  const handleCloseListTemplatModal = () => {
    setIsTemplatListModal(false)
  }

  const fetchData = async () => {
    try {

      const email = sessionStorage.getItem("currentUserEmail");
      const response = await getTemplates({ email });
      console.log("DATA FROM BUCKET  ", response.data);
      setTemplateList(response.data)
    } catch (error) {
      console.error('Error fetching templates:', error.message);
    }
  };

 

  const handleRadioChange = (templateId, path) => {
    console.log("recived ID from checkbox", templateId, path)
    setSelectedTemplateListId(templateId)
    setSelectedTemplateListPath(path)

  };


  async function getListTemplateData() {
    console.log("ScanFile2 ", selectedTemplateListPath)


    const urlParts = selectedTemplateListPath.split('/');

    // Get the last part of the URL
    let filenames = urlParts[urlParts.length - 1];


    setLoading(true)
    try {
      const filename = filenames
      let docFormat = filename.slice(-4).replace(/\./g, '');
      const docxContent = await getFileContent(filename, userInfo.organizationName, userInfo._id);

      console.log("fetchDataFile", docxContent, filename, docFormat)
      if (docFormat == "doc") {
        showToast('Warning.noPreview')
      }
      else if (docFormat == "docx") {

        console.log("DOCX CONTENT ", docxContent.textResponse)
        setContent(docxContent.textResponse)
        setIsgetDataEditor(true)
        handleCloseListTemplatModal()
      }
      else if (docFormat == "html") {

        console.log("DOCX CONTENT ", docxContent.textResponse)
        setIsgetDataEditor(true)
        setContent(docxContent.textResponse)
        handleCloseListTemplatModal()
      }

      else if (docFormat == "pdf") {
        setFileURL(docxContent.blobURL)
        handleCloseListTemplatModal()
        //  setPopupOpen(true)
      }
    } catch (error) {
      console.error('Error fetching and processing file content:', error);
      // showNoPreviewInIframe();
    }
    setLoading(false)
  }


  const handleProceedListTemplate = () => {
    getListTemplateData()
    handleCloseListTemplatModal()
  }



  const handleOpenWithPlaceholderlistModal = async (title, sectionId, createdby) => {
    let featurename = "Placeholder";
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename);
    console.log(checkfeatureName[0].access, "checkfeatureName");

    if (!checkfeatureName[0].access) {
      showToast('Error.upload')
    } else {
      const UserEmail = sessionStorage.getItem("currentUserEmail");
      const data = {
        assetId: proposalWritingData.assetid,
        userEmail: UserEmail,
        assetType: "proposal"
      };

      const response = await validateFunctionalityAccess(data);
      if (response.status === 200) {
        if (title === "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessDenied')
        } else {
          setisTemplatListWithPlaceholderModal(true);
          setAssetSetionId(sectionId);
          setTemplateManagement(true);
          setGallery(false);
          setPreview(false);
          setPlaceholder(false);
          setPreviousPage("template");
          setSectionTitle(title);
        }
      } else if (response.status === 403 && response.isFinanceTeamUser && title === "Cost and Estimation") {
        setisTemplatListWithPlaceholderModal(true);
        setAssetSetionId(sectionId);
        setTemplateManagement(true);
        setGallery(false);
        setPreview(false);
        setPlaceholder(false);
        setPreviousPage("template");
        setSectionTitle(title);
      } else {
        showToast('Error.errorOccured')
      }
    }
  };




  const downloadFunction = async (name, type, htmlContent, sectionId, title) => {
    try {
      //setSectionTitle('')
      //console.log("name of the file on which i am working ", name)
      if (Array.isArray(name) && typeof name[0] === 'string') { // Check if name is an array and the first element is a string
        const newName = decodeURIComponent(name[0]); // Decode the filename
        const parts = newName.split('/');
        const FilteredName = parts.pop();
        console.log("File name for editor__:", FilteredName);

        const docxContent = await getSectionDocument(FilteredName, userInfo.organizationId, proposalWritingData.id, proposalWritingData.assetid, userInfo._id, sectionId);
        console.log("yes", docxContent, FilteredName)


        if (docxContent.textResponse) {
          // seteditSectionTitle(false)
          // setIsgetDataEditor(true)
          const parsedResponse = JSON.parse(docxContent.textResponse);
          console.log("download resp   ", (parsedResponse.data.documentBody));
          setDownloadTitle(title)

          setDownloadContent(parsedResponse.data.documentBody)
        }
      }
    } catch (error) {
      console.error('Error fetching and Section file content:', error);
    }
  }


  const handleDownload = async (documentpath, type, htmlContent, _id, title, createdby) => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    const response = await validateFunctionalityAccess(data);
    if (response.status === 200) {
      if (title === "Cost and Estimation" && !response.isFinanceTeamUser) {
        showToast('Proposal.accessDenied')
      } else {
        setShowDownloadModal(true);
        downloadFunction(documentpath, type, htmlContent, _id, title);
      }
    } else if (response.status === 403 && response.isFinanceTeamUser && title === "Cost and Estimation") {
      setShowDownloadModal(true);
      downloadFunction(documentpath, type, htmlContent, _id, title);
    } else {
      showToast('Error.errorOccured')
    }
  };





  const downloadSinglePDF = () => {
    if (downloadContent === undefined) {
      showToast('Proposal.nothingDownload')
      setShowDownloadModal(false)
      return;
    }

    let fileName = downloadTitle

    let pdfContent = `<div><h5>${fileName}</h5><p>${downloadContent}</p></div>`;

    pdfContent = pdfContent.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);
  
  
  pdfContent = pdfContent.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');

    const element = document.createElement('div');
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: `${fileName}.pdf`, // Set the filename to the document name
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }).then((pdf) => {
      saveAs(pdf, `${fileName}.pdf`);
    });
    //onHide();
    setShowDownloadModal(false)
  };


  



  const handleDocxDownload = async () => {
    if (downloadContent === undefined) {
      showToast('Proposal.nothingDownload')
      setShowDownloadModal(false)
      return
    }
    let fileName = downloadTitle
       // Ensure images do not overflow the container
       const adjustedItem = downloadContent.replace(/<img[^>]*>/g, (imgTag) => {
        return imgTag.replace(/style="[^"]*"/, '') // Remove existing styles
                     .replace(/\/?>/, `style="max-width: 100%; height: auto;" />`); // Add max-width to ensure it fits within the container
      });
    let htmlString = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>${fileName}</title>
    </head>
    <body>
        <h3>${fileName}</h3>
        <hr/>
        ${adjustedItem}
    </body>
    </html>`;

    htmlString = htmlString.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);
  
  
  htmlString = htmlString.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');
    console.log("htmlString",htmlString)
    asBlob(htmlString).then(docx => {
      saveAs(docx, `${fileName}.docx`);
      setShowDownloadModal(false) // Close the modal after the download
    });
  };

  const autoFormatting = () => {
    if (editor1.current) {
      // alert("knbcsjdbj")
      const editorInstance = editor1.current;

      // // Get the HTML content from the editor instance
      const currentHtml = editorInstance.value;

      // // Remove unwanted tags and attributes (e.g., style, bold, underline)
      const cleanedHtml = sanitizeHtml(currentHtml);

      // // Set the cleaned HTML content back to the editor
      editorInstance.value = cleanedHtml;
      console.log(cleanedHtml, "cleanedHtml")
      setContent(cleanedHtml);
    }
  }


  const applyDefaultStyling = async () => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    if (showBtnInMerge) {
      // If showBtnInMerge is true, give access without validation
      autoFormatting();
    } else {
      // Validate functionality access
      const response = await validateFunctionalityAccess(data);

      if (response.status === 200) {
        if (sectionTitle === "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessDenied')
        } else {
          autoFormatting();
        }
      } else if (response.status === 403 && response.isFinanceTeamUser && sectionTitle === "Cost and Estimation") {
        autoFormatting();
      } else {
        showToast('Error.errorOccured')
      }
    }
  };


  // Helper function to sanitize HTML content (remove unwanted tags and attributes)
  const sanitizeHtml = (html) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    // Remove specific tags and their contents
    const tagsToRemove = ['b', 'u']; // Add more tags as needed
    tagsToRemove.forEach((tag) => {
      const elements = tempElement.querySelectorAll(tag);
      elements.forEach((element) => {
        element.parentNode.removeChild(element);
      });
    });

    // Remove inline styles from all elements
    const allElements = tempElement.querySelectorAll('*');
    allElements.forEach((element) => {
      element.removeAttribute('style');
    });

    return tempElement.innerHTML;
  };




  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) {
      return fileName;
    } else {
      return fileName.substring(0, maxLength) + '...';
    }
  };


  const getGalleryTemplateList = async () => {
    // setLoading(true)
    try {
      const email = sessionStorage.getItem("currentUserEmail")
      const responce = await GetGalleryList(email)

      setGalleryTemplateList(responce.data)



    }
    catch (error) {
      console.log("Error from Gallery List api", error)
    }
    // setLoading(false)

  }

  const handleGalleryClick = () => {
    setTemplateManagement(false);
    setGallery(true);
    setPreview(false);
    setPlaceholder(false);
    setPreviousPage("gallery");
  }

  const handlePreviewClick = (file, path, fileName, category, name1) => {
    console.log("details    ", file, path, fileName, category, name1)
    setTemplateManagement(false);
    setGallery(false);
    setPreview(true);
    setPlaceholder(false);
    if (name1 === "gallery") {
      fetchPreviewContent(fileName, category)
    }
    if (name1 === "management") {
      fetchDataFile(file, path)
    }

    // setPreviousPage("preview");
  }

  function handleCloseListTemplatListWithPlaceholderModal() {
    setisTemplatListWithPlaceholderModal(false)
  }


  const handlePlaceholderClick = () => {
    setDataTypeMap({})
    setTemplateManagement(false);
    setGallery(false);
    setPreview(false);
    setPlaceholder(true);
    // setPreviousPage("placeholder");
  }

  const handleGalleryBack = () => {
    setTemplateManagement(true);
    setGallery(false);
    setPreview(false);
    setPlaceholder(false);
  }

  const handlePreviewClose = () => {

    if (previousPage === "template") {
      setTemplateManagement(true);
      setGallery(false);
      setPreview(false);
      setPlaceholder(false);
    }
    if (previousPage === "gallery") {
      setTemplateManagement(false);
      setGallery(true);
      setPreview(false);
      setPlaceholder(false);
    }

  }






  function handlePlaceholderBack() {
    if (previousPage === "template") {
      setTemplateManagement(true);
      setGallery(false);
      setPreview(false);
      setPlaceholder(false);
    }
    if (previousPage === "gallery") {
      setTemplateManagement(false);
      setGallery(true);
      setPreview(false);
      setPlaceholder(false);
    }
  }
  useEffect(() => {
    if (templateManagement && !gallery && !preview && !placeholder) {
      setPreviousPage("template")
    }
  }, [templateManagement, gallery, preview, placeholder])





  const fetchPreviewContent = async (filename, category) => {
    console.log(filename, " this ", category)
    setEditorStateWithPlaceholder(null)
    setFileName(filename)
    setLoading(true)
    try {
      const response = await GetPreviewContent(category, filename);

      console.log("$$$$$$   ", response)
      setEditorStateWithPlaceholder(response.textResponse)



    } catch (error) {
      console.error('Error fetching preview content:', error.message);
    }

    setLoading(false)

  };


  async function fetchDataFile(name, path) {
    setFileName(name)
    setEditorStateWithPlaceholder(null)
    // alert(path)
    const urlParts = path.split('/');

    // Get the last part of the URL
    let filenames = urlParts[urlParts.length - 1];
    setLoading(true)
    try {
      const filename = filenames
      let docFormat = name.slice(-4).replace(/\./g, '');
      let userid = userInfo?._id;
      let orgName = userInfo?.organizationName;

      // .slice(-4).replace(/\./g, '');
      console.log(filename, orgName, userid, "fetchdatafilecontent")
      const docxContent = await getFileContent(filename, orgName, userid);

      console.log("fetchDataFile", docxContent)
      console.log(docFormat, "docFormat")
      if (docxContent.rawResponse.status == 200) {

        if (docFormat == "doc") {
          showToast('Warning.noPreview')
        }
        else if (docFormat == "docx") {
          console.log("EditorResponce  ", docxContent.textResponse)
          setEditorStateWithPlaceholder(docxContent.textResponse)
          //  setIsPreviewOpen(true)

        }
        else if (docFormat == "html") {
          console.log("EditorResponce  ", docxContent.textResponse)
          setEditorStateWithPlaceholder(docxContent.textResponse)
          //  setIsPreviewOpen(true)

        }
        // else if (docFormat == "pdf") {
        //     setFileURL(docxContent.blobURL)
        //     setPopupOpen(true)
        // }
      }
      else {
        showToast('Error.errorOccured')
      }
    } catch (error) {
      console.error('Error fetching and processing file content:', error);
      showToast('Error.errorOccured')
    }
    setLoading(false)
  }



  const handleBlankTemplateInWithPlaceholder = () => {
    handleCloseListTemplatListWithPlaceholderModal()
    setIsgetDataEditor(true)
    setContent(null)
  }




  const handleGalleryClick2 = () => {
    setTemplateManagement2(false);
    setGallery2(true);
    setPreview2(false);
    setPreviousPage2("gallery");
  }

  const handlePreviewClick2 = (file, path, fileName, category, name1) => {
    console.log("details    ", file, path, fileName, category, name1)
    setTemplateManagement2(false);
    setGallery2(false);
    setPreview2(true);
    if (name1 === "gallery") {
      fetchPreviewContent(fileName, category)
    }
    if (name1 === "management") {
      fetchDataFile(file, path)
    }


  }



  const handleGalleryBack2 = () => {
    setTemplateManagement2(true);
    setGallery2(false);
    setPreview2(false);

  }

  const handlePreviewClose2 = () => {

    if (previousPage2 === "template") {
      setTemplateManagement2(true);
      setGallery2(false);
      setPreview2(false);

    }
    if (previousPage2 === "gallery") {
      setTemplateManagement2(false);
      setGallery2(true);
      setPreview2(false);

    }

  }



  useEffect(() => {
    if (templateManagement2 && !gallery2 && !preview2) {
      setPreviousPage2("template")
    }
  }, [templateManagement2, gallery2, preview2])


  //   const stripHtmlTagsProposal = (html) => {

  // console.log("stylled222 ", html)

  //     const div = document.createElement('div');
  //     div.innerHTML = html;
  //     return div.textContent || div.innerText || '';
  //   };
  const renderHtmlContent = (html) => {
    return { __html: html };
  };

  const handleWithoutPlaceholderModal = async (title, sectionId, createdby) => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };

    const response = await validateFunctionalityAccess(data);
    if (response.status === 200) {
      if (title === "Cost and Estimation" && !response.isFinanceTeamUser) {
        showToast('Proposal.accessDenied')
      } else {
        setAssetSetionId(sectionId);
        setIsTemplatListModal(true);
        setTemplateManagement2(true);
        setGallery2(false);
        setPreview2(false);
      }
    } else if (response.status === 403 && response.isFinanceTeamUser && title === "Cost and Estimation") {
      setAssetSetionId(sectionId);
      setIsTemplatListModal(true);
      setTemplateManagement2(true);
      setGallery2(false);
      setPreview2(false);
    } else {
      showToast('Error.errorOccured')
    }
  };


  function handleCloseWithoutPlaceholder() {
    setIsTemplatListModal(false)
  }


  const handleBlankTemplateInWithPlaceholder2 = () => {
    handleCloseWithoutPlaceholder()
    setIsgetDataEditor(true)
    setContent(null)
  }


  const handleCardClick = (fileName, title, _id, path, GalleryFile, category, nameOfType) => {


    setScanFileName(fileName)
    setPath(path)
    if (nameOfType === "template") {
      HandleTemplateCardClick(fileName)
    }
    if (nameOfType === "gallery") {
      handleGalleryCardClick(GalleryFile, category)
    }

  };









  const HandleTemplateCardClick = async (fileName1, path) => {



    setLoading(true)
    try {
      // const filename = fileName1;

      console.log(userid, "userid")
      console.log(orgName, fileName1, "orgname")
      let docFormat = fileName1.slice(-4).replace(/\./g, '');
      console.log("ppp template", fileName1, orgName, userid)

      const docxContent = await getFileContent(fileName1, orgName, userid);


      console.log("fetchDataFile", docxContent, fileName1, docFormat)
      if (docFormat == "doc") {
        showToast('Warning.noPreview')
      }
      else if (docFormat == "docx") {


        setContent(docxContent.textResponse);
        setHtmlContent(docxContent.textResponse)

      }
      else if (docFormat == "html") {
        setContent(docxContent.textResponse);
        setHtmlContent(docxContent.textResponse)


      }


    } catch (error) {
      console.error('Error fetching and processing file content:', error);
      // showNoPreviewInIframe();
    }
    handleCloseWithoutPlaceholder()
    setIsgetDataEditor(true)

    setLoading(false)




  }

  const handleGalleryCardClick = async (filename, category) => {

    console.log(filename, " this ", category)
    // setEditorStateWithPlaceholder(null)
    setLoading(true)
    try {
      const response = await GetPreviewContent(category, filename);

      console.log("$$$$$$   ", response)
      setContent(response.textResponse)
      setHtmlContent(response.textResponse)




    } catch (error) {
      console.error('Error fetching preview content:', error.message);
    }


    handleCloseWithoutPlaceholder()
    setIsgetDataEditor(true)
    setLoading(false)


  }

  const getAssetData = async () => {
    try {
      let data = {
        assetId: proposalWritingData?.assetid
      };
      const response = await getSpecificAsset(data);
      if (response.status == 200) {
        console.log(response, "specific asset found");
        const data = response.data
        setAssetData(data)
      }
    }
    catch (error) {
      console.log(error)
    }

  }


  const handleClauseExtraction = async () => {
    setExtractedClause("");

    try {
      const UserEmail = sessionStorage.getItem("currentUserEmail");
      const dataa = {
        assetId: proposalWritingData.assetid,
        userEmail: UserEmail,
        assetType: "proposal"
      };
      const validationResponse = await validateFunctionalityAccess(dataa);

      if (validationResponse.status !== 200) {
        toast.error(validationResponse.message);
        return;
      }

      setDownloadFileName(assetData?.opportunityid.title);
      let data = {
        resourceLinks: assetData?.opportunityid.resourceLinks,
        assetId: proposalWritingData?.assetid,
        email: UserEmail
      };

      setLoading(true);
      const response = await getResourceLinks(data);

      if (response.status === 201) {
        console.log("extracted clauses:", response);
        setExtractedClause(response.data);
        handleOpenClauseModal();
      } else if (response.status === 400) {
        getExtractedClause(proposalWritingData?.assetid);
        handleOpenClauseModal();
      } else {
        console.log("error in getting extracted clauses");
        handleOpenClauseModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  const handleOpenClauseModal = () => {
    setIsOpenClauseModal(true);
    handleGetDeletedClause()
  };


  const getExtractedClause = async () => {
    try {
      let featurename = "Clause"
      const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
      console.log(checkfeatureName[0].access, "checkfeatureName")
      // if (!checkfeatureName[0].access) {

      //   toast.error("You are not allowed to upload")
      // } else {
      let data = {
        assetid: proposalWritingData.assetid,
        email: sessionStorage.getItem("currentUserEmail")
      }
      setLoading(true)
      const response = await getClauses(data)
      console.log("response", response)
      setExtractedClause(response?.data)
      setDocClauseArray(response)
      setIsOpenClauseModal(true)
      extractResponseData(response)
      // }



    } catch (e) {
      console.log(e, "erro in getExtractedClause")
    } finally {
      setLoading(false)
    }
  }


  const handleAddClauses = (e) => {
    console.log("add clauses", e.target.value)
    setAddManualClause(e.target.value)

  }

  function updateImgWidth(match, p1, p2, p3) {
    // p2 is the current width value
    const currentWidth = parseInt(p2, 10);
    // Check if the current width is less than 600
    if (currentWidth < 600) {
        return `<img${p1}width="${currentWidth}"${p3}>`;
    }
    // If the current width is 600 or more, set it to 600
    return `<img${p1}width="600"${p3}>`;
}

  const handleAddManualClause = async () => {
    try {
      console.log("xxxxxxx")

      // if (userInfo?.userRole[0] === "Application User") {
      //   return toast.error("You are not allowed to add clause.")
      // }
      if (addReason == "") {
        return showToast('Proposal.reasonAddClause')
      }


      const status = userInfo.userRole.map(role=> role.trim().toLowerCase()).some(role => role === "manager" || role === "application admin")  ? "approved" : "pending";
      let data = {
        assetId: proposalWritingData.assetid,
        clauses: addManualClause,
        filename: "Manually Added Clauses",
        status: status,
        createdBy: cuurentUser,
        assetType: "proposal",
        reason: addReason
      }
      const response = await addClauses(data)
      if (response.status === 200) {
        
        showToast('Success.clauseAdded')
        getExtractedClause()
        setAddManualClause('')
        handleAddReasonModalclose()
      }else{
        toast.error("Manager role not found in organization")
      }
    } catch (error) {
      console.log(error, "erro in adding manual clauses ")
    }
  }

  const downloadWordDocumentForMapping = () => {
    let content = document.getElementById('table-to-pdf').innerHTML;
  
 
  content = content.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);
  content = content.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');

    const blob = htmlDocx.asBlob(content);
    saveAs(blob, 'RequirementMapping.docx');
    setShowDownloadMappingModal(false)

  };


  const DownloadMergeSection2 = async () => {

    const assetType = "proposal"
  
    let data = {
      assetId: assetIdMerge,
      // assetId: AssetId,
      assetType: assetType,
      includeCostEstimation: isLastStage

    }
    try {
      let responce = await TemplateMerge(data)

      console.log("responce from mmmerge", responce)
      DownloadInEditorWord(responce.all)

    }
    catch (error) {
      console.log("error in merge ", error)
    }
  }



  const DownloadInEditorWord = (dataArray1) => {
    // Define the background color for each "page"
    const pageBackgroundColor = 'white'; // Adjust the color as needed

    // Define the height of the empty space between items
    const emptySpaceHeight = '80px'; // Adjust the height as needed

    // Extract data field from dataArray and wrap each element in a div with background color
    const filteredData = dataArray1.slice().filter(item => !item.startsWith('<p>Electronic signatures of submitted users:</p>'));
    const extracted = filteredData.map(item => `
            <div style="background-color: ${pageBackgroundColor}; width: 100%; padding: 50px;">
            <div style="background-color:${pageBackgroundColor};">${item}</div>
            </div>
            <div style="height: ${emptySpaceHeight};"></div>
    `).join('');

    let wordContent = `
        <div>
            <h5>Merge Sections</h5>
            <p>${extracted}</p>
        </div>
    `;


  wordContent = wordContent.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);
  
  
  wordContent = wordContent.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');

    const converted = htmlDocx.asBlob(wordContent, { orientation: 'portrait' });
    saveAs(converted, 'MergeSection.docx');
    setShowDownloadModalForMerge(false)

};





  const DownloadMergeSection = async () => {

    const assetType = "proposal"

    let data = {
      assetId: assetIdMerge,
      // assetId: AssetId,
      assetType: assetType,
      includeCostEstimation: isLastStage

    }
    try {
      let responce = await TemplateMerge(data)

      console.log("responce from mmmerge", responce)
      DownloadInEditorPDF(responce.all)
    }
    catch (error) {
      console.log("error in merge ", error)
    }
  }

  const DownloadInEditorPDF = (dataArray1) => {
    const pageBackgroundColor = 'white'; // Background color for each "page"
    const emptySpaceHeight = '80px'; // Height of empty space between items
  
    const filteredData = dataArray1.slice().filter(item => !item.startsWith('<p>Electronic signatures of submitted users:</p>'));
  
    const extracted = filteredData.map((item, index) => {
      const title = allSectioDetails.data[index]?.title;
  
      // Ensure images do not overflow the container
      const adjustedItem = item.replace(/<img[^>]*>/g, (imgTag) => {
        return imgTag.replace(/style="[^"]*"/, '') // Remove existing styles
                     .replace(/\/?>/, `style="max-width: 100%; height: auto;" />`); // Add max-width to ensure it fits within the container
      });
  
      const titleDiv = title ? `<div id="title-${index}" class="title-class" style="font-weight: bold; background-color: ${pageBackgroundColor};">${title}</div><br />` : '';
  
      return `
        ${titleDiv}
        <div style="background-color: ${pageBackgroundColor}; width: 100%; padding: 50px;">${adjustedItem}</div>
        <div style="height: ${emptySpaceHeight};"></div>`;
    }).join('');
  
    const pdfContent = `<div><h5> Merge Sections </h5><p>${extracted}</p></div>`;
  
    const element = document.createElement('div');
    element.innerHTML = pdfContent;
  
    html2pdf().from(element)
      .set({
        margin: 10,
        filename: "MergeSection.pdf", // Set the filename to the document name
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
      })
      .save()
      .then(() => {
        setShowDownloadModalForMerge(false);
      });
  }




  const MergeTemplateforAllContent = async () => {
    setMergeDocColor(true)
    // setShowBtnInMerge(true)

    const assetType = "proposal"

    let data = {
      assetId: assetIdMerge,
      // assetId: AssetId,
      // assetType: assetType,
      includeCostEstimation: isLastStage

    }
    try {
      let responce = await TemplateMerge(data)

      let totalWordCount = getTotalWordCountFromHtmlArray(responce.all);

      // Set the total word count
      setWordCount(totalWordCount);

      console.log("responce from mmmerge", responce)
      setAllSectionDetails(responce)
      // handleBlankTemplate(responce.all)
      setSectionTitle("Merge Section")
      setMergeSectionData(responce.data)

    }
    catch (error) {
      console.log("error in merge ", error)
    }


  }

  function getWordCountFromHtml(htmlString) {
    // Create a temporary element to parse HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    // Extract text content and replace <br> and <div> with newlines
    let textContent = tempElement.innerText.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n').trim();

    // Split the text content into words and filter empty entries
    return textContent.split(/\s+/).filter(word => word.length > 0).length;
  }

  // Function to get total word count from an array of HTML strings
  function getTotalWordCountFromHtmlArray(htmlArray) {
    return htmlArray.reduce((total, htmlString) => {
      // Count words in the current HTML string and add to total
      return total + getWordCountFromHtml(htmlString);
    }, 0);
  }




  const MergeTemplate = async () => {
    setSendBackButtonClicked(false)
    setSubmitButtonShow(false)
    setMergeDocColor(true)
    setShowBtnInMerge(true)

    const assetType = "proposal"

    let data = {
      assetId: assetIdMerge,
      // assetId: AssetId,
      assetType: assetType,
      includeCostEstimation: isLastStage

    }
    try {
      let responce = await TemplateMerge(data)


      let totalWordCount = getTotalWordCountFromHtmlArray(responce.all);

      // Set the total word count
      setWordCount(totalWordCount);

      console.log("responce from mmmerge", responce)
      setAllSectionDetails(responce)
      handleBlankTemplate(responce.all)
      setSectionTitle("Merge Section")
      setIsEditorReadOnly(false)
      setIsReadOnly(false)
      setMergeSectionData(responce.data)
    }
    catch (error) {
      console.log("error in merge ", error)
    }


  }


  useEffect(() => {
    MergeTemplateforAllContent()
  }, [])

  const handleBlankTemplate = (dataArray1) => {
    console.log("All details ", allSectioDetails)
    // Set original data
    setOriginalData(dataArray1);

    const pageBackgroundColor = 'white';
    const emptySpaceHeight = '80px';


    const extracted = dataArray1.map((item, index) => {
      const title = allSectioDetails.data[index]?.title;
      return `
        <div style="background-color: ${pageBackgroundColor}; width: 100%; padding: 50px;" data-index="${index}">
          ${title ? `<div id="title-${index}" class="title-class" style="font-weight: bold; background-color:  ${pageBackgroundColor};">${title}</div><br />` : ''}
          <div style="background-color:${pageBackgroundColor};">${item}</div>
        </div>
        <div style="height: ${emptySpaceHeight};"></div>
      `;
    }).join('');




    const filteredData = dataArray1.slice().filter(item => !item.startsWith('<p>Electronic signatures of submitted users:</p>'));
    const filteredExtracted = filteredData.map((item, index) => `
    <div style="background-color: ${pageBackgroundColor}; width: 100%; padding: 50px;" data-index="${index}">
      <div style="background-color:${pageBackgroundColor};">${item}</div>
    </div>
    <div style="height: ${emptySpaceHeight};"></div>`
    ).join('');

    console.log("Stylled1  ", stylledContent)
    setstylledContent(filteredExtracted)
    setContent(extracted);
    setIsgetDataEditor(true);
  };



  const displayMappedClauses = (mapped_clauses, filename) => {
    if (!mapped_clauses || mapped_clauses.every((clause) => clause === "undefined")) {
      return "N/A";
    } else {

      // console.log("mapped_clauses",mapped_clauses)
      return mapped_clauses.map((clause, index) => {
        const isPresent = extractedClause?.clauses?.some(data =>
          data.filename === filename && data.responseData.split('\n').includes(clause)

        );
        console.log("isPresent", isPresent)
        return (
          <span key={index} style={{ color: isPresent ? 'green' : 'red' }}>
            {clause}
          </span>
        );
      })

    }
  };




  const handleDownloadClause = () => {
    if (userInfo?.userRole[0] === "Application User") {
      return showToast('Error.download')
    }
    setClauseshowDownloadModal(true);
  };

  const ClausePDFDownload = () => {
    let pdfContent = "";
    console.log(extractedClause, downloadFileName, "aaaa");
    extractedClause.clauses.forEach((item) => {
      const responseData = item.responseData
        .split("\n")
        .map((clause) => `<p>${clause}</p>`)
        .join("");
      pdfContent += `<div><h5>${item.filename}</h5>${responseData}</div><br>`;
    });
    console.log("pdfContent", pdfContent);
    const element = document.createElement("div");
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: `${downloadFileName}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }).then((pdf) => {
      saveAs(pdf, `${downloadFileName}.pdf`);
    });
    setClauseshowDownloadModal(false);
  };

  const ClauseDocxDownload = async () => {
    // const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
    // const contentHTML = stateToHTML(contentState);
    // let downloadFileName = fileForSave.slice(0, -5);

    let pdfContent = "";
    console.log(extractedClause, downloadFileName, "aaaa");
    extractedClause.clauses.forEach((item) => {
      const responseData = item.responseData
        .split("\n")
        .map((clause) => `<p>${clause}</p>`)
        .join("");
      pdfContent += `<div><h5>${item.filename}</h5>${responseData}</div><br>`;
    });

    const htmlString = `<!DOCTYPE html>
      <html lang="en">
      <head>
      <meta charset="UTF-8">
      <title>${downloadFileName}</title>
      </head>
      <body>
      <h3>${downloadFileName}</h3>
      <hr/>
      ${pdfContent}
      </body>
      </html>`;

    asBlob(htmlString).then((docx) => {
      saveAs(docx, `${downloadFileName}.docx`);
      setClauseshowDownloadModal(false); // Close the modal after the download
    });
  };

  const handleDeleteClause = async () => {
    console.log("idclause", dataForDeleteClause);
    try {
      // if (userInfo?.userRole[0] === "Application User") {
      //   return toast.error("You are not allowed to delete clause.")
      // }
      if (deleteReason == "") {
        return showToast('Proposal.reasonDeleteClause')
      }
      let data = {
        clauseText: dataForDeleteClause.clause,
        clauseId: dataForDeleteClause.id,
        clauseDocumentId: dataForDeleteClause.dataid,
        reason: deleteReason
      };
      const response = await deleteClause(data);
      if (response.status == 200) {
        showToast('Proposal.clauseDelete')
        console.log("response of delete clause", response);
        getExtractedClause();
        handleGetDeletedClause()
        handleDeleteReasonModalclose()
        console.log("abhay", extractedClause);
      }
    } catch (e) {
      console.log("error in delete");
    }
  };


  const handleGetDeletedClause = async () => {
    try {
      let data = {
        assetId: rfmManagementData.ProposalData.assetid
      };
      const response = await getDeletedClause(data);
      if (response.status == 200) {
        console.log("dddddd", response);
        setDeletedClauseArray(response.data)
        getExtractedClause();
      }
    } catch (e) {
      console.log("error in delete");
    }
  };

  const handleClauseModalProject = async (clause, clauseObjectId, clauseDocumentId, assetId) => {
    setClasueProjectData("")
    setShowEditButton(false)
    try {
      let data = {
        clauseText: clause,
        clauseObjectId,
        clauseDocumentId,
        assetId
      }
      setLoading(true)
      const response = await getClauseProject(data)
      if (response.status === 200) {
        setClauseDataEditable(true)
        console.log("response of get project data", response)
        const clasue = response.data[0]
        const formatDate = (dateStr) => dateStr ? dateStr.split('T')[0] : '';
        setClasueProjectData({
          projectName: clasue.projectName,
          description: clasue.description,
          projectName: clasue.projectName,
          number: clasue.number,
          startDate: formatDate(clasue.startDate),
          endDate: formatDate(clasue.endDate),
          fundingSource: clasue.fundingSource,
          contractType: clasue.contractType,
          projectLocation: clasue.projectLocation
        })
      } else {
        setClauseDataEditable(false)
      }

    } catch (error) {
      console.log("error in fetching clause project data",)
    }
    finally {
      setLoading(false)
    }
    console.log("clause", clause, clauseObjectId, clauseDocumentId, assetId);
    // setIsOpenClauseModal(false);

    setIsClauseProjectModalOpen(true);
    setSelectedClause(clause);
    setSelectedClauseObjectId(clauseObjectId);
    setSelectedClauseDataId(clauseDocumentId);
    setSelectedClauseAssetId(assetId);

  };

  const handleAddProjectClause = (e, fieldName) => {
    const { value } = e.target;
    setClasueProjectData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleCloseClauseProject = () => {
    setIsClauseProjectModalOpen(false);
  };


  const handleAddProjectClauseData = async () => {
    try {
      setLoading(true)
      const data = {
        assetId: selectedClauseAssetId,
        clauseDocumentId: selectedClauseDocumentId,
        clauseText: selectedClause,
        clauseObjectId: selectedClauseObjectId,
        projectName: clauseProjectData.projectName,
        description: clauseProjectData.description,
        number: clauseProjectData.number,
        startDate: clauseProjectData.startDate,
        endDate: clauseProjectData.endDate,
        fundingSource: clauseProjectData.fundingSource,
        contractType: clauseProjectData.contractType,
        projectLocation: clauseProjectData.projectLocation,
      }
      console.log("data to send ", data)
      const response = await addClauseProject(data)
      if (response.status == 200) {
        showToast('Success.dataAdded')
        console.log("response of adding project", response)
        handleCloseClauseProject()
      }
      if (response.status == 400) {
        showToast('Error.errorOccured')
        console.log("response of adding project", response.message)
      }


    } catch (error) {
      console.log("error in addind project details ", error)
    } finally {
      setLoading(false)
    }
  }

  const handleEnableUpdate = () => {
    setClauseDataEditable(false)
    setShowEditButton(true)
  }
  const handleUpdateClauseProject = async () => {

    try {
      const data = {
        clauseText: selectedClause,
        clauseObjectId: selectedClauseObjectId,
        clauseDocumentId: selectedClauseDocumentId,
        assetId: selectedClauseAssetId,
        projectName: clauseProjectData.projectName,
        description: clauseProjectData.description,
        projectName: clauseProjectData.projectName,
        number: clauseProjectData.number,
        startDate: clauseProjectData.startDate,
        endDate: clauseProjectData.endDate,
        fundingSource: clauseProjectData.fundingSource,
        contractType: clauseProjectData.contractType,
        projectLocation: clauseProjectData.projectLocation
      }
      const response = await updateClauseProject(data)
      if (response.status == 200) {
        showToast('Success.dataUpdated')
        getExtractedClause()
        await addActivity(proposalWritingData.assetid, assetType, activityMsg.addClause, userInfo.username)
        handleGetActivity()
      }
      if (response.status === 400) {
        showToast('Error.errorOccured')
      }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    const fetchProposalWritingUpdates = () => {
      handleGetActivity();
      handleGetComment();
      handleGetTypeComment();
    };
    fetchProposalWritingUpdates();

    const intervalId = setInterval(fetchProposalWritingUpdates, 30000);
    return () => clearInterval(intervalId);
  }, []);

  console.log(userInfo, "______userinfor")

  useEffect(() => {
    if (isgetDataEditor === false) {
      setMergeDocColor(false)
    }
  }, [isgetDataEditor])

  const setShowPopup = (assetType, assetsectionId, assetId, taskassignid) => {
    console.log(assetType, assetsectionId, assetId, taskassignid, "kkkkkkk");

    setmainAssetid(assetId);

    setsOpen(assetsectionId);
  };

  const setShowPopupfoApproval = (

    assetType,
    assetsectionId,
    assetId,
    taskassignid,
    taskAssignWith,
    subSectionsData
  ) => {

    console.log(assetType, assetsectionId, assetId, taskassignid, taskAssignWith, subSectionsData, "kkkkkkk");
    setTaskAssignWithData(taskAssignWith)
    setAllSubsectionData(subSectionsData)
    setmainAssetid(assetId);

    setOpenPopup();
    setTaskApprovalOwnerData('')
    setTaskApprovalReceiverData('')
    setOpenJoditPopup(assetsectionId)
  };

  const handleAcceptClose = () => {
    
    setOpen(false);
    setsOpen(false)

    setOpenPopup(false);
  };

  let assignedBy;

  const handleAcceptClick = async (
    assetType,
    assetsectionId,
    assetId,
    taskassigid
  ) => {
    // alert(taskassigid);

    try {
      // console.log(
      //   assetType,
      //   assetsectionId,
      //   mainAssetid,
      //   taskassigid,
      //   allSharedAsset,
      //   "hhhhhhhhhh"
      // );

      const assetSectionId = assetsectionId;

      const assetId = mainAssetid;

      allSharedAsset.forEach((item) => {
        // Check if any object's id matches the assetSectionId

        const matchedSection = item.assetSection.find(
          (section) => section.id === assetSectionId
        );

        if (matchedSection) {
          assignedBy = item;

          // console.log("Found matching section in object:", item);

          // console.log("Matching section:", matchedSection);
        } else {
          console.log("No matching section found in object:", item);
        }
      });

      assignedBy = assignedBy?.sharedBy;

      // console.log(assignedBy, "assignedByassignedBy");

      setAssignbyUser(assignedBy);

      // console.log(assignedBy, "sharedByEmail");

      const status = "accepted";

      const response = await decisionByReceiver(
        assetType,
        assetsectionId,
        assetId,
        taskassigid,
        userInfo.organizationId,
        cuurentUser,
        assignedBy,
        status
      );

      if (response.status == 200) {
        getAssetSectionData();

        setSuccess(true);

        setStatusAccepted(true);
        showToast('Proposal.taskAccept')
      }

      // console.log(response, "rerrererere");
    } catch (error) {
      console.log(error, "errorerror");
    }

    setEditshow(true);

    setAcceptedSections((prev) => [...prev, assetsectionId]);

    setsOpen(false);
  };

  const handleRejectClick = async (
    assetType,
    assetsectionId,
    // assetId,
    taskassigid,
    document
  ) => {
    console.log("trrigerd  of rejcect click ", assetType, assetsectionId, taskassigid, document)
    setsOpen(false);
    // const assetsectionId = sectionId
    const assetId = mainAssetid;

    allSharedAsset.forEach((item) => {
      // Check if any object's id matches the assetsectionId

      const matchedSection = item.assetSection.find(
        (section) => section.id === assetsectionId
      );

      if (matchedSection) {
        assignedBy = item;

        // console.log("Found matching section in object:", item);

        // console.log("Matching section:", matchedSection);
      } else {
        console.log("No matching section found in object:", item);
      }
    });

    assignedBy = assignedBy?.sharedBy;
    console.log("assignedBy@", assignedBy)
    // console.log(assignedBy, "assignedByassignedBy");

    setAssignbyUser(assignedBy);

    // console.log(assignedBy, "sharedByEmail");

    const status = "reject";

    const response = await decisionByReceiver(
      assetType,
      assetsectionId,
      assetId,
      taskassigid,
      userInfo.organizationId,
      cuurentUser,
      assignedBy,
      status
    );

    if (response.status == 200) {
      getAssetSectionData();

      setSuccess(true);

      setStatusAccepted(true);
      showToast('Proposal.taskReject')
    }

  };

  const tasks = taskAssignWithData?.filter(
    (task) => task?.statusofSection === "Task performed"
  );
  // const subSectionTasks = allSubsectionData?.filter(
  //   (task) => task?.statusofSection === "Task performed"
  // );

  console.log(tasks, taskAssignWithData, "taskusers");

  const handleSaveTask = async (
    assetType,
    assetsectionId,
    assetId,
    taskassignwith,
    id,
    htmlContentdata,
    sectionTitle
  ) => {
    // alert("approved manually");
    // Combine both contents in the first editor

    const taskassigid = specifiSectiontaskData.taskassignwith;

    const assignToEmail = specifiSectiontaskData.assignToEmail;

    const link = specifiSectiontaskData.link;

    console.log(taskassignwith, "taskassignwithtaskassignwith");

    console.log(
      assetType,
      assetsectionId,
      assetId,
      taskassignwith,
      htmlContent,
      htmlOwnerContent,
      "asdfgasdfg"
    );

    const status = "approved";

    try {
      const response = await decisionByReceiver(
        assetType,
        assetsectionId,
        assetId,
        taskassigid,
        userInfo.organizationId,
        cuurentUser,
        assignedBy,
        status,
        link,
        assignToEmail,
        htmlOwnerContent
      );

      if (response.status == 200) {
        getAssetSectionData();

        setSuccess(true);

        setStatusAccepted(true);

        setSpecifiSectiontaskData(null);
        showToast('Proposal.taskAcceptManually')
        const activityResponse = await addActivity(
          proposalWritingData.assetid,
          assetType,
          activityMsg.rejectChanges,
          userInfo.username,
          sectionTitle
        );

        handleGetActivity();
      }
    } catch (error) {
      console.log(error);
    }

    setTaskApprovalOwnerData(htmlContent);

    setOpennewPopup(false);
    setOpenPopup(false);
  };


  const handleFinalMerge = async (taskassignwith,subsectioninfo) => {
    // alert("called")
    console.log(taskassignwith, taskApprovalOwnerData, "taskassignwith");
    console.log(subsectioninfo, "subsectioninfosubsectioninfo");
    // const allAccepted = subsectioninfo.every(item => item.acceptanceStatus === true);
    // if(!allAccepted){
    //   return toast.warn("Please check all the corresponding subsections")
    // }
    const assignToEmailArray = taskassignwith.map(k => k.assignToEmail)

    console.log(reviewData, "reviewDatareviewData")
    console.log(assignToEmailArray, "assignToEmailArray")
    const assignByEmail = taskassignwith[0].assignByEmail;
    const data = { assignToEmailArray, assignByEmail, taskApprovalOwnerData };
    console.log(data, "dadadadadad")




    try {
      const response = await decisionByReceiver(
        reviewData?.assetType,
        reviewData?.assetsectionId,
        reviewData?.assetId,
        reviewData?.taskassigid,
        userInfo.organizationId,
        reviewData?.cuurentUser,
        reviewData?.assignedBy,
        reviewData?.status,
        reviewData?.link,
        reviewData?.assignToEmail,
        taskApprovalOwnerData,
        commentMessage
      );

      if (response.status == 200) {
        getAssetSectionData();

        setSuccess(true);
        setStatusAccepted(true);
        setSpecifiSectiontaskData(null);
        showToast('Proposal.taskReview')
        setOpenJoditPopup(false)
      }
    } catch (error) {
      console.log(error);
    }


    // try {
    //   const response = await sendFinalEmail(data);
    //   console.log(response.data);

    //   if (response.status == 200) {

    //     toast.success("Task saved successfully");
    //   } else {
    //     console.log("error in getting response");
    //   }
    // } catch (error) {

    // }

  }

  const handlegetFileNames = (
    name,
    type,
    htmlContent,
    sectionId,
    title,
    assetType,
    taskassignwith,
    submittedlink,
    content,
    assignToEmail,
    link,
    subsectionsData,
    sectionName
  ) => {
    console.log(
      name,
      type,
      htmlContent,
      sectionId,
      title,
      assetType,
      taskassignwith,
      submittedlink,
      content,
      subsectionsData,
      sectionName,
      assignToEmail,
      "linkkk"
    );

    let specificdata = {
      name,
      type,
      htmlContent,
      sectionId,
      title,
      assetType,
      taskassignwith,
      submittedlink,
      content,
      assignToEmail,
      link,
      subsectionsData,
      sectionName
    };

    setSpecifiSectiontaskData(specificdata);



    // const matchedSubsections = subsectionsData
    //   .map(subsection => ({
    //     ...subsection,
    //     taskAcceptedEmail: subsection.taskAcceptedEmail.filter(task => task.email === assignToEmail)
    //   }))
    //   .filter(subsection => subsection.taskAcceptedEmail.length > 0);


    // console.log(matchedSubsections, "matchedSubsections");

    // setSeubsectionDataforApproval(matchedSubsections)



    // const getHighlightedContent = (original, updated) => {
    //   console.log(original, "updatedcontent", updated);
      
    
    //   // Helper function to strip HTML tags
    //   // const stripHtmlTags = (str) => str.replace(/<[^>]*>/g, '');
    //   const stripHtmlTags = (str) => {
    //     if (typeof str !== 'string') {
    //       return ''; // return an empty string if input is not valid
    //     }
    //     return str.replace(/<[^>]*>/g, '');
    //   };
      
    
    //   const strippedOriginal = stripHtmlTags(original);
    //   const strippedUpdated = stripHtmlTags(updated);
    //   console.log(strippedOriginal, "strippedUpdated");
    //   console.log(strippedUpdated, "strippedUpdated");
    //   // Get the diff based on the stripped content
    //   const diff = diffWordsWithSpace(strippedOriginal, strippedUpdated);
    
    //   if (!Array.isArray(diff)) {
    //     return updated;
    //   }
    
    //   let updatedContent = updated; // Copy of the updated content
    
    //   // Function to safely replace a word in the content
    //   const replaceWord = (content, word, replacement) => {
    //     const escapedWord = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escape special chars in word
    //     const regex = new RegExp(`\\b${escapedWord}\\b`, 'g'); // Create regex for the whole word
    //     // return content.replace(regex, replacement); // Replace the word
    //     if (typeof content === 'string') {
    //       content = content.replace(regex, replacement);
    //     } else {
    //       content = ''; // Or handle it in any other way if content is not a string
    //     }
        
    //   };
    
    //   diff.forEach((part, index) => {
    //     const color = part.added
    //       ? "#A4BCFA"
    //       : part.removed
    //         ? "#F8CCAD"
    //         : "#eefaa0";
    
    //     const style = color !== "#eefaa0" ? `background-color: ${color};` : "";
    
    //     if (color !== "#eefaa0") {
    //       // Split part.value into individual words
    //       const words = part.value.split(/\s+/);
    
    //       // Replace each word in the updated content with a <span> tag
    //       words.forEach(word => {
    //         if (word.trim()) { // Ignore empty strings
    //           const replacement = `<span key=${index} style="${style}">${word}</span>`;
    //           updatedContent = replaceWord(updatedContent, word, replacement);
    //         }
    //       });
    //     }
    //   });
    
    //   return updatedContent;
    // };

    const getHighlightedContent = (original, updated) => {
      console.log(original, "updatedcontent", updated);
    
      // Helper function to strip HTML tags
      const stripHtmlTags = (str) => {
        if (typeof str !== 'string') {
          return ''; // return an empty string if input is not valid
        }
        return str.replace(/<[^>]*>/g, '');
      };
    
      const strippedOriginal = stripHtmlTags(original);
      const strippedUpdated = stripHtmlTags(updated);
      console.log(strippedOriginal, "strippedOriginal");
      console.log(strippedUpdated, "strippedUpdated");
    
      // Get the diff based on the stripped content
      const diff = diffWordsWithSpace(strippedOriginal, strippedUpdated);
    
      if (!Array.isArray(diff)) {
        return updated;
      }
    
      let updatedContent = updated; // Copy of the updated content
    
      // Function to safely replace a word in the content
      const replaceWord = (content, word, replacement) => {
        const escapedWord = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escape special chars in word
        const regex = new RegExp(`\\b${escapedWord}\\b`, 'g'); // Create regex for the whole word
        if (typeof content === 'string') {
          return content.replace(regex, replacement); // Return the updated content
        } 
        return ''; // Return an empty string if content is not a string
      };
    
      diff.forEach((part, index) => {
        const color = part.added
          ? "#A4BCFA"
          : part.removed
            ? "#F8CCAD"
            : "#eefaa0";
    
        const style = color !== "#eefaa0" ? `background-color: ${color};` : "";
    
        if (color !== "#eefaa0") {
          // Split part.value into individual words
          const words = part.value.split(/\s+/);
    
          // Replace each word in the updated content with a <span> tag
          words.forEach(word => {
            if (word.trim()) { // Ignore empty strings
              const replacement = `<span key=${index} style="${style}">${word}</span>`;
              updatedContent = replaceWord(updatedContent, word, replacement); // Update content
            }
          });
        }
      });
    
      return updatedContent;
    };
    

    const highlightedContent = getHighlightedContent(htmlContent, content);
    console.log(highlightedContent,"highlightedContent")

    setTaskApprovalOwnerData(htmlContent);

    setTaskApprovalReceiverData(highlightedContent);

    setRawUpdatedContent(content);

    setOpennewPopup(true);

    setAcceptedBothData(htmlContent + content);
  };

  const handlegetSectionContent = async () => {
    // alert("section");
    console.log(specifiSectiontaskData, "SpecifiSectiontaskDatasection")

    const subsectionsData = specifiSectiontaskData?.subsectionsData;
    const htmlContent = specifiSectiontaskData?.htmlContent
    const content = specifiSectiontaskData?.content
    const assignToEmail = specifiSectiontaskData?.assignToEmail

    const matchedSubsections = subsectionsData
      .map(subsection => ({
        ...subsection,
        taskAcceptedEmail: subsection.taskAcceptedEmail.filter(task => task.email === assignToEmail)
      }))
      .filter(subsection => subsection.taskAcceptedEmail.length > 0);


    console.log(matchedSubsections, "matchedSubsections");

    setSeubsectionDataforApproval(matchedSubsections)

    const getHighlightedContent = (original, updated) => {
      console.log(original, updated, "updatedcontent")
      const diff = diffWordsWithSpace(original, updated);

      if (!Array.isArray(diff)) {
        // If diff is not an array, return the updated content as is

        return updated;
      }

      return diff
        .map((part, index) => {
          const color = part.added ? "green" : part.removed ? "red" : "black";

          const style = color !== "black" ? `background-color: ${color};` : "";

          return `<span key=${index} style="${style}">${part.value}</span>`;
        })
        .join("");
    };

    const highlightedContent = getHighlightedContent(htmlContent, content);

    setTaskApprovalOwnerData(htmlContent);

    setTaskApprovalReceiverData(highlightedContent);

    setRawUpdatedContent(content);

    setOpennewPopup(true);

    setAcceptedBothData(htmlContent + content);
  }

  const handlegetSubsectionContent = async (originalContent, updatedContent, subsectionIds) => {
    // alert(subsectionButtons)
    // alert(subsectionIds)

    setSubsectionIdforAcceptance(subsectionIds)
    setSubsectionButtons(true)
    setTaskApprovalOwnerData(originalContent)
    setTaskApprovalReceiverData(updatedContent)
  }


  const handleApprove = async (
    assetType,
    assetsectionId,
    assetId,
    taskassignwith
  ) => {
    console.log(seubsectionDataforApproval, "seubsectionDataforApproval")
    if (seubsectionDataforApproval?.map(k => k.acceptanceStatus === false)) {
      showToast('Proposal.checkSubsection')
    } else {
      //  if (seubsectionDataforApproval?.every(k => k.acceptanceStatus === true)) {


      const taskassigid = specifiSectiontaskData.taskassignwith;
      const assignToEmail = specifiSectiontaskData.assignToEmail;
      const link = specifiSectiontaskData.link;

      console.log(taskassignwith, "taskassignwithtaskassignwith");
      const organizationId = userInfo.organizationId;
      const status = "approved";
      setTaskApproveStatus("approved")
      handleOpenTaskAssignedCommentModal()
      const data = {
        assetType,
        assetsectionId,
        assetId,
        taskassigid,
        organizationId,
        cuurentUser,
        assignedBy,
        status,
        link,
        assignToEmail,
        rawUpdatedContent
      }
      setReviewData(data)

      // try {
      //   const response = await decisionByReceiver(
      //     assetType,
      //     assetsectionId,
      //     assetId,
      //     taskassigid,
      //     userInfo.organizationId,
      //     cuurentUser,
      //     assignedBy,
      //     status,
      //     link,
      //     assignToEmail,
      //     rawUpdatedContent
      //   );

      //   if (response.status == 200) {
      //     getAssetSectionData();

      //     setSuccess(true);
      //     setStatusAccepted(true);
      //     setSpecifiSectiontaskData(null);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }

      // Replace original content with updated content
      setTaskApprovalOwnerData(rawUpdatedContent);
      setOpenPopup(false);
      setOpennewPopup(false);
    }
  };

  const handleAcceptBoth = async (
    assetType,
    assetsectionId,
    assetId,
    taskassignwith
  ) => {
    // Combine both contents in the first editor
    if (seubsectionDataforApproval?.map(k => k.acceptanceStatus == false)) {
      showToast('Proposal.checkSubsection')
    } else {
      // alert("trigger")
      const taskassigid = specifiSectiontaskData.taskassignwith;

      const assignToEmail = specifiSectiontaskData.assignToEmail;

      const link = specifiSectiontaskData.link;

      handleOpenTaskAssignedCommentModal()

      const status = "acceptboth";
      setTaskApproveStatus("acceptboth")
      console.log(taskassignwith, "taskassignwithtaskassignwith");
      const organizationId = userInfo.organizationId;
      const data = {
        assetType,
        assetsectionId,
        assetId,
        taskassigid,
        organizationId,
        cuurentUser,
        assignedBy,
        status,
        link,
        assignToEmail,
        rawUpdatedContent
      }
      setReviewData(data)

      // try {
      //   const response = await decisionByReceiver(
      //     assetType,
      //     assetsectionId,
      //     assetId,
      //     taskassigid,
      //     userInfo.organizationId,
      //     cuurentUser,
      //     assignedBy,
      //     status,
      //     link,
      //     assignToEmail,
      //     acceptedBothData
      //   );

      //   if (response.status == 200) {
      //     getAssetSectionData();

      //     setSuccess(true);

      //     setStatusAccepted(true);

      //     setSpecifiSectiontaskData(null);
      //     toast.success("Task Accepted");
      //   }
      // } catch (error) {
      //   console.log(error);
      // }

      setTaskApprovalOwnerData(taskApprovalOwnerData + taskApprovalReceiverData);

      setOpennewPopup(false);
      setOpenPopup(false);
    }
  };

  const handleRejectTask = async (
    assetType,
    assetsectionId,
    assetId,
    taskassignwith,
    id,
    htmlContent
  ) => {
    console.log( assetType,
      assetsectionId,
      assetId,
      taskassignwith,
      id,
      htmlContent,"checkrejecteddatadata")
    // Combine both contents in the first editor

    if (seubsectionDataforApproval?.map(k => k.acceptanceStatus == false)) {
      showToast('Proposal.checkSubsection')
    } else {

      handleOpenTaskAssignedCommentModal()
      const taskassigid = specifiSectiontaskData.taskassignwith;

      const assignToEmail = specifiSectiontaskData.assignToEmail;

      const link = specifiSectiontaskData.link;



      const status = "rejected";
      setTaskApproveStatus("reject")
      console.log(taskassignwith, "taskassignwithtaskassignwith");
      const organizationId = userInfo.organizationId;
      const data = {
        assetType,
        assetsectionId,
        assetId,
        taskassigid,
        organizationId,
        cuurentUser,
        assignedBy,
        status,
        link,
        assignToEmail,
        rawUpdatedContent
      }
      setReviewData(data)

      // try {
      //   const response = await decisionByReceiver(
      //     assetType,
      //     assetsectionId,
      //     assetId,
      //     taskassigid,
      //     userInfo.organizationId,
      //     cuurentUser,
      //     assignedBy,
      //     status,
      //     link,
      //     assignToEmail,
      //     htmlContent
      //   );

      //   if (response.status == 200) {
      //     getAssetSectionData();

      //     setSuccess(true);

      //     setStatusAccepted(true);

      //     setSpecifiSectiontaskData(null);
      //     toast.success("Task Rejected");
      //   }
      // }
      // catch (error) {
      //   console.log(error);
      // }
      alert()

      setTaskApprovalOwnerData(htmlContent);

      setOpennewPopup(false);
      setOpenPopup(false);
    }
  };

  ///functions for subsections
  const handleApproveSubsection = async (

  ) => {
    // alert(subsectionIdforAcceptance);



    // const singlesubsection = seubsectionDataforApproval?.filter(k => k.subsectionIds == subsectionIdforAcceptance);
    // let data;
    // if (singlesubsection) {
    let data = seubsectionDataforApproval;
    console.log(data, "singlesubsection")
    // }

    const htmlContentoriginal = data.htmlData
    const content = data.taskAcceptedEmail[0].htmlUpdatedData;

    console.log(content, "contentcontent")
    const htmlContent = content
    // alert(htmlContent);
    const status = "approvedSubsection";
    try {
      const response = await decisionByReceiverforSubsection(
        data,
        status,
        htmlContent
      );

      if (response.status == 200) {
        getAssetSectionData();
        showToast('Proposal.taskApprove')
        setSuccess(true);

        setStatusAccepted(true);

        setSpecifiSectiontaskData(null);
        setSubsectionIdforAcceptance(null);

      }
    } catch (error) {
      console.log(error);
    }

    // Replace original content with updated content

    setTaskApprovalOwnerData(rawUpdatedContent);

    setOpenPopup(false);
    setOpennewPopup(false);

  };
  console.log(rfmManagementData, "____rfmManagementData")

  const handleAcceptBothSubsection = async () => {
alert("subsectionboth")
    // const singlesubsection = seubsectionDataforApproval?.filter(k => k.subsectionIds == subsectionIdforAcceptance);
    // let data=seubsectionDataforApproval;
    // if (singlesubsection) {
    let data = seubsectionDataforApproval;
    console.log(data, "singlesubsection")
    // }

    const htmlContentoriginal = data.htmlData
    const changedcontent = data?.taskAcceptedEmail[0]?.htmlUpdatedData;


    const htmlContent = (htmlContentoriginal + changedcontent);
    alert(htmlContent);
    const status = "acceptbothSubsection";
    try {
      const response = await decisionByReceiverforSubsection(
        data,
        status,
        htmlContent
      );
console.log(response,"acceptbothsubsection")
      if (response.status == 200) {
        getAssetSectionData();
        showToast('Proposal.taskApprove')
        setSuccess(true);

        setStatusAccepted(true);

        setSpecifiSectiontaskData(null);
        setSubsectionIdforAcceptance(null)
      }
    } catch (error) {
      console.log(error);
    }

    // Replace original content with updated content

    setTaskApprovalOwnerData(rawUpdatedContent);

    setOpenPopup(false);
    setOpennewPopup(false);

  };

  const handleRejectTaskSubsection = async () => {

    // const status = "rejected";

    // const singlesubsection = seubsectionDataforApproval?.filter(k => k.subsectionIds == subsectionIdforAcceptance);
    // let data;
    // if (singlesubsection) {
    let data = seubsectionDataforApproval;
    console.log(data, "singlesubsection")
    // }

    const htmlContentoriginal = data?.htmlData
    const content = data.taskAcceptedEmail[0].htmlUpdatedData;


    const htmlContent = htmlContentoriginal;
    // alert(htmlContent);
    const status = "rejectedSubsection";
    try {
      const response = await decisionByReceiverforSubsection(
        data,
        status,
        htmlContentoriginal
      );

      if (response.status == 200) {
        getAssetSectionData();
        showToast('Proposal.taskReject')
        setSuccess(true);

        setStatusAccepted(true);

        setSpecifiSectiontaskData(null);
        setSubsectionIdforAcceptance(null)
      }
    } catch (error) {
      console.log(error);
    }

    // Replace original content with updated content

    setTaskApprovalOwnerData(rawUpdatedContent);

    setOpenPopup(false);
    setOpennewPopup(false);


  };

  const getaalassetsharings = async () => {
    try {
      const response = await getAllassetsharing();

      // console.log(response, "asdfghjk");

      setallSharedAsset(response.data);
    } catch (error) {
      console.log(error, "errorerror");
    }
  };
  useEffect(() => {
    getaalassetsharings();
  }, []);

  const getTaskAssignments = async () => {
    try {
      const response = await getAllTaskAssignments();

      // console.log(response, "responseresponse");

      setallTaskAssignments(response.data);
    } catch (error) {
      console.log(error, "errorerror");
    }
  };

  useEffect(() => {
    getTaskAssignments();
  }, []);

  const handleSubmitteTask = async (id) => {
    // console.log(particularSection, "taskassigniddd");

    if(!saveClicked){
      toast.warn('Please save the data before submitting')
      return
    }

    // console.log(id, "idid");

    // alert(assetsectionId);

    // alert("checkingsubmission");

    const workflowName = `Task Assignment`;

    const fromStageName = `Open`;

    const toStageName = `Open`;

    const FromStatusName = `Accepted`;

    const ToStatusName = `Task Submitted`;

    const orgId = userInfo.organizationId;

    const assetId = mainAssetid;

    const assetSectionId = assetsectionId;

    const assetType = particularSection.assetType;

    const assignedTo = cuurentUser;

    const taskassigid = particularSection.taskassignids;

    const link = particularSection?.name;

    const status = "submit";

    allSharedAsset.forEach((item) => {
      // Check if any object's id matches the assetSectionId

      const matchedSection = item.assetSection.find(
        (section) => section.id === assetSectionId
      );

      if (matchedSection) {
        assignedBy = item;

        // console.log("Found matching section in object:", item);

        // console.log("Matching section:", matchedSection);
      } else {
        // console.log("No matching section found in object:", item);
      }
    });

    assignedBy = assignedBy?.sharedBy;

    // console.log(assignedBy, "sharedByEmail");

    // console.log(assetType, assignedBy, taskassigid, "kalpesh");

    try {
      const response = await decisionByReceiver(
        assetType,
        assetsectionId,
        assetId,
        taskassigid,
        userInfo.organizationId,
        cuurentUser,
        assignedBy,
        status,
        link
      );

      // console.log(response, "=============");

      if (response) {
        // console.log(response?.data, "rerrerererecheck");

        setTaskDetail(response?.data);

        getAssetSectionData();

        getTaskAssignments();
        showToast('Proposal.taskSubmit')
      }
    } catch (error) {
      // console.log(error, "responseerror");
    }

    setSubmittedStatus(true);

    setIsgetDataEditor(false);
  };




  const handleOpenResourceAllocation = async (id) => {
    const UserEmail = sessionStorage.getItem("currentUserEmail")
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };



    // if(!isAdmin){

    const response = await validateFunctionalityAccess(data);
    if (response.status === 403 && response.isFinanceTeamUser) {
      setAssetId(id);
      setOpenResourceAllocation(true)
    } else {
      showToast('Error.access')
    }
  }



  const handleCloseResourceAllocation = () => {
    setOpenResourceAllocation(false);
    setAssetId(null);
  };

  const handleAcceptClause = async (manualClauseId, assetid, extractedClauseid) => {

    try {
      let data = {
        assetId: assetid,
        extractedClauseId: extractedClauseid,
        email: cuurentUser,
        clauseId: manualClauseId

      }
      const response = await approveClause(data)
      if (response.status == 200) {
        getExtractedClause()
        console.log(response, "Response of approved clause")
      }
    } catch (error) {
      console.log(error)
    }
  }


  const handleRejectClause = async (manualClauseId, assetid, extractedClauseid) => {

    try {
      let data = {
        assetId: assetid,
        extractedClauseId: extractedClauseid,
        email: cuurentUser,
        clauseId: manualClauseId
      }
      const response = await rejectClause(data)
      if (response.status == 200) {
        console.log(response, "Response of approved clause")
        getExtractedClause()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteReasonModalOpen = (id, clause, dataid) => {
    console.log(id, clause, dataid, "VVVV")
    setDataForDeleteClause({
      id,
      clause,
      dataid
    });
    setIsOpenDeleteReasonModel(true);
  };
  const handleDeleteReasonModalclose = () => {
    setIsOpenDeleteReasonModel(false);
    setDeleteReason("");
  };

  const handleAddReasonModalOpen = () => {
    if (addManualClause == "") {
      return showToast('Proposal.provideClause')
    }
    // Extract existing clauses from clauses and manualClause
    const existingClauses = new Set();
    if (extractedClause?.clauses?.length) {
      extractedClause.clauses.forEach(clause => {
        clause.responseData.split("\n").forEach(c => existingClauses.add(c.trim()));
      });
    }
    if (extractedClause?.manualClause?.length) {
      extractedClause.manualClause.forEach(clause => {
        existingClauses.add(clause.clause.trim());
      });
    }

    // Check if the addManualClause already exists
    if (existingClauses.has(addManualClause.trim())) {
      return showToast('Proposal.clauseExist')
    }
    setIsOpenAddReasonModel(true);
  };
  const handleAddReasonModalclose = () => {
    setIsOpenAddReasonModel(false);
    setAddReason("");
  };

  console.log(dataForDeleteClause, "dataForDeleteClause")
  console.log(isOpenDeleteReasonModel, "isOpenDeleteReasonModel")

  const handleRowSelect = (row) => {
    setSelectedRow(row);
  };

  const handleSave = (rowId) => {
    setDummy(prevDummy =>
      prevDummy.map(row =>
        row.requirement_id === rowId
          ? { ...row, requirement_text: editedRequirementText }
          : row
      )
    );
    showToast('Success.dataSaved')
    setEditableRow(null);
  };
  const handleEdit = (rowId, requirementText) => {
    setEditableRow(rowId);
    setEditedRequirementText(requirementText);
  };

  const [selectedSectionMap, setSelectedSectionMap] = useState()
  const handleSelectSectionChange = (selectedOptions, requirementId) => {
    setSelectedSectionMap(prevSelected => ({
      ...prevSelected,
      [requirementId]: selectedOptions
    }));

    const selectedSection = selectedOptions.map(option => option.value);

    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        return {
          ...row,
          manualSection: selectedSection,
        };
      }
      return row;
    });
    // console.log("updatedDummy",updatedDummy)
    setDummy(updatedDummy);
    // setDummy1(updatedDummy);

  };

  const handleSelectClauseChange = (selectedOptions, requirementId) => {
    setselectedClauseResources(prevSelected => ({
      ...prevSelected,
      [requirementId]: selectedOptions
    }));

    const selectedClauses = selectedOptions.map(option => option.value);

    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        return {
          ...row,
          manualClauses: selectedClauses,
        };
      }
      return row;
    });
    // console.log("updatedDummy",updatedDummy)
    setDummy(updatedDummy);
    // setDummy1(updatedDummy);

  };

  const handleResourcesChange = (selectedOptions, requirementId) => {
    console.log("5432  ", selectedOptions)

    const selected = selectedOptions || [];
    setSelectedResources(prevState => ({
      ...prevState,
      [requirementId]: selected
    }));

    const payRates = selected.reduce((acc, resource) => {
      const resourceOption = resourceOptions.find(option => option.value === resource.value);
      if (resourceOption) {
        acc[resource.value] = resourceOption.payperhour
      }
      return acc;
    }, {});

    setResourcePayRates(prevState => ({
      ...prevState,
      [requirementId]: payRates
    }));

    const hours = effectiveHours[requirementId] || 0;
    const totalAmount = selected.reduce((sum, resource) => {
      const payRate = payRates[resource.value] || 0;
      const totalHours = hours / selected.length;
      return sum + (payRate * totalHours);
    }, 0);

    setTotalBillingAmounts(prevState => ({
      ...prevState,
      [requirementId]: totalAmount
    }));

    const selectedResource = selected.map((option) => {
      return {
        label: option.label,
        value: option.value
      }
    })

    const resourceDetails = selected.reduce((acc, option) => {
      const resourceOption = resourceOptions.find(res => res.value === option.value);
      if (resourceOption) {
        acc.push({
          name: option.label,
          id: option.value,
          rate: resourceOption.payperhour
        });
      }
      return acc;
    }, []);
    console.log("ZZX", "selectedResource:", selectedResource, "totalAmount:", totalAmount, "payrate:", payRates)
    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        console.log("resourceDetails12 ", resourceDetails)
        return {
          ...row,
          Resources: selectedResource,
          totalAmount: totalAmount,
          payRates: resourceDetails, // Store as an object
        };
      }
      return row;
    });
    // console.log("updatedDummynew",updatedDummy)
    setDummy(updatedDummy);
  };
  // const handleEffectiveHoursChange = (e, requirementId) => {
  //   const hours = e.target.value;
  //   setEffectiveHours(prevState => ({
  //     ...prevState,
  //     [requirementId]: hours
  //   }));

  //   const selected = selectedResources[requirementId] || [];
  //   const payRates = selected.reduce((acc, resource) => {
  //     const resourceOption = resourceOptions.find(option => option.value === resource.value);
  //     if (resourceOption) {
  //       acc[resource.value] = resourceOption.payperhour;
  //     }
  //     return acc;
  //   }, {});

  //   const totalAmount = selected.reduce((sum, resource) => {
  //     const payRate = payRates[resource.value] || 0;
  //     const totalHours = hours / selected.length;
  //     return sum + (payRate * totalHours);
  //   }, 0);

  //   setTotalBillingAmounts(prevState => ({
  //     ...prevState,
  //     [requirementId]: totalAmount
  //   }));



  //   const updatedDummy = dummy.map((row) => {
  //     if (row.requirement_id === requirementId) {
  //       return {
  //         ...row,
  //         hours: hours,
  //         totalAmount: totalAmount,
  //       };
  //     }
  //     return row;
  //   });
  //   // console.log("updatedDummynew22",updatedDummy)
  //   setDummy(updatedDummy);

  //   console.log("ZZX1", "hours:", hours, "payRates:", payRates, "totalAmount:", totalAmount)
  // };

  const calculateTotalBillingAmount = (requirementId, resources, hours, resourcePayRates) => {
    return resources.reduce((sum, resource) => {
      const payRate = resourcePayRates[requirementId][resource.value] || 0;
      return sum + (payRate * hours);
    }, 0);
  };
  const handleEffectiveHoursChange = (e, requirementId) => {
    const hours = e.target.value;
    setEffectiveHours(prevState => ({
      ...prevState,
      [requirementId]: hours
    }));
    const selected = selectedResources[requirementId] || [];
    const totalAmount = calculateTotalBillingAmount(requirementId, selected, hours, resourcePayRates);
    setTotalBillingAmounts(prevState => ({
      ...prevState,
      [requirementId]: totalAmount
    }));
    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        return {
          ...row,
          hours: hours,
          totalAmount: totalAmount,
        };
      }
      return row;
    });
    setDummy(updatedDummy);
  };

  const handleRemarksChange = (e, requirementId) => {
    const remark = e.target.value;
    setRemarks(prevState => ({
      ...prevState,
      [requirementId]: remark
    }));

    const updatedDummy = dummy.map((row) => {
      if (row.requirement_id === requirementId) {
        return {
          ...row,
          remark
        };
      }
      return row;
    });
    setDummy(updatedDummy);
  };
  const openModalCustom = () => {
    setCheckValidation(true);
    checkYellowOrRedRows(dummy);
  };
  const closeModalCustom = () => {
    setIsModalOpenCustom(false);

  };

  const checkYellowOrRedRows = (updatedDummy) => {
    const hasYellowOrRed = updatedDummy.some(row => (
      (row.manualClauses && row.manualClauses.length > 0) ||
      (!row.section_title || row.clauses.includes("undefined")) ||
      (row.manualSection && row.manualSection.length > 0)
    ));
    setHasYellowOrRedRows(hasYellowOrRed);

    if (hasYellowOrRed) {
      setIsModalOpenCustom(true);
    } else {
      handleSubmitRequirements()
    }
  };
  useEffect(() => {
    fetchClausesList()
  }, []);

  const fetchClausesList = async () => {
    const response = await getClausesList()
    const processClauses = (clauses) => {
      return clauses.map(clause => clause.split('#')[1]);
    };
    // console.log("fetchClausesList",response)
    setFarClauses(processClauses(response?.farClauses));
    setDfarClauses(processClauses(response?.dfarClauses));

  }
  const combinedClauses = [
    ...farClauses.map(clause => ({ value: clause, label: clause })),
    ...dfarClauses.map(clause => ({ value: clause, label: clause }))
  ];

  const sectionDummyData = [
    { label: "Section A", value: "Section_A" },
    { label: "Section B", value: "Section_B" },
    { label: "Section C", value: "Section_C" },
  ];

  const handleOpenDownloadMappingModal = () => {
    setShowDownloadMappingModal(true);
  };
  const handleDownloadMappingModalClose = () => {
    setShowDownloadMappingModal(false);
  };

  const options = keywords.map((keyword) => ({
    value: keyword.keyword,
    label: keyword.keyword
  }));

  const handleSubmitRequirements = async () => {
    try {

  
      console.log("dummyDataBefore ",dummy)
      
      let data = {
        assetId: proposalWritingData.assetid,
        dummyData: dummy,
        orgId: userInfo.organizationId,
        submitedBy: userInfo.username,
        opportunityid: rfmManagementData.value.id,
        userId: userInfo._id
      }
      const response = await mappedReuirementClause(data)
      if (response.status == 200) {
        toast.success(response.message)
        closeAttachmentModal()
        setIsModalOpenCustom(false)
        console.log("response of mappedReuirementClause", response.data)

        let description = "empty";
        let title = "Cost and Estimation";
        let assetType = "proposal";

        const isDeleted = await deleteExistingSections(data)
        console.log("isDeleted", isDeleted)


        const response1 = await addAssetsection(
          proposalWritingData.assetid,
          description,
          title,
          assetType,
          "Ai-generated",
          userInfo.organizationId,
          userInfo._id
        );
        // console.log("response1",response1)
        const sectionId = response1.data._id


        const { totalResources, totalHours, totalAmount } = calculateAggregates(dummy);

        let text = `Total number of resources - ${totalResources}, \nTotal number of hours - ${totalHours} hrs, \nTotal Billing Amount - $${totalAmount}`;
        let htmlText = text.replace(/\n/g, '<br>');
        // console.log("Check now if ",dummy)
        const newText = generateTableHTML(htmlText, dummy)
        console.log("bbbc", newText)
        setHtmlContent(newText)
        const check = handleAssetsectionuploadReq(sectionId, newText,title)
        console.log("Check value", check)




      }
    } catch (error) {
      console.log("error in submiting requirement", error)
    }
  }


  const handleSendData = async () => {
    if (selectedRow) {
      setLoading(true)
      const dataToSend = {
        requirementData: requirementDataForSuggestion,
        requirementText: selectedRow.requirement_text,
        sectionTitle: selectedRow.section_title
      };

      const response = await keywordForSamrtSuggestion(dataToSend);
      if (response.status == 200) {
        console.log("keywords___", response.data);
        const parsedKeywords = parseKeywords(response.data[0]);
        setKeywords(parsedKeywords)
        setLoading(false)
      }
    } else {
      showToast('Proposal.selectRow')
    }
    setLoading(false)
  };

  const deleteExistingSections = async (data) => {
    try {
      const response = await deleteExisting(data);
      if (response.status === 200) {
        return response;
      } else {
        console.log("Failed to delete existing sections.");
        return null;
      }
    } catch (error) {
      console.log("error in deleting existing section", error);
      return null;
    }
  };

  const calculateAggregates = (dummy) => {
    let totalResources = 0;
    let totalHours = 0;
    let totalAmount = 0;
    console.log("Aggregate", dummy)
    dummy.forEach(item => {
      // Check if Resources is an array and sum the number of resources
      if (Array.isArray(item.Resources)) {
        totalResources += item.Resources.length;
      }

      if (item.hours) {
        let hours = parseFloat(item.hours);
        if (!isNaN(hours)) {
          totalHours += hours;
        } else {
          console.log("Invalid hours value:", item.hours);
        }
      }

      // Check and sum the amount
      if (item.totalAmount) {
        let amount = parseFloat(item.totalAmount);
        if (!isNaN(amount)) {
          totalAmount += amount;
        } else {
          console.log("Invalid amount value:", item.totalAmount);
        }
      }
    });

    return {
      totalResources,
      totalHours,
      totalAmount
    };
  }

  const parseKeywords = (keywordsString) => {
    const keywordsArray = keywordsString.split('\n')
      .map(keyword => keyword.trim()) // Trim whitespace
      .filter(keyword => keyword.length > 0); // Remove empty lines

    // Remove number prefix from the first element if it exists
    if (keywordsArray.length > 0) {
      const firstKeyword = keywordsArray[0].replace(/^\d+\.\s*/, ''); // Remove number and dot at the start
      keywordsArray[0] = firstKeyword;
    }

    // Map to array of objects with id and keyword
    const parsedKeywords = keywordsArray.map((keyword, index) => ({
      id: index + 1,
      keyword
    }));

    return parsedKeywords;
  };

  const handleCloseDiv = () => {
    setKeywordData('');
    setKeywords([])
  };


  useEffect(() => {

    fetchResources();
  }, []);

  async function fetchResources() {
    try {
      const assetId = proposalWritingData.assetid;
      console.log('Asset ID:', assetId);

      if (!orgId || !assetId) {
        console.error('orgId and assetId are required.');
        return;
      }

      const response = await getresourceallocation(assetId, orgId);
      const data = response.data;
      const options = data.map(resource => ({
        value: resource._id,
        label: resource.resourceFullName,
        payperhour: resource.payPerHour
      }));
      setResourceOptions(options);
      console.log(resourceOptions, "resourceOptions")
    } catch (error) {
      console.error('Error fetching resource allocation:', error.message);
    }
  }
  const fetchRequirementMappingHistory = async () => {
    try {
      const assetId = proposalWritingData.assetid;



      if (!orgId || !assetId) {
        console.error('orgId and assetId are required.');
        return;
      }

      const response = await getRequirementMapping(assetId, orgId);
      const data = response?.requirements;
      setRequirementMappingHistory(data)


    } catch (error) {
      console.error('Error fetching requirement mapping history:', error.message);
    }
  }

  useEffect(() => {
    if (requirementMappingHistory.length > 0) {
      setRequirementMappingHistoryExists(true)
    }
  }, [requirementMappingHistory])




  // Function to generate HTML for the table
const generateTableHTML = (text, dummym) => {
  return (
    `<p>${text}</p>
    <table border="1">
      <thead>
        <tr>
          <th>Requirement ID</th>
          <th>Filename</th>
          <th>Requirement Description</th>
          <th>Mapped Section in Proposal Template</th>
          <th>Clauses</th>
          <th>Resources</th>
          <th>Hourly Pay</th>
          <th>Effective Hours</th>
          <th>Total Billing Amount</th>
          <th>Remarks</th>
        </tr>
      </thead>
      <tbody>
        ${dummym.map(row => (
      `<tr key="${row._id}">
          <td>${row.requirement_id || '-'}</td>
          <td>${row.filename || '-'}</td>
          <td>${row.requirement_text || '-'}</td>
          <td>${row.section_title || '-'} <br> ${row?.manualSection?.length > 0 ? row.manualSection.join('<br>') : 'None'}</td>
          <td>${row.clauses && row.clauses !== "undefined" ? row.clauses : '-'} <br> ${row?.manualClauses?.length > 0 ? row.manualClauses.join('<br>') : 'None'}</td>
          <td>${Array.isArray(row.Resources) && row.Resources.length > 0 ? row.Resources.map(resource => `${resource.label}`).join('<br>') : '-'}</td>
          <td>${Array.isArray(row.payRates) && row.payRates.length > 0 ? row.payRates.map(rate => `${rate.name}: ${rate.rate}/hr`).join('<br>') : '-'}</td>
          <td>${row.hours !== undefined ? row.hours : '-'}</td>
          <td>${row.totalAmount !== undefined ? `$${row.totalAmount}` : '-'}</td>
          <td>${row.remarks || '-'}</td>
        </tr>`
    )).join('')}
      </tbody>
    </table>`
  );
};


  const selectedOption = options?.find(option => option.value === selectedKeyword);



  const handleBackFromEditor = () => {
    setIsgetDataEditor(false)
    setShowBtnInMerge(false)
    setIsEditorReadOnly(false)
    setInAttachmentSection(false)
    setSubmitButtonShow(false)
  }



  const handleSectionTitle = async () => {
    const UserEmail = sessionStorage.getItem("currentUserEmail");
    const data = {
      assetId: proposalWritingData.assetid,
      userEmail: UserEmail,
      assetType: "proposal"
    };
    if (showBtnInMerge) {
      // If showBtnInMerge is true, give access without validation
      showToast('Proposal.accessEdit')
      return

    } else {
      // Validate functionality access
      const response = await validateFunctionalityAccess(data);


      if (response.status === 200) {
        if (sectionTitle == "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessEdit')
          return

        } else if (sectionTitle !== "Cost and Estimation" && !response.isFinanceTeamUser) {


        }

      }
      else if (response.status === 403 && response.isFinanceTeamUser) {
        if (sectionTitle !== "Cost and Estimation" && response.isFinanceTeamUser) {
          showToast('Proposal.accessEdit')
          return
        }
        else if (sectionTitle == "Cost and Estimation" && response.isFinanceTeamUser) {
          showToast('Proposal.accessEdit')
          return
        }
      }

      else {
        showToast('Proposal.accessEdit')
        return

      }
    }


    seteditSectionTitle(true)
  }
  const formatDeadline = (deadline) => {
    return new Date(deadline).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };


  const getTaskCompletionOnpageLoad = async () => {
    try {
      let data = {
        assetId: proposalWritingData?.assetid,
        orgId: sessionStorage.getItem("organizationId")
      };
      const response = await getTeamTask(data);
      if (response.status == 200) {
        setTeamTasks(response.data)
        console.log("getTaskCompletionOnpageLoad", response)
        if (response.data.deadLine) {
          const deadline = new Date(response?.data?.deadLine).getTime();
          const currentData = Date.now();
          const timeDifference = deadline - currentData;
          const hoursDifference = timeDifference / (1000 * 60 * 60);
          console.log("deadline", deadline)
          setDeadLineProposal(formatDeadline(response.data.deadLine));
          if (hoursDifference < 24) {
            let data = {
              assetId: proposalWritingData?.assetid,
              orgId: sessionStorage.getItem("organizationId"),
              teamDetailsObjectId: response.data._id,
              loggedInEmail: sessionStorage.getItem("currentUserEmail"),
              deadLine: deadline
            }
            const checkifSent = await isNotificationSent(data)
            console.log("checkifSent", checkifSent)
            if (checkifSent?.status == 200 && checkifSent?.data?.notificationSent == false) {
              const send = await sentNotitfication(data)
              console.log("send", send)
            }
          }

        }

      } else {
        // toast.error("Error in fetching tasks. Please try again.");
      }

    } catch (error) {
      console.log(error);
      showToast('Error.errorOccured')
    }
  }

  useEffect(() => {
    const hasProposalSectionCreation = teamTasks?.teamTasks?.some(task => task.task === "Proposal-Section creation");
    setIsBlueTeam(hasProposalSectionCreation);

  }, [teamTasks])

  function checkDocumentPaths(data) {
    for (let item of data) {
      if (item.htmlContent.startsWith("<p>Electronic signatures of submitted users:</p>")) {
        return true;
      }
      if (item.documentpath[0].length === 0 && item.documentpathforReceiver[0].length === 0) {
        return false;
      }
    }
    return true;
  }




  useEffect(() => {
    const updateDummyState = () => {
      const updatedDummy = dummy.map(item => {
        if (resourcePayRates[item.requirement_id]) {
          return {
            ...item,
            payRates: item.payRates.map(rate => {
              const newRate = resourcePayRates[item.requirement_id][rate.id];
              if (newRate !== undefined) {
                return {
                  ...rate,
                  rate: newRate
                };
              }
              return rate;
            })
          };
        }
        return item;
      });

      console.log("dummys23", JSON.stringify(updatedDummy, null, 2));

      setDummy(updatedDummy);
    };

    updateDummyState();
    console.log("resourcePayRates23", JSON.stringify(resourcePayRates, null, 2));

  }, [resourcePayRates]);













  useEffect(() => {



    checkUserForBlueTeam();
  }, []);


  const checkUserForBlueTeam = async () => {


    const userEmail = sessionStorage.getItem("currentUserEmail")
    const assetId = proposalWritingData.assetid;

    let data = {
      userEmail,
      assetId
    }

    try {
      const result = await isFirstTeamUser(data);

      if (result.status == 200) {
        setFirstTeamUser(result.isFirstTeamUser)
      }



    } catch (error) {

      console.log("Error finding blue team member")
    }
  };



  const proposalSubmitClick = () => {
    if (!allTaskCompleted) {
     showToast('Proposal.taskPending')
      return
    }
    if (!checkDocumentPaths(mergeSectionData)) {
      // alert("Please fill all the section content");

      showToast('Proposal.fillAllSection')
      return setIsSignatureModalOpen(false)
    }
    setIsSignatureModalOpen(true)
  }


  const proposalTerminateClick = async () => {
    setTerminateProjectModal(true)
  }
  const proposalTerminateClose = async () => {
    setTerminateProjectModal(false)
  }

  const handleTerminateReason = (reason) => {
   
    setTerminateReason(reason); // Store the selected reason from the modal
  };

  // Function to terminate the project with the selected reason
  const proposalTerminate = async (reason) => {

    try {
      setLoading(true);
      let data = {
        assetId: rfmManagementData.ProposalData.assetid,
        reason: reason, // Pass the selected reason in the request
      };

      // Call your backend API here
      const response = await terminateProject(data);
      // let response; // Mocked response

      if (response && response.status === 200) {
        showToast('Proposal.projectTerminate');
        history.push("/OppourtunityManagementSubadmin");
      } else {
        showToast('Error.errorOccured');
      }
    } catch (error) {
      console.log(error);
      showToast('Error.errorOccured');
    } finally {
      setLoading(false);
    }
  };

  const getTaskCompletion = async () => {
    try {
      let data = {
        assetId: rfmManagementData.ProposalData.assetid,
        orgId
      };
      const response = await getTeamTask(data);
      if (response.status == 200) {
        setTeamTasks22(response.data)
        setTaskCompletionModal(true)
      } else {
        showToast('Error.errorOccured')
      }

    } catch (error) {
      console.log(error);
      showToast('Error.errorOccured')
    }
  }

  const handleTaskCompletion = async (updatedTasks) => {

    // Update the status of "Terminate" tasks directly in the array
    console.log("tttt  ", updatedTasks)
    updatedTasks.forEach(task => {
      if (task.task === "Terminate" && task.Status === false) {
        task.Status = true;
      }
    });


    if (!sendBackButtonClicked) {


      const allTasksCompleted = updatedTasks
        .every(task => task.Status === true);





      if (!allTasksCompleted) {
        showToast('Proposal.allTaskComplete')
        return;
      }
    }
    console.log("ttt2222222  ", updatedTasks)


    try {
      let data = {
        assetId: rfmManagementData.ProposalData.assetid,
        orgId,
        taskStatus: updatedTasks,
        teamDetailsObjectId: teamTasks22._id
      };
      const response = await updateTeamTask(data);
      if (response.status == 200) {
        sendBackButtonClicked ? getPreviousTeams() : handleOpenTeamModal();
        showToast('Proposal.taskComplete')
      } else {
        showToast('Error.errorOccured')
      }

    } catch (error) {
      console.log(error);
      showToast('Error.errorOccured')
    }
    closeTaskCompletionModal()
  }






  const handleProposalSubmit = async () => {
    try {
      console.log(mergeSectionData, "mergeSectionDatamergeSectionData")
      const allContentForDocumentpath = mergeSectionData?.map(k => k.documentpath)
      const allContentFordocumentpathforReceiver = mergeSectionData?.map(k => k.allContentForDocumentpath)
      console.log(allContentForDocumentpath, "allContentForDocumentpath")
      console.log(allContentFordocumentpathforReceiver, "allContentFordocumentpathforReceiver")



      const currentAssetId = rfmManagementData.ProposalData.assetid
      console.log(currentAssetId, "currentAssetId")
      const data = {
        assetId: currentAssetId,
        deadLine, mergeSectionData,
        userEmail: sessionStorage.getItem("currentUserEmail")
      }
      const addSignature = await addElectronicSignatureSection(signaturePayload)
      const response = await submitProposal(data)
      console.log(response.data, "proposalresponse")
      if (response.status == 404) {
        showToast('Error.errorOccured')
        handleCloseTeamModal()
      }
      else if (response.data == "") {
        setFinalProposal(true)
        auditHistoryAddition("Submitted", "NIL", teamTasks.teamName)
      }
      else {
        auditHistoryAddition("Submitted", "NIL", teamTasks.teamName)
        setSignaturePayload([])
        history.push("/OppourtunityManagementSubadmin");
        if (isLastStage) {
          showToast('Proposal.proposalCompleted')

        } else {
          showToast('Proposal.proposalSubmit2')

        }
      }
      // console.log(response,"resssss-")
      // if(response.status==200){
      //   history.push("/OppourtunityManagementSubadmin");
      // toast.success("Proposal submitted successfully.");
      // }

    } catch (error) {
      console.log("error in submitting proposal:", error)
      showToast('Proposal.errProposalSubmit')
    }

  };

  const handleStartDate = (date) => {
    setStartDate(date)
  }
  const handleEndDate = (date) => {
    setEndDate(date)
  }


  const handleOpenTeamModal = () => {
    if (isLastStage) {
      handleProposalSubmit()
    } else {
      setIsTeamModalState(true)

    }
  }

  const handleCloseTeamModal = () => {
    setIsTeamModalState(false)
  }

  const handleTeamDeadLine = (value) => {
    setDeadLne(value)
  }
  const handleTeamSubmit = async () => {
    // alert("submitdeadline")
    try {
      if (!deadLine) {
        return showToast('Proposal.checkDeadline')
      }
      handleProposalSubmit()


    } catch (e) {
      console.log(e)
    }
  }






  const handleDefaultFile = async () => {
    // const resourceLinks = "../../public/DOT_171847Right of Way (Row)sys_rfpV4 10242017.pdf";

    // try {
    //   const pdfFile = await fetch(resourceLinks)
    //    setIspdfFile(pdfFile)
    //    setIsCanContinue(true)
    //   ;}
    //   catch(error){
    //     console.log("error in fetching ",error)
    //   }
  }


  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };




  const fetchTemplate = async () => {
    let data = {
      path: "titlePages/Building1/"
    }
    try {
      const response = await getS3Data(data)
      if (response.status == 200) {
        console.log("response of full proposal", response)
        setFullProposalvrushabh(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {

    fetchTemplate();
  }, [])

  const handleCoverPreview = async (template) => {
    try {
      let fileExtension = template?.split('.').pop().toLowerCase();
      const data = {
        filename: template,
        folderPath: "titlePages/Building1",
      };

      console.log("check2", data);
      const docxContent = await getCoverPages(data);
      // alert("hit")
      console.log("fetchDataFile", docxContent);
      console.log(fileExtension, "docFormat");

      // Assuming docxContent is a Blob with application/octet-stream
      const blob = new Blob([docxContent], { type: "application/octet-stream" });
      console.log(blob, "blob");

      // Convert Blob to text to check its content
      const textContent = await blob.text();
      console.log(textContent, "textContent");

      if (fileExtension === "html") {
        // Create a new Blob with the correct MIME type for HTML
        const htmlBlob = new Blob([textContent], { type: "text/html" });
        const url = URL.createObjectURL(htmlBlob);
        console.log(url, "url");
        setHTMLPreview("")
        setFilePreviewEditor("")
        // Update state to set the file URL and open the preview
        setFileURL(url);
        setIsFilePreviewOpen(true);

      } else {
        // Handle other types of content or file extensions if needed
        const url = URL.createObjectURL(blob);
        console.log(url, "url");
        setFileURL(url);
        setIsFilePreviewOpen(true);
      }

    } catch (error) {
      console.log(error);
    }
  }



  const replacePlaceholders = (htmlContent, orgData) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Define the mapping from class names to orgData fields
    const fieldMapping = {
      'yc': 'orgName',
      'yd': 'address',  // Address1
      'yf': 'city',
      'yf': 'state',
      'y10': 'postalCode',
      'y11': 'phoneNumber',
      'y13': 'fax',
      'y14': 'domain'
    };
    const replacedPlaceholders = new Set();

    Object.keys(fieldMapping).forEach(className => {
      const elements = doc.querySelectorAll(`div.t.m0.${className}`);
      elements.forEach(element => {
        const fieldName = fieldMapping[className];
        let fieldValue = (fieldName === 'orgName') ? orgData[fieldName] : orgData.orgData[fieldName];

        if (fieldValue) {
          // Replace the inner HTML with the corresponding orgData value
          if (!replacedPlaceholders.has(fieldName)) {
            element.innerHTML = fieldValue;
            replacedPlaceholders.add(fieldName);
          } else {
            // If the field has already been replaced, remove the duplicate element
            element.remove();
          }
        } else {
          // Remove the entire placeholder div if there is no corresponding orgData value
          element.remove();
        }
      });
    });

    // Handle remaining placeholders without corresponding classes
    const placeholders = doc.querySelectorAll('div.t.m0');
    placeholders.forEach(element => {
      if (element.innerHTML.includes('&lt;&lt;') && element.innerHTML.includes('&gt;&gt;')) {
        // Remove placeholders with no corresponding value
        element.remove();
      }
    });

    // Serialize the updated DOM back to an HTML string
    const updatedContent = new XMLSerializer().serializeToString(doc);
    return updatedContent;
  };


  const handleCoverClick = async (template) => {
    try {
      const orgId = sessionStorage.getItem("organizationId");
      const org = await getorgdata(orgId);

      if (org.status !== 200) {
        setErrorMessage(org.error);
        setShowErrorPopup(true);
        return;
      }

      let fileExtension = template?.split('.').pop().toLowerCase();
      const data = {
        filename: template,
        folderPath: "titlePages/Building1",
      };

      const docxContent = await getCoverPages(data);

      const reader = new FileReader();
      reader.onload = function (event) {
        let htmlContent = event.target.result;

        // Parse the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        // Extract and clean styles
        const styleElements = doc.querySelectorAll('style');
        let pageStyles = '';
        styleElements.forEach(style => {
          style.textContent = style.textContent.replace(/background-color:\s*#[0-9a-fA-F]{6};?/g, '');
          pageStyles += style.outerHTML;
        });

        // Remove inline styles that set the grey background
        const inlineStyledElements = doc.querySelectorAll('[style*="background-color"]');
        inlineStyledElements.forEach(el => {
          el.style.backgroundColor = '';
        });

        // Serialize the cleaned HTML content back to a string
        const cleanedContent = new XMLSerializer().serializeToString(doc);
        console.log(cleanedContent, "cleanedContent")
        // Separate content and styles
        const contentWithoutStyles = cleanedContent.replace(/<style[\s\S]*?<\/style>/gi, '');

        // Find the image within a specific div (example: div.pc.pc1.w0.h0)
        const divWithImage = doc.querySelector('.pc.pc1.w0.h0');
        let imageSrc = null;

        if (divWithImage) {
          const imgElement = divWithImage.querySelector('img');
          if (imgElement) {
            imageSrc = imgElement.getAttribute('src');
          }
        }

        console.log('Image Source:', imageSrc);
        setEditorImage(imageSrc);
        setEditorIm(imageSrc);
        setCoverImage(imageSrc);

        // Replace placeholders with org data
        const updatedContent = replacePlaceholders(cleanedContent, org.data);

        console.log(updatedContent, "updatedContent");

        // Set the updated content to editor
        if (editor1.current) {
          editor1.current.innerHTML = updatedContent;
        }

        // Other state updates as needed
        setContent(updatedContent);
        setHtmlContent(updatedContent);
        setPageStyles(pageStyles);

        setLoading(false);
        handleCloseWithoutPlaceholder();
        setIsOpenNewModal(false);
        setIsgetDataEditor(true);
        getAssetSectionData();
      };

      reader.readAsText(new Blob([docxContent]));
    } catch (error) {
      console.error('Error handling document', error);
    }
  };

  const handleRemoveTemplate = async () => {
    setLoading(true)
    // const assetid = proposalWritingData.assetid
    // alert(assetid)
    const data = {
      assetId: proposalWritingData.assetid,
    };
    const response = await removeTemplate(data);
    if(response.status === 200){
      setEditorImage('')
      setThemeExist(false)
      getAssetSectionData()
    }else{
      toast.error("Error in removing template")
    }

    setLoading(false)

  }


  // const replacePlaceholders = (htmlContent, orgData) => {
  //   // Define the mapping from placeholder names to orgData fields
  //   const fieldMapping = {
  //     'Company': 'orgName',
  //     'Address1': 'address',
  //     'City': 'city',
  //     'State': 'state',
  //     'PostalCode': 'postalCode',
  //     'WorkPhone': 'phoneNumber',
  //     'Fax': 'fax',
  //     'Domain': 'domain'
  //   };

  //   // Replace placeholders with corresponding orgData values
  //   Object.keys(fieldMapping).forEach(placeholder => {
  //     const fieldName = fieldMapping[placeholder];
  //     const fieldValue = placeholder === 'Company' ? orgData[fieldName] : orgData.orgData[fieldName] || '';

  //     // Create a regular expression to find the placeholder
  //     const regex = new RegExp(`&lt;&lt;${placeholder}&gt;&gt;`, 'g');
  //     htmlContent = htmlContent.replace(regex, fieldValue);
  //   });

  //   return htmlContent;
  // };
  // const handleCoverClick = async (template) => {
  //   try {
  //     const orgId = sessionStorage.getItem("organizationId");
  //     const org = await getorgdata(orgId);

  //     if (org.status !== 200) {
  //       setErrorMessage(org.error);
  //       setShowErrorPopup(true);
  //       return;
  //     }

  //     let fileExtension = template?.split('.').pop().toLowerCase();
  //     const data = {
  //       filename: template,
  //       folderPath: "titlePages/Building1",
  //     };

  //     const docxContent = await getCoverPages(data);

  //     const reader = new FileReader();
  //     reader.onload = function(event) {
  //       let htmlContent = event.target.result;

  //       // Parse the HTML content
  //       const parser = new DOMParser();
  //       const doc = parser.parseFromString(htmlContent, 'text/html');

  //       // Extract and clean styles
  //       const styleElements = doc.querySelectorAll('style');
  //       let pageStyles = '';
  //       styleElements.forEach(style => {
  //         style.textContent = style.textContent.replace(/background-color:\s*#[0-9a-fA-F]{6};?/g, '');
  //         pageStyles += style.outerHTML;
  //       });

  //       // Remove inline styles that set the grey background
  //       const inlineStyledElements = doc.querySelectorAll('[style*="background-color"]');
  //       inlineStyledElements.forEach(el => {
  //         el.style.backgroundColor = '';
  //       });

  //       // Serialize the cleaned HTML content back to a string
  //       const cleanedContent = new XMLSerializer().serializeToString(doc);
  //       console.log(cleanedContent, "cleanedContent")
  //       // Separate content and styles
  //       const contentWithoutStyles = cleanedContent.replace(/<style[\s\S]*?<\/style>/gi, '');

  //       // Find the image within a specific div (example: div.pc.pc1.w0.h0)
  //       const divWithImage = doc.querySelector('.pc.pc1.w0.h0');
  //       let imageSrc = null;

  //       if (divWithImage) {
  //         const imgElement = divWithImage.querySelector('img');
  //         if (imgElement) {
  //           imageSrc = imgElement.getAttribute('src');
  //         }
  //       }

  //       console.log('Image Source:', imageSrc);
  //       setEditorImage(imageSrc);
  //       setEditorIm(imageSrc);
  //       setCoverImage(imageSrc);

  //       // Replace placeholders with org data
  //       const updatedContent = replacePlaceholders(cleanedContent, org.data);

  //       console.log(updatedContent, "updatedContent");

  //       // Set the updated content to editor
  //       if (editor1.current) {
  //         editor1.current.innerHTML = updatedContent;
  //       }

  //       // Other state updates as needed
  //       setContent(updatedContent);
  //       setHtmlContent(updatedContent);
  //       setPageStyles(pageStyles);

  //       setLoading(false);
  //       handleCloseWithoutPlaceholder();
  //       setIsOpenNewModal(false);
  //       setIsgetDataEditor(true);
  //       getAssetSectionData();
  //     };

  //     reader.readAsText(new Blob([docxContent]));
  //   } catch (error) {
  //     console.error('Error handling document', error);
  //   }
  // };


  const handleRedirect = () => {
    history.push("/organization")
  };

  const setCoverImage = async (imageSrc) => {
    try {
      const assetid = proposalWritingData.assetid
      const data = { assetid, imageSrc };
      console.log(data, "setcoverdata")
      const response = await addCoverImage(data);
      if (response.status == 200) {
        return true;
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleCloseTaskAssignmentModal = () => {
    setTaskAssignmentModalOpen(false);
  };

  // const handleOpenTaskAssignmentModal = (title, sectionid, createdby,assetType,documenttype) => {
  //   if (!assetData?.isBidRequested) {
  //     let featurename = "Add User";
  //     const checkfeatureName = accessFeatures?.filter(
  //       (k) => k.name == featurename
  //     );
  //     // console.log(checkfeatureName[0].access, "checkfeatureName");
  //     if (!checkfeatureName[0].access) {
  //       toast.error("You are not allowed to assign task.");
  //     } else {
  //       if (createdby !== userInfo._id && userInfo.userRole[0] === "Application User") {
  //         toast.error("You are not allowed to assign task.")
  //       } else {
  //         if(assetType !== "proposal"){
  //           return toast.warn("This section does not belong to proposal.")
  //         }
  //         if(documenttype !== "sec"){
  //           return toast.warn("This section is not allowed to share.")
  //          }else{
  //         setTaskAssignmentModalOpen(true);
  //         setTaskAssignmentsectionId(sectionid);
  //         setnewSectionTitle(title);
  //         setSharedAssetSectionScreenType(Assettype);
  //         setSharedAssetSectionType(documenttype);
  //         getAllUserForSameOrgData()
  //          }
  //       }
  //     }
  //   } else {
  //     toast.warn("This RFP has been sent to management for bid request approval.")
  //   }
  // };


  const handleOpenTaskAssignmentModal = async (title, sectionid, createdby, assetType, documenttype) => {
    let featurename = "Add User";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    // console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast('Proposal.taskAssign')
    } else {
      const UserEmail = sessionStorage.getItem("currentUserEmail");
      const data = {
        assetId: proposalWritingData.assetid,
        userEmail: UserEmail,
        assetType: "proposal"
      };

      const response = await validateFunctionalityAccess(data);
      if (response.status === 200) {
        if (title === "Cost and Estimation" && !response.isFinanceTeamUser) {
          showToast('Proposal.accessDenied')
        } else {
          if (assetType !== "proposal") {
            return showToast('Proposal.sectionNotProposal')
          } else if (createdby !== sUserId) {
            showToast('Proposal.taskAssign')
          }
          else if (documenttype !== "sec") {
            return showToast('Proposal.sectionShare')
          } else {
            setTaskAssignmentModalOpen(true);
            setTaskAssignmentsectionId(sectionid);
            setnewSectionTitle(title);
            setSharedAssetSectionScreenType(assetType);
            setSharedAssetSectionType(documenttype);
            getAllUserForSameOrgData()
            setIsSectionSharing(true);
          }
        }
      } else if (response.status === 403 && response.isFinanceTeamUser && title === "Cost and Estimation") {
        // setAssetSetionId(sectionid);
        setTaskAssignmentModalOpen(true);
        setTaskAssignmentsectionId(sectionid);
        setnewSectionTitle(title);
        setSharedAssetSectionScreenType(assetType);
        setSharedAssetSectionType(documenttype);
        getAllUserForSameOrgData();
        setIsSectionSharing(true);
      } else {
        showToast('Error.errorOccured')
      }
    }
  };

  

  // const handleEnterKey = (editor) => {
  //   console.log("Enter key pressed");

  //   const selection = window.getSelection();
  //   if (!selection.rangeCount) return;

  //   const range = selection.getRangeAt(0);
  //   const anchorNode = range.startContainer;
  //   const currentPageEditableArea = anchorNode.nodeType === Node.ELEMENT_NODE
  //     ? anchorNode
  //     : anchorNode.parentElement.closest('.editable-area');

  //   if (!currentPageEditableArea) {
  //     console.log("No editable area found");
  //     return;
  //   }

  //   const maxHeight = 470; // Adjust as needed
  //   const currentPage = currentPageEditableArea.closest('.pf');
  //   const currentEditableArea = currentPage.querySelector('.editable-area');

  //   // Measure content height with added line break
  //   const tempDiv = document.createElement('div');
  //   tempDiv.style.position = 'absolute';
  //   tempDiv.style.visibility = 'hidden';
  //   tempDiv.style.width = currentEditableArea.clientWidth + 'px';
  //   tempDiv.style.padding = getComputedStyle(currentEditableArea).padding;
  //   tempDiv.style.whiteSpace = 'pre-wrap';
  //   tempDiv.style.border = getComputedStyle(currentEditableArea).border;
  //   document.body.appendChild(tempDiv);

  //   tempDiv.innerHTML = currentEditableArea.innerHTML + '<br>';
  //   const tempHeight = tempDiv.scrollHeight;
  //   document.body.removeChild(tempDiv);

  //   console.log("Temporary Height with New Line:", tempHeight);

  //   const isCursorAtEnd = range.endOffset === anchorNode.textContent.length;
  //   const contentFits = tempHeight <= maxHeight;

  //   if (tempHeight > maxHeight) {
  //     if (isCursorAtEnd) {
  //       moveCursorToNextPage(editor, currentPageEditableArea);
  //     } else {
  //       // Move content to the next page if between content
  //       moveContentToNextPage(editor, currentPageEditableArea);
  //     }
  //   } else {
  //     insertNewLineAtCursor(editor);
  //   }
  // };
  const moveCursorToNextPage = (editor, currentPageEditableArea) => {
    const currentPage = currentPageEditableArea.closest('.pf');
    const currentPageNo = parseInt(currentPage.dataset.pageNo, 10);
    const nextPageEditableArea = document.querySelector(`.pf[data-page-no="${currentPageNo + 1}"] .editable-area`);

    if (nextPageEditableArea) {
      setCursorInEditableArea(editor, nextPageEditableArea);
    } else {
      createNewPage(editor, currentPageEditableArea);
      const newNextPageEditableArea = document.querySelector(`.pf[data-page-no="${currentPageNo + 1}"] .editable-area`);
      setCursorInEditableArea(editor, newNextPageEditableArea);
    }
  };

  const moveContentToNextPage = (editor, currentPageEditableArea) => {
    const range = editor.selection.sel.getRangeAt(0);
    const contentAfterCursor = range.extractContents();
    const currentPage = currentPageEditableArea.closest('.pf');
    const currentPageNo = parseInt(currentPage.dataset.pageNo, 10);
    let nextPageEditableArea = document.querySelector(`.pf[data-page-no="${currentPageNo + 1}"] .editable-area`);

    if (nextPageEditableArea) {
      nextPageEditableArea.appendChild(contentAfterCursor);
    } else {
      createNewPage(editor, currentPageEditableArea);
      nextPageEditableArea = document.querySelector(`.pf[data-page-no="${currentPageNo + 1}"] .editable-area`);
      nextPageEditableArea.appendChild(contentAfterCursor);
    }

    setCursorInEditableArea(editor, nextPageEditableArea);
  };

  const insertNewLineAtCursor = (editor) => {
    const range = editor.selection.sel.getRangeAt(0);
    const br = document.createElement('br');
    range.insertNode(br);
    range.setStartAfter(br);
    range.collapse(true);
    editor.selection.sel.removeAllRanges();
    editor.selection.sel.addRange(range);
  };

  const setCursorInEditableArea = (editor, editableArea) => {
    const range = document.createRange();
    const selection = window.getSelection();

    if (editableArea.firstChild) {
      range.setStart(editableArea.firstChild, 0);
    } else {
      range.setStart(editableArea, 0);
    }
    range.collapse(true);

    selection.removeAllRanges();
    selection.addRange(range);
  };

  const createNewPage = (editor, currentPageEditableArea) => {
    const currentPage = currentPageEditableArea.closest('.pf');
    const currentEditableArea = currentPage.querySelector('.editable-area');
    const currentEditableAreaStyle = window.getComputedStyle(currentEditableArea);

    const newPage = document.createElement('div');
    newPage.className = 'pf w0 h0';
    newPage.dataset.pageNo = parseInt(currentPage.dataset.pageNo, 10) + 1;

    const newPageContent = document.createElement('div');
    newPageContent.className = 'pc';

    const newEditableArea = document.createElement('div');
    newEditableArea.className = 'editable-area';
    newEditableArea.style.top = currentEditableAreaStyle.top;
    newEditableArea.style.left = currentEditableAreaStyle.left;
    newEditableArea.style.right = currentEditableAreaStyle.right;
    newEditableArea.style.bottom = currentEditableAreaStyle.bottom;
    newEditableArea.style.border = currentEditableAreaStyle.border;

    newPageContent.appendChild(newEditableArea);
    newPage.appendChild(newPageContent);
    currentPage.after(newPage);

    setCursorInEditableArea(editor, newEditableArea);
  };



  const [taskAsignmentcomment, setTaskAsignmentComment] = useState({
    approved: '',
    acceptboth: '',
    reject: ''
  });
  const [isTaskAssignedCommentOpen, setisTaskAssignedCommentOpen] = useState(false)
  const [isFetchedCommentModalOpen, setIsFetchedCommentModalOpen] = useState(false)
  const [sectionCommentData, setSectionCommentsdata] = useState('')

  const handleOpenTaskAssignedCommentModal = () => {
    setTaskAsignmentComment({
      'approved': '',
      'acceptboth': '',
      'reject': ''
    })
    setisTaskAssignedCommentOpen(true)
    console.log("handleOpenTaskAssignedCommentModal")

  }

  const handleCloseTaskAssignedCommentModal = () => {
    setisTaskAssignedCommentOpen(false)

  }


  const handleTaskAsignmentCommentChange = (event) => {
    const value = event.target.value;

    setTaskAsignmentComment(prevState => ({
      approved: taskApproveStatus === 'approved' ? value : '',
      acceptboth: taskApproveStatus === 'acceptboth' ? value : '',
      reject: taskApproveStatus === 'reject' ? value : '',
    }));
  };

  const handleSubmitTaskComment = () => {
    const comment = taskAsignmentcomment[taskApproveStatus];

    if (!comment) {
      showToast('Proposal.enterComment')
      return;
    }

    if (taskApproveStatus === 'approved') {
      showToast('Proposal.taskApprove')
    } else if (taskApproveStatus === 'acceptboth') {
      showToast('Proposal.bothTaskApproved')
    } else {
      showToast('Proposal.taskReject')
    }

    // Here, you can add any additional logic needed after submission
    setCommentMessage(comment)
    handleCloseTaskAssignedCommentModal();
  };

  const handleFetchComments = (document) => {
    setSectionCommentsdata(document)
    setIsFetchedCommentModalOpen(true)
    console.log("handleFetchComments", document)

  }

  const handleCloseFetchedCommentModal = () => {
    setIsFetchedCommentModalOpen(false)
  }









  return (
    <>
      <Loader loading={loading} />


      <>
        <div className="row" style={{ backgroundColor: "white",width:"100%", marginLeft:"1%" , overflow: "auto" }}>
          {!isgetDataEditor &&
            <>
              <div className={windowWidth <1024 ? "col-md-12" :"col-md-9"}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}>
                <div style={{width:"100%", display: "flex", flexWrap: "wrap", rowGap: window.innerWidth < 500 ? "10px" : "5px", alignItems: "center",justifyContent:window.innerWidth < 500 ? "space-between" : "" }}>
 
 
 <Button
onClick={() => { handleAddopenModal(true); }}
variant="outlined"
className="CTAButtons"
data-tip
data-for="addsections"
style={{
width: window.innerWidth < 500 ? "30%" : "auto",
// padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
fontSize: window.innerWidth < 500 ? "14px" : "16px",
display: "flex",
alignItems: "center",
justifyContent: "center",
}}
>
<ReactTooltip id="addsections" place="bottom" effect="solid">
Add Sections
</ReactTooltip>
Add
<span style={{ marginLeft: "8px" }}>
<AddIcon />
</span>
</Button>



   <Button
     onClick={toggleModal}
     variant="outlined"
     className="CTAButtons"
     data-tip
     data-for="swap"
     style={{
       width: window.innerWidth < 500 ? "30%" : "auto",
       // padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
       fontSize: window.innerWidth < 500 ? "14px" : "16px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
     }}
   >
     <ReactTooltip id="swap" place="bottom" effect="solid">
       Swap Your Sections
     </ReactTooltip>
     Swap

     <span >
       <img style={{ height: '19px', marginTop: "0px", marginLeft: "8px", verticalAlign: "middle" }} src={Swapping} alt="Description of the image" />
     </span>
   </Button>
   {modalOpen && <ReorderModal isOpen={modalOpen} onClose={closeModal} items={asssetsectionData} setItems={setAssetSectionData} />}

   <Button
     onClick={toggleReorderModal}
     variant="outlined"
     className="CTAButtons"
     data-tip
     data-for="Reorder"
     style={{
       width: window.innerWidth < 500 ? "30%" : "auto",
       // padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
       fontSize: window.innerWidth < 500 ? "14px" : "16px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
     }}
   >
     <ReactTooltip id="Reorder" place="bottom" effect="solid">
       Reorder Your Sections
     </ReactTooltip>
     Reorder
     <span style={{ marginLeft: "8px" }}>
       <SwapVertRoundedIcon />
     </span>

   </Button>

   <Button
     className="CTAButtons"
     variant="outlined"
     id="clauseButton"
     onClick={() => { handleClauseExtraction() }}
     data-tip
     data-for="Clauses"
     style={{
       width: window.innerWidth < 500 ? "30%" : "auto",
       // padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
       fontSize: window.innerWidth < 500 ? "14px" : "16px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
     }}
   >
     <ReactTooltip id="Clauses" place="bottom" effect="solid">
       Extract Clauses
     </ReactTooltip>
     Clause

   </Button>


   {showReorderModal && <ReorderSequence items={asssetsectionData} setItems={setAssetSectionData} toggleModal={toggleReorderModal} />
   }




   <Button
     className="CTAButtons"
     variant="outlined"
     id="clauseButton"
     onClick={handleComplianceCheck}
     data-tip
     data-for="compliances"
     style={{
       width: window.innerWidth < 500 ? "30%" : "auto",
       // padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
       fontSize: window.innerWidth < 500 ? "10px" : "16px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
     }}
   >
     <ReactTooltip id="compliances" place="bottom" effect="solid">
       Check FAR compliances
     </ReactTooltip>
     compliance

   </Button>

   <Dialog
     open={showPopupFarCompliance}
     onClose={closeModalFarCompliance}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
     style={{

       minHeight: '200px',
       minWidth: '400px',
       padding: '50px',

     }}
   >
     <DialogTitle id="alert-dialog-title">{popupTitleFarCompliance}</DialogTitle>
     <DialogContent>
       <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: popupMessageFarCompliance }}
       >
       </DialogContentText>
     </DialogContent>
     <DialogActions>
       <Button onClick={closeModalFarCompliance} color="primary">
         Close
       </Button>
     </DialogActions>
   </Dialog>


   <Button
     className="CTAButtons"
     variant="outlined"
     id="clauseButton"
     onClick={() => { handleOpenResourceAllocation(proposalWritingData?.assetid) }}
     data-tip
     data-for="addresources"
     style={{
       width: window.innerWidth < 500 ? "30%" : "auto",
       // padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
       fontSize: window.innerWidth < 500 ? "14px" : "16px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
     }}
   >
     <ReactTooltip id="addresources" place="bottom" effect="solid">
       Allocate resources
     </ReactTooltip>
     Resources

   </Button>

   <ResourceAllocation open={openResourceAllocation} handleClose={handleCloseResourceAllocation} assetId={assetId} />

     
     <div 
      style={{
     width: window.innerWidth < 500 ? "30%" : "auto",
     // padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
    //  fontSize: window.innerWidth < 500 ? "14px" : "16px",
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
   }}
     >
                  {!firstTeamUser &&
                    <Button variant="contained" color="primary" 
                    // style={{ marginLeft: '30px' }}
                     onClick={handleOpenSendBackModal}>Send Back</Button>
                   } 
                  <SendBackModal
                    open={sendBackModalOpen}
                    handleClose={handleCloseSendBackModal}
                    teams={previousTeams}
                    handleSendBack={handleSendBack}
                  />

                  <TaskCompletionModal
                    open={taskCompletionModal}
                    handleClose={closeTaskCompletionModal}
                    tasks={teamTasks22.teamTasks}
                    handleComplete={handleTaskCompletion}
                  />
                </div>

   <div
    style={{
     width: window.innerWidth < 500 ? "30%" : "auto",
     // padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
    //  fontSize: window.innerWidth < 500 ? "14px" : "16px",
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
     marginLeft:"1%"
   }}
   >
     <Button onClick={() => { handleBacktoOpportunity() }} variant="contained" color="primary"
        //  style={{
        //    width: window.innerWidth < 500 ? "100%" : "auto",
        //    // padding: window.innerWidth < 500 ? "8px 16px" : "8px 24px",
        //    fontSize: window.innerWidth < 500 ? "14px" : "16px",
        //    display: "flex",
        //    alignItems: "center",
        //    justifyContent: "center",
        //  }}
       >
       Back
     </Button>
   </div>
   
 </div>
                  {/*
              <Button  variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: '#1976D2', borderColor: '#1976D2', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }}>Back</Button> */}


                </div>

              



                {/* <div style={{ boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", width: "100%", display: "flex",flexwrap:"wrap", justifyContent: "space-between", alignItems: "center", padding: "10px", borderRadius: "10px", marginTop: "20px" }}>
                  <div style={{ width: "65%",border:"2px solid green", display: "flex",flexwrap:"wrap",flexDirection:windowWidth<768 ? "column":"row", justifyContent: "space-between", alignItems: "center" }}>

                    <h5 style={{ margin: "0px", marginTop: "8px", width: "fit-content", maxWidth: windowWidth <768 ? "100%":"40%" }}> <b>{propWritingData?.title}</b> </h5>


                    {teamTasks &&
                      <div
                        style={{
                          textAlign: "center",
                          width: "fit-content",
                          maxWidth: windowWidth <768 ? "100%" : "30%",
                          border:"2px solid blue"
                        }}
                      >
                       
                        {auditHistory &&
                          <div>
                            <p><b>Last Action:</b> {auditHistory?.userName} from {auditHistory?.fromTeam} {auditHistory?.action} to {auditHistory?.toTeam} at {formatDate2(auditHistory?.createdAt)}</p>
                          </div>
                        }
                      </div>
                    }

                    {deadLineProposal &&
                      <div
                      style={{width:windowWidth <768 && "100%" }}
                      >
                        <b>DeadLine: {deadLineProposal}</b>
                      </div>
                    }
                     </div>
                     <div style={{ width: "65%",border:"2px solid green", display: "flex",flexwrap:"wrap",flexDirection:windowWidth<768 ? "column":"row", justifyContent: "space-between", alignItems: "center" }}>

<div style={{ display: "flex", justifyContent: "center" }}>
                          <p
                            style={{
                              margin: "8px",
                              marginRight: "0px",
                              marginLeft: "0px"

                            }}
                          >
                            <b>
                              {teamTasks?.teamName}
                            </b>
                          </p>

                          <p
                            style={{
                              margin: "8px"
                            }}
                          >
                            <b>
                              -
                            </b>
                          </p>
                          <p
                            style={{
                              margin: "8px",
                              color: "#3ded97",
                              marginRight: "0px",
                              marginLeft: "0px"
                            }}
                          >
                            <b>
                              {teamTasks?.status}
                            </b>
                          </p>
                        </div>
                  
                  <div
                   style={{width:windowWidth <768 && "100%" }}
                  >
                    <b><p>Submission Date</p> </b>
                    <p>{normalDate}</p>
                  </div>
                  <div
                   style={{width:windowWidth <768 && "100%" }}
                  >
                    <b><p>{userInfo.username}</p> </b>
                    <p title="Created Asset Time">{createdTime}</p>
                  </div>

                  <div
                   style={{width:windowWidth <768 && "100%" }}
                    data-tip
                    data-for="requirementmap"
                  >

                    <ReceiptLongIcon onClick={handleAttachmentModal} />
                  </div>
                 
                 </div>
                  <ReactTooltip id="requirementmap" place="bottom" effect="solid">
                    Requirement extraction
                  </ReactTooltip>
                </div> */}

<div class="title-card-proposal">
  <div class="title-card-heading-proposal">
    <h5 style={{fontSize:'17px'}}><b>{propWritingData?.title}</b></h5>
  </div>
 
  <div class="DeadLine-date-proposal">
    <b><p>DeadLine</p></b>
    <p>{deadLineProposal}</p>
  </div>
  <div class="submission-date-proposal">
    <b><p>Submission Date</p></b>
    <p style={{color: statusColor}}>{normalDate}</p>
  </div>
 
  <div class="created-time-proposal">
    <b><p>{userInfo.username}</p></b>
    <p title="Created Asset Time">{createdTime}</p>
  </div>
  <div class="title-auditHistory">
 
  <p><b>Last Action:</b>{auditHistory?.userName} from {auditHistory?.fromTeam} {auditHistory?.action} to {auditHistory?.toTeam} at {formatDate2(auditHistory?.createdAt)}</p>
    {/* <p style={{margin:'10px'}}><b> - </b><span class="status"><b>{teamTasks?.status}</b></span></p> */}
  </div>
  <div class="title-current-team-proposal">
  <p ><b>{teamTasks?.teamName}</b><span> - <b className="status">{teamTasks?.status}</b></span></p>
 
  </div>
  <div
                   style={{width:windowWidth <768 && "100%" }}
                    data-tip
                    data-for="requirementmap"
                  >
 
                    <ReceiptLongIcon onClick={handleAttachmentModal} />
                  </div>
                 
                 <div>
                  <ReactTooltip id="requirementmap" place="bottom" effect="solid">
                    Requirement extraction
                  </ReactTooltip>
                </div>
</div>

                
                <Accordion style={{ borderRadius: "10px", border: "none", marginTop: "10px", width: "100%" }}>
                  <AccordionSummary
                    onClick={(e) => handleSummaryModalOpen(e)}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: "10px" }}>
                      <div>
                        <b>
                          <h4 style={{ marginBottom: "3px" }} data-tip data-for="summaryTip">Summary</h4>
                          <ReactTooltip id="summaryTip" place="bottom" effect="solid">
                            detail notice summary
                          </ReactTooltip>
                        </b>

                      </div>

                      {/* <div >
                        <button 
                        style={{ padding: "4px", color: "#1976D2" }} onClick={handleButtonClick}
                         data-tip
        data-for="PlagiarismIcon"
                        ><PlagiarismIcon /></button>
                         <ReactTooltip id="PlagiarismIcon" place="bottom" effect="solid">
        detail Notice Summary
      </ReactTooltip>
                        <button 
                        style={{ padding: "4px", color: "#1976D2" }} onClick={handleButtonClick}
                         data-tip
        data-for="buttonTip"
                        ><MailOutlineIcon /></button>
                         <ReactTooltip id="buttonTip" place="bottom" effect="solid">
        Check Mail
      </ReactTooltip>
                        <button 
                        style={{ padding: "4px", color: "#1976D2" }} onClick={handleButtonClick}
                         data-tip
        data-for="print"
                        ><PrintIcon /></button>
                        <ReactTooltip id="print" place="bottom" effect="solid">
        Print Your Section
      </ReactTooltip>
                        <button 
                        style={{ padding: "4px", color: "#1976D2" }} onClick={handleButtonClick}
                         data-tip
        data-for="download"
                        ><DownloadIcon /></button>
                        <ReactTooltip id="download" place="bottom" effect="solid">
        Download Notice
      </ReactTooltip>
                        <button 
                        style={{ padding: "4px", color: "#1976D2" }} onClick={handleButtonClick}
                         data-tip
        data-for="adduser"
                        ><PersonAddIcon /></button>
                         <ReactTooltip id="adduser" place="bottom" effect="solid">
        Add User
      </ReactTooltip>
                        <button 
                        style={{ padding: "4px", color: "red" }} onClick={handleButtonClick}
                         data-tip
        data-for="delete"
                        ><DeleteIcon /></button>
  <ReactTooltip id="delete" place="bottom" effect="solid">
       Click to Delete Summary
      </ReactTooltip>
                      </div> */}
                    </div>
                  </AccordionSummary>
                </Accordion>
                <Modal
                  open={attachmentModal}
                  onClose={closeAttachmentModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >

                  <Box className="attachmentBox">
                    <Typography variant="h5" component="div" style={{ color: '#1976D2' }}>
                      Attachments
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={closeAttachmentModal}
                      style={{ color: 'red', position: "absolute", top: "0px", right: "0px" }}
                    >
                      <CloseIcon />
                    </IconButton>





                    {/* Main Content */}
                    <Typography id="modal-modal-description" style={{ marginTop: "15px", height: "70%" }}>

                    {showSection && !showRequirnments && !showMapping && (  
  <>
    <Box style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h5" component="div" style={{ color: '#1976D2' }}>
        Documents List
      </Typography>

     

    </Box>

    <Box sx={{ height: '350px', overflowY: 'auto', marginTop: '10px',border:"1px solid grey" }}>
      {documentData && documentData.map((attachment, index) => {
        const attachmentName = attachment?.linkname;
        if (isPDFAttachment(attachmentName)) {
          const isSelected = resourceLinks.includes(attachment.url);
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
                cursor: 'pointer',
                backgroundColor: isSelected ? 'lightblue' : 'inherit',
                padding: '5px',
                borderRadius: '4px',
              }}
              onClick={() => handleSelectAttachment(attachment.url)}
            >
              <Checkbox
                checked={isSelected}
                onChange={() => handleSelectAttachment(attachment.url)}
                color="primary"
                inputProps={{ 'aria-label': 'select attachment' }}
              />
              <InsertDriveFileIcon sx={{ marginRight: '5px' }} />
              <Typography>{attachmentName}</Typography>
            </Box>
          );
        }
        return null;
      })}
    </Box>

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        position: 'relative',
        bottom: '20px',
        width: '100%',
        padding: '10px',
      }}
    >

                          <Box style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                        
                            <Button variant="contained"
                              // disabled={documentData?.length > 0 ? false : true} 
                              color="primary" onClick={() => handleShowRequirement(documentData)}>
                              Requirement
                            </Button>
                          </Box>
                        </Box>

                      </>
                      )}






                      {!showSection && showRequirnments && !showMapping && (
                        <>

                          <Modal
                            open={showDownloadModalForRequirement}
                            onHide={() => setShowDownloadModalForRequirement(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80%', // Adjusted width
                                maxWidth: 400,
                                bgcolor: 'white',
                                borderRadius: 8,
                                boxShadow: 24,
                                p: 2,
                                textAlign: 'center',
                                overflowY: 'auto', // Enable scrolling if content exceeds box height
                              }}
                            >
                              <div className="modal-header">
                                <h3 id="modal-modal-title" className="modal-title">Download Options</h3>
                                <CloseIcon onClick={() => setShowDownloadModalForRequirement(false)} className="close-icon" style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: 10, right: 20 }} />
                              </div>

                              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <span>Download as...</span>
                                <br />
                                <br />
                                <br />
                                <br />
                                <Button onClick={downloadSinglePDFForRequirement} variant="contained" color="primary" style={{ marginRight: "30px", marginBottom: "10px" }}>
                                  PDF
                                </Button>
                                <Button onClick={handleDocxDownloadForRequirement} variant="contained" color="primary" style={{ marginBottom: "10px" }}>
                                  Word
                                </Button>
                              </div>
                            </Box>
                          </Modal>


                          <div className="Mapping-DownloadBtn" >

                            {requirementMappingHistoryExists &&
                              <Button
                                style={{ marginRight: "20px", height: "30px" }}
                                onClick={() => setRetrieveRequirementModal(true)}
                                variant="contained"
                                color="primary"
                              >
                                Retrieve Requirements
                              </Button>
                            }
                            <label htmlFor="file-upload" style={{ display: 'inline-block' }}>
                              <Button style={{ height: "30px" }} variant="contained" color="primary" component="span">
                                Upload <UploadIcon style={{ cursor: 'pointer' }} />
                              </Button>
                              {selectedFile && (
                                <span style={{ marginLeft: '5px' }}>{selectedFileName}</span>
                              )}
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              accept="application/pdf"
                              style={{ display: 'none' }}
                              onChange={handleFileUploadInMapping}
                            />

                            <Button style={{ marginLeft: "20px", height: "30px" }} variant="contained" color="primary" onClick={() => setShowDownloadModalForRequirement(true)} >
                              Download <DownloadIcon />
                            </Button>
                          </div>


                          <Modal
                            open={retrieveRequirementModal}
                            onClose={closeRetrieveRequirementModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80%',
                                height: '80%',
                                maxWidth: 400,
                                bgcolor: 'white',
                                borderRadius: 8,
                                boxShadow: 24,
                                p: 2,
                                textAlign: 'center',
                                overflowY: 'auto',
                              }}
                            >
                              <div className="modal-header" style={{ position: 'relative' }}>
                                <h3 id="modal-modal-title" className="modal-title">
                                  Retrieve Requirement
                                </h3>
                                <CloseIcon
                                  onClick={closeRetrieveRequirementModal}
                                  className="close-icon"
                                  style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: 10,
                                    right: 20,
                                  }}
                                />
                              </div>

                              <div style={{ marginTop: '20px', marginBottom: '20px' }}>

                                <div>
                                  {requirementMappingHistory.map((item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        margin: '10px 0',
                                      }}
                                    >
                                      <span>{item.submitedBy}</span>
                                      <span>{formatDate2(item.Date)}</span>
                                      <IconButton color="primary" aria-label="add">
                                        <AddIcon onClick={() => addRetrievedRequirement(item._id)} />
                                      </IconButton>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Box>
                          </Modal>



                          <div style={{ overflow: "auto" }}>
                            <JoditEditor
                              ref={editorForRequirnment}
                              value={aiRequirnmentsData}
                              config={{
                                readonly: true, // Set editor as read-only
                                toolbar: false, // Disable toolbar for rendering HTML content
                                buttons: false,
                                height: 450,
                                overflow: "auto",
                                branding: false,
                                statusbar: false,
                              }}

                            />

                          </div>
                          <div style={{ display: "flex", marginTop: "15px" }}>
                            <Button variant="contained" color="primary" onClick={handleRequirnmentBack}>Back</Button>
                            <div style={{ position: "relative", left: "40%" }}>
                              <Button variant="contained" color="primary" onClick={handleRequirementsMapping} style={{ marginRight: "20px" }}>
                                Requirements Mapping
                              </Button>

                            </div>
                          </div>
                        </>
                      )}

                      {!showSection && !showRequirnments && showMapping && (
                        <div style={{
                          position: 'fixed',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '95%',
                          maxHeight: '90%',
                        }}>
                          {keywordData && keywordData.length > 0 && (
                            <div style={{ maxHeight: '100px', overflowY: 'auto', marginTop: '30px', border: '1px solid #ddd', padding: '10px', marginBottom: "8px", display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                              <div>
                                {keywordData.map((data, index) => (
                                  <div key={index} style={{ marginBottom: '10px' }}>
                                    <p><b>{selectedKeyword}: </b>{data}</p>
                                  </div>
                                ))}
                              </div>
                              <IconButton
                                style={{ cursor: 'pointer' }}
                                onClick={handleCloseDiv}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          )}

                          <div className="Mapping-DownloadBtn" style={{
                            marginBottom: "8px",
                            justifyContent:"end",
                            marginTop: keywordData?.length > 0 ? "10px" : "30px"
                          }}>

                            {retrieveRequirementClicked && (
                              <>
                                {!historyClicked ?
                                  <div style={{width:"100%", display: "flex", flexDirection: windowWidth > 768 ? "row" : "column", justifyContent: "end" }}>
                                    {keywords.length > 0 && (
                                      <>
                                        <FormControl style={{ minWidth: '200px', marginRight: '8px', marginBottom: windowWidth > 768 ? "0px" : "15px" }}>
                                          <Select
                                            options={options}
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                            styles={customStyles}
                                            placeholder="Select keyword"
                                          />
                                        </FormControl>
                                      </>
                                    )}
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                      <Button
                                        onClick={() => handleHistoryClicked()}
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: '8px', cursor: 'pointer', marginBottom: windowWidth > 768 ? "0px" : "15px" }}
                                      >
                                        History
                                      </Button>
                                      <Button
                                        title="Smart Suggestion"
                                        onClick={handleSendData}
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: '8px', cursor: 'pointer', marginBottom: windowWidth > 768 ? "0px" : "15px" }}
                                      >
                                        <AutoAwesomeIcon />
                                      </Button>
                                      <Button
                                        onClick={handleOpenDownloadMappingModal}
                                        variant="contained"
                                        color="primary"
                                        style={{ marginBottom: windowWidth > 768 ? "0px" : "15px" }}
                                      >
                                        {windowWidth > 768 ? "Download" : ""} <DownloadIcon />
                                      </Button>
                                    </div>
                                  </div>
                                  :
                                  <div style={{width:"100%",}}>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between",flexDirection: windowWidth > 768 ? "row" : "column", }}>
                                      <h4 style={{ color: "#1976d2" }}>Mapping History</h4>
                                      <div>
                                        <Button
                                          onClick={() => setHistoryClicked(false)}
                                          variant="contained"
                                          color="primary"
                                          style={{ marginRight: '8px', cursor: 'pointer' }}
                                        >
                                          Back
                                        </Button>
                                        <Button
                                          onClick={() => handleRevertClicked()}
                                          variant="contained"
                                          color="primary"
                                          style={{ cursor: 'pointer' }}
                                        >
                                          Revert
                                        </Button>
                                      </div>

                                    </div>

                                  </div>
                                }
                              </>
                            )}
                          </div>

                          <Modal
                            open={showDownloadMappingModal}
                            onHide={() => setShowDownloadMappingModal(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "80%", // Adjusted width
                                maxWidth: 400,
                                bgcolor: "white",
                                borderRadius: 8,
                                boxShadow: 24,
                                p: 2,
                                textAlign: "center",
                                overflow: "auto", // Enable scrolling if content exceeds box height
                              }}
                            >
                              <div className="modal-header">
                                <h3
                                  id="modal-modal-title"
                                  className="modal-title"
                                >
                                  Download Options
                                </h3>
                                <CloseIcon
                                  onClick={handleDownloadMappingModalClose}
                                  className="close-icon"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 10,
                                    right: 20,
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                }}
                              >
                                <span>Download as...</span>
                                <br />
                                <br />
                                <br />
                                <br />
                                <Button
                                  onClick={downloadTableAsPDF}
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    marginRight: "30px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  PDF
                                </Button>
                                <Button
                                  onClick={downloadWordDocumentForMapping}
                                  variant="contained"
                                  color="primary"
                                  style={{ marginBottom: "10px" }}
                                >
                                  Word
                                </Button>
                              </div>
                            </Box>
                          </Modal>

                          <div style={{ width: "100%", height: "450px", overflow: "auto" }}>
                            <TableContainer
                              component={Paper}
                              style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              id="table-to-pdf"
                              ref={tableRef}

                            >
                              <Table aria-label="simple table" style={{ minWidth: 1500 }}>
                                <TableHead style={{ backgroundColor: "#1976d2" }}>
                                  <TableRow>
                                    <TableCell>
                                    </TableCell>
                                    <TableCell style={{ borderRight: "1px solid #ccc", color: "white", width: "150px" }}>Requirement ID</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: '150px', maxWidth: 'fit-content' }}>Filename</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: "250px", maxWidth: 'fit-content' }}>Requirement Description</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: "250px", maxWidth: 'fit-content' }}>Mapped Section in Proposal Template</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: "200px", maxWidth: 'fit-content' }}>Clauses</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: "350px", maxWidth: 'fit-content' }}>Resources</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: "250px", maxWidth: 'fit-content' }}>Hourly Pay</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: "250px", maxWidth: 'fit-content' }}>Effective Hours</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: "150px", maxWidth: 'fit-content' }}>Total Billing Amount</TableCell>
                                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", minWidth: "350px", maxWidth: 'fit-content' }}>Remarks</TableCell>

                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {dummy.map((row) => (
                                    <TableRow
                                      key={row.requirement_id}
                                      sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                      }}
                                      style={
                                        checkValidation
                                          ? row.manualClauses && row.manualClauses.length > 0 || row.manualSection && row.manualSection.length > 0
                                            ? { backgroundColor: 'yellow' }
                                            : row.clauses && !row.clauses.includes("undefined") && row.section_title
                                              ? greenRowStyle
                                              : redRowStyle
                                          : {}
                                      }
                                      onClick={() => handleRowSelect(row)}
                                    >
                                      <TableCell>
                                        <Radio
                                          checked={selectedRow?.requirement_id === row.requirement_id}
                                          onChange={() => handleRowSelect(row)}
                                        />
                                      </TableCell>
                                      <TableCell  scope="row" style={{ borderRight: "1px solid #ccc", minWidth: "150px", maxWidth: 'fit-content' }} >
                                        {row.requirement_id}
                                      </TableCell>
                                      <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "150px", maxWidth: 'fit-content' }} >{row.filename}</TableCell>
                                      <TableCell align="center" style={{ borderRight: "1px solid #ccc" }}>
                                        {selectedRow?.requirement_id === row.requirement_id && editableRow === row.requirement_id ? (
                                          <>
                                            <TextField
                                              value={editedRequirementText}
                                              onChange={(e) => setEditedRequirementText(e.target.value)}
                                              fullWidth
                                              autoFocus
                                            />
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              style={{ marginLeft: "8px", cursor: "pointer" }}
                                              onClick={() => handleSave(row.requirement_id)}
                                            >
                                              Save
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            {row.requirement_text}
                                            {selectedRow?.requirement_id === row.requirement_id && keywordData &&
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginLeft: "8px", cursor: "pointer" }}
                                                onClick={() => handleEdit(row.requirement_id, row.requirement_text)}
                                              >
                                                Edit
                                              </Button>
                                            }
                                          </>
                                        )}
                                      </TableCell>
                                      {!historyClicked ?
                                        <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "200px", maxWidth: 'fit-content' }} >{row.section_title}

                                          <div
                                            style={
                                              {
                                                marginTop: "4px",
                                                marginBottom: "4px"
                                              }
                                            }>
                                            <Divider />
                                          </div>
                                          <div>
                                            <Select
                                              isMulti
                                              options={sectionDummyData}
                                              value={selectedSectionMap?.[row.requirement_id] || []}
                                              onChange={(selectedOptions) => handleSelectSectionChange(selectedOptions, row.requirement_id)}
                                              styles={customStyles}
                                            />
                                          </div>
                                        </TableCell>
                                        :
                                        <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "200px", maxWidth: 'fit-content' }}>
                                          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: row.manualSection.length > 0 ? 'auto auto auto auto' : 'auto', gap: '8px' }}>
                                            <div style={{ gridColumn: '1 / 2', gridRow: '1 / 2', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                              Original Version
                                            </div>
                                            <div style={{ gridColumn: '2 / 3', gridRow: '1 / 2' }}>
                                              <Chip label={row.section_title} style={{ backgroundColor: '#007bff', color: 'white', fontSize: '9px', margin: '2px' }} />
                                            </div>
                                            {row.manualSection.length > 0 && (
                                              <>
                                                <div style={{ gridColumn: '1 / 3', gridRow: '2 / 3', borderBottom: '1px solid #ccc' }}></div>
                                                <div style={{ gridColumn: '1 / 2', gridRow: '3 / 4', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'blue' }}>
                                                  Updated Version
                                                </div>
                                                <div style={{ gridColumn: '2 / 3', gridRow: '3 / 4' }}>
                                                  {row.manualSection.map((section, index) => (
                                                    <Chip key={index} label={section.label} style={{ backgroundColor: '#007bff', color: 'white', fontSize: '9px', margin: '2px' }} />
                                                  ))}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </TableCell>
                                      }

                                      {!historyClicked ?
                                        <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "350px", maxWidth: 'fit-content' }} >
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center"
                                          }}>
                                            <div>
                                              {displayMappedClauses(row.clauses, row.filename)}
                                            </div>

                                          </div>
                                          <div
                                            style={
                                              {
                                                marginTop: "4px",
                                                marginBottom: "4px"
                                              }
                                            }>
                                            <Divider />
                                          </div>
                                          <div>
                                            <Select
                                              isMulti
                                              options={combinedClauses}
                                              value={selectedClauseResources?.[row.requirement_id] || []}
                                              onChange={(selectedOptions) => handleSelectClauseChange(selectedOptions, row.requirement_id)}
                                              styles={customStyles1}
                                            />
                                          </div>

                                        </TableCell>
                                        :

                                        <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "200px", maxWidth: 'fit-content' }}>
                                          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: row.manualClauses.length > 0 ? 'auto auto auto auto' : 'auto', gap: '8px' }}>
                                            <div style={{ gridColumn: '1 / 2', gridRow: '1 / 2', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                              Original Version
                                            </div>
                                            <div style={{ gridColumn: '2 / 3', gridRow: '1 / 2' }}>
                                              {row.clauses.map((clause, index) => (
                                                <Chip key={index} label={clause} style={{ backgroundColor: '#007bff', color: 'white', fontSize: '9px', margin: '2px' }} />
                                              ))}
                                            </div>
                                            {row.manualClauses.length > 0 && (
                                              <>
                                                <div style={{ gridColumn: '1 / 3', gridRow: '2 / 3', borderBottom: '1px solid #ccc' }}></div>
                                                <div style={{ gridColumn: '1 / 2', gridRow: '3 / 4', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'blue' }}>
                                                  Updated Version
                                                </div>
                                                <div style={{ gridColumn: '2 / 3', gridRow: '3 / 4' }}>
                                                  {row.manualClauses.map((clause, index) => (
                                                    <Chip key={index} label={clause.label} style={{ backgroundColor: '#007bff', color: 'white', fontSize: '9px', margin: '2px' }} />
                                                  ))}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </TableCell>
                                      }

                                      <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "350px", maxWidth: 'fit-content' }}>
                                        <Select
                                          isMulti
                                          options={resourceOptions}
                                          value={selectedResources[row.requirement_id] || []}
                                          onChange={(selectedOptions) => handleResourcesChange(selectedOptions, row.requirement_id)}
                                          styles={customStyles1}
                                        />
                                      </TableCell>
                                      <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "150px", maxWidth: 'fit-content' }}>
                                        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                                          {selectedResources[row.requirement_id]?.map(resource => (
                                            <div key={resource.value} style={{ marginBottom: '5px' }}>
                                              <div>{resource.label}</div>
                                              <div style={{ color: '#1976d2' }}>
                                                {editState[row.requirement_id]?.[resource.value] ? (
                                                  <>
                                                    <input
                                                      type="number"
                                                      value={newRate[row.requirement_id][resource.value]}
                                                      onChange={(e) => handleRateChange(row.requirement_id, resource.value, e.target.value)}
                                                    />
                                                    <SaveIcon onClick={() => handleSaveClick(row.requirement_id, resource.value)} />
                                                  </>
                                                ) : (
                                                  <>
                                                    ${resourcePayRates[row.requirement_id] && resourcePayRates[row.requirement_id][resource.value]
                                                      ? resourcePayRates[row.requirement_id][resource.value]
                                                      : resourcePayRates[row.requirement_id]?.find(pay => pay.value === resource.value)?.label
                                                    } / hr
                                                    <EditIcon onClick={() => handleEditClick(row.requirement_id, resource.value, resourcePayRates[row.requirement_id][resource.value] || 0)} />
                                                  </>
                                                )}
                                              </div>
                                              <hr style={{ margin: '5px 0' }} />
                                            </div>
                                          ))}
                                        </div>
                                      </TableCell>

                                      <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "150px", maxWidth: 'fit-content' }}>
                                        <TextField
                                          size="small"
                                          placeholder="Total hours"
                                          value={effectiveHours[row.requirement_id] || ''} // Use state to track the value
                                          onChange={(e) => handleEffectiveHoursChange(e, row.requirement_id)}
                                        />
                                      </TableCell>
                                      <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "#1976d2", minWidth: "350px" }}>${totalBillingAmounts[row.requirement_id] || 0}</TableCell>
                                      <TableCell align="center" style={{ borderRight: "1px solid #ccc", minWidth: "350px", maxWidth: 'fit-content' }}>
                                        <TextField
                                          size="small"
                                          placeholder="Add remark"
                                          value={remarks[row.requirement_id] || ''} // Use state to track the value
                                          onChange={(e) => handleRemarksChange(e, row.requirement_id)}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>

                          {!historyClicked &&
                            <div className="mapping-btn">

                              <IconButton
                                style={{ cursor: 'pointer' }}
                                onClick={() => { handleDownloadWithoutTable() }}
                              >
                                <DownloadIcon />
                              </IconButton>

                              <Button
                                onClick={closeAttachmentModal}
                                style={{
                                  marginRight: "15px",
                                  color: "red",
                                  border: "1px solid red",
                                }}
                                variant="outlined"
                              >
                                Cancel
                              </Button>
                              <Button
                                style={{ marginRight: "15px" }}
                                variant="contained"
                                color="primary"
                                onClick={() => { openModalCustom() }}
                              >
                                Submit
                              </Button>

                            </div>
                          }
                        </div>
                      )}
                    </Typography>




                  </Box>

                </Modal>







                {asssetsectionData && asssetsectionData.length > 0 && (
                  <>
                    <React.Fragment
                    // key={index}
                    >
                      <div className="mt-2" style={{
                        display: "flex",
                        width:"100%"                        // flexDirection: "column-reverse", minHeight: "100vh"
                      }}>

                        <div className="opp-section"
                          style={{
                            display:windowWidth <768 ? "none" :"block",
                            flex: isLeftPaneVisible ? "1 1 20%" : "1 1 0%",
                            padding: "10px",
                            borderRight: "1px solid #ccc",
                            // position: "fixed",
                            overflowY: "auto",
                            maxHeight: "50vh",
                            textAlign: "left", fontSize: "1rem",
                          }}
                        >
                          <button className="btn btn-primary btn-sm" onClick={toggleLeftPane}
                          >
                            {isLeftPaneVisible ? "Hide" : "Show"} Sections
                          </button>
                          {/* <h2>Sections</h2> */}
                          {isLeftPaneVisible && (
                            <ul style={{ listStyleType: "none", textAlign: "left", width: "100%" }}>
                              {asssetsectionData.map((document, index) => (
                                <li
                                  key={document._id}
                                  onClick={() => handleSectionClick(document._id)}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#f5f5f5", margin: "10px 0", padding: "10px", borderRadius: "5px", overflowX: "auto"
                                  }}
                                >
                                  {index + 1}. {document.title}/{document.type}
                                </li>
                              ))}
                            </ul>

                          )}
                        </div>




                        <div
                          style={{
                            flex: "1 1 80%", padding: "10px",
                            // marginLeft: "50%",
                            maxHeight: "50vh", overflowY: "auto",
                            width:"100%",
                          }}
                        >
                          <div style={{display:"flex",justifyContent:"space-between"}}>
                          {windowWidth <1024 ?<h2>Document Contents</h2> : <h4>Document Contents</h4>}
                          {(windowWidth <1024 && !isSidebarOpen) && <div onClick={toggleSidebar} 
                          style={{ margin: '15px' }}
                          >
                          <KeyboardDoubleArrowLeftIcon />
                          </div>
}
                          </div>
                          {asssetsectionData.map((document, index) => (
                            <div
                              key={document._id}
                              id={document._id}
                            style={{width:"100%",padding:0 }}
                            >
                              <Accordion style={{ borderRadius: "10px", width: "100%"}}
                              sx={{border:"2px solid blue"}}
                              >
                                <AccordionSummary
                                  // expandIcon={<ExpandMoreIcon onClick={(e) => { handlegetFileName(document.documentpath, document.type, document.htmlContent, document._id, document.title, document?.assetType, document?.taskassignwith, "owner"); e.stopPropagation(); }} />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  // style={{ width: "100%", display: "flex" }}
                                  sx={{ width: "100%", display: "flex", minHeight: 30, height: 'auto' }}
                                // onClick={() => { handlegetFileName(document.documentpath,document.type) }}
                                >
                                  <div style={{
                                    // backgroundColor: "orange",
                                    backgroundColor: assignedTaskdetails?.find(
                                      (task) =>
                                        task.assetId === proposalWritingData.assetid &&
                                        task.sectionId === document._id &&
                                        task.members.some((member) => member.email === userInfo?.email)
                                    )?.members.find(
                                      (member) => member.email === userInfo?.email
                                    )?.color || '#3d83fa',
                                    width: "1%",
                                    // border: "1px solid red",
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                  }}></div>

                                  <div style={{
                                    width: "99%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: document.type !== "sec" ? "center" : "none",
                                    padding: "10px",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                                  }}>

                                    <div style={{ width: "60%" }}>
                                      <b>
                                        <h4 style={{ marginBottom: "3px", width: "70%", color: "black" }}>{document.title}</h4>
                                        <h4
                                          style={{
                                            marginBottom: "3px",
                                            width: "70%",
                                          }}
                                        >
                                          Created By:{" "}{document && document.createdby === sUserId ? "You" : document?.createdbyName}
                                        </h4>
                                        {document.taskAssignWith.some(
                                          (task) =>
                                            task.assignToEmail ===
                                            cuurentUser ||
                                            task.assignByEmail ===
                                            assignbyUser
                                        ) && (
                                            <>
                                              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px", marginRight: "25px" }}>
                                                <p style={{ color: "black" }}>
                                                  Status:{" "}
                                                  <span style={{ color: "green" }}>
                                                    {
                                                      document.taskAssignWith.find(
                                                        (task) =>
                                                          task.assignToEmail ===
                                                          cuurentUser ||
                                                          task.assignByEmail ===
                                                          assignbyUser
                                                      )?.statusofSection
                                                    }
                                                  </span>
                                                </p>
                                                {assignedTaskdetails?.length > 0 && document.assetType == "proposal" &&
                                                  <>
                                                    <p style={{ color: "black" }}>
                                                      Due Date:{" "}
                                                      <span style={{
                                                        color: assignedTaskdetails?.find(
                                                          (task) =>
                                                            task.assetId === proposalWritingData.assetid &&
                                                            task.sectionId === document._id &&
                                                            task.members.some((member) => member.email === userInfo?.email)
                                                        )?.members.find(
                                                          (member) => member.email === userInfo?.email
                                                        )?.color || 'orange',
                                                      }}>
                                                        {assignedTaskdetails
                                                          .filter(
                                                            (task) =>
                                                              task.assetId === proposalWritingData.assetid &&
                                                              task.sectionId === document._id
                                                          )
                                                          .map((task) =>
                                                            task.members
                                                              .filter((member) => member.email == userInfo?.email)
                                                              .map((member) =>
                                                                new Date(member.dueDate).toLocaleDateString()
                                                              )
                                                          )
                                                          .flat()
                                                          .join(", ")}
                                                      </span>
                                                    </p>

                                                    <p style={{ color: "black" }}>
                                                      Priority:{" "}
                                                      <span style={{
                                                        color: assignedTaskdetails?.find(
                                                          (task) =>
                                                            task.assetId === proposalWritingData.assetid &&
                                                            task.sectionId === document._id &&
                                                            task.members.some((member) => member.email === userInfo?.email)
                                                        )?.members.find(
                                                          (member) => member.email === userInfo?.email
                                                        )?.color || 'orange',
                                                      }}>
                                                        {assignedTaskdetails
                                                          .filter(
                                                            (task) =>
                                                              task.assetId === proposalWritingData.assetid &&
                                                              task.sectionId === document._id
                                                          )
                                                          .map((task) =>
                                                            task.members
                                                              .filter((member) => member.email == userInfo?.email)
                                                              .map((member) =>
                                                                member.priority
                                                              )
                                                          )
                                                          .flat()
                                                          .join(", ")}
                                                      </span>
                                                    </p>


                                                    {document.taskAssignWith.some(data => data.statusofSection === "Task Approved" || data.statusofSection === "Task Denied") && (
                                                      <IconButton onClick={() => handleFetchComments(document)}>
                                                        <RemoveRedEyeIcon />
                                                      </IconButton>
                                                    )}
                                                  </>
                                                }
                                              </div>
                                              <div>
                                                {assignedTaskdetails?.length > 0 && document.assetType == "proposal" &&
                                                  <>
                                                    <p style={{ color: "black" }}>
                                                      Task Description:{" "}
                                                      <span style={{ color: "#1976D2" }}>
                                                        {assignedTaskdetails
                                                          .filter(
                                                            (task) =>
                                                              task.assetId === proposalWritingData.assetid &&
                                                              task.sectionId === document._id
                                                          )
                                                          .map((task) =>
                                                            task.members
                                                              .filter((member) => member.email === userInfo?.email)
                                                              .map((member) =>
                                                                member.description
                                                              )
                                                          )
                                                          .flat()
                                                          .join(", ")}
                                                      </span>
                                                    </p>

                                                  </>
                                                }
                                              </div>
                                            </>
                                          )}

                                        {document.taskAssignWith.some(
                                          (task) =>
                                            task.assignByEmail ===
                                            cuurentUser
                                        ) && (
                                            <>
                                              <p style={{ color: "black", marginTop: "15px", }}>
                                                Status:{" "}
                                                <span style={{ color: "green" }}>
                                                  {
                                                    document.taskAssignWith?.find(
                                                      (task) =>
                                                        task.assignByEmail ===
                                                        cuurentUser
                                                    )?.statusofSection
                                                  }
                                                </span>
                                              </p>


                                              {document.taskAssignWith.some(data => data.statusofSection === "Task Approved" || data.statusofSection === "Task Denied") && (
                                                <IconButton onClick={() => handleFetchComments(document)}>
                                                  <RemoveRedEyeIcon />
                                                </IconButton>
                                              )}


                                            </>
                                          )}
                                      </b>
                                    </div>
                                    <div>
                                      {/* <button
                                        // title="Share section"
                                        style={{ padding: "6px", color: "#1976D2", }} onClick={() => {
                                          openModalSectionshr(
                                            document._id,
                                            document.type,
                                            document.assetType,
                                            document.createdby,
                                            document.title
                                          );
                                        }}
                                        data-tip
                                        data-for="sharesection"
                                      >

                                        <SendIcon />
                                      </button>
                                      <ReactTooltip id="sharesection" place="bottom" effect="solid">
                                        Share this section
                                      </ReactTooltip> */}
                                      {/* <ShareAssetsSectionPage isOpen={modalOpenSectionshr} onClose={closeModalSectionshr} users={users} existingusers={existingusers} assetId={proposalWritingData.assetid} currentuser={cuurentUser} assetType={assetType} assetSectionId={assetsectionId} sectionType={sharedAssetSectionType} sectionScreenType={sharedAssetSectionScreenType} userName={userInfo.username} /> */}
                                      {document.assetType === 'proposal' && (
                                        <button
                                          // title="Add Subsection"
                                          style={{ padding: "6px", color: "#1976d2" }} onClick={() => { handleAddSubSectionModal(document._id, document.createdby, document.title) }}
                                          data-tip
                                          data-for="addsubsection"
                                        >
                                          <ReactTooltip id="addsubsection" place="bottom" effect="solid">
                                            Add sub-sections
                                          </ReactTooltip>
                                          <AddIcon />
                                        </button>

                                      )}
                                      <button

                                        title="With Placeholder"

                                        style={{ padding: "6px", color: "#1976D2" }}
                                        onClick={() => { handleOpenWithPlaceholderlistModal(document.title, document._id, document.createdby) }}
                                        data-tip
                                        data-for="Placeholder"
                                      >
                                        <ReactTooltip id="Placeholder" place="bottom" effect="solid">
                                          With Placeholder
                                        </ReactTooltip>
                                        <CodeIcon /></button>
                                      {/* <button style={{ padding: "6px", color: "#1976D2" }} onClick={(e) => { handleOpenListTemplatModal(document._id, document.title); e.stopPropagation() }}><CodeOffIcon /></button> */}

                                      <button
                                        title="Without Placeholder"
                                        style={{ padding: "6px", color: "#1976D2" }}
                                        onClick={() => { handleWithoutPlaceholderModal(document.title, document._id, document.createdby) }}
                                        data-tip
                                        data-for="WithoutPlaceholder"
                                      >
                                        <ReactTooltip id="WithoutPlaceholder" place="bottom" effect="solid">
                                          Without Placeholder
                                        </ReactTooltip>
                                        <CodeOffIcon /></button>

                                      {/* <button
                                        style={{ padding: "6px", color: "#1976D2" }} onClick={(e) => { handleButtonClick(e, document.createdby) }}
                                        data-tip
                                        data-for="MailOutlineIcon"
                                      >
                                        <ReactTooltip id="MailOutlineIcon" place="bottom" effect="solid">
                                          Check Mail
                                        </ReactTooltip>
                                        <MailOutlineIcon /></button>
                                      <button
                                        style={{ padding: "6px", color: "#1976D2" }} onClick={(e) => { handleButtonClick(e, document.createdby) }}
                                        data-tip
                                        data-for="Printout"
                                      >
                                        <ReactTooltip id="Printout" place="bottom" effect="solid">
                                          Printout section content
                                        </ReactTooltip>
                                        <PrintIcon /></button> */}
                                      <button
                                        style={{ padding: "6px", color: "#1976D2" }} onClick={(e) => { handleDownload(document.documentpath, document.type, document.htmlContent, document._id, document.title, document.createdby) }}
                                        data-tip
                                        data-for="downloadcontent"
                                      >
                                        <ReactTooltip id="downloadcontent" place="bottom" effect="solid">
                                          Download section content
                                        </ReactTooltip>
                                        <DownloadIcon /></button>

                                      <TaskAssignmentModal
                                        isOpen={isTaskAssignmentModalOpen}
                                        onClose={handleCloseTaskAssignmentModal}
                                        memberList={users}
                                        sectionHead={newSectionTitle}
                                        sectionId={taskAssignmentsectionId}
                                        assetId={document?.assetId}
                                        assetType="proposal"
                                        currnetUser={cuurentUser}
                                        currentUserName={userInfo?.username}
                                        assetSectionS={asssetsectionData?.filter((f) => f.type == "sec" && f.assetType == "proposal")}
                                        existingusers={existingusers}
                                        sectionType={sharedAssetSectionType}
                                        // assetSharingData={assetSharingData}
                                        callTaskAssignment={fetchAssignedTaskdetails}
                                      />


                                      <button
                                        style={{ padding: "6px", color: "#1976D2" }}
                                        data-tip
                                        data-for="addnewuser"
                                        onClick={() => {
                                          handleOpenTaskAssignmentModal(
                                            document.title,
                                            document._id,
                                            document.createdby,
                                            document.assetType,
                                            document.type,
                                          );
                                        }}
                                      >
                                        <ReactTooltip id="addnewuser" place="bottom" effect="solid">
                                          Add user
                                        </ReactTooltip>
                                        <PersonAddIcon /></button>
                                      {/* <button style={{ padding: "6px", color: "red" }} onClick={(e)=>{handleDeleteFile(e,document.documentpath,document._id)}}><DeleteIcon /></button> */}
                                      <button style={{ padding: "6px", color: "red" }} onClick={(e) => { handleDeleteFile(e, document.documentpath, document._id, document.type, document.createdby, document.title); e.stopPropagation(); }}
                                        data-tip
                                        data-for="sectiondelete"
                                      >
                                        <DeleteIcon /></button>
                                      <ReactTooltip id="sectiondelete" place="bottom" effect="solid">
                                        Delete section
                                      </ReactTooltip>

                                      {document.taskAssignWith.some(
                                        (task) =>
                                          task.assignToEmail ===
                                          cuurentUser &&
                                          task.isAccepted &&
                                          !task.isSubmitted
                                      ) &&
                                        document.taskAssignWith

                                          .filter(
                                            (task) =>
                                              task.assignToEmail ===
                                              cuurentUser &&
                                              task.isAccepted &&
                                              !task.isSubmitted
                                          )

                                          .map((task, index) => (
                                            <EditIcon
                                              key={index}
                                              onClick={() => {
                                                handlegetFileName(
                                                  task.link
                                                    ? task.link
                                                    : document.documentpath,

                                                  document.type,

                                                  task.htmlcontent ? task.htmlcontent : document.htmlContent,

                                                  document._id,

                                                  document.title,

                                                  document.assetType,

                                                  document.taskAssignWith,

                                                  // task?.link
                                                  //   ? "receiver"
                                                  //   : "owners",

                                                  !task?.isTaskReassign && !task?.link && task?.isReassignCount == 0 ? "owner" :
                                                  task?.isTaskReassign
                                                  ? task?.link 
                                                    ? "receiver"   // If task is reassigned and link exists
                                                    : "owner"      // If task is reassigned and no link exists
                                                  : task?.link 
                                                    ? "receiver"   // If task is not reassigned and link exists
                                                    : "owners"    // If task is not reassigned and no link exists
                                                ,


                                                  document.assetId,
                                                  document
                                                );
                                              }}
                                            />
                                          ))}

                                      {document.taskAssignWith.some(
                                        (task) =>
                                          task.assignToEmail ===
                                          cuurentUser &&
                                          !task.isAccepted
                                          // given statusofSection ! =Task Rejected so once rejected this button should not be shown 
                                          && task.statusofSection !== "Task Rejected"
                                      ) && (
                                          <button
                                            onClick={() =>
                                              setShowPopup(
                                                document.assetType,
                                                document._id,
                                                rfmManagementData.value
                                                  .assetid,
                                                document?.taskassignid
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-asterisk blurb-ripple-out point"
                                              style={{ color: "orange" }}
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        )}

                                      {document.taskAssignWith.some(
                                        (task) =>
                                          task.assignToEmail ===
                                          cuurentUser &&
                                          task.isSubmitted &&
                                          task.statusofSection ==
                                          "Task performed"
                                      ) && (
                                          <button
                                          // onClick={() =>
                                          //   setShowPopup(
                                          //     document.assetType,
                                          //     document._id,
                                          //     rfmManagementData.value
                                          //       .assetid,
                                          //     document.taskassignid
                                          //   )
                                          // }
                                          >
                                            Task Submitted
                                          </button>
                                        )}

                                      {document.taskAssignWith.some(
                                        (task) =>
                                          task.assignByEmail ===
                                          cuurentUser &&
                                          task.isSubmitted &&
                                          task?.statusofSection ==
                                          "Task performed"
                                      ) && (
                                          <button
                                            onClick={() =>
                                              setShowPopupfoApproval(
                                                document.assetType,
                                                document._id,
                                                rfmManagementData.value
                                                  .assetid,
                                                  document?.taskAssignWith[0]?.taskassignid,
                                                document?.taskAssignWith,
                                                document?.subSectionsData
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-asterisk blurb-ripple-out point"
                                              style={{ color: "orange" }}
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        )}

                                      {opens === document._id && (
                                        <Dialog
                                          open={opens === document._id}
                                          onClose={handleAcceptClose}
                                          PaperProps={{
                                            className: "dialogPaper",
                                          }}
                                        >
                                          <div className="dialogHeader">
                                            <DialogTitle className="dialogTitle">
                                              Requesting Task for
                                              {document.title}
                                            </DialogTitle>

                                            <div className="dialogClose">
                                              <CloseIcon
                                                onClick={
                                                  handleAcceptClose
                                                }
                                              />
                                            </div>
                                          </div>

                                          <DialogContent className="dialogContent">
                                            {document.taskAssignWith.filter(task => task.assignToEmail == cuurentUser)
                                              .map((task, index) => (
                                                <Button
                                                  key={index}
                                                  className="dialogButton"
                                                  onClick={() =>
                                                    handleAcceptClick(
                                                      document.assetType,
                                                      document._id,
                                                      rfmManagementData
                                                        .value.assetid,
                                                      task.taskassignid,
                                                      document.title
                                                    )
                                                  }
                                                  variant="contained"
                                                  color="primary"
                                                >
                                                  Accept
                                                </Button>
                                              )
                                              )}
                                            {document.taskAssignWith.filter(task => task.assignToEmail == cuurentUser)
                                              .map((task, index) => (
                                                <>
                                                <Button
                                                  className="dialogButton"
                                                  onClick={() => {
                                                    handleOpenRejectDialog()
                                                  }}
                                                  variant="contained"
                                                  color="primary"
                                                >
                                                  Reject
                                                </Button>
                                                <Dialog open={openRejectDialog} onClose={handleCloseRejectDialog}>
                                                    <DialogTitle>Reject Task Confirmation</DialogTitle>
                                                    <DialogContent>
                                                      <DialogContentText>
                                                        Are you sure you want to reject this task?
                                                      </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                      <Button onClick={()=>{
                                                        handleRejectClick(
                                                          document.assetType,
                                                          document._id,
                                                          task.taskassignid,
                                                          document
                                                        )
                                                      }} color="secondary" autoFocus>
                                                        Yes
                                                      </Button>
                                                      <Button onClick={handleCloseRejectDialog} color="primary">
                                                        No
                                                      </Button>
                                                    </DialogActions>
                                                  </Dialog>
                                                </>
                                              ))}
                                          </DialogContent>
                                        </Dialog>
                                        

                                      )}

                                      <Modal
                                        open={openPopup === document._id}
                                        onClose={handleAcceptClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                      >
                                        <Box sx={style}>
                                          {!opennewPopup && (
                                            <>
                                              <div
                                                className="dialogHeader"
                                                style={{
                                                  width: "100%",
                                                  margin: "auto",
                                                  display: "flex",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    textAlign: "center",
                                                    marginLeft: "33%",
                                                  }}
                                                  variant="h6"
                                                  className="dialogTitle"
                                                >
                                                  Requesting Approval for
                                                  {document.title}
                                                </Typography>

                                                <div
                                                  className="dialogClose"
                                                  style={{
                                                    marginLeft: "10%",
                                                  }}
                                                >
                                                  <IconButton
                                                    onClick={
                                                      handleAcceptClose
                                                    }
                                                  >
                                                    <CloseIcon />
                                                  </IconButton>
                                                </div>
                                              </div>

                                              <div
                                                className="dialogContent"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "center",
                                                  position: "absolute",
                                                  top: "50%",
                                                  left: "10%",
                                                }}
                                              >
                                                {document.taskAssignWith.map(
                                                  (task, index) => (
                                                    <>
                                                      {task?.statusofSection ==
                                                        "Task performed" && (
                                                          <div
                                                            style={{
                                                              display:
                                                                "flex",
                                                              marginBottom:
                                                                "20px",
                                                              width: "50%",
                                                            }}
                                                            key={index}
                                                          >
                                                            <Typography
                                                              style={{
                                                                display:
                                                                  "flex",
                                                                alignItems:
                                                                  "center",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              {task.assignToEmail
                                                                ? task.assignToEmail
                                                                : task.assignToEmail -
                                                                "yet to submit"}
                                                            </Typography>

                                                            <Button
                                                              className="dialogButton"
                                                              onClick={() =>
                                                                handlegetFileNames(
                                                                  document.documentpath,
                                                                  document.type,
                                                                  document.htmlContent,
                                                                  document._id,
                                                                  document.title,
                                                                  document?.assetType,
                                                                  task?.taskassignid,
                                                                  task?.linkSubmitted,
                                                                  task?.htmlcontent,
                                                                  task?.assignToEmail,
                                                                  task?.link,
                                                                  document?.subSectionsData,
                                                                  document?.title
                                                                )
                                                              }
                                                              variant="contained"
                                                              color="primary"
                                                              style={{
                                                                marginLeft:
                                                                  "28%",
                                                                height:
                                                                  "25px",
                                                                width:
                                                                  "58px",
                                                              }}
                                                            >
                                                              Review
                                                            </Button>
                                                          </div>
                                                        )}
                                                    </>
                                                  )
                                                )}
                                              </div>
                                            </>
                                          )}

                                          {/* {opennewPopup && (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "center",
                                                  // width:"700px",
                                                  // border:"2px solid blue"
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-end",
                                                  }}
                                                >
                                                  <button
                                                    onClick={() =>
                                                      setOpennewPopup(
                                                        false
                                                      )
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "rgb(61, 131, 250)",
                                                      color: "white",
                                                      outline: "none",
                                                      border: "none",
                                                    }}
                                                  >
                                                    Back
                                                  </button>
                                                </div>

                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "center",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      height: "80%",
                                                      width: "20%",
                                                      marginRight: "15px",
                                                    }}
                                                  >
                                                    <h4 style={{ color: "rgb(61, 131, 250)" }}>
                                                      All Subsections
                                                    </h4>
                                                    <p onClick={() => handlegetSectionContent()}>{specifiSectiontaskData?.sectionName}</p>
                                                    {seubsectionDataforApproval.map((data) => (
                                                      <p onClick={() => handlegetSubsectionContent(data?.htmlData, data?.taskAcceptedEmail[0]?.htmlUpdatedData, data?.subsectionIds)}>{data.subsectionName}</p>
                                                    ))}

                                                  </div>
                                                  <div
                                                    style={{
                                                      height: "80%",
                                                      width: "40%",
                                                      marginRight: "15px",
                                                    }}
                                                  >
                                                    <h4
                                                      style={{
                                                        color:
                                                          "rgb(61, 131, 250)",
                                                      }}
                                                    >
                                                      Original Section
                                                    </h4>

                                                    <JoditEditor
                                                      value={
                                                        taskApprovalOwnerData
                                                      }
                                                      config={config2}
                                                      style={{
                                                        height: "80%",
                                                        width: "100%",
                                                      }}
                                                    />
                                                  </div>

                                                  <div
                                                    style={{
                                                      height: "80%",
                                                      width: "40%",
                                                    }}
                                                  >
                                                    <h4
                                                      style={{
                                                        color:
                                                          "rgb(61, 131, 250)",
                                                      }}
                                                    >
                                                      Submitted Section by
                                                      Receiver
                                                    </h4>

                                                    <JoditEditor
                                                      value={
                                                        taskApprovalReceiverData
                                                      }
                                                      config={config2}
                                                      style={{
                                                        height: "80%",
                                                        width: "100%",
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                {!subsectionButtons ? <>
                                                  <div
                                                    style={{
                                                      marginTop: "20px",
                                                      display: "flex",
                                                      justifyContent:
                                                        "center",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    <button
                                                      onClick={() =>
                                                        handleApprove(
                                                          document.assetType,
                                                          document._id,
                                                          rfmManagementData
                                                            .value.assetid,
                                                          document.taskassignwith,
                                                          specifiSectiontaskData.taskassignwith
                                                        )
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "green",
                                                        color: "white",
                                                        padding:
                                                          "10px 20px",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                      }}
                                                    >
                                                      Approved
                                                    </button>

                                                    <button
                                                      onClick={() =>
                                                        handleAcceptBoth(
                                                          document.assetType,
                                                          document._id,
                                                          rfmManagementData
                                                            .value.assetid,
                                                          document.taskassignwith,
                                                          specifiSectiontaskData.taskassignwith
                                                        )
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "blue",
                                                        color: "white",
                                                        padding:
                                                          "10px 20px",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                      }}
                                                    >
                                                      Accept Both
                                                    </button>

                                                    <button
                                                      onClick={() =>
                                                        handleRejectTask(
                                                          document.assetType,
                                                          document._id,
                                                          rfmManagementData
                                                            .value.assetid,
                                                          document.taskassignwith,
                                                          specifiSectiontaskData.taskassignwith,
                                                          document.htmlContent
                                                        )
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "red",
                                                        color: "white",
                                                        padding:
                                                          "10px 20px",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                      }}
                                                    >
                                                      Reject
                                                    </button>
                                                  </div>
                                                </> : <>
                                                  <div
                                                    style={{
                                                      marginTop: "20px",
                                                      display: "flex",
                                                      justifyContent:
                                                        "center",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    <button
                                                      onClick={() =>
                                                        handleApproveSubsection()
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "green",
                                                        color: "white",
                                                        padding:
                                                          "10px 20px",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                      }}
                                                    >
                                                      Approved Subsection
                                                    </button>

                                                    <button
                                                      onClick={() =>
                                                        handleAcceptBothSubsection(
                                                          document.assetType,
                                                          document._id,
                                                          rfmManagementData
                                                            .value.assetid,
                                                          document.taskassignwith,
                                                          specifiSectiontaskData.taskassignwith
                                                        )
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "blue",
                                                        color: "white",
                                                        padding:
                                                          "10px 20px",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                      }}
                                                    >
                                                      Accept Both Subsection
                                                    </button>

                                                    <button
                                                      onClick={() =>
                                                        handleRejectTaskSubsection(
                                                          document.assetType,
                                                          document._id,
                                                          rfmManagementData
                                                            .value.assetid,
                                                          document.taskassignwith,
                                                          specifiSectiontaskData.taskassignwith,
                                                          document.htmlContent
                                                        )
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "red",
                                                        color: "white",
                                                        padding:
                                                          "10px 20px",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                      }}
                                                    >
                                                      Reject Subsection
                                                    </button>
                                                  </div>
                                                </>}
                                              </div>
                                            </>
                                          )} */}
                                        </Box>
                                      </Modal>

                                      <Modal
                                        open={openJoditPopup === document._id}>
                                        <Box sx={style}>
                                          {openJoditPopup && (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <h3
                                                    style={{
                                                      color:
                                                        "rgb(61, 131, 250)",
                                                    }}
                                                  >
                                                    Review and Approval
                                                  </h3>

                                                  <HighlightOffIcon
                                                    style={{
                                                      color: "red",
                                                    }}
                                                    onClick={() =>
                                                      setOpenJoditPopup(
                                                        false
                                                      )
                                                    }
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                      "center",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      height: "550px", // Adjust this as needed for your layout
                                                      width: "50%",
                                                      marginRight: "15px",
                                                    }}
                                                  >
                                                    <h4
                                                      style={{
                                                        color:
                                                          "rgb(61, 131, 250)",
                                                      }}
                                                    >
                                                      Original Section
                                                    </h4>

                                                    <JoditEditor
                                                      value={
                                                        taskApprovalOwnerData
                                                      }
                                                      onChange={() => {
                                                        getHtmlOwnercontent();
                                                      }}
                                                      ref={editor1}
                                                      config={config2}
                                                      style={{
                                                        overflow: "auto",
                                                        height: "100%", // Adjust for header height
                                                        width: "100%",
                                                      }}
                                                    />
                                                    <div style={{ marginTop: '10px' }}>
                                                      <button
                                                        onClick={() =>
                                                          setOpenJoditPopup(
                                                            false
                                                          )
                                                        }
                                                        style={{
                                                          height: "40px",
                                                          color: "white",
                                                          backgroundColor:
                                                            "rgb(61, 131, 250)",
                                                        }}
                                                      >
                                                        Back
                                                      </button>
                                                      <button
                                                        onClick={() => {
                                                          handleFinalMerge(
                                                            document?.taskAssignWith,
                                                            document?.subSectionsData,
                                                          )
                                                        }}
                                                        style={{
                                                          height: "40px",
                                                          color: "white",
                                                          backgroundColor:
                                                            "rgb(61, 131, 250)",
                                                          marginLeft: "20px",
                                                        }}
                                                      >
                                                        Save
                                                      </button>

                                                    </div>
                                                  </div>

                                                  <div
                                                    style={{
                                                      height: "100%", // Adjust this as needed for your layout
                                                      width: "50%",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",

                                                      }}
                                                    >
                                                      <h4
                                                        style={{
                                                          color:
                                                            "rgb(61, 131, 250)",
                                                        }}
                                                      >Reviewer Section
                                                      </h4>
                                                      {tasks.length > 0 && (
                                                        <>

                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent: "space-between",
                                                              width: "43%",
                                                              marginTop: '-5px'
                                                            }}
                                                          >
                                                            <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                                                              {/* <Select
                                                                value={selectedTaskIndex}
                                                                label="Select User"
                                                                onChange={() => handleChange({ target: { value: index } })}
                                                                style={{ width: '100%', height: "30px", marginRight: "5px" }}
                                                              >
                                                                {tasks?.map((task, index) => (
                                                                  <MenuItem
                                                                    key={index}
                                                                    value={selectedTaskIndex}
                                                                    onClick={() => handledropdownclick(
                                                                      document?.documentpath,
                                                                      document?.type,
                                                                      document?.htmlContent,
                                                                      document?._id,
                                                                      document?.title,
                                                                      document?.assetType,
                                                                      task,
                                                                      task?.assignToEmail)}
                                                                  >
                                                                    {task?.assignToEmail || "yet to submit"}
                                                                   
                                                                  </MenuItem>
                                                                ))}
                                                              </Select> */}

                                                              {/* <label htmlFor="user-select" style={{ display: 'block', marginBottom: '5px' }}>
        Select User
      </label> */}
                                                              <select
                                                                id="user-select"
                                                                value={selectedTaskIndex}
                                                                onChange={handleChange}
                                                                style={{ width: '100%', height: '30px', marginRight: '5px' }}
                                                              >
                                                                <option value="">-- Select User --</option>
                                                                {tasks?.map((task, index) => (
                                                                  <option key={index} value={JSON.stringify({ index, document: document })}
                                                                  >
                                                                    {task?.assignToEmail || 'yet to submit'}
                                                                  </option>
                                                                ))}
                                                              </select>

                                                              <select
                                                                id="sub-select"
                                                                value={selectedTaskIndexforsubsection}
                                                                label="Select Subsection"
                                                                onChange={handleChangeforsubsection}
                                                                style={{ width: '100%', height: "30px" }}
                                                              >
                                                                <option value="">-- Select Subsection --</option>
                                                                {allSubsectionData.map((subtask, index) => (
                                                                  <option
                                                                    key={index}
                                                                    value={JSON.stringify({ index, subtask })}
                                                                  >
                                                                    {subtask.subsectionName}
                                                                  </option>
                                                                ))}
                                                              </select>


                                                            </div>

                                                          </div>
                                                        </>
                                                      )}
                                                    </div>

                                                    <JoditEditor
                                                      value={
                                                        taskApprovalReceiverData
                                                      }
                                                      config={config3}
                                                      style={{
                                                        height: "100%", // Adjust for header height and divide by 3
                                                        width: "100%",
                                                      }}
                                                    />

                                                    {!subsectionButtons ? <div
                                                      style={{
                                                        padding: "10px 0px",
                                                        display: "flex",
                                                        justifyContent: 'center'
                                                      }}
                                                    >
                                                      <button
                                                        onClick={() =>
                                                          handleApprove(
                                                            document.assetType,
                                                            document._id,
                                                            rfmManagementData
                                                              .value
                                                              .assetid,
                                                            document.taskassignwith,
                                                            specifiSectiontaskData.taskassignwith,
                                                            document.title
                                                          )
                                                        }
                                                        style={{
                                                          height: "40px",
                                                          color: "white",
                                                          backgroundColor:
                                                            "rgb(61, 131, 250)",
                                                        }}
                                                      >
                                                        Approved
                                                      </button>

                                                      <button
                                                        onClick={() =>
                                                          handleAcceptBoth(
                                                            document.assetType,
                                                            document._id,
                                                            rfmManagementData
                                                              .value
                                                              .assetid,
                                                            document.taskassignwith,
                                                            specifiSectiontaskData.taskassignwith,
                                                            document.title
                                                          )
                                                        }
                                                        style={{
                                                          height: "40px",
                                                          color: "white",
                                                          backgroundColor:
                                                            "rgb(61, 131, 250)",
                                                          marginLeft: "20px",
                                                        }}
                                                      >
                                                        Accept Both
                                                      </button>

                                                      <button
                                                        onClick={() =>
                                                          handleRejectTask(
                                                            document.assetType,
                                                            document._id,
                                                            rfmManagementData
                                                              .value
                                                              .assetid,
                                                            document.taskassignwith,
                                                            specifiSectiontaskData.taskassignwith,
                                                            document.htmlContent,
                                                            document.title
                                                          )
                                                        }
                                                        style={{
                                                          height: "40px",
                                                          color: "white",
                                                          backgroundColor:
                                                            "rgb(61, 131, 250)",
                                                          marginLeft: "20px",
                                                        }}
                                                      >
                                                        Reject
                                                      </button>

                                                      {/* <button
                                                              onClick={() =>
                                                                handleSaveTask(
                                                                  document.assetType,
                                                                  document._id,
                                                                  rfmManagementData
                                                                    .value
                                                                    .assetid,
                                                                  document.taskassignwith,
                                                                  specifiSectiontaskData.taskassignwith,
                                                                  document.htmlContent,
                                                                  document.title
                                                                )
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  "black",
                                                                color: "white",
                                                                padding:
                                                                  "10px 20px",
                                                                border: "none",
                                                                borderRadius:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Edit Manually
                                                            </button> */}
                                                    </div>


                                                      : <>
                                                        <div
                                                          style={{
                                                            paddingTop: "5px",
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <button
                                                            onClick={() =>
                                                              handleApproveSubsection(
                                                              )
                                                            }
                                                            style={{
                                                              height: "40px",
                                                              color: "white",
                                                              backgroundColor:
                                                                "rgb(61, 131, 250)",

                                                            }}
                                                          >
                                                            Approved sub
                                                          </button>

                                                          <button
                                                            onClick={() =>
                                                              handleAcceptBothSubsection(
                                                                document.assetType,
                                                                document._id,
                                                                rfmManagementData
                                                                  .value.assetid,
                                                                document.taskassignwith,
                                                                specifiSectiontaskData.taskassignwith
                                                              )
                                                            }
                                                            style={{
                                                              height: "40px",
                                                              color: "white",
                                                              backgroundColor:
                                                                "rgb(61, 131, 250)",
                                                              marginLeft: "20px",
                                                            }}
                                                          >
                                                            Accept Both sub
                                                          </button>

                                                          <button
                                                            onClick={() =>
                                                              handleRejectTaskSubsection(
                                                                document.assetType,
                                                                document._id,
                                                                rfmManagementData
                                                                  .value.assetid,
                                                                document.taskassignwith,
                                                                specifiSectiontaskData.taskassignwith,
                                                                document.htmlContent
                                                              )
                                                            }
                                                            style={{
                                                              height: "40px",
                                                              color: "white",
                                                              backgroundColor:
                                                                "rgb(61, 131, 250)",
                                                              marginLeft: "20px",
                                                            }}
                                                          >
                                                            Reject sub
                                                          </button>
                                                          {/*  
                                                            <button
                                                              onClick={() =>
                                                                handleSaveTask(
                                                                  document.assetType,
                                                                  document._id,
                                                                  rfmManagementData
                                                                    .value
                                                                    .assetid,
                                                                  document.taskassignwith,
                                                                  specifiSectiontaskData.taskassignwith,
                                                                  document.htmlContent,
                                                                  document.title
                                                                )
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  "black",
                                                                color: "white",
                                                                padding:
                                                                  "10px 20px",
                                                                border: "none",
                                                                borderRadius:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Edit Manually sub
                                                            </button> */}
                                                        </div>
                                                      </>}


                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  margin: "15px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center"
                                                }}
                                              >
                                                <div>
                                                  {/* <button
                                                    onClick={() =>
                                                      setOpenJoditPopup(
                                                        false
                                                      )
                                                    }
                                                    style={{
                                                      height: "40px",
                                                      color: "white",
                                                      backgroundColor:
                                                        "rgb(61, 131, 250)",
                                                    }}
                                                  >
                                                    Back
                                                  </button>
                                                  <button
                                                    onClick={() => {
                                                      handleFinalMerge(
                                                        document?.taskAssignWith
                                                      )
                                                    }}
                                                    style={{
                                                      height: "40px",
                                                      color: "white",
                                                      backgroundColor:
                                                        "rgb(61, 131, 250)",
                                                      marginLeft: "20px",
                                                    }}
                                                  >
                                                    Save
                                                  </button> */}
                                                </div>
                                              </div>
                                              {/* </div> */}

                                            </>
                                            // </div>
                                          )}
                                        </Box>
                                      </Modal>

                                      <button
                                        style={{
                                          padding: "6px",
                                          color: "#1976D2",
                                        }}
                                        onClick={(e) => {
                                          console.log(document, "lolololppp")
                                          const assignedTask = document.taskAssignWith.find(
                                            (task) => task.assignToEmail === userEmail && (task.statusofSection === "Task Performed" || task.statusofSection === "Review Inprogress")
                                          );
                                          if (assignedTask) {
                                            // If a matching task is found, use the special function
                                            handlegetFileName(
                                              assignedTask.link ? assignedTask.link : document.documentpath,
                                              document.type,
                                              assignedTask.htmlcontent ? assignedTask.htmlcontent : document.htmlContent,
                                              document._id,
                                              document.title,
                                              document.assetType,
                                              document.taskAssignWith,
                                              assignedTask.link ? "receiver" : "owners",
                                              document.assetId
                                            );
                                          } else {

                                            handlegetFileName(
                                              document.documentpath,
                                              document.type,
                                              document.htmlContent,
                                              document._id,
                                              document.title,
                                              document.assetType,
                                              document.taskAssignWith,
                                              "owner",
                                              document.assetId,
                                              document.createdby,
                                              document
                                            );
                                          }
                                        }}
                                        data-tip
                                        data-for="expandmore"
                                      >
                                        {/* {(projectType === "myProject" || sUserId == document.createdby) && (
                                          <ExpandMoreIcon />
                                        )} */}
                                        <ExpandMoreIcon />
                                      </button>
                                      <ReactTooltip id="expandmore" place="bottom" effect="solid">
                                        click to view content of section11
                                      </ReactTooltip>

                                    </div>




                                    <Modal
                                      open={showDownloadModal}
                                      onHide={() => setShowDownloadModal(false)}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Box
                                        sx={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          width: '80%', // Adjusted width
                                          maxWidth: 400,
                                          bgcolor: 'white',
                                          borderRadius: 8,
                                          boxShadow: 24,
                                          p: 2,
                                          textAlign: 'center',
                                          overflowY: 'auto', // Enable scrolling if content exceeds box height
                                        }}
                                      >
                                        <div className="modal-header">
                                          <h3 id="modal-modal-title" className="modal-title">Download Options</h3>
                                          <CloseIcon onClick={() => setShowDownloadModal(false)} className="close-icon" style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: 10, right: 10 }} />
                                        </div>

                                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                          <span>Download as...</span>
                                          <br />
                                          <br />
                                          <br />
                                          <br />
                                          <Button onClick={downloadSinglePDF} variant="contained" color="primary" style={{ marginRight: "30px", marginBottom: "10px" }}>
                                            PDF
                                          </Button>
                                          <Button onClick={handleDocxDownload} variant="contained" color="primary" style={{ marginBottom: "10px" }}>
                                            Word
                                          </Button>
                                        </div>
                                      </Box>
                                    </Modal>

                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {/* {document.type == "sec" && */}
                                  <div
                                    style={{
                                      borderRadius: "10px",
                                      width: "100%",
                                      display: "flex",
                                      border: "1px solid #ccc",
                                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                                    }}>
                                    <div style={{
                                      backgroundColor: assignedTaskdetails?.find(
                                        (task) =>
                                          task.assetId === proposalWritingData.assetid &&
                                          task.sectionId === document._id &&
                                          task.members.some((member) => member.email === userInfo?.email)
                                      )?.members.find(
                                        (member) => member.email === userInfo?.email
                                      )?.color || '#3d83fa',
                                      width: "1%",
                                      // border: "1px solid red",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                    }}></div>
                                    <div
                                      style={{
                                        width: "99%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: document.type !== "sec" ? "center" : "none",
                                        borderTopRightRadius: "10px",
                                        borderBottomRightRadius: "10px",
                                        padding: "10px",
                                        // border: "1px solid black"
                                      }}
                                    >
                                      <Typography>
                                        {document.description ? (
                                          <>
                                            <b>Description:</b>{" "} {document.description}
                                          </>
                                        ) : (
                                          <b>No description available.</b>
                                        )}

                                      </Typography>
                                    </div>
                                  </div>
                                  {/* } */}
                                  {subsectionsMap[document._id] && subsectionsMap[document._id].map(subsection => (
                                    <Accordion key={subsection._id}>

                                      <AccordionSummary
                                        // expandIcon={<ExpandMoreIcon onClick={(e) => {
                                        //   handlesubsectionFileName(subsection.documentpath, subsection.type, subsection.htmlContent, subsection._id, subsection.title,
                                        //     document?.subSectionsData); e.stopPropagation();
                                        // }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <div
                                          style={{
                                            borderRadius: "10px",
                                            width: "100%",
                                            display: "flex",
                                            border: "1px solid #ccc",
                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                                          }}
                                        >
                                          <div style={{
                                            backgroundColor: assignedTaskdetails?.find(
                                              (task) =>
                                                task.assetId === proposalWritingData.assetid &&
                                                task.sectionId === document._id &&
                                                task.members.some((member) => member.email === userInfo?.email)
                                            )?.members.find(
                                              (member) => member.email === userInfo?.email
                                            )?.color || '#3d83fa',
                                            width: "1%",
                                            borderTopLeftRadius: "10px",
                                            borderBottomLeftRadius: "10px",
                                          }}></div>

                                          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", borderRadius: "10px" }}>
                                            <div style={{ width: "60%" }}>
                                              <b>
                                                <h4 style={{ marginBottom: "3px", width: "70%" }}>{subsection.title}</h4>
                                              </b>
                                            </div>
                                            <div>
                                              {/* Delete and Download buttons */}
                                              <button style={{ padding: "6px", color: "red" }} ><DeleteIcon onClick={(e) => { handleSubsectionDeleteFile(e, subsection.documentpath, subsection._id, subsection.sectionId, subsection.type,document?.createdby); e.stopPropagation(); }} /></button>
                                              <button style={{ padding: "6px", color: "#1976D2" }} ><DownloadIcon onClick={(e) => { handleDownload(subsection.documentpath, subsection.type, subsection.htmlContent, subsection._id, subsection.title, document.createdby) }} /></button>
                                              <button
                                                style={{
                                                  padding: "6px",
                                                  color: "#1976D2",
                                                }}
                                                onClick={(e) => {
                                                  handlesubsectionFileName(subsection.documentpath, subsection.type, subsection.htmlContent, subsection._id, subsection.title,
                                                    document?.subSectionsData, document?.taskAssignWith, document?.createdby); e.stopPropagation();
                                                }}
                                                data-tip
                                                data-for="expandmore2"
                                              >

                                                <ExpandMoreIcon />
                                              </button>
                                              <ReactTooltip id="expandmore2" place="bottom" effect="solid">
                                                click to view content of section
                                              </ReactTooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div
                                          style={{
                                            borderRadius: "10px",
                                            width: "100%",
                                            // marginTop: "15px",
                                            // boxShadow:
                                            //   "rgba(0, 0, 0, 0.1) 0px 2px 4px",
                                            // padding: "10px",
                                            display: "flex",
                                            border: "1px solid #ccc",
                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                                          }}>
                                          <div style={{
                                            // backgroundColor: "orange",
                                            backgroundColor: assignedTaskdetails?.find(
                                              (task) =>
                                                task.assetId === proposalWritingData.assetid &&
                                                task.sectionId === document._id &&
                                                task.members.some((member) => member.email === userInfo?.email)
                                            )?.members.find(
                                              (member) => member.email === userInfo?.email
                                            )?.color || '#3d83fa',
                                            width: "1%",
                                            // border: "1px solid red",
                                            borderTopLeftRadius: "10px",
                                            borderBottomLeftRadius: "10px",
                                          }}></div>
                                          <div
                                            style={{
                                              width: "99%",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: document.type !== "sec" ? "center" : "none",
                                              borderTopRightRadius: "10px",
                                              borderBottomRightRadius: "10px",
                                              padding: "10px",
                                              // border: "1px solid black"
                                            }}
                                          >
                                            <Typography>
                                              <b>Description:</b>{" "} {subsection.description}
                                            </Typography>
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))}
                                </AccordionDetails>

                              </Accordion>
                            </div>
                          ))}

                          {asssetsectionData.length > 1 ? (
                            <div

                              style={{ marginBottom: "20px" }}
                            >
                              <Accordion style={{ borderRadius: "10px", width: "100%", marginTop: "15px" }}>
                                <AccordionSummary
                                  expandIcon={
                                    <span data-tip data-for="viewmergesection">
                                      <ExpandMoreIcon onClick={() => MergeTemplate()} />
                                    </span>

                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", borderRadius: "10px" }}>
                                    <div style={{ width: "60%" }}>
                                      <b>
                                        <h4 style={{ marginBottom: "3px", width: "70%", color: "#1A57F2" }}>Merge Sections</h4>
                                      </b>
                                    </div>
                                    <div>
                                      <button
                                        style={{ padding: "6px", color: "#1976D2" }} onClick={() => setShowDownloadModalForMerge(true)}
                                        data-tip
                                        data-for="mergesection"
                                      >
                                        <DownloadIcon />
                                      </button>
                                      <ReactTooltip id="mergesection" place="bottom" effect="solid">
                                        Download merge Section content
                                      </ReactTooltip>
                                    </div>




                                    <VersionComapreModal
                                      versionModalState={versionModalOpen}
                                      handleVersionModalClose={handleCloseVersionModal}
                                      SecId={versionSectionId}
                                      versiondata={specificVersionData}
                                      versionNo={selectedVersion}
                                    />

                                          <Modal
                                      open={showDownloadModalForMerge}
                                      onHide={() => setShowDownloadModalForMerge(false)}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Box
                                        sx={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          width: '80%', // Adjusted width
                                          maxWidth: 400,
                                          bgcolor: 'white',
                                          borderRadius: 8,
                                          boxShadow: 24,
                                          p: 2,
                                          textAlign: 'center',
                                          overflowY: 'auto', // Enable scrolling if content exceeds box height
                                        }}
                                      >
                                        <div className="modal-header">
                                          <h3 id="modal-modal-title" className="modal-title">Download Options</h3>
                                          <CloseIcon onClick={() => setShowDownloadModalForMerge(false)} className="close-icon" style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: 10, right: 10 }} />
                                        </div>

                                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                          <span>Download as...</span>
                                          <br />
                                          <br />
                                          <br />
                                          <br />
                                          <Button onClick={DownloadMergeSection} style={{ marginRight: "30px", marginBottom: "10px", color: "white", backgroundColor: "#1976D2", }}>
                                            PDF
                                          </Button>
                                          <Button onClick={DownloadMergeSection2} style={{ marginBottom: "10px", color: "white", backgroundColor: "#1976D2", }}>
                                            Word
                                          </Button>
                                        </div>
                                      </Box>
                                          </Modal>




                                  </div>
                                </AccordionSummary>
                                <ReactTooltip id="viewmergesection" place="bottom" effect="solid">
                                  view merge section content
                                </ReactTooltip>
                              </Accordion>
                            </div>

                          ) : null}

                        </div>
                      </div>
                    </React.Fragment>
                  </>
                )}




                <div style={{ display: "flex", justifyContent: "end", marginTop: "20px", padding: "5px" }}>
                  <div style={{ display: "flex", width: "fitcontent", alignItems: "center", color: "#1976D2", borderBottom: "1px solid #1976D2" }}>
                    <button onClick={() => handleAddTemplate()} style={{ padding: "0px", color: "#1976D2", border: "none", outline: "none" }}
                      data-tip
                      data-for="add template"
                    >
                      <ReactTooltip id="add template" place="bottom" effect="solid">
                        Click to add template
                      </ReactTooltip>
                      <NoteAltIcon />
                      Add Template
                    </button>
                  </div>
                </div>
              </div>
            </>

          }

          {isgetDataEditor &&

            <div
            //  className={`transition-width ${isExpanded ? 'col-md-12' : 'col-md-12'}`}
             className={windowWidth<1024 ? 'col-md-12' : 'col-md-9'}
             >
              {/* Jodit Editor Row */}

              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: "10px", padding: "5px" }}>

                <div style={{ position: "relative", marginBottom: "15px",width:"100%" }}>
                  <TextField
                    style={{ borderRadius: "20px", width: "100%" }}
                    onChange={(e) => { handlSectiontitle(e.target.value) }}
                    value={sectionTitle}
                    variant="outlined"
                    disabled={!editSectionTitle}
                    fullWidth
                    InputProps={{
                      style: { color: "#1976d2", fontWeight: "bold" } // Apply color to the input element
                    }}
                  />
                  <div style={{ position: "absolute", top: "10%", right: "1%", display: "flex", alignItems: "center" }}>
                    <button style={{ color: "#1976d2" }} onClick={() => handleSectionTitle()} ><BorderColorIcon /></button>
                    {inAttachmentSection && (
                      <>
                        <IconButton style={{ color: "#1976d2" }} onClick={handleUploadClick} data-tip data-for="Attachment" >
                          <ReactTooltip id="Attachment" place="bottom" effect="solid">
                            Create custom and reference attachment
                          </ReactTooltip>
                          <UploadFileIcon />

                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleCustomAttachments}>Custom generated attachments</MenuItem>
                          <MenuItem onClick={handleReferenceAttachments}>Reference attachments</MenuItem>
                        </Menu>

                        <Dialog open={openReferencePopup} onClose={handleCloseReferensePopup}>
                          <DialogTitle>Reference Attachments</DialogTitle>
                          <DialogContent>
                            <List>
                              {documentData.map((doc, index) => (
                                <ListItem button key={index} onClick={() => handleReferenceFileUpload(doc.url, doc.linkname)}>
                                  <ListItemText primary={doc.linkname} />
                                </ListItem>
                              ))}
                            </List>
                          </DialogContent>
                          <DialogActions>

                            <Button onClick={handleCloseReferensePopup} color="secondary">
                              Close
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                    <div onClick={toggleExpand} style={{ cursor: "pointer" }}>
                      {isExpanded ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
                    </div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      onChange={onFileChange}
                      style={{ display: 'none' }}
                      key={inputKey} // To reset the input element
                    />

                  </div>


                </div>

                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' ,width:"100%"}}>
                  <JoditEditor
                    config={config}
                    ref={editor1}
                    value={content}
                    onChange={getHtmlcontent}
                    onBlur={(newContent) => setContent(newContent)}
                    tabIndex={1} // tabIndex of textarea
                    style={{ flexGrow: 1, overflow: 'auto', backgroundColor: "red" }}
                  />
              
                
                </div>


              </div>

              <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <div className="col-md-4">
                  <b>10000 Words limit | Total words: {wordCount}</b>
                </div>
                <div className="col-md-8" >

                  <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                    <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#1976d2' }}>
                      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="simple tabs example">
                        <Tab label="Sections" />
                        <Tab label="Clauses" onClick={fetchClausesData}/>
                      </Tabs>
                      <CloseIcon onClick={handleDialogClose} style={{ position: 'absolute', top: '15px', right: '15px', color: 'red' }} />
                    </DialogTitle>
                    <DialogContent dividers style={{ maxHeight: '40vh', overflowY: 'auto', width: '400px' }}>
                      {selectedTab === 0 && (
                        <div style={{ padding: '5px' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={allSelected}
                                onChange={handleSelectAllToggle}
                              />
                            }
                            label={<span style={{ fontWeight: 'bold' }}>Select All</span>}
                            style={{ margin: '0px 0px 10px 0px' }}
                          />
                          {asssetsectionData.map((section) => (
                            <div key={section._id} style={{ marginBottom: '5px' }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedSections.some(s => s._id === section._id)}
                                    onChange={() => handleSectionToggle(section)}
                                  />
                                }
                                label={<span style={{ fontWeight: 'bold' }}>{section.title}</span>}
                                style={{ margin: '0px' }}
                              />
                              <ul style={{ paddingLeft: '15px', margin: '0px', listStyleType: 'none' }}>
                                {subsectionsMap[section._id] && subsectionsMap[section._id].map(subsection => (
                                  <li key={subsection._id} style={{ margin: '0px' }}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={selectedSections.some(s => s._id === subsection._id)}
                                          onChange={() => handleSectionToggle(subsection)}
                                        />
                                      }
                                      label={subsection.title}
                                      style={{ margin: '0px' }}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      )}
                      {selectedTab === 1 && (
                        <div style={{ padding: '5px' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={allClausesSelected}
                                onChange={handleSelectAllClausesToggle}
                              />
                            }
                            label={<span style={{ fontWeight: 'bold' }}>Select All</span>}
                            style={{ margin: '0px 0px 10px 0px' }}
                          />
                          {clausesData.map((section) => (
                            <div key={section.sectionId} style={{ marginBottom: '10px' }}>
                              {(section.farClauses.length > 0 ||
                                section.dfarClauses.length > 0 ||
                                section.subsections.some(
                                  (subsection) =>
                                    subsection.farClauses.length > 0 || subsection.dfarClauses.length > 0
                                )) && (
                                  <FormControlLabel
                                    control={<span />} // Empty control
                                    label={<span style={{ fontWeight: 'bold' }}>{section.sectionTitle}</span>}
                                    style={{ margin: '0px' }}
                                  />
                                )}
                              <ul style={{ paddingLeft: '15px', margin: '0px', listStyleType: 'none' }}>
                                {[...new Set([...section.farClauses, ...section.dfarClauses].flatMap(clause => clause))] // Unique clauses for the section
                                  .map((clause, index) => (
                                    <li key={index} style={{ margin: '0px' }}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={selectedClauses.some(
                                              (c) => c.sectionId === section.sectionId && c.clause === clause
                                            )}
                                            onChange={() => handleClauseToggle({ sectionId: section.sectionId, clause: clause })}
                                          />
                                        }
                                        label={clause}
                                        style={{ margin: '0px' }}
                                      />
                                    </li>
                                  ))}
                                {section.subsections.map((subsection) => (
                                  <li key={subsection.subsectionId} style={{ margin: '0px' }}>
                                    <FormControlLabel
                                      control={<span />} // Empty control
                                      label={subsection.subsectionTitle}
                                      style={{ margin: '0px', fontWeight: 'bold' }}
                                    />
                                    <ul style={{ paddingLeft: '15px', margin: '0px', listStyleType: 'none' }}>
                                      {[...new Set([...subsection.farClauses, ...subsection.dfarClauses].flatMap(clause => clause))] // Unique clauses for the subsection
                                        .map((clause, index) => (
                                          <li key={index} style={{ margin: '0px' }}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={selectedClauses.some(
                                                    (c) => c.sectionId === subsection.subsectionId && c.clause === clause
                                                  )}
                                                  onChange={() =>
                                                    handleClauseToggle({ sectionId: subsection.subsectionId, clause: clause })
                                                  }
                                                />
                                              }
                                              label={clause}
                                              style={{ margin: '0px' }}
                                            />
                                          </li>
                                        ))}
                                    </ul>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      )}

                    </DialogContent>
                    <DialogActions>
                      <div style={{ display: "flex", justifyContent: 'center' }}>
                        <IconButton
                          onClick={handleDialogClose}
                          style={{
                            fontSize: "12px",
                            borderRadius: "10px",
                            color: "red",
                            border: "solid 1px red",
                            boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          Cancel
                        </IconButton>
                        <IconButton
                          onClick={handleAddSelection}
                          style={{
                            fontSize: "12px",
                            borderRadius: "10px",
                            color: "#1976D2",
                            border: "solid 1px #1976D2",
                            boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                            marginLeft: "10px"
                          }}
                        >
                          Add
                        </IconButton>
                      </div>
                    </DialogActions>
                  </Dialog>


                  <Dialog
                    open={openCompliance}
                    onClose={handleCloseCompliance}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                      '& .MuiDialog-paper': {
                        minHeight: '200px',
                        minWidth: '400px',
                        padding: '20px',
                        backgroundColor: 'red',
                      }
                    }}
                  >
                    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseCompliance} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>






                  <button className="buttt" onClick={() => { handleFilesModal(document.title, document._id, document.createdby) }} style={{ borderRadius: "10px", fontSize: "14px", width: "auto", border: "1px solid grey", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", marginRight: "5px", marginBottom: "5px", padding: "2px, 13px, 2px, 13px" }}>Add files</button>

{ themeExist &&
                    <button className="buttt" onClick={() => handleRemoveTemplate()} style={{ borderRadius: "10px", fontSize: "14px", width: "auto", border: "1px solid grey", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", marginRight: "5px", marginBottom: "5px", padding: "2px, 13px, 2px, 13px" }}>Remove Template</button>

}

                  <button className="buttt" onClick={handleClauseExtractionForCompliense} style={{ borderRadius: "10px", fontSize: "14px", width: "auto", border: "1px solid grey", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", marginRight: "5px", marginBottom: "5px", padding: "2px, 13px, 2px, 13px" }}>Compliance Check</button>

                  <button className="buttt" onClick={handleSectionsListToggle} style={{ borderRadius: "10px", fontSize: "14px", width: "auto", border: "1px solid grey", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", marginRight: "5px", marginBottom: "5px", padding: "2px, 13px, 2px, 13px" }}>Cross-Referencing</button>
                  <button className="buttt"  onClick={() => { handleContentLibrary() }} style={{ borderRadius: "10px", fontSize: "14px", width: "auto", border: "1px solid grey", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", marginRight: "5px", marginBottom: "5px", padding: "2px, 13px, 2px, 13px" }}>Content Library</button>


                  <button className="buttt" onClick={() => applyDefaultStyling()} style={{ borderRadius: "10px", fontSize: "14px", width: "auto", border: "1px solid grey", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", marginRight: "5px", marginBottom: "5px", padding: "2px, 13px, 2px, 13px" }}>Auto Formatting </button>


                  <button className="buttt" onClick={() => { handleBackFromEditor(); setIsExpanded(false); getAssetSectionData(); }} style={{ borderRadius: "10px", fontSize: "14px", width: "auto", border: "1px solid grey", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", marginRight: "5px", marginBottom: "5px", padding: "2px, 13px, 2px, 13px" }}>
                    Back
                  </button>


                  {showBtnInMerge && <button style={{
                    borderRadius: "10px",
                    fontSize: "14px",
                    backgroundColor: "white",
                    color: "black",
                    width: "auto",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    padding: "5px, 13px, 5px, 13px",
                    border: "1px solid grey",
                    marginRight: "5px",
                    marginBottom: "5px",
                  }} onClick={mergeChangeDetect}>Save</button>}


                  {showBtnInMerge && <button style={{
                    borderRadius: "10px",
                    fontSize: "14px",
                    backgroundColor: "#1976D2",
                    color: "white",
                    width: "auto",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    padding: "5px, 13px, 5px, 13px",
                    marginRight: "5px",
                    marginBottom: "5px",
                  }} onClick={proposalSubmitClick}>Submit</button>}



                  {showTerminateButton && showBtnInMerge && <button style={{
                    borderRadius: "10px",
                    fontSize: "14px",
                    backgroundColor: "#1976D2",
                    color: "white",
                    width: "auto",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    padding: "5px, 13px, 5px, 13px",
                  }} onClick={proposalTerminateClick}>Terminate</button>}

                  <TerminateProjectModal
                  open={terminateProjectModal}
                  handleClose={proposalTerminateClose}
                  handleComplete={proposalTerminate}
                  handleTerminateReason={handleTerminateReason}
                  />
                  <TaskCompletionModal
                    open={taskCompletionModal}
                    handleClose={closeTaskCompletionModal}
                    tasks={teamTasks22.teamTasks}
                    handleComplete={handleTaskCompletion}
                  />

                  <SignatureModal
                    open={isSignatureModalOpen}
                    handleClose={closeSignatureModal}
                    handleSignatureSubmit={handleSignatureSubmit}
                  />

                  <TeamDeadline
                    TeamModalState={isTeamModalState}
                    handleTeamModalClose={handleCloseTeamModal}
                    handleTime={handleTeamDeadLine}
                    selectedDate={deadLine}
                    handleProceed={handleTeamSubmit}
                  />


                  {isSubmitButtonShow &&  (
                    <button
                      onClick={() => {
                        // Handle the button click, you may need to modify this to handle the specific document if needed
                        const document = asssetsectionData?.find((document) =>
                          document?.taskAssignWith?.some(
                            (task) => task?.assignToEmail === cuurentUser
                          )
                        );

                        if (document) {
                          handleSubmitteTask(document?._id);
                        }
                      }}
                      style={{
                        borderRadius: "10px",
                        fontSize: "14px",
                        width: "100px",
                        border: "1px solid grey",
                        boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                        marginRight: "5px",
                        padding: "2px, 13px, 2px, 13px",
                      }}
                      
                    >
                      Submit
                    </button>
                  )}

                  {
                    rfmManagementData?.ProjectType?.data === "Shared Project" &&
                    sharedSectionDetails?.some(
                      (sharedData) =>
                        sharedData.id == assetsectionId &&
                        sharedData.selectedOption === "Edit"
                    ) && (
                      <button
                        onClick={() => {
                          handleAssetsectionupload(true);
                        }}
                        style={{
                          borderRadius: "10px",
                          fontSize: "14px",
                          backgroundColor: "#1976D2",
                          color: "white",
                          width: "100px",
                          boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                          padding: "5px, 13px, 5px, 13px",
                        }}
                        className="buttt"
                      >
                        Save
                      </button>
                    )}

                  {!showBtnInMerge && (
                    // rfmManagementData?.ProjectType?.data === "Oppoupload" 
                    (userInfo?.organizationId == orgId && asssetsectionData.find((document) =>
                      document?.taskAssignWith?.some((task) => task?.assignToEmail === cuurentUser)
                    ) )
                    ? (
                      <button
                        onClick={() => {
                          handleAssetsectionupload(true);
                        }}
                        style={{
                          borderRadius: "10px",
                          fontSize: "14px",
                          backgroundColor: "white",
                          color: "black",
                          width: "auto",
                          boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                          padding: "5px, 13px, 5px, 13px",
                          border: "1px solid grey",
                          
                        }}
                        className="buttt"
                      >
                        Save
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            handleAssetsectionupload();
                          }}
                          style={{
                            borderRadius: "10px",
                            fontSize: "14px",
                            backgroundColor: "white",
                            color: "black",
                            width: "auto",
                            boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                            padding: "5px, 13px, 5px, 13px",
                            border: "1px solid grey",

                            }}
                            className="buttt"
                          >
                            Save
                          </button>
                        </>
                      )
                  )}

                </div>
                
                {contentLibraryOpen &&
                  <ContentLibrary editorContent={editor1.current.value} setContent={setContent} setHtmlContent={setHtmlContent} openEditor={setIsgetDataEditor} isOpen={contentLibraryOpen} modalClose={closeContentLibrary} orgName={userInfo.organizationName} assetId={proposalWritingData.assetid} sectionId={assetsectionId} userId={userInfo._id} />
                }

              </div>
            </div>
          }
   
{/* <div style={{
        position: 'fixed',
        top: 70,
        right: isSidebarOpen ? 0 : '-100%',
        width: '500px',
        // height: '100vh',
        backgroundColor: 'white',
        boxShadow: '-5px 0px 5px rgba(0, 0, 0, 0.3)',
        transition: 'right 0.3s ease-in-out',
        overflowY: 'auto',
        zIndex: 1000,
        border:"2px solid red",
        // display:windowWidth <1760 ? "none" : "block"
      }}> */}
    
        <div className={(windowWidth <1024) ? "activityBlock" :"col-md-3"} >
          {/* Close button */}
          {(windowWidth <1024 && isSidebarOpen) &&
          <IconButton onClick={toggleSidebar} style={{ color:"red"}}>
          <CloseIcon />
        </IconButton>
          } 
          <div style={{display:windowWidth<1024 && !isSidebarOpen? "none":"block", padding: '10px',width:"100%"}} className={windowWidth <1024 ? 'col-md-12' : 'col-md-12'}>
            <div style={{ height: "90vh",width:"100%", overflow: "auto", display: "flex", flexDirection: "column", boxShadow: "-5px 0px 5px rgba(0, 0, 0, 0.3)", padding: "5px", paddingLeft: "10px" }}>
              <div style={{ border: '1px solid #1976D2', borderRadius: '5px',width:"100%" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      // <ExpandMoreIcon />
                      <span data-tip data-for="expandIconactivities">
                        <ExpandMoreIcon />
                      </span>
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography
                      style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}
                      data-tip
                      data-for="Activities"
                    >

                      Activities</Typography>
                    <ReactTooltip id="Activities" place="bottom" effect="solid">
                      Check all activities
                    </ReactTooltip>
                  </AccordionSummary>
                  <ReactTooltip id="expandIconactivities" place="bottom" effect="solid">
                    view all activities
                  </ReactTooltip>
                  <AccordionDetails>
                    <Typography>
                      <div style={{overflowY:'auto', height:'65vh'}}>
                      {activityData && activityData.sort((a, b) => b.seq - a.seq).slice(0, displayCount).map((data, i) => {
                        return (
                          <div key={i} style={{ marginBottom: "8px" }}>
                            {/* <span style={{ fontWeight: "600" }}>{data.createdBy}: </span> */}
                            <ReusableCard
                              text={data.msg}
                              Id={data._id}
                              createdby={data.createdBy}
                              date={data.createdOn}

                            >

                            </ReusableCard>
                          </div>
                        );
                      })}

                      </div>

                      <div className="flexbetween"  >
                        <div>
                          <h4 className="count_tag_">({activityCount})</h4>
                        </div>
                        <div className="flex">
                          {activityData && displayCount > 5 && (
                            <div style={{ cursor: "pointer", transition: "0.9s ease-in-out" }} onClick={handleShowLess}>
                              <ExpandLessIcon />
                            </div>
                          )}
                          {activityData && displayCount < activityData.length && displayCount !== activityData.length && (
                            <div style={{ cursor: "pointer", transition: "0.7s ease-in-out" }} onClick={handleShowMore}>
                              <ExpandMoreIcon />
                            </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>


              <div style={{ width: "96%", margin: "auto", height: "2px", backgroundColor: "grey", marginTop: "10px", marginBottom: "10px" }}>
              </div>

              <div style={{ border: '1px solid #1976D2', borderRadius: '5px', marginTop: "10px" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      // <ExpandMoreIcon />
                      <span data-tip data-for="expandIconTool">
                        <ExpandMoreIcon />
                      </span>
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", }}>
                      <div className="note_count_flex">
                        <Typography
                          style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}
                          data-tip
                          data-for="Notes"
                        >Notes</Typography>
                        <ReactTooltip id="Notes" place="bottom" effect="solid">
                          Notes for asset
                        </ReactTooltip>
                        <div>
                          <h4 className="count_tag_">({noteCount})</h4>
                        </div>
                      </div>
                      <button
                        className="open_button_"
                        onClick={(e) => { handleOpenModal(e) }}
                        data-tip
                        data-for="addnotetolist"
                      >
                        <AddCircleOutlineIcon />
                      </button>
                      <ReactTooltip id="addnotetolist" place="bottom" effect="solid">
                        Add notes
                      </ReactTooltip>
                      {/* <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                      >
                        <div className="modal-paper">
                          <h2 id="modal-title">Add Note</h2>
                          <TextField
                            id="note-textfield"
                            label="Note"
                            multiline
                            rows={4}
                            variant="outlined"
                          />
                          <div>
                            <Button onClick={handleAddNote} color="primary">
                              Add
                            </Button>
                            <Button onClick={handleCloseModal} color="secondary">
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </Modal> */}
                    </div>
                  </AccordionSummary>
                  <ReactTooltip id="expandIconTool" place="bottom" effect="solid">
                    view detail notes
                  </ReactTooltip>
                  <AccordionDetails>
                    <Typography>
                      {notesData &&
                        <div>
                          {notesData.sort((a, b) => b.seq - a.seq).map((note, index) => (
                            <ReusableCard
                              viewType={"noteCard"}
                              text={note.text}
                              Id={note._id}
                              deleteNote={handleDeleteNotes}
                              updateNote={handleUpdateNotes}
                              createdby={note.createdby}
                              onClickCard={openCard}
                              date={note.createdon}
                              replyToComment={handleNotesReply}
                              replyView={showReply ? "showReply" : ""}
                              replyArray={note.commentReply}
                              deleteNotesReply={handleDeleteNotesReply}
                              updateCommentReply={handleNotesReply}
                            > </ReusableCard>
                          ))}

                        </div>}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>

              <Modal open={isModalOpen} onClose={handleModalClose} className="modal_layout">
                <div className="modal_layout_div">
                  <div className="comment_modal_flex">
                    <div className="modal_title_">
                      {updateIcon == true ? (<h3>Update Note</h3>) : (<h3>Add Note</h3>)}
                    </div>
                    <div className="modal_closeIcon">
                      <IconButton onClick={handleModalClose}>
                        <HighlightOffIcon className="modal_closeIcon" />
                      </IconButton>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <TextField
                      multiline
                      value={note}
                      onChange={handleNoteChange}
                      // label={updateIcon == true ? ( "Update Note"):("Add Note")}
                      variant="outlined"
                      rows={11}
                      fullWidth
                      style={{ marginTop: '10px' }}
                    />
                  </div>
                  <div className="modal_div_buttons">
                    <Button
                      variant="outlined"
                      onClick={() => { handleCancel() }}
                      style={{
                        border: "1px solid #FF0000",
                        color: "#FF0000",
                        borderRadius: "10px",
                        outline: "none"
                      }}
                    >
                      Cancel
                    </Button>

                    {updateIcon === true ? (

                      <Button
                        variant="outlined"
                        onClick={() => { handleUpdateNoteApi() }}
                        style={{
                          backgroundColor: "#1976D2",
                          color: "white",
                          borderRadius: "10px",
                          outline: "none",
                          border: "none"
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => { handleAddNote() }}
                        style={{
                          backgroundColor: "#1976D2",
                          color: "white",
                          borderRadius: "10px",
                          outline: "none",
                          border: "none"
                        }}
                      >
                        Add
                      </Button>
                    )}


                  </div>
                </div>
              </Modal>

              <Modal open={isNotesReplyModelOpen} onClose={handleNotesReplyModelClose} className="modal_layout">
                <div className="modal_layout_div">

                  <div className="comment_modal_flex">
                    <div className="modal_title_">
                      {/* <h3>Add Reply</h3> */}
                      {notesReplyUpdateIcon == true ? (
                        <h3>Update Reply</h3>
                      ) : (
                        <h3>Add Reply</h3>
                      )}
                    </div>
                    <IconButton className="modal_closeIcon" onClick={handleNotesReplyModelClose} >
                      <CloseIcon className="modal_closeIcon" />
                    </IconButton>
                  </div>

                  <TextField
                    multiline
                    value={notesReplyText}
                    onChange={handleNotesReplyChange}
                    //  label="Add Comment.."
                    variant="outlined"
                    rows={11}
                    fullWidth
                    style={{ marginTop: '10px' }} />

                  <div className="modal_div_buttons">
                    {notesReplyUpdateIcon == true ? (
                      <Button
                        variant="outlined"
                        onClick={(e) => { handleUpdateNotesReply(e) }}
                        style={{
                          fontSize: "12px",
                          borderRadius: "10px",
                          color: "#1976D2",
                          borderColor: "#1976D2",
                          boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                          marginLeft: "10px"
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={(e) => { handleReplyToNotes(e) }}
                        style={{
                          fontSize: "12px",
                          borderRadius: "10px",
                          color: "#1976D2",
                          borderColor: "#1976D2",
                          boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                          marginLeft: "10px"
                        }}
                      >
                        Add Reply
                      </Button>
                    )}


                    <Button
                      variant="outlined"
                      onClick={() => { handleCancelNotesReply() }}
                      style={{
                        fontSize: "12px",
                        borderRadius: "10px",
                        color: "red",
                        borderColor: "red",
                        boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                        marginLeft: "10px"
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Modal>

              <div style={{ width: "96%", margin: "auto", height: "2px", backgroundColor: "grey", marginTop: "10px", marginBottom: "10px" }}>
              </div>

              <div style={{ border: '1px solid #1976D2', borderRadius: '5px' }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      // <ExpandMoreIcon />
                      <span data-tip data-for="expandIconTooltip">
                        <ExpandMoreIcon />
                      </span>
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography
                      style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}
                      data-tip
                      data-for="VersionHistory"
                    >Version History</Typography>
                    <ReactTooltip id="VersionHistory" place="bottom" effect="solid">
                      Sections and subsection version history
                    </ReactTooltip>


                  </AccordionSummary>
                  <ReactTooltip id="expandIconTooltip" place="bottom" effect="solid">
                    view detail history
                  </ReactTooltip>

                  <AccordionDetails>
                    {versionData?.map((data) => {
                      return (
                        <VersionHistoryCard
                          title={data.title}
                          date={data.date}
                          version={data.version}
                          createdBy={data.createdBy}
                          AssetSectionId={data.AssetSectionId}
                          //  handleSpecifcVersionData={specificVersion}
                          versionModal={handleVersionModal}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </div>


              <div style={{ width: "96%", margin: "auto", height: "2px", backgroundColor: "grey", marginTop: "10px", marginBottom: "10px" }}>
              </div>



              <div style={{ border: '1px solid #1976D2', borderRadius: '5px' }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      // <ExpandMoreIcon />
                      <span data-tip data-for="expandIconcomment">
                        <ExpandMoreIcon />
                      </span>
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <div className="comment_Flex_Button">
                      <div className="note_count_flex">
                        <Typography style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}
                          data-tip
                          data-for="addcomments"
                        >Comments</Typography>
                        <ReactTooltip id="addcomments" place="bottom" effect="solid">
                          View all comments
                        </ReactTooltip>
                        <div>
                          <h4 className="count_tag_" >({commentCount})</h4>
                        </div>
                      </div>
                      <button
                        className="open_button_"
                        onClick={(e) => { handleOpenCommentModal(e) }}
                        data-tip
                        data-for="addcommenttolist"
                      >
                        <AddCircleOutlineIcon />
                      </button>
                      <ReactTooltip id="addcommenttolist" place="bottom" effect="solid">
                        Add comments
                      </ReactTooltip>
                    </div>
                  </AccordionSummary>
                  <ReactTooltip id="expandIconcomment" place="bottom" effect="solid">
                    view all comments
                  </ReactTooltip>
                  <AccordionDetails>
                    <Typography>

                      {commentData &&
                        <div style={{ marginTop: '20px' }}>
                          {commentData.sort((a, b) => b.seq - a.seq).map((data, index) => (
                            <ReusableCard
                              viewType={"commentCard"}
                              text={data.text}
                              Id={data._id}
                              deleteComment={handleDeleteComments}
                              updateComment={handleUpdateComments}
                              createdby={data.createdby}
                              onClickCard={openCard}
                              date={data.createdon}
                              replyToComment={handleReply}
                              replyView={showReply ? "showReply" : ""}
                              replyArray={data.commentReply}
                              deleteCommentReply={handleDeleteCommentReply}
                              updateCommentReply={handleReply}


                            > </ReusableCard>
                          ))}
                        </div>}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              {isgetDataEditor &&
                <>

                  <div style={{ width: "96%", margin: "auto", height: "2px", backgroundColor: "grey", marginTop: "10px", marginBottom: "10px" }}>
                  </div>

                  <div style={{ border: '1px solid #1976D2', borderRadius: '5px' }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography style={{ fontWeight: "600", fontSize: "18px", color: "#1976D2" }}>Track Changes</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {trackedChanges && trackedChanges.length > 0 && (
                            <div>
                              {trackedChanges
                                .sort((a, b) => b.seq - a.seq)
                                .slice(0, displayCount)
                                .map((data, index) => (
                                  <div key={index} style={{ marginBottom: "8px" }}>
                                    <ReusableCard
                                      viewType="trackedChangesCard"
                                      Id={data._id}
                                      createdby={data.createdBy}
                                      date={data.createdOn}
                                      addedContent={data.addedContent}
                                      removedContent={data.removedContent}
                                      s3Version={data.s3VersionId}
                                      onClickCard={handleRevertChanges}
                                      documentBody={data.documentBody}
                                      mouseEnter={handleMouseEnter}
                                      mouseLeave={handleMouseLeave}
                                    />

                                  </div>
                                ))}
                              <div className="flexbetween">
                                <div>
                                  <h4 className="count_tag_">({trackedChanges.length})</h4>
                                </div>
                                <div className="flex">
                                  {displayCount > 5 && (
                                    <div style={{ cursor: "pointer", transition: "0.9s ease-in-out" }} onClick={handleShowLess}>
                                      <ExpandLessIcon />
                                    </div>
                                  )}
                                  {displayCount < trackedChanges.length && displayCount !== trackedChanges.length && (
                                    <div style={{ cursor: "pointer", transition: "0.7s ease-in-out" }} onClick={handleShowMore}>
                                      <ExpandMoreIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </>

              }

              <Button variant="contained" color="primary" style={{ marginTop: '15px' }} onClick={() => openChatModal()}>Chat</Button>
              <ChatModal
                open={chatModal}
                handleClose={closeChatModal}
                orgUsers={orgUsers}
                aid = {proposalWritingData.assetid}
                usrN = {userInfo.username}
              />
            </div>
          </div>
          </div>
{/* </div> */}

        </div >

        <Modal open={isCommentModalOpen} onClose={handleCommentModalClose} className="modal_layout">
          <div className="modal_layout_div">

            <div className="comment_modal_flex">
              <div className="modal_title_">
                {updatCommentIcon == true ? (<h3>Update Comment</h3>) : (<h3>Add Comment</h3>)}
              </div>
              <IconButton className="modal_closeIcon" onClick={handleCommentModalClose} >
                <CloseIcon className="modal_closeIcon" />
              </IconButton>
            </div>

            <TextField
              multiline
              value={commentsText}
              onChange={handleCommentChange}
              //  label="Add Comment.."
              variant="outlined"
              rows={11}
              fullWidth
              style={{ marginTop: '10px' }} />

            <div className="modal_div_buttons">
              <Button
                variant="outlined"
                onClick={() => { handleCancelComment() }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "red",
                  borderColor: "red",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px",
                  outline: "none"
                }}
              >
                Cancel
              </Button>
              {updatCommentIcon === true ? (
                <Button
                  variant="outlined"
                  onClick={(e) => { handleUpdateCommentApi(e) }}
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    borderRadius: "10px",
                    outline: "none",
                    border: "none"
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={(e) => { handleAddComment(e) }}
                  style={{
                    backgroundColor: "#1976D2",
                    color: "white",
                    borderRadius: "10px",
                    outline: "none",
                    border: "none"
                  }}
                >
                  Add Comment
                </Button>
              )}


            </div>
          </div>
        </Modal>

        <Modal open={isReplyModelOpen} onClose={handleReplyModelClose} className="modal_layout">
          <div className="modal_layout_div">

            <div className="comment_modal_flex">
              <div className="modal_title_">
                {/* <h3>Add Reply</h3> */}
                {commentReplyUpdateIcon == true ? (
                  <h3>Update Reply</h3>
                ) : (
                  <h3>Add Reply</h3>
                )}
              </div>
              <IconButton className="modal_closeIcon" onClick={handleReplyModelClose} >
                <CloseIcon className="modal_closeIcon" />
              </IconButton>
            </div>

            <TextField
              multiline
              value={replyText}
              onChange={handleReplyChange}
              //  label="Add Comment.."
              variant="outlined"
              rows={11}
              fullWidth
              style={{ marginTop: '10px' }} />

            <div className="modal_div_buttons">
              {commentReplyUpdateIcon == true ? (
                <Button
                  variant="outlined"
                  onClick={(e) => { handleUpdateCommentReply(e) }}
                  style={{
                    fontSize: "12px",
                    borderRadius: "10px",
                    color: "#1976D2",
                    borderColor: "#1976D2",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    marginLeft: "10px"
                  }}
                >
                  Update Reply
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={(e) => { handleReplyTocomment(e) }}
                  style={{
                    fontSize: "12px",
                    borderRadius: "10px",
                    color: "#1976D2",
                    borderColor: "#1976D2",
                    boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                    marginLeft: "10px"
                  }}
                >
                  Add Reply
                </Button>
              )}


              <Button
                variant="outlined"
                onClick={() => { handleCancelReply() }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "red",
                  borderColor: "red",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px"
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        <Modal open={isCardDatModalOpen} onClose={handleCardDataModalClose} className="modal_layout">
          <div className="modal_layout_div">

            <div className="comment_modal_flex">
              <div className="modal_title_">
                <h4>{createdByCard}</h4>
              </div>
              <IconButton className="modal_closeIcon" onClick={handleCardDataModalClose} >
                <CloseIcon className="modal_closeIcon" />
              </IconButton>
            </div>

            <TextField
              multiline
              value={cardData}
              variant="outlined"
              InputProps={{ readOnly: true }}
              rows={11}
              fullWidth
              style={{ marginTop: '10px' }} />
            <div className="modal_div_buttons">

              <Button
                // variant="outlined"
                onClick={() => { handlePreviousCard() }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "#1976D2",
                  borderColor: "#1976D2",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px"
                }}
              >
                Previous
              </Button>

              <Button
                // variant="outlined"
                onClick={() => { handleNextCard() }}
                style={{
                  fontSize: "12px",
                  borderRadius: "10px",
                  color: "#1976D2",
                  borderColor: "#1976D2",
                  boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
                  marginLeft: "10px"
                }}
              >
                Next
              </Button>



            </div>
          </div>
        </Modal>


        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div>
                <>
                  <div className="proposal_header_flex">
                    <h3 className='proposal_header_tag'>Proposal Details</h3>
                    <IconButton style={{}} className='proposal_close_button' onClick={handleClose}>
                      <CloseIcon className='proposal_close_button' />
                    </IconButton>
                  </div>
                  <form style={{ display: "flex", flexDirection: "column" }}>

                    {fieldsToRender?.map((match, index) => {
                      let labelText = "";
                      console.log("Rendering field:", match);
                      // Existing code for other label types
                      return (
                        <div key={index} className="form-group" style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className='sub_parentflex_label'>
                            <div className='Lable_inner_flex'>
                              <div className='Lable_inner_tag'>
                                <label htmlFor={match} style={{ marginBottom: "10px" }}><b>{match}:</b></label>
                              </div>
                              <div className='Dropdown_width'>
                                <FormControl className='FormControl_Tag' sx={{ m: 1, }}>
                                  <Select
                                    value={dataTypeMap[match] || ''}
                                    onChange={(e) => handleDataTypeChange(e, match)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"Input"}>Input</MenuItem>
                                    <MenuItem value={"Date"}>Date</MenuItem>
                                    <MenuItem value={"Image"}>Image</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                            <div className='rightSide_flex'>
                              {dataTypeMap[match] == "Input" &&
                                <div>
                                  <TextField
                                    type="text"
                                    style={{ width: "100%" }}
                                    id={match}
                                    variant='outlined'
                                    name={match.toLowerCase().replace(/\s/g, "-")}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              }

                              {dataTypeMap[match] == "Image" &&
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                  <IconButton title="Upload" htmlFor={`uploadLogo-${index}`} onClick={() => document.getElementById(`uploadLogo-${index}`).click()}>
                                    <UploadIcon />   <VisuallyHiddenInput
                                      id={`uploadLogo-${index}`}
                                      type="file"
                                      onChange={(e) => { logoUpload(e, match) }}
                                    />
                                  </IconButton>
                                </div>
                              }
                              {dataTypeMap[match] === "Date" &&
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                  <input
                                    type='Date'
                                    style={{ padding: "14px", width: "100%" }}
                                    // selected={formData[match] || null}
                                    placeholder='Enter'
                                    value={formData[match.toLowerCase().replace(/\s/g, "-")]}
                                    onChange={(e) => handleDatePicker(e, match)}
                                  />
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div style={{ display: "flex", justifyContent: "center" }}>

                      <Button style={{ margin: "10px" }} variant="contained" color="primary" onClick={handleSubmitClick}>Submit</Button>
                    </div>
                  </form>
                </>

              </div>
            </Typography>
          </Box>
        </Modal>


        <Modal
          open={openSummaryModal}
          onClose={() => setOpenSummaryModal(false)}
          aria-labelledby="summary-modal-title"
          aria-describedby="summary-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              outline: "none",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              width: "80%",
              marginTop: "25px"
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10px", alignItems: "center" }}>
              <Typography variant="h5" id="summary-modal-title" style={{ color: "#007bff", fontWeight: 700 }}>
                Notice Summary
              </Typography>
              <IconButton style={{ color: "red" }} onClick={() => setOpenSummaryModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>

              <div style={{ width: "270px", marginBottom: "15px" }}>
                <OpportunityInputBoxMui fontBold={"bold"} value={propWritingData?.type} label={"Type"} />
              </div>

              <div style={{ width: "270px", marginBottom: "15px" }}>
                <OpportunityInputBoxMui fontBold={"bold"} value={propWritingData?.agancy} label={"Issuing Agency"} />
              </div>

              <div style={{ width: "270px", marginBottom: "15px" }}>
                <OpportunityInputBoxMui fontBold={"bold"} value={normalDate} label={"Submission deadline"} statusColor={statusColor} />
              </div>

            </div>

            <div style={{ marginBottom: "25px" }}>
              <OpportunityInputBoxMui fontBold={"bold"} value={propWritingData?.title} label={"Title"} />
            </div>

            <div style={{ marginBottom: "25px" }}>
              <OpportunityMultilineInputBoxMui color={"black"} value={cleanedInitialContent} label={"Description"} rows={8} />


            </div>

            <div style={{ marginBottom: "25px", overflow: "auto" }}>
              <OpportunityMultilineInputBoxMui style={{ marginTop: "10px" }} color={"black"} value={summarizedDescription} label={"Summarized Description"} rows={8} />

            </div>

          </div>
        </Modal>

        <Modal
          open={openAddSubSectionModal}
          onClose={() => setOpenAddSubSectionModal(false)}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              outline: "none",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              Width: "400px",
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10px", width: "400px" }}>
              <Typography variant="h5" id="summary-modal-title" style={{ color: '#1976d2' }}>
                Add Sub-Section
              </Typography>
              <IconButton onClick={() => setOpenAddSubSectionModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>

            <TextField
              label="Title"
              value={subTitle}
              variant="outlined"
              fullWidth
              required
              margin="normal"
              onChange={handleSubAppNameChange}
            />
            <TextField
              label="Description"
              value={addSubDescription}
              variant="outlined"
              fullWidth
              multiline
              required
              rows={4}
              margin="normal"
              onChange={handleSubDescriptionChange}
            />

            <div style={{
              marginTop: "20px", display: "flex", justifyContent: "center",
              columnGap: "5px"
            }}>
              <Button
                style={{
                  width: "100px",
                  border: "1px solid #FF0000",
                  color: "#FF0000",
                  borderRadius: "10px",
                }}
                color="secondary"
                onClick={() => setOpenAddSubSectionModal(false)}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: "100px",
                  backgroundColor: "#1976D2",
                  color: "white",
                  borderRadius: "10px",
                }}
                onClick={AddSubSectionData}
              >
                Save
              </Button>

            </div>
          </div>
        </Modal>


        <Modal
          open={openAddModal}
          onClose={() => setOpenAddModal(false)}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              outline: "none",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              Width: "65%",

            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10px", width: "100%", color: "#1976D2" }}>
              <Typography variant="h5" id="summary-modal-title">
                Add Section
              </Typography>
              <IconButton onClick={() => setOpenAddModal(false)}>
                <HighlightOffIcon style={{ color: "red" }} />
              </IconButton>
            </div>


            <TextField
              label="Title"
              value={title}
              variant="outlined"
              fullWidth
              required
              margin="normal"
              onChange={handleAppNameChange}
            />
            <TextField
              label="Description"
              value={adddescription}
              variant="outlined"
              fullWidth
              multiline
              required
              rows={4}
              margin="normal"
              onChange={handleDescriptionChange}
            />



            <div style={{
              marginTop: "20px", display: "flex", justifyContent: "center",
              columnGap: "5px"
            }}>
              <Button
                style={{
                  width: "100px",
                  border: "1px solid #FF0000",
                  color: "#FF0000",
                  borderRadius: "10px",
                }}
                color="secondary"
                onClick={() => { setOpenAddModal(false) }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: "100px",
                  backgroundColor: "#1976D2",
                  color: "white",
                  borderRadius: "10px",
                }}
                onClick={() => AddSectionData()}
              >
                Save
              </Button>

            </div>
          </div>
        </Modal>

        {/* Without placeholder */}
        <Modal

          open={isTemplatListModal}
          onClose={handleCloseWithoutPlaceholder}
        >


          <div className="template_list_modal">
            {templateManagement2 && !gallery2 && !preview2 && (
              <>
                {/* <h1>Template management </h1>
                <Button onClick={handleGalleryClick2}>Gallery</Button>
                <Button onClick={handlePreviewClick2}>Preview</Button> */}




                <div className="common_hader_flex_style">
                  <Typography className='proposal_header_tag' variant="h5" id="summary-modal-title">
                    Template Management
                  </Typography>
                  <IconButton onClick={handleCloseWithoutPlaceholder}>
                    <CloseIcon className='proposal_close_button' />
                  </IconButton>
                </div>



                <div className="template_list_div">

                  <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>

                    {/* Blank Template */}
                    <Box
                      style={{
                        backgroundColor: "white",
                        width: "200px",
                        height: "240px",
                        padding: "5px",
                        borderRadius: "10px",
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                        margin: "5px"
                      }}
                      onClick={handleBlankTemplateInWithPlaceholder2}
                      sx={{ minWidth: 200 }}
                    >
                      <React.Fragment>
                        <CardContent>


                          <div style={{ height: "220px", width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'start' }} >

                            <div style={{ height: "180px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <ControlPointIcon style={{ fontSize: "80px", color: "#1976D2" }} />
                            </div>

                            <div style={{ height: "40px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", borderTop: "1px solid black" }}>
                              Blank Template
                            </div>



                          </div>

                        </CardContent>
                      </React.Fragment>
                    </Box>




                    <Box
                      onClick={handleGalleryClick2}
                      style={{
                        backgroundColor: "white",
                        width: "200px",
                        height: "240px",
                        padding: "5px",
                        borderRadius: "10px",
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)", margin: "5px"
                      }}
                      sx={{ minWidth: 200 }}
                    >
                      <React.Fragment>
                        <CardContent>
                          <div style={{ height: "220px", width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'start' }} >

                            <div style={{ height: "180px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <ImageIcon style={{ fontSize: "80px", color: "#1976D2" }} />
                            </div>

                            <div style={{ height: "40px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", borderTop: "1px solid black" }}>
                              Gallery
                            </div>



                          </div>
                        </CardContent>
                      </React.Fragment>
                    </Box>


                  </div>

                  <div style={{ display: "flex", justifyContent: "start", flexWrap: "wrap", gap: "20px" }}>
                    <div style={{ width: "100%", marginTop: "30px", marginBottom: "30px", height: "30px" }}>
                      <Typography variant="h5" gutterBottom>My Templates</Typography>
                    </div>

                    {templateList?.length > 0 && templateList.map((template, index) => (
                      <Box
                        key={index}
                        style={{
                          backgroundColor: "white",
                          width: "200px",
                          height: "240px",
                          padding: "5px",
                          borderRadius: "10px",
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                          margin: "5px",
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        sx={{ minWidth: 200 }}
                      >
                        <CardContent>
                          <div style={{ backgroundColor: "white", height: "150px", whiteSpace: 'normal', maxWidth: "100%" }}
                            onClick={() => handleCardClick(template.fileName, template.title, template._id, template.path, "", "", "template")}

                          >
                            <h5 style={{ fontSize: "18px" }}>{truncateFileName(template.title, 15)}</h5>
                            <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: "14px" }}>
                              <b>Created by: </b>{template.createdBy}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: "14px" }}>
                              <b>Created on: </b>{formatDate(template.createdOn)}
                            </Typography>
                          </div>
                        </CardContent>
                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 'auto', width: "98%" }}>
                          {/* Preview Icon */}
                          <IconButton className="icon_button_padding" title="Preview" disableRipple>
                            <PreviewIcon onClick={() => handlePreviewClick2(template.fileName, template.path, "", "", "management")} style={{ color: "green" }} />
                          </IconButton>
                        </div>
                      </Box>
                    ))}


                    <div style={{ width: "100%", marginTop: "30px", marginBottom: "30px", height: "30px" }}>
                      <Typography variant="h5" gutterBottom>My cover</Typography>
                    </div>

                    {fullProposalvrushabh &&
                      <div
                        style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap', gap: '20px' }}
                      >
                        {fullProposalvrushabh?.map((template, index) => (
                          <SingleTemplate
                            // key={index}
                            title={template}
                            preview={() => handleCoverPreview(template)}
                            AddClick={() => handleCoverClick(template)}
                          />
                        ))}
                      </div>
                    }

                  </div>
                </div>







              </>
            )}



            {!templateManagement2 && gallery2 && !preview2 && (
              <>
                {/* <h1>Gallery</h1>
                <Button onClick={handleGalleryBack2}>Back</Button>
                <Button onClick={handlePreviewClick2}>Preview</Button> */}

                <div className="common_hader_flex_style" >
                  <Typography className='proposal_header_tag' variant="h5" id="summary-modal-title">
                    Gallery
                  </Typography>
                  <IconButton onClick={handleCloseWithoutPlaceholder}>
                    <CloseIcon className='proposal_close_button' />
                  </IconButton>
                </div>
                <div style={{ overflowY: "auto", minHeight: "70vh", maxHeight: "70vh" }}>
                  {galleryTemplateList && galleryTemplateList.length > 0 ? (
                    galleryTemplateList.map((categoryData, index) => (
                      <div key={index} style={{ marginBottom: "30px" }}>
                        <h3>{categoryData.category}</h3>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "30px", // Spacing between items
                          }}
                        >
                          {categoryData.files.map((data, fileIndex) => (
                            <Box
                              key={fileIndex}
                              style={{
                                backgroundColor: "white",
                                width: "200px",
                                height: "240px",
                                padding: "5px",
                                borderRadius: "10px",
                                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                                marginBottom: "30px",
                              }}
                              sx={{ minWidth: 200 }}
                            >
                              <CardContent
                                onClick={() => handleCardClick("", "", "", "", data.fileName, categoryData.category, "gallery")}
                                style={{ cursor: "pointer" }}
                              >
                                <div style={{ height: "150px", whiteSpace: "normal" }}>
                                  <h5 style={{ fontSize: "18px" }}>
                                    {truncateFileName(data.fileName, 15)}
                                  </h5>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ fontSize: "14px" }}
                                  >
                                    <b>Created by:</b> person 1
                                  </Typography>
                                </div>
                              </CardContent>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  marginTop: "auto",
                                  width: "100%",
                                }}
                              >
                                <IconButton
                                  className="icon_button_padding"
                                  title="Preview"
                                  disableRipple
                                >
                                  <PreviewIcon
                                    onClick={() =>
                                      handlePreviewClick2("", "", data.fileName, categoryData.category, "gallery")}
                                    style={{ color: "green" }}
                                  />
                                </IconButton>
                              </div>
                            </Box>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No gallery templates available.</p>
                  )}
                </div>




                <Button color="primary" variant="contained" style={{ marginTop: "20px" }} onClick={handleGalleryBack2} >Back</Button>


              </>
            )}



            {!templateManagement2 && !gallery2 && preview2 && (
              <>
                {/* <h1>Preview page</h1>

                <Button onClick={handlePreviewClose2}>Close</Button> */}
                <div style={{ height: "40px", width: "100%", display: "flex", flexDirection: "row" }}>
                  <div style={{ color: "#1976D2", height: "40px", width: "90%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                    <h3>{fileName}</h3>
                  </div>

                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", width: "10%" }}>


                    <CloseIcon onClick={handlePreviewClose2} className="close-icon" style={{ color: 'red', cursor: 'pointer' }} />


                  </div>
                </div>



                <div className="p-2 mt-2" style={{ height: "80vh" }}>
                  <JoditEditor
                    value={editorStateWithPlaceholder}
                    ref={editor}
                    config={configForPlaceholder}
                    style={{ height: '70%', width: "90%" }}
                  />


                  {isPopupOpen && (
                    <Modal
                      open={isPopupOpen}
                      onClose={handleClosePopup}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}

                    >

                      <div
                        style={{
                          backgroundColor: 'white',
                          padding: '20px',
                          borderRadius: '10px',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          height: '95%', width: "90%", // Set a fixed height
                          overflow: 'auto', // Hide any overflow
                          // position: 'relative',
                          //  zIndex: 2,
                        }}
                      >
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                          <Button

                            onClick={handleClosePopup}
                            style={{ marginTop: '10px' }}
                          >
                            <CloseIcon className='.proposal_close_button' />
                          </Button>
                        </div>

                        <iframe
                          src={fileURL}
                          id='previewIframe'
                          title="PDF Viewer"
                          width="100%"
                          height="700"
                        ></iframe>


                      </div>



                    </Modal>
                  )}
                </div>



              </>
            )}


          </div>






        </Modal>

        <Modal open={isOpenNewModal} onClose={() => setIsOpenNewModal(false)}>
          <div className="requestModal">
            {!isFilePreviewOpen ? (
              <>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", width: "100%", alignItems: "center" }}>
                  <Typography variant="h5" className='proposal_header_tag'>
                    Add Files
                  </Typography>
                  <IconButton onClick={() => setIsOpenNewModal(false)}>
                    <CloseIcon id="closeIcon" />
                  </IconButton>
                </div>

                <div style={{ width: "100%", marginTop: "30px", marginBottom: "30px", height: "30px" }}>
                  <Typography variant="h5" gutterBottom>Flowchart and Diagrams</Typography>
                </div>

                <div style={{ display: "flex", justifyContent: "start", flexWrap: "wrap", gap: "20px" }}>
                  {showFlowcharts && (
                    <div style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap', gap: '20px' }}>
                      {showFlowcharts?.map((template, index) => (
                        <div 
                        // style={{ border: "1px solid #ccc", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
                        >
                          <SingleTemplate
                            key={index}
                            title={template}
                            preview={() => handleImagePreview(template)}
                            AddClick={() => handleDiagramsClick(template)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div style={{ width: "100%", marginTop: "30px", marginBottom: "30px", height: "30px" }}>
                  <Typography variant="h5" gutterBottom>My cover</Typography>
                </div>

                {fullProposalvrushabh &&
                  <div
                    style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap', gap: '20px' }}
                  >
                    {fullProposalvrushabh?.map((template, index) => (
                      <div 
                      // style={{ border: "1px solid #ccc", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
                      >
                        <SingleTemplate
                          // key={index}
                          title={template}
                          preview={() => handleCoverPreview(template)}
                          AddClick={() => handleCoverClick(template)}
                        />
                      </div>
                    ))}
                  </div>
                }

                <div style={{ width: "100%", marginTop: "30px", marginBottom: "30px", height: "30px" }}>
                  <Typography variant="h5" gutterBottom>Spreadsheets</Typography>
                </div>

                <div style={{ display: "flex", justifyContent: "start", flexWrap: "wrap", gap: "20px" }}>
                  {showEstimateTemplate && (
                    <div style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap', gap: '20px' }}>
                      {showEstimateTemplate?.map((template, index) => (
                        <div 
                        // style={{ border: "1px solid #ccc", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}
                        >
                          <SingleTemplate
                            key={index}
                            title={template}
                            preview={() => handleEstimatePreview(template)}
                            AddClick={() => handlePDFDataInEditor(template)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div style={{ height: "40px", width: "100%", display: "flex", flexDirection: "row" }}>
                  <div style={{ color: "#1976D2", height: "40px", width: "90%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                    <h3>{fileName}</h3>
                  </div>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", width: "10%" }}>
                    <CloseIcon onClick={handleFilePreviewClose} className="close-icon" style={{ color: 'red', cursor: 'pointer' }} />
                  </div>
                </div>
                <div className="p-2 mt-2" style={{ height: "80vh" }}>
                  {filePreviewEditor && <JoditEditor
                    value={filePreviewEditor}
                    ref={editor}
                    config={configForPlaceholder}
                    style={{ height: '70%', width: "90%" }}
                  />}
                  {fileURL && <>
                    <iframe
                      src={fileURL}
                      id='previewIframe'
                      title="PDF Viewer"
                      width="100%"
                      height="700"
                    ></iframe>
                  </>}


                  {htmlPreview && <>
                    <div style={{ width: "100%", pointerEvents: htmlPreview ? 'none' : 'auto', }}>
                      {htmlPreview && (
                        <div>
                          <div dangerouslySetInnerHTML={{ __html: htmlPreview }} />
                        </div>


                      )}
                    </div>
                  </>}
                </div>

              </>
            )}
          </div>
        </Modal>

        {/* With placeholder */}

        <Modal
          open={isTemplatListWithPlaceholderModal}
          onClose={() => handleCloseListTemplatListWithPlaceholderModal()}

        >
          <div className="template_list_modal">
            {templateManagement && !gallery && !preview && !placeholder && (
              <>


                <div className="common_hader_flex_style">
                  <Typography className='proposal_header_tag' variant="h5" id="summary-modal-title">
                    Template Management
                  </Typography>
                  <IconButton onClick={handleCloseListTemplatListWithPlaceholderModal}>
                    <CloseIcon className='proposal_close_button' />
                  </IconButton>
                </div>



                <div className="template_list_div">

                  <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>

                    {/* Blank Template */}
                    <Box
                      style={{
                        backgroundColor: "white",
                        width: "200px",
                        height: "240px",
                        padding: "5px",
                        borderRadius: "10px",
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                        margin: "5px"
                      }}
                      onClick={handleBlankTemplateInWithPlaceholder}
                      sx={{ minWidth: 200 }}
                    >
                      <React.Fragment>
                        <CardContent>


                          <div style={{ height: "220px", width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'start' }} >

                            <div style={{ height: "180px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <ControlPointIcon style={{ fontSize: "80px", color: "#1976D2" }} />
                            </div>

                            <div style={{ height: "40px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", borderTop: "1px solid black" }}>
                              Blank Template
                            </div>



                          </div>

                        </CardContent>
                      </React.Fragment>
                    </Box>




                    <Box
                      onClick={handleGalleryClick}
                      style={{
                        backgroundColor: "white",
                        width: "200px",
                        height: "240px",
                        padding: "5px",
                        borderRadius: "10px",
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)", margin: "5px"
                      }}
                      sx={{ minWidth: 200 }}
                    >
                      <React.Fragment>
                        <CardContent>
                          <div style={{ height: "220px", width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'start' }} >

                            <div style={{ height: "180px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <ImageIcon style={{ fontSize: "80px", color: "#1976D2" }} />
                            </div>

                            <div style={{ height: "40px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", borderTop: "1px solid black" }}>
                              Gallery
                            </div>



                          </div>
                        </CardContent>
                      </React.Fragment>
                    </Box>


                  </div>

                  <div style={{ display: "flex", justifyContent: "start", flexWrap: "wrap", gap: "20px" }}>
                    <div style={{ width: "100%", marginTop: "30px", marginBottom: "30px", height: "30px" }}>
                      <Typography variant="h5" gutterBottom>My Templates</Typography>
                    </div>

                    {templateList?.length > 0 && templateList.map((template, index) => (
                      <Box
                        key={index}
                        style={{
                          backgroundColor: "white",
                          width: "200px",
                          height: "240px",
                          padding: "5px",
                          borderRadius: "10px",
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                          margin: "5px",
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        sx={{ minWidth: 200 }}
                      >
                        <CardContent>
                          <div style={{ backgroundColor: "white", height: "150px", whiteSpace: 'normal', maxWidth: "100%" }}
                            onClick={() => handlePlaceholderFields(template.fileName, template.title, template._id, template.path, "", "", "template")}

                          >
                            <h5 style={{ fontSize: "18px" }}>{truncateFileName(template.title, 15)}</h5>
                            <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: "14px" }}>
                              <b>Created by: </b>{template.createdBy}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: "14px" }}>
                              <b>Created on: </b>{formatDate(template.createdOn)}
                            </Typography>
                          </div>
                        </CardContent>
                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 'auto', width: "98%" }}>
                          {/* Preview Icon */}
                          <IconButton className="icon_button_padding" title="Preview" disableRipple>
                            <PreviewIcon onClick={() => handlePreviewClick(template.fileName, template.path, "", "", "management")} style={{ color: "green" }} />
                          </IconButton>
                        </div>
                      </Box>
                    ))}
                  </div>
                </div>



              </>
            )}



            {/* Conditional rendering for other pages */}
            {!templateManagement && gallery && !preview && !placeholder && (
              <>

                <div className="common_hader_flex_style" >
                  <Typography className='proposal_header_tag' variant="h5" id="summary-modal-title">
                    Gallery
                  </Typography>
                  <IconButton onClick={handleCloseListTemplatListWithPlaceholderModal}>
                    <CloseIcon className='proposal_close_button' />
                  </IconButton>
                </div>
                <div style={{ overflowY: "auto", minHeight: "70vh", maxHeight: "70vh" }}>
                  {galleryTemplateList && galleryTemplateList.length > 0 ? (
                    galleryTemplateList.map((categoryData, index) => (
                      <div key={index} style={{ marginBottom: "30px" }}>
                        <h3>{categoryData.category}</h3>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "30px", // Spacing between items
                          }}
                        >
                          {categoryData.files.map((data, fileIndex) => (
                            <Box
                              key={fileIndex}
                              style={{
                                backgroundColor: "white",
                                width: "200px",
                                height: "240px",
                                padding: "5px",
                                borderRadius: "10px",
                                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                                marginBottom: "30px",
                              }}
                              sx={{ minWidth: 200 }}
                            >
                              <CardContent
                                onClick={() =>
                                  handlePlaceholderFields(
                                    "",
                                    "",
                                    "",
                                    "",
                                    data.fileName,
                                    categoryData.category,
                                    "gallery"
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div style={{ height: "150px", whiteSpace: "normal" }}>
                                  <h5 style={{ fontSize: "18px" }}>
                                    {truncateFileName(data.fileName, 15)}
                                  </h5>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    gutterBottom
                                    style={{ fontSize: "14px" }}
                                  >
                                    <b>Created by:</b> person 1
                                  </Typography>
                                </div>
                              </CardContent>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  marginTop: "auto",
                                  width: "100%",
                                }}
                              >
                                <IconButton
                                  className="icon_button_padding"
                                  title="Preview"
                                  disableRipple
                                >
                                  <PreviewIcon
                                    onClick={() =>
                                      handlePreviewClick(
                                        "",
                                        "",
                                        data.fileName,
                                        categoryData.category,
                                        "gallery"
                                      )
                                    }
                                    style={{ color: "green" }}
                                  />
                                </IconButton>
                              </div>
                            </Box>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No gallery templates available.</p>
                  )}
                </div>




                <Button color="primary" variant="contained" style={{ marginTop: "20px" }} onClick={handleGalleryBack} >Back</Button>
              </>


            )}
            {!templateManagement && !gallery && preview && !placeholder && (
              <>

                {/* <Button onClick={handlePreviewClose}>Close</Button> */}



                {/* <div
                  style={{
                 
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    width: '70%',  // Set a fixed width
                    height: '85%', // Set a fixed height
                    overflow: 'auto', // Hide any overflow
                    position: 'relative',
                  }}
                > */}

                <div style={{ height: "40px", width: "100%", display: "flex", flexDirection: "row" }}>
                  <div style={{ color: "#1976D2", height: "40px", width: "90%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                    <h3>{fileName}</h3>
                  </div>

                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", width: "10%" }}>


                    <CloseIcon onClick={handlePreviewClose} className="close-icon" style={{ color: 'red', cursor: 'pointer' }} />


                  </div>
                </div>



                <div className="p-2 mt-2" style={{ height: "80vh" }}>
                  <JoditEditor
                    value={editorStateWithPlaceholder}
                    ref={editor}
                    config={configForPlaceholder}
                    style={{ height: '70%', width: "90%" }}
                  />
                </div>


                {/* </div> */}


              </>
            )}
            {!templateManagement && !gallery && !preview && placeholder && (
              <>








                <div>
                  <>
                    <div className="proposal_header_flex">
                      <h3 className='proposal_header_tag'>Proposal Details</h3>
                      <IconButton style={{}} className='proposal_close_button' onClick={handleCloseListTemplatListWithPlaceholderModal}>
                        <CloseIcon className='proposal_close_button' />
                      </IconButton>
                    </div>
                    <form style={{ height: "500px", display: "flex", flexDirection: "column", overflow: "auto" }}>

                      {fieldsToRender?.map((match, index) => {
                        let labelText = "";
                        console.log("Rendering field:", match);
                        // Existing code for other label types
                        return (
                          <div key={index} className="form-group" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className='sub_parentflex_label'>
                              <div className='Lable_inner_flex'>
                                <div className='Lable_inner_tag'>
                                  <label htmlFor={match} style={{ marginBottom: "10px" }}><b>{match}:</b></label>
                                </div>
                                <div className='Dropdown_width'>
                                  <FormControl className='FormControl_Tag' sx={{ m: 1, }}>
                                    <Select
                                      value={dataTypeMap[match] || ''}
                                      onChange={(e) => handleDataTypeChange(e, match)}
                                      displayEmpty
                                      inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      <MenuItem value={"Input"}>Input</MenuItem>
                                      <MenuItem value={"Date"}>Date</MenuItem>
                                      <MenuItem value={"Image"}>Image</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className='rightSide_flex'>
                                {dataTypeMap[match] == "Input" &&
                                  <div>
                                    <TextField
                                      type="text"
                                      style={{ width: "100%" }}
                                      id={match}
                                      variant='outlined'
                                      name={match.toLowerCase().replace(/\s/g, "-")}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                }

                                {dataTypeMap[match] == "Image" &&
                                  <div style={{ display: "flex", justifyContent: "center" }}>
                                    <IconButton title="Upload" htmlFor={`uploadLogo-${index}`} onClick={() => document.getElementById(`uploadLogo-${index}`).click()}>
                                      <UploadIcon />   <VisuallyHiddenInput
                                        id={`uploadLogo-${index}`}
                                        type="file"
                                        onChange={(e) => { logoUpload(e, match) }}
                                      />
                                    </IconButton>
                                  </div>
                                }
                                {dataTypeMap[match] === "Date" &&
                                  <div style={{ display: "flex", justifyContent: "center" }}>
                                    <input
                                      type='Date'
                                      style={{ padding: "14px", width: "100%" }}
                                      // selected={formData[match] || null}
                                      placeholder='Enter'
                                      value={formData[match.toLowerCase().replace(/\s/g, "-")]}
                                      onChange={(e) => handleDatePicker(e, match)}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    </form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button style={{ margin: "10px" }} variant="contained" color="primary" onClick={handlePlaceholderBack}>Back</Button>
                      <Button style={{ margin: "10px" }} variant="contained" color="primary" onClick={handleSubmitClick}>Submit</Button>
                    </div>
                  </>

                </div>










              </>
            )}
          </div>
        </Modal>

        <Modal
          open={isOpenClauseModal}
          onClose={() => setIsOpenClauseModal(false)}
        >
          <div className="requestModal">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" id="Clause-modal-title">
                Clauses
              </Typography>
              <IconButton onClick={() => setIsOpenClauseModal(false)}>
                <CloseIcon id="closeIcon" />
              </IconButton>
            </div>

            <div id="clauses_textfeild_flex">
              <div id="clauses_textfeild_main">
                <TextField
                  label={<div>Add Clause</div>}
                  value={addManualClause}
                  variant="outlined"
                  size="small"
                  style={{ width: "100%", borderRadius: "20px" }}
                  onChange={handleAddClauses}
                />
              </div>
              <div id="clauses_addButton_main">
                <Button
                  title="Upload"
                  variant="outlined"
                  onClick={handleAddReasonModalOpen}
                  id="addclauseButton"
                >
                  Add
                  {/* <span style={{ marginLeft: "8px" }}>
                        <SendIcon />
                      </span> */}
                </Button>
              </div>

              {extractedClause &&
                <div>
                  <IconButton>
                    <div title="Download" onClick={handleDownloadClause}>
                      <DownloadIcon />
                    </div>
                  </IconButton>
                </div>
              }
            </div>

            {!extractedClause &&
              <div style={{ display: "flex", justifyContent: "center", }}>
                <h4>No clauses found </h4>
              </div>
            }

            <div id="extractedClause_div">
              {extractedClause && (
                <>
                  <div>
                    {extractedClause?.clauses?.map((clauseData, i) => (
                      <div key={i}>

                        <h5 id="filename_ptag">
                          Filename:{" "}
                          <span className="filname_Style">
                            {clauseData.filename}
                          </span>
                        </h5>

                        {clauseData?.responseData
                          ?.split("\n")
                          .map((clause, j) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={j}
                            >
                              <p
                                id="clause_ptag"
                                onClick={() => {
                                  handleClauseModalProject(
                                    clause,
                                    clauseData._id,
                                    extractedClause._id,
                                    extractedClause.assetId
                                  );
                                }}
                              >
                                {clause}
                              </p>

                            </div>
                          ))}
                      </div>

                    ))}
                  </div>
                  <div>
                    {extractedClause && extractedClause.manualClause && extractedClause.manualClause.length > 0 &&
                      <div>
                        <h4 id="filename_ptag" style={{ fontSize: "18px" }}>Manually Added Clauses</h4>
                      </div>
                    }
                    {extractedClause?.manualClause?.map((data, i) => {

                      const shouldRender =
                        (data.status === "pending" && data.createdBy === cuurentUser) ||
                        (data.status === "pending" && 
                          userInfo.userRole.map(role => role.trim().toLowerCase()).some(role => 
                            role === "manager" || role === "application admin"
                          )
                        ) || 
                        (data.status === "approved") ||
                        (data.status === "rejected" && data.createdBy === cuurentUser);

                      if (!shouldRender) {
                        return null; // Do not render this clause
                      }
                      return (
                        <div style={{ padding: "5px 0px", borderBottom: "1px solid lightgray" }}>


                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {i + 1}.
                              <span
                                id="clause_ptag"
                                onClick={() => {
                                  handleClauseModalProject(
                                    data.clause,
                                    data._id,
                                    extractedClause._id,
                                    extractedClause.assetId
                                  );
                                }}
                              >{data.clause}
                              </span>
                            </div>


                            <div
                              style={{
                                width: "fit-content",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                              }}
                            >
                              {!userInfo?.userRole?.map(role => role.trim().toLowerCase()).some(role => 
                                    role === "manager" || role === "application admin"
                                  ) && data.createdBy === cuurentUser && 
                                  <div>
                                    <b>{data.status}</b>
                                  </div>
                                }
                              {userInfo?.userRole?.map(role => role.trim().toLowerCase()).some(role => 
                                    role === "manager" || role === "application admin"
                                  )  && data.status == "pending" &&
                                <div style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"

                                }}>
                                  <IconButton
                                    onClick={() => { handleAcceptClause(data._id, extractedClause.assetId, extractedClause._id) }}
                                  >
                                    <CheckIcon
                                      style={{ color: "green" }}
                                    />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => { handleRejectClause(data._id, extractedClause.assetId, extractedClause._id) }}
                                  >

                                    <CloseIcon
                                      style={{ color: "red" }}
                                    />
                                  </IconButton>

                                </div>
                              }

                              {
                                (data.status === "approved" ||
                                  (data.status === "pending" && data.createdBy === cuurentUser) ||
                                  (data.status === "rejected" && data.createdBy === cuurentUser) ||
                                  (data.status === "pending" && !userInfo.userRole.map(role => role.trim().toLowerCase()).some(role => 
                                    role === "manager" || role === "application admin"
                                  ))) && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end"
                                    }}
                                  >
                                    <IconButton
                                      style={{ padding: "0px", color: "red" }}
                                      onClick={() =>
                                        handleDeleteReasonModalOpen(
                                          data._id,
                                          data.clause,
                                          extractedClause._id
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                )
                              }

                            </div>
                          </div>

                          <div>
                            <strong>Reason: </strong>
                            <span>{data.reasonForAdd}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div>
                    {deletedClauseArray?.length > 0 && (
                      <>
                        <div>
                          <h4 id="filename_ptag" style={{ fontSize: "18px", marginTop: "15px" }}>Deleted Clauses</h4>
                        </div>
                        {deletedClauseArray.map((d, i) => (
                          <div
                            key={d._id}
                            style={{ padding: "5px 0px", borderBottom: "1px solid lightgray" }}
                          >
                            <div>
                              {i + 1}. <span id="clause_ptag">{d.clause}</span>
                            </div>

                            <div>
                              <strong>Reason: </strong>
                              <span>{d.reasonForDelete}</span>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                  </div>
                </>
              )}
            </div>

            {isOpenDeleteReasonModel && (
              <Dialog
                open={isOpenDeleteReasonModel}
                onClose={handleDeleteReasonModalclose}
                PaperProps={{
                  style: {
                    minWidth: '400px',
                    maxWidth: '20vw',
                    padding: "15px",
                    zIndex: 2000
                  },
                }}
              >
                <DialogContent>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h4>Reason for delete clause</h4>
                      <HighlightOffIcon onClick={handleDeleteReasonModalclose} style={{ color: "red" }} />
                    </div>
                    <br />
                    <TextareaAutosize
                      minRows={8}
                      // placeholder="Enter rejection reason"
                      value={deleteReason}
                      onChange={(e) => setDeleteReason(e.target.value)}
                      style={{ width: "100%" }}
                    />
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                      <Button
                        variant="contained"
                        style={{ marginRight: "15px", backgroundColor: "green", color: "white" }}
                        onClick={handleDeleteClause}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}


            {isOpenAddReasonModel && (
              <Dialog
                open={isOpenAddReasonModel}
                onClose={handleAddReasonModalclose}
                PaperProps={{
                  style: {
                    minWidth: '400px',
                    maxWidth: '20vw',
                    padding: "15px",
                    zIndex: 2000
                  },
                }}
              >
                <DialogContent>
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h4>Reason for adding clause</h4>
                      <HighlightOffIcon onClick={handleAddReasonModalclose} style={{ color: "red" }} />
                    </div>
                    <br />
                    <TextareaAutosize
                      minRows={8}
                      // placeholder="Enter rejection reason"
                      value={addReason}
                      onChange={(e) => setAddReason(e.target.value)}
                      style={{ width: "100%" }}
                    />
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                      <Button
                        variant="contained"
                        style={{ marginRight: "15px", backgroundColor: "green", color: "white" }}
                        onClick={handleAddManualClause}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}




            <div id="clause_footer_div">
              <div>
                <Button variant="contained" color="secondary">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={isClauseProjectModalOpen}
          onClose={handleCloseClauseProject}
        >
          <div className="clauseProjectModal">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" id="Clause-modal-title">
                Clause Project
              </Typography>
              <IconButton onClick={() => handleCloseClauseProject()}>
                <CloseIcon id="closeIcon" />
              </IconButton>
            </div>

            <div className="addProejct_input_div">
              <TextField

                label="Clause"
                variant="outlined"
                size="small"
                value={selectedClause}
                style={{ width: "100%", borderRadius: "20px" }}
              />
            </div>

            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData?.projectName}
                label="Project Name"
                variant="outlined"
                size="small"
                style={{ width: "100%", borderRadius: "20px" }}
                InputProps={{
                  readOnly: clauseDataEditable
                }}
                onChange={(e) => handleAddProjectClause(e, "projectName")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>
            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData?.description}
                label="Description"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: clauseDataEditable
                }}
                style={{ width: "100%", borderRadius: "20px" }}
                onChange={(e) => handleAddProjectClause(e, "description")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>
            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData?.number}
                label="Number"
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                  readOnly: clauseDataEditable
                }}
                style={{ width: "100%", borderRadius: "20px", }}
                onChange={(e) => handleAddProjectClause(e, "number")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>
            <div className="addProejct_input_div">
            <div className="datepicker_Div">
                <div>
                  <p>Start Date</p>
                </div>
                <div>
                  <TextField
                    id="date-picker"
                    type="date"
                    value={clauseProjectData.startDate}
                    onChange={(e) => handleAddProjectClause(e, "startDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        height: "30px",
                        boxShadow: "1px 1px 4px -2px #000000",
                      },
                      readOnly: clauseDataEditable,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="addProejct_input_div">
            <div className="datepicker_Div">
                <div>
                  <p>End Date:</p>
                </div>
                <div>
                  <TextField
                    id="date-picker"
                    type="date"
                    value={clauseProjectData.endDate}
                    onChange={(e) => handleAddProjectClause(e, "endDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        height: "30px",
                        boxShadow: "1px 1px 4px -2px #000000",
                      },
                      readOnly: clauseDataEditable,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData?.fundingSource}
                label="Enter Funding Source"
                variant="outlined"
                size="small"
                style={{ width: "100%", borderRadius: "20px" }}
                InputProps={{
                  readOnly: clauseDataEditable
                }}
                onChange={(e) => handleAddProjectClause(e, "fundingSource")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>

            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData?.contractType}
                label="Enter Contract Type"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: clauseDataEditable
                }}
                style={{ width: "100%", borderRadius: "20px" }}
                onChange={(e) => handleAddProjectClause(e, "contractType")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>

            <div className="addProejct_input_div">
              <TextField
                value={clauseProjectData?.projectLocation}
                label="Enter Project Location "
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: clauseDataEditable
                }}
                style={{ width: "100%", borderRadius: "20px" }}
                onChange={(e) => handleAddProjectClause(e, "projectLocation")} // Assuming handleSearchChange takes the field name as an argument
              />
            </div>

            {clauseDataEditable == false && !showEditButton &&
              <div id="clauseProject_addButton_main">
                <Button
                  title="Add"
                  variant="outlined"
                  onClick={handleAddProjectClauseData}
                  id="addclauseButton"
                >
                  Add
                </Button>
              </div>
            }

            {clauseDataEditable == true && !showEditButton &&
              <div id="clauseProject_addButton_main">
                <Button
                  title="Edit"
                  variant="outlined"
                  onClick={handleEnableUpdate}
                  id="addclauseButton"
                >
                  Edit
                </Button>
              </div>
            }


            {showEditButton &&
              <div id="clauseProject_addButton_main">
                <Button
                  title="Save"
                  variant="outlined"
                  onClick={handleUpdateClauseProject}
                  id="addclauseButton"
                >
                  Save
                </Button>
              </div>
            }

          </div>
        </Modal>

        <Modal
          open={showClauseDownloadModal}
          onHide={() => setClauseshowDownloadModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%", // Adjusted width
              maxWidth: 400,
              bgcolor: "white",
              borderRadius: 8,
              boxShadow: 24,
              p: 2,
              textAlign: "center",
              overflowY: "auto", // Enable scrolling if content exceeds box height
            }}
          >
            <div className="modal-header">
              <h3 id="modal-modal-title" className="modal-title">
                Download Options
              </h3>
              <CloseIcon
                onClick={() => setClauseshowDownloadModal(false)}
                className="close-icon"
                style={{
                  color: "red",
                  cursor: "pointer",
                  position: "absolute",
                  top: 10,
                  right: 20,
                }}
              />
            </div>

            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <span>Download as...</span>
              <br />
              <br />
              <br />
              <br />
              <Button
                onClick={ClausePDFDownload}
                variant="contained"
                color="primary"
                style={{ marginRight: "30px", marginBottom: "10px" }}
              >
                PDF
              </Button>
              <Button
                onClick={ClauseDocxDownload}
                variant="contained"
                color="primary"
                style={{ marginBottom: "10px" }}
              >
                Word
              </Button>
            </div>
          </Box>
        </Modal>

        {/* finalproposal Modal */}
        <Modal
          open={finalProposal}
          onClose={handleCloseFinalProposal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "90%",
              height: "90vh",
              overflowY: 'auto',
              bgcolor: 'background.paper',
              border: '1px solid #000',
              boxShadow: 24,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #000',
                pb: 1,
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Final Proposal
              </Typography>
              <Box>
                <IconButton onClick={() => setShowDownloadModalForMerge(true)}>
                  <DownloadIcon />
                </IconButton>

                <Modal
                  open={showDownloadModalForMerge}
                  onHide={() => setShowDownloadModalForMerge(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width:"30%" ,
                       height: "30vh",
                      bgcolor: 'white',
                      borderRadius: 8,
                      boxShadow: 24,
                      p: 2,
                      textAlign: 'center',
                      overflowY: 'auto', // Enable scrolling if content exceeds box height
                    }}
                  >
                    <div className="modal-header">
                      <h3 id="modal-modal-title" className="modal-title">Download Options</h3>
                      <CloseIcon onClick={() => setShowDownloadModalForMerge(false)} className="close-icon" style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: 10, right: 10 }} />
                    </div>

                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                      <span>Download as...</span>
                      <br />
                      <br />
                      <br />
                      <br />
                      <Button onClick={DownloadMergeSection} style={{ marginRight: "30px", marginBottom: "10px", color: "white", backgroundColor: "#1976D2", }}>
                        PDF
                      </Button>
                      <Button onClick={DownloadMergeSection2} style={{ marginBottom: "10px", color: "white", backgroundColor: "#1976D2", }}>
                        Word
                      </Button>
                    </div>
                  </Box>
                </Modal>
                <IconButton onClick={() => {
                  handleCloseFinalProposal();
                  history.push('/OppourtunityManagementSubadmin');
                }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                mt: 2,
                overflowY: 'auto',
                height: '100%',
                width:"99%",
                margin:"auto",// Ensure padding is included in width calculations
              }}
            >
              {/* <Typography id="modal-description" sx={{ mt: 2 }}>
              {stripHtmlTagsProposal(stylledContent)}
            </Typography> */}
              <Typography id="modal-description" sx={{ mt: 2 }} dangerouslySetInnerHTML={renderHtmlContent(stylledContent)} />

            </Box>
          </Box>
        </Modal>



        {
          isPopupOpen && (
            <div
              style={{
                position: 'fixed',
                top: "10%",
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2,

              }}
            >

              <div
                style={{
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  width: '80%',
                  height: '80%',
                  overflow: 'auto',
                  position: 'relative',
                }}
              >
                <div style={{ width: "100%" }}>
                  <iframe
                    src={fileURL}
                    id='previewIframe'
                    // ref={pdfViewer}
                    title="PDF Viewer"
                    width="100%"
                    height="500"
                  // frameBorder="0"
                  ></iframe>
                </div>


                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClosePopup}
                    style={{ marginTop: '10px' }}
                  >
                    Close Template
                  </Button>
                </div>
              </div>



            </div>
          )
        }


        <Modal
          open={isModalOpenCustom}
          onClose={() => setIsModalOpenCustom(false)}
        >
          <>
            <div className="modal_layout_custom">
              <div className="modal_content_custom">
                <div className="modalCloseDiv">
                  <IconButton
                    className="modal_closeIcon"
                    onClick={closeModalCustom}
                  >
                    <CloseIcon className="modal_closeIcon" />
                  </IconButton>
                </div>
                <h6 style={{ display: 'flex', alignItems: 'center', color: 'red', marginBottom: '10px' }}>
                  <InfoIcon style={{ marginRight: '8px' }} />
                  Section mapping incomplete
                </h6>
                <p style={{ color: '#1976D2', marginBottom: '20px' }}>
                  Please review and correct the mapping to proceed with the submission of your proposal.
                </p>
                <div style={{
                  display: 'flex',
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <Button variant="contained" color="primary" onClick={closeModalCustom}>
                    Got it
                  </Button>

                  <Button variant="contained" color=" " style={{ marginLeft: '10px' }} onClick={handleSubmitRequirements}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>


          </>

        </Modal>
        <PreviewEditor
          open={isPreviewOpen}
          handlePreviewCancel={closePreview}
          content={previewContent}
          joditRef={editor}
        />
      </>
      <Modal
        open={showErrorPopup}
        onClose={() => setShowErrorPopup(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            position: 'relative',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '400px',
            maxWidth: '80%',
          }}
        >
          <IconButton
            style={{ position: 'absolute', top: '10px', right: '10px' }}
            onClick={() => setShowErrorPopup(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{ marginTop: '40px', marginBottom: '20px' }}>{errorMessage}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirect}
            style={{ marginTop: '10px' }}
          >
            Go to Organization Page
          </Button>
        </div>
      </Modal>

      <TaskAssignmentAddComentModal
        open={isTaskAssignedCommentOpen}
        close={handleCloseTaskAssignedCommentModal}
        value={taskAsignmentcomment[taskApproveStatus]}
        onChange={handleTaskAsignmentCommentChange}
        submit={handleSubmitTaskComment}
      />


      <TaskAssignmentComentModal
        open={isFetchedCommentModalOpen}
        close={handleCloseFetchedCommentModal}
        commentData={sectionCommentData}
      />
    </>


  );
};


export default ProposalWriting;
